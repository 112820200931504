export const OCCUPATION_GROUPS = [
  { value: '', text: 'Select' },
  {
    value: '1',
    text: 'Senior management in large business organisation, government administration and defence, and qualified professionals'
  },
  {
    value: '2',
    text: 'Other business managers, arts/media/sportspersons and associate professionals'
  },
  {
    value: '3',
    text: 'Tradespeople, clerks and skilled office, sales and service staff'
  },
  {
    value: '4',
    text: 'Machine operators, hospitality staff, assistants, labourers and related workers'
  },
  {
    value: '8',
    text: 'Have not been in paid work in the last 12 months'
  }
]
