// See README.md for more info about field configuration

import moment from 'moment'

import { FIELD_TYPE, PREFILL_NOTIFICATION } from '@/constants'

import { YES_NO } from './droplistData/boolean'

import { GENDERCODE } from './droplistData/genderCode'

import { getFirstName, getLabelName, getHeading } from './helper'

export default [
  {
    label() {
      return getLabelName(null, 'Siblings')
    },
    description(application) {
      const name = getFirstName(application)
      return getLabelName(
        'If ',
        name,
        ' has any brothers or sisters that have been enrolled in a public school they will be added as a family member.',
        'the student'
      )
    },
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'hasAnySiblings',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Does ',
        name,
        ' have any brothers or sisters that have been enrolled in a NSW Government school in the last 5 years?',
        'the student'
      )
    },
    type: FIELD_TYPE.RADIO,
    required: true,
    options: YES_NO
  },
  {
    type: FIELD_TYPE.NOTIFICATION,
    notificationProps: {
      inPage: true,
      type: 'info',
      elevation: 0,
      alertClass: 'blue-alert'
    },
    text() {
      return PREFILL_NOTIFICATION
    },
    visible(applicaition) {
      return (
        applicaition.meta &&
        applicaition.meta.prefillFlags &&
        applicaition.meta.prefillFlags.siblings
      )
    }
  },
  {
    apiKey: 'siblings',
    type: FIELD_TYPE.COLLECTION,
    heading(item) {
      return getHeading(
        'Sibling',
        `${item.siblingsFirstName} ${item.siblingsFamilyName}`
      )
    },
    description:
      'Please provide details of the most recently enrolled brother or sister:',
    visible(application) {
      if (
        application.hasAnySiblings &&
        application.hasAnySiblings !== 'false'
      ) {
        return true
      }
      return false
    },
    required: true,
    headingAdd: 'Add another brother/sister',
    fields(preschool, index) {
      var apiKeyPrefix = `${this.apiKey}[${index}]`
      return [
        {
          apiKey: `${apiKeyPrefix}.siblingsFirstName`,
          label: 'Given name',
          placeHolder: 'Enter given name',
          type: FIELD_TYPE.NAME,
          maxLength: 100,
          required: true
        },
        {
          apiKey: `${apiKeyPrefix}.siblingsOtherName`,
          label: ' Middle name',
          placeHolder: 'Enter middle name',
          type: FIELD_TYPE.NAME,
          maxLength: 100,
          required: false
        },
        {
          apiKey: `${apiKeyPrefix}.siblingsFamilyName`,
          label: 'Family name',
          placeHolder: 'Enter family name',
          type: FIELD_TYPE.NAME,
          maxLength: 100,
          required: true
        },

        {
          apiKey: `${apiKeyPrefix}.siblingsGenderCode`,
          label: 'Gender',
          required: true,
          type: FIELD_TYPE.RADIO,
          options: GENDERCODE
        },
        {
          apiKey: `${apiKeyPrefix}.siblingsDOB`,
          label: 'Date of birth',
          required: true,
          type: FIELD_TYPE.DATE,
          pastYears: 30,
          futureYears: 0,
          validation(value) {
            if (moment(value) > moment()) {
              return 'Cannot be a future date'
            }
          }
        },
        {
          apiKey: `${apiKeyPrefix}.siblingsSchoolName`,
          label: 'Name of most recent school attended',
          placeHolder: 'Enter school name',
          required: true,
          maxLength: 132
        }
      ]
    }
  }
]
