<template>
  <div class="NoPendingError">
    <ErrorContent>
      <template #body="">
        <p>
          There are currently no active applications for
          <span class="typed-email-headline">{{ email }}</span>
          {{ canStartNewApplication ? `at ${schoolName}` : '' }}
        </p>
        <p>
          If you have entered your email address incorrectly, you can
          <strong>re-enter your email</strong> to try again.
        </p>
        <p>
          Alternatively, you can
          <span v-if="canStartNewApplication"
            ><strong>start a new application</strong> using this email
            address.</span
          >
          <span v-else>
            use the <strong>School Finder website</strong> to find your local
            school.</span
          >
        </p>
      </template>

      <template #footer>
        <b-button variant="primary" @click="reEnterEmail">
          <font-awesome-icon :icon="['fas', 'redo-alt']" /> Re-enter your email
        </b-button>

        <span><strong> OR </strong></span>

        <b-button
          v-if="canStartNewApplication"
          variant="outline-primary"
          @click="startNewApplication"
        >
          <font-awesome-icon :icon="['fas', 'plus-circle']" /> Start a new
          application
        </b-button>

        <b-button
          v-else
          variant="outline-primary"
          href="https://my.education.nsw.gov.au/school-finder"
        >
          <font-awesome-icon :icon="['fas', 'map-marker-alt']" /> Go to School
          finder!
        </b-button>
      </template>
    </ErrorContent>
  </div>
</template>

<script>
import ErrorContent from './ErrorContent.vue'

export default {
  name: 'NoPendingError',
  components: {
    ErrorContent
  },
  computed: {
    email() {
      return this.$store.state.auth.userId
    },
    application() {
      return this.$store.state.application
    },
    school() {
      return this.$store.state.school
    },
    schoolCode() {
      if (this.application) return this.application.schoolCode
      else if (this.school) return this.school.schoolCode
      return ''
    },
    catchmentLevel() {
      if (this.application) return this.application.catchmentLevel
      else if (this.school) return this.school.catchmentLevel
      return ''
    },
    schoolName() {
      if (this.application) return this.application.schoolName
      else if (this.school) return this.school.schoolName
      return ''
    },
    canStartNewApplication() {
      return this.schoolCode && this.catchmentLevel
    }
  },
  methods: {
    startNewApplication() {
      this.$store.dispatch('resetError')
      this.$router.push({
        name: 'WelcomeView',
        query: {
          schoolCode: this.schoolCode,
          catchmentLevel: this.catchmentLevel
        }
      })
    },
    reEnterEmail() {
      this.$store.dispatch('resetError')
      this.$router.push({ name: 'LoginView' })
    }
  }
}
</script>

<style lang="scss" scoped>
.typed-email-headline {
  color: $color-red;
  line-height: normal;
  font-weight: bold;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

#app.v-application {
  .error__body > p {
    margin-bottom: 2rem;
  }
}
</style>
