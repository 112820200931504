// Contains some common functions and calculated values used by a number of Application components.
// Note that "section" and "field" props must be present on the component importing this mixin.
import { mapGetters } from 'vuex'

export default {
  computed: {
    fieldId() {
      return this.section.id + '>' + this.field.apiKey
    },
    application() {
      return this.$store.state.application
    },
    alertContainerCssClasses() {
      return (
        (this.getAlert().type ? ' hasAlert' : '') +
        (this.isIgnored() ? ' alertIgnored' : '')
      )
    },
    ...mapGetters(['blockNavigation'])
  },
  methods: {
    setValue(field, val) {
      this.$store.dispatch('set', [`application.${field.apiKey}`, val])
    },
    getAlert() {
      var alerts = this.$store.state.alerts
      var alert = alerts.find(
        (a) =>
          a.section.id === this.section.id &&
          a.field.apiKey === this.field.apiKey
      )
      return alert || {}
    },
    isIgnored() {
      return (
        this.showIgnore() &&
        this.application.alertResolutions.find(
          (resolution) =>
            resolution.id === this.fieldId && resolution.type === 'ALERT'
        )
      )
    },
    showIgnore() {
      return this.getAlert().type === 'ALERT'
    },
    handleBlockNavigation() {
      if (this.blockNavigation) {
        const reason = this.blockNavigation.reason
        if (reason === 'uploading') {
          this.$store.dispatch('openModal', {
            componentName: 'ModalUploading',
            size: 'small'
          })
        } else if (reason === 'removing') {
          this.$store.dispatch('openModal', {
            componentName: 'ModalRemoving',
            size: 'small'
          })
        }
      }
    }
  }
}
