import { dropDownSelect } from './helper'

const condition = [
  { value: 'ALC', text: "Cow's milk" },
  { value: 'ALE', text: 'Egg' },
  { value: 'ALF', text: 'Fish' },
  { value: 'ALI', text: 'Insect sting/bite' },
  { value: 'ALM', text: 'Medication' },
  { value: 'ALP', text: 'Peanuts' },
  { value: 'ASE', text: 'Sesame' },
  { value: 'ALS', text: 'Shellfish' },
  { value: 'ALL', text: 'Soy' },
  { value: 'ALT', text: 'Tree nuts' },
  { value: 'ALW', text: 'Wheat' },
  { value: 'ALO', text: 'Other' }
]

const medicalConditionAllergies = dropDownSelect(condition)
export default medicalConditionAllergies
