// this array maps will be converted to object attribute for submit

const learningAdjustment = [
  {
    value: 'changeToLearningPrograms',
    text: 'Changes to learning programs and/or teaching strategies'
  },
  {
    value: 'communication',
    text: 'Communication, eg speaking and/or listening'
  },
  {
    value: 'modificationToEquipment',
    text: 'Modification to equipment, furniture, learning spaces and/or learning materials'
  },
  {
    value: 'supportForPersonalNeeds',
    text: 'Support for personal care needs, eg hygiene, mealtimes and/or health care needs'
  },
  {
    value: 'socialSupport',
    text: 'Social support to engage safely with other children and teachers'
  },
  { value: 'otherAdjustment', text: 'Other' }
]

export const LEARNING_ADJUSTMENT = learningAdjustment.map((item) => {
  item.group = 'learningAdjustment'
  return item
})
