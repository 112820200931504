import Vue from 'vue'
import Router from 'vue-router'

import ApplicationView from './views/ApplicationView.vue'
import ConfirmationView from './views/ConfirmationView.vue'
import ErrorView from './views/ErrorView.vue'
import LoginView from './views/LoginView.vue'
import PilotView from './views/PilotView.vue'
import WelcomeView from './views/WelcomeView.vue'
import SchoolReminderView from './views/SchoolReminderView.vue'
import CancelView from './views/CancelView.vue'
import LogoutView from './views/LogoutView.vue'
import PendingOffers from './views/PendingOffers.vue'
import OoaDeclineView from './views/OoaDeclineView.vue'
import Y67TDeclineView from './views/Y67TDeclineView.vue'
import PendingApplicationsView from './views/PendingApplicationsView.vue'
import store from '@/store/store'
import EHubNew from './views/EHubNew.vue'
import EHubResume from './views/EHubResume.vue'
import EHubAcceptOoaOffer from './views/EHubAcceptOoaOffer.vue'
import EHubAcceptEoiOffer from './views/EHubAcceptEoiOffer.vue'
import EHubProceedShsOffer from './views/EHubProceedShsOffer.vue'

Vue.use(Router)

const isEhubApp = window.location.origin.indexOf('ehub') !== -1
const isEhubLocalDev = process.env.VUE_APP_EHUB_LOCAL_DEV === 'true'

const router = new Router({
  mode: 'history',
  base: isEhubApp || isEhubLocalDev ? '/local/' : '',
  routes: [
    {
      path: '/',
      name: 'WelcomeView',
      component: WelcomeView
    },
    {
      path: '/pilot',
      name: 'PilotView',
      component: PilotView,
      meta: {
        title: 'Privacy'
      }
    },
    {
      path: '/login',
      name: 'LoginView',
      component: LoginView,
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/schoolreminder',
      name: 'SchoolReminderView',
      component: SchoolReminderView,
      meta: {
        title: 'Local School'
      }
    },
    {
      path: '/application',
      name: 'ApplicationView',
      component: ApplicationView,
      meta: {
        title: 'Application'
      }
    },
    {
      path: '/login/:type',
      name: 'LoginView',
      component: LoginView
    },
    {
      path: '/confirmation',
      name: 'ConfirmationView',
      component: ConfirmationView,
      meta: {
        title: 'Confirmation'
      }
    },
    {
      path: '/declined',
      name: 'OoaDeclineView',
      props: true,
      component: OoaDeclineView,
      meta: {
        title: 'Offer Declined'
      }
    },
    {
      path: '/y67tdeclined',
      name: 'Y67TDeclineView',
      props: true,
      component: Y67TDeclineView,
      meta: {
        title: 'Offer Declined'
      }
    },
    {
      path: '/error',
      name: 'ErrorView',
      component: ErrorView,
      meta: {
        title: 'Error'
      }
    },
    {
      path: '/cancel',
      name: 'CancelView',
      component: CancelView,
      meta: {
        title: 'Cancelled Application'
      }
    },
    {
      path: '/logout',
      name: 'LogoutView',
      props: true,
      component: LogoutView,
      meta: {
        title: 'Logout'
      }
    },
    {
      path: '*',
      component: ErrorView
    },
    {
      path: '/pendingoffers',
      name: 'PendingOffers',
      props: true,
      component: PendingOffers,
      meta: {
        title: 'Pending Offers'
      }
    },
    {
      path: '/pendingApplications',
      name: 'PendingApplicationsView',
      component: PendingApplicationsView,
      meta: {
        title: 'Pending Applications'
      }
    },
    {
      path: '/ehub',
      name: 'eHubRouteWrapper',
      component: {
        // Inline declaration of a component that renders our <router-view>
        render: (c) => c('router-view')
      },
      // There will be multiple routes prefixed with /ehub/
      children: [
        {
          name: 'EHubNew',
          path: 'new',
          component: EHubNew
        },
        {
          name: 'EHubResume',
          path: 'resume',
          component: EHubResume
        },
        {
          name: 'EHubAcceptOoaOffer',
          path: 'ooa/accept',
          component: EHubAcceptOoaOffer
        },
        {
          name: 'EHubAcceptEoiOffer',
          path: 'eoi/accept',
          component: EHubAcceptEoiOffer
        },
        {
          name: 'EHubProceedShsOffer',
          path: 'shs/proceed',
          component: EHubProceedShsOffer
        }
      ]
    }
  ],
  //Scroll to top when navigating back to landing page
  scrollBehavior(to) {
    if (to.name === 'WelcomeView' || to.name === 'LoginView') {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const loginTitles = {
    timeout: 'Session Expired',
    new: 'Login',
    resume: 'Login'
  }
  const titlePrefix =
    to.name === 'LoginView' ? loginTitles[to.params.type] : to.meta.title
  const DEFAULT_TITLE = 'Online Enrolment'
  document.title = titlePrefix
    ? `${titlePrefix} | ${DEFAULT_TITLE}`
    : DEFAULT_TITLE

  if (
    (from.name === 'LogoutView' && to.name === 'ApplicationView') ||
    (from.name === 'LogoutView' && to.name === 'PendingOffers') ||
    (from.name === 'LogoutView' && to.name === 'PilotView') ||
    (from.name === 'OoaDeclineView' && to.name === 'PendingOffers') ||
    (from.name === 'Y67TDeclineView' && to.name === 'PendingOffers') ||
    (from.name === 'CancelView' && to.name === 'PendingOffers')
  ) {
    let schoolCode = store.state.school.schoolCode
    let catchmentLevel = store.state.school.catchmentLevel
    store.dispatch('set', ['school', null])
    next(`/?schoolCode=${schoolCode}&catchmentLevel=${catchmentLevel}`)
  } else {
    next()
  }
})

export default router
