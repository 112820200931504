import medicalConditionAllergies from '@/applicationDefinition/droplistData/medicalConditionAllergies'
import medicalConditionName from '@/applicationDefinition/droplistData/medicalConditionName'

const medicalConditionList = medicalConditionName
  .concat(medicalConditionAllergies)
  .filter((item) => !(item.value === '' && item.text === 'Select'))

export default (item) => {
  // Convert medical condition name
  const medicalConditionName = medicalConditionList.find(
    (medicalCondition) => medicalCondition.value === item.medicalConditionType
  )

  if (medicalConditionName) {
    item.medicalConditionName = medicalConditionName.text
  }

  // Medications can not be null
  if (!item.medications) {
    item.medications = []
  }

  // Default category type for condition
  item.medications = item.medications.map((medication) => {
    if (!medication.medicationCategory) {
      medication.medicationCategory = 'OTHER'
    }
    return medication
  })

  // Upstream (ERN) autoinjector fields are mapped a bit wierdly, so we need to
  // insert details in the notes field.
  if (item.hasAutoinjector) {
    // Displayed date in Notes fields requires DD-MM-YYYY
    const reformattedDate = item.autoinjectorExpiryDate.replace(
      /(\d{4})-(\d{2})-(\d{2})/,
      '$3-$2-$1'
    )

    if (!item.notes) item.notes = ''

    if (item.notes.length > 0) {
      item.notes += `\r\n`
    }

    item.notes += `Autoinjector expiry date: ${reformattedDate}`
  }

  return item
}
