<template>
  <div class="ErrorView">
    <ApplicationBanner :content="'<h1>' + heading + '</h1>'" />
    <div class="error-content">
      <NoPendingError v-if="error.type === ERROR_CODES.NO_PENDING" />
      <APIError
        v-else-if="error.type === ERROR_CODES.API"
        :error="error"
        :include-stack-trace="isDevEnvironment"
        :previous-route="previousRoute"
      />
      <ErrorContent
        v-else
        :include-stack-trace="isDevEnvironment"
        :stack-trace="stackTrace"
      />
    </div>
  </div>
</template>

<script>
import { ERROR_CODES } from '@/constants'
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import ErrorContent from '@/components/error/ErrorContent'
import NoPendingError from '@/components/error/NoPendingError'
import APIError from '@/components/error/APIError'

export default {
  name: 'ErrorView',
  components: {
    ApplicationBanner,
    ErrorContent,
    NoPendingError,
    APIError
  },
  data() {
    return {
      ERROR_CODES: ERROR_CODES,
      previousRoute: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from
    })
  },
  computed: {
    isDevEnvironment() {
      return process.env.NODE_ENV === 'development'
    },
    heading() {
      switch (this.error.type) {
        case ERROR_CODES.NO_PENDING:
          return 'No Application found'
        default:
          return 'Whoops! That was unexpected…'
      }
    },
    error() {
      return this.$store.state.error || {}
    },
    stackTrace() {
      return (
        (this.error &&
          this.error.errorObject &&
          this.error.errorObject.stack) ||
        null
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.ErrorView {
  height: 100%;
}
.error-content {
  padding: 5rem 1.5rem;
}

@include desktop {
  .ErrorView {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
    .Content {
      max-width: 1110px;
    }
  }
}

@include tablet-wide {
  .ErrorView {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
    .error-row {
      max-width: 940px;
    }
  }
  content {
    max-width: 700px;
  }
}

@include tablet {
  .ErrorView {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }

    .content {
      padding-top: 2rem;

      & ::v-deep h1 {
        font-size: 3.2rem;
      }
    }
  }
  .Content {
    max-width: 520px;
  }
}

@include mobile {
  .ErrorView {
    .content {
      padding-top: 2rem;

      & ::v-deep h1 {
        font-size: 2.6rem;
      }
    }
  }
  .Content {
    max-width: 420px;
    padding: 1rem 2rem;
  }
}
</style>
