<template>
  <div
    class="ApplicationBanner"
    :class="{
      'ApplicationBanner--training': isTraining,
      'ApplicationBanner--welcome': isWelcomeView
    }"
  >
    <b-container>
      <b-row>
        <b-col class="headline">
          <v-container class="bannerTitle d-flex align-center">
            <v-card
              v-if="showLogo"
              class="logo-card"
              :class="$vuetify.breakpoint.xsOnly ? 'mr-4 p-2' : 'mr-10 p-4'"
              :height="$vuetify.breakpoint.xsOnly ? 61 : 118"
              :width="$vuetify.breakpoint.xsOnly ? 61 : 118"
              data-testid="school-logo"
            >
              <v-img
                :src="logo || require('@/assets/schoolPlaceholder.svg')"
                alt="school logo"
                class="mx-auto"
                :height="$vuetify.breakpoint.xsOnly ? 47 : 90"
                :width="$vuetify.breakpoint.xsOnly ? 47 : 90"
                :contain="true"
              />
            </v-card>
            <div v-html="content"></div>
          </v-container>
          <div class="bgLeftElement"></div>
          <div class="bgRightElement"></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'ApplicationBanner',
  props: {
    content: String,
    logo: String,
    schoolName: String,
    showLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isTraining() {
      return this.$store.state.inTrainingMode
    },
    isWelcomeView() {
      return this.$route.name === 'WelcomeView'
    }
  }
}
</script>

<style lang="scss">
.ApplicationBanner {
  position: relative;
  padding: 30px;
  h1 {
    display: inline;
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        color: $color-focus;
      }
      &:focus {
        color: white;
      }
    }
  }
}

@include mobile {
  #app .ApplicationBanner.ApplicationBanner--training {
    top: $header-height-training-small;
    &.ApplicationBanner--welcome {
      top: $header-height-training-large;
    }
  }
}

.logo-card {
  border-radius: 0 !important;
}
</style>
