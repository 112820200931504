//This object is used to set a blank enrolment application even it is the mock folder.
import address from './helper/address'
import allergies from './helper/allergies'

export default {
  enrolmentFormRecordNo: null,
  applicationID: '',
  correspondenceName: '',
  schoolCode: '',
  schoolName: '',
  catchmentLevel: 'primary',
  emailAddress: '',
  meta: {
    userId: '',
    hasReturnAustralia: null,
    hasSibling: null,
    isAltParent: null,
    reasonForSuspension: [],
    isAnaphylactic: null,
    isNonAnaphylactic: null,
    isOtherMedicalCondition: null,
    hasDoctorDetails: null,
    sectionExpandedId: 'student',
    visitedSections: [],
    hasAttemptToSubmit: null,
    additionalEmergencyContacts: ['yes'],
    hasAdditionalPostalAddress: false,
    toAddMoreParent: null,
    isEhubLinkedStudent: null
  },
  student: {
    studentRecordNo: null,
    srn: null,
    firstName: '',
    familyName: '',
    prefFirstName: '',
    otherName: '',
    genderCode: '',
    dateOfBirth: '',
    detUserId: '',
    emailAddress: '',
    mobileNumber: '',
    medicare: [
      {
        expiryDate: '',
        number: '',
        cardRef: ''
      }
    ],
    countryBorn: '',
    isAboriTorStraitIslander: '',
    homeLangOtherThanEnglish: null,
    mainLanguageOtherThanEnglish: '',
    otherLanguage: '',
    sre: ''
  },
  residentialAddress: {
    addressRecordNo: '',
    addressLine1: '',
    addressLine2: '',
    suburbName: '',
    suburbCode: '',
    stateCode: '',
    postCode: '',
    countryCode: ''
  },
  scholasticYear: '',
  intendedStartDate: '',
  enterStartDate: '',
  prevAttendSchool: null,
  prevSchoolId: '',
  prevSchoolType: '',
  prevSchoolLocation: '',
  prevSchoolStartDate: '',
  prevSchoolLastDate: '',
  otherSchoolAttendedName: '',
  otherSchoolAttendedLocation: '',
  hasAttendedPreSchool: null,
  hasAnySiblings: null,
  dateArriveAustralia: '',
  dateReturnAustralia: '',
  hasATE: null,
  residencyStatus: '',
  tempResVisaClass: '',
  tempResVisaSubClass: '',
  tempResVisaExpDate: '',
  passportNumber: '',
  tempResPassportCountryOfIssue: '',
  anyAdditionalInforPriorEnrol: null,
  anyAdditionalInforDetails: '',
  isSupportForLearningRequired: null,
  areThereModifiStratUsedHome: null,
  modifiStratUsedHome: '',
  hasPrevLearningSupportPlan: null,
  prevLearningSupportPlanDetails: '',
  riskOfAnyTypeToStudentsORStaff: null,
  riskOfAnyTypeDetails: '',
  healthProfHaveKnowledge: null,
  healthProfContact: [
    {
      doctorName: '',
      doctorPhoneNumber: ''
    }
  ],
  anyHistoryViolentBehaviour: null,
  violentBehaviourDetails: '',
  hasSuspendedorExpelled: null,
  hasOtherIncident: null,
  otherIncidentDetails: '',
  permissionToPublish: null,
  permissionToOnlineServices: true,
  anyIntentionToApplyAnotherSch: null,
  visaSubClass: '',
  visaExpiryDate: '',
  firstEnrolmentDate: '',
  hasStudentDeclaration: null,
  studentDeclarationDate: '',
  studentIdentityVerificationStatus: '',
  studentIdentityVerifiedDate: '',
  residentialAddressVerificationStatus: '',
  residentialAddressVerifiedDate: '',
  isInArea: true,
  immunisationVerificationStatus: '',
  immunisationVerifiedDate: '',
  citizenshipVerificationStatus: '',
  citizenshipVerifiedDate: '',
  enrolmentNotes: '',
  interviewConducted: null,
  isSpecialCircumstancesAssessed: null,
  isRiskAssementRequired: null,
  isRiskAssessed: null,
  hasConsultedParent: null,
  hasPersonalLearningSupportPlan: null,
  hasBehaviorManagementPlanViolence: null,
  hasBehaviorManagementPlanOther: null,
  hasHealthCarePlan: null,
  hasEmergencyResponsePlan: null,
  hasCommunicatedStaff: null,
  hasPrincipalAccepted: null,
  principalAcceptedDate: '',
  dateReceived: '',
  lastModifiedDatetime: '',
  isPersonalLearningSupportRequired: null,
  applicationStatus: '',
  lastModifiedUser: '',
  siblings: [
    {
      siblingsFamilyName: '',
      siblingsFirstName: '',
      siblingsSchoolName: '',
      siblingsDOB: '',
      siblingsGenderCode: ''
    }
  ],
  parentCarers: [
    {
      collectionItemUniqueId: 1,
      isEnrolmentOwner: null, //This is used to indicate if the parent lives with the student
      parentCarerRelation: '',
      parentCarerTitle: '',
      parentCarerFamilyName: '',
      parentCarerGivenName: '',
      parentCarerGenderCode: '',
      parentCarerEmail: '',
      isParentCarerAboriOrTorres: '',
      parentCarerOccupationGroup: '',
      parentCarerOccupationName: '',
      parentCarerSchoolEducationCode: '',
      parentCarerEducationQualificationCode: '',
      contactDetails: [
        {
          contactDetailRecordNo: null,
          contactType: '',
          contactValue: '',
          comments: ''
        }
      ],
      meta: {
        isCompletingApplication: null
      },
      contactOrder: null,

      // Fields only for parents that live with student
      parentCarerCountryBirth: '',
      isParentCarerSpeakLangOtherEnglish: null,
      parentCarerMainLanguage: '',
      parentCarerSpeakLang: '',
      parentCarerRequireInterpreter: null,

      // Field only for parent that DO NOT live with student
      doesStudentLivesWithParentSometimes: null,
      doesParentCarerHaveCorrespondenceAddress: null,
      residentialAddress: {
        addressRecordNo: null,
        addressLine1: '',
        addressLine2: '',
        suburbName: '',
        suburbCode: '',
        stateCode: '',
        postCode: '',
        countryCode: ''
      },
      correspondenceAddress: {
        addressRecordNo: null,
        addressLine1: '',
        addressLine2: '',
        suburbName: '',
        suburbCode: '',
        stateCode: '',
        postCode: '',
        countryCode: ''
      }
    }
  ],
  allergies: [allergies(false)],
  allergiesAnaphylactic: [allergies(true)],
  nonAllergies: [allergies(false)],
  learningAndSupport: {
    learningSupportNeeds: [],
    otherDisabilityDetails: null,
    learningAdjustments: [],
    otherAdjustmentDetails: null,
    hasPrevLearningSupportPlan: null
  },
  doctorDetails: [
    {
      collectionItemUniqueId: 1,
      doctorRecordNo: null,
      doctorName: '',
      doctorAddress: address,
      doctorPhoneNumber: ''
    }
  ],
  preschools: [
    {
      preschoolType: '',
      preschoolHoursType: '',
      postcode: ''
    }
  ],
  supportingDocuments: {
    studentIdentity: { includeFiles: null, files: [] },
    residentialAddress: { includeFiles: null, files: [] },
    immunisation: { includeFiles: null, files: [] },
    travel: { includeFiles: null, files: [] },
    medical: { includeFiles: null, files: [] },
    disability: { includeFiles: null, files: [] },
    familyLaw: { includeFiles: null, files: [] }
  },
  sibling: [
    {
      preschoolType: '',
      preschoolHoursType: '',
      postcode: ''
    }
  ],
  emergencyContact: [
    {
      contactFirstName: '',
      contactFamilyName: '',
      contactRelationship: '',
      contactDetails: [
        {
          contactDetailRecordNo: null,
          contactType: '',
          contactValue: '',
          comments: ''
        }
      ]
    }
  ],
  parentCarerName_1: '',
  selectedSreOrSeeOrAmaOption: ''
}
