<template>
  <div class="ApplicationReviewPanels">
    <div class="panel" :key="panel.apiKey" v-for="panel in field.panels">
      <h3 class="panel__header">
        <template v-if="panel.label">
          {{ getLabel(panel, application) }}
        </template>
        <span
          class="jt-vhtml-label"
          v-else-if="panel.vHtmlLabel"
          v-html="getVHtmlLabel(panel, application)"
        ></span>
      </h3>
      <div v-if="getValue(panel)">
        <ApplicationReviewRow
          :key="panelField.apiKey"
          v-for="panelField in panel.fields"
          :field="panelField"
          :section="section"
          :alerts="alerts"
        />
      </div>
      <div v-else class="empty">(empty)</div>
    </div>
  </div>
</template>

<script>
import fieldsMixin from '@/components/mixins/fieldsMixin'
export default {
  name: 'ApplicationReviewPanels',
  components: {
    ApplicationReviewRow: () => import('./ApplicationReviewRow.vue') //To deal with circular dependency
  },
  mixins: [fieldsMixin],
  props: {
    section: {
      type: Object
    },
    alerts: {
      type: Array,
      default: () => []
    },
    field: {
      type: Object
    }
  },
  computed: {
    application() {
      return this.$store.state.application || {}
    }
  }
}
</script>

<style lang="scss" scoped>
.ApplicationReviewPanels {
  .panel {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    padding: 2rem;
    background: #fafafa;

    &__header {
      font-size: 2rem;
      color: $color-primary;
      font-weight: 700;
      padding: 0.25rem 0;
      border-bottom: solid 1px #b3b3b3;
    }
  }

  .empty {
    font-weight: $text-normal;
    font-size: 2rem;
    color: $color-grey-60;
    margin: 2.5rem 0;
  }
}
</style>
