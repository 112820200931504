export const VISA_CLASSES = [
  { value: '', text: 'Select' },
  { value: '101', text: '101 - Child' },
  { value: '102', text: '102 - Adoption' },
  { value: '117', text: '117 - Orphan Relative' },
  { value: '808', text: '808 - Confirmatory (residence)' },
  { value: '124', text: '124 - Distinguished talent (Australian support)' },
  { value: '119', text: '119 - Regional Sponsored Migration Scheme' },
  { value: '121', text: '121 - Employer nomination' },
  { value: '120', text: '120 - Labour agreement' },
  { value: '834', text: '834 - Permanent resident of Norfolk Island' },
  { value: '103', text: '103 - Parent' },
  { value: '155', text: '155 - Five year resident return' },
  { value: '157', text: '157 - Three month resident return' },
  { value: '100', text: '100 - Spouse' },
  { value: '110', text: '110 - Interdependency' },
  { value: '800', text: '800 - Territorial asylum (Residence)' },
  { value: '845', text: '845 - Established business in Australia' },
  {
    value: '846',
    text: '846 - State/Territory sponsored regional established bus. (REBA)'
  },
  { value: '136', text: '136 - Skilled - Independent' },
  {
    value: '137',
    text: '137 - Skilled - State/Territory Nominated Independent'
  },
  { value: '114', text: '114 - Aged dependent relative' },
  { value: '115', text: '115 - Remaining Relative' },
  { value: '116', text: '116 - Carer' },
  { value: '804', text: '804 - Aged parent' },
  { value: '138', text: '138 - Skilled - Australian-Sponsored' },
  { value: '139', text: '139 - Skilled - Designated Area Sponsored' },
  { value: '134', text: '134 - Skill Matching' },
  { value: '801', text: '801 - Spouse' },
  { value: '814', text: '814 - Interdependency' },
  { value: '802', text: '802 - Child (Residence)' },
  { value: '837', text: '837 - Orphan Relative' },
  { value: '835', text: '835 - Remaining Relative' },
  { value: '836', text: '836 - Carer' },
  { value: '838', text: '838 - Aged Dependent Relative' },
  { value: '855', text: '855 - Labour Agreement' },
  { value: '856', text: '856 - Employer Nomination Scheme' },
  { value: '857', text: '857 - Regional Sponsored Migration Scheme' },
  { value: '858', text: '858 - Distinguished Talent' },
  { value: '143', text: '143 - Contributory Parent' },
  { value: '151', text: '151 - Former resident' },
  {
    value: '883',
    text: '883 - Skilled - Designated Area-Sponsored (Residence)'
  },
  { value: '851', text: '851 - Resolution of status (permanent)' },
  {
    value: '861',
    text: '861 - Skilled - Onshore Independent New Zealand Citizen'
  },
  {
    value: '862',
    text: '862 - Skilled - Onshore Australian-sponsored NZ Citizen'
  },
  {
    value: '863',
    text: '863 - Skilled - Onshore Designated Area - sponsored NZ Citizen'
  },
  { value: '880', text: '880 - Skilled - Independent Overseas Student' },
  {
    value: '881',
    text: '881 - Skilled - Australian-Sponsored Overseas Student'
  },
  {
    value: '882',
    text: '882 - Skilled - Designated Area-Sponsored Overseas Student'
  },
  { value: '890', text: '890 - Business Owner (Residence)' },
  { value: '891', text: '891 - Investor (Residence)' },
  {
    value: '892',
    text: '892 - State/Territory Sponsored Business Owner (Residence)'
  },
  {
    value: '893',
    text: '893 - State/Territory Sponsored Investor (Residence)'
  },
  { value: '864', text: '864 - Contributory Aged Parent' },
  { value: '852', text: '852 - Witness Protection (Trafficking)' },
  { value: '132', text: '132 - Business Talent' },
  {
    value: '888',
    text: '888 - Business Innovation and Investment (Permanent)'
  },
  { value: '186', text: '186 - Employer Nomination Scheme' },
  { value: '187', text: '187 - Regional Sponsored Migration Scheme' },
  { value: '189', text: '189 - Skilled - Independent' },
  { value: '190', text: '190 - Skilled - Nominated' },
  { value: '885', text: '885 - Skilled - Independent' },
  { value: '886', text: '886 - Skilled - Sponsored' },
  { value: '887', text: '887 - Skilled - Regional' },
  { value: '175', text: '175 - Skilled - Independent' },
  { value: '176', text: '176 - Skilled - Sponsored' },
  { value: '866', text: '866 - Protection' },
  { value: '200', text: '200 - Refugee' },
  { value: '201', text: '201 - In-country special humanitarian' },
  { value: '202', text: '202 - Global special humanitarian' },
  { value: '203', text: '203 - Emergency rescue' },
  { value: '204', text: '204 - Woman at risk' },
  { value: '998', text: '998 - Australian Declaratory' }
]
