<template>
  <div v-if="school" class="ApplicationWelcome">
    <a :href="school.website" target="_blank">
      <ApplicationBanner
        :logo="school.logo"
        :content="
          '<h1>' +
          school.schoolName +
          '</h1><h3>Local enrolment application</h3>'
        "
        :show-logo="true"
      />
    </a>

    <b-container class="content">
      <b-row class="WelcomeCopy">
        <b-col>
          <h2>Before you start, please read the following:</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="4">
          <div class="intro" aria-hidden="true">
            <img
              class="intro-icon"
              alt="What will I need?"
              src="@/assets/icon-whats-online.svg"
            />
          </div>

          <div class="intro-desc">
            <h2>What is this online application for?</h2>
            <ul>
              <li>Local enrolment into NSW Government schools only</li>
              <li>
                Children living in NSW who are Australian or New Zealand
                citizens or Australian permanent residents
              </li>
            </ul>
          </div>
        </b-col>

        <b-col lg="4">
          <div class="intro" aria-hidden="true">
            <img
              class="intro-icon"
              alt="What will I need?"
              src="@/assets/icon-temporary-residents.svg"
            />
          </div>

          <div class="intro-desc">
            <h2>Can temporary residents apply using this form?</h2>
            <p>
              No. Please go to our website for
              <a href="https://www.deinternational.nsw.edu.au" target="_blank"
                >temporary residents and international students</a
              >
              <br />for this type of enrolment
            </p>
          </div>
        </b-col>

        <b-col lg="4">
          <div class="intro" aria-hidden="true">
            <img
              class="intro-icon"
              alt="What will I need?"
              src="@/assets/icon-complete-app.svg"
            />
          </div>

          <div class="intro-desc">
            <h2>What will I need to complete this application?</h2>
            <ul>
              <li>
                Your child's medical details - Medicare and doctor details
              </li>
              <li>Parent contact details</li>
              <li>Emergency contact details</li>
            </ul>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <hr />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <ul class="horizontal-list">
            <li class="come-back">
              <h2>Resume your application within 30 days</h2>
              <p>
                If you don’t have all the information you need right now, that’s
                okay - the application will be auto-saved as you go. Any saved
                progress will be kept for 30 days.
              </p>
            </li>
            <li class="what-happens">
              <h2>What happens once you apply?</h2>
              <p>
                After completing the online application part of the process, you
                may need to visit the school to provide further documentation
                and progress the application.
              </p>
            </li>
          </ul>
        </b-col>
      </b-row>

      <b-row class="search-address-row white-card">
        <b-col>
          <h2>Get started with your application</h2>
          <p>
            We use these details to verify the student you are applying for
            lives within the school’s intake area.
          </p>
          <AppCatchmentFinder
            class="AppCatchmentFinder"
            v-if="school"
            :school="school"
            :catchment-level="urlCatchmentLevel"
            :prefill="catchmentPrefill"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-container class="Note">
      <b-row>
        <b-col>
          <h4>
            <strong>NOTE</strong>
          </h4>
          <p class="iNote">
            If you are not eligible to use this online enrolment process, or you
            don't want to, you can always use existing enrolment processes to
            complete an application. You can find out more on our
            <a
              href="https://education.nsw.gov.au/public-schools/going-to-a-public-school"
              target="_blank"
              class="link-outline"
              >going to a public school website</a
            >.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AppCatchmentFinder from '@/components/app/AppCatchmentFinder.vue'
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'

export default {
  name: 'ApplicationWelcome',
  components: {
    AppCatchmentFinder,
    ApplicationBanner
  },
  data() {
    let decodedPrefillData
    if (this.$route.query.catchmentPrefill) {
      try {
        decodedPrefillData = JSON.parse(
          atob(this.$route.query.catchmentPrefill)
        )
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Catchment prefill data invalid', err)
      }
    }

    return {
      urlCatchmentLevel: this.$route.query.catchmentLevel,
      catchmentPrefill: decodedPrefillData
    }
  },
  computed: {
    school() {
      return this.$store.state.school
    }
  },
  created() {
    const { schoolCode } = this.$route.query
    this.$store
      .dispatch('getSchool', schoolCode)
      .then((resp) => {
        if (resp && resp.isError) {
          this.$router.history.push('/error')
        }
      })
      .catch(() => {
        this.$router.history.push('/error')
      })
  }
}
</script>

<style lang="scss" scoped>
$sideBarWidth: 200px;

.ApplicationWelcome {
  padding: 0 0 30px;
  .content.container {
    padding: 94px 15px 0;
    .WelcomeCopy {
      h2 {
        line-height: 28px;
      }
    }
    .search-address-row {
      position: relative;

      form {
        padding: 6px 0 0;
      }

      h2 {
        padding: 0 0 5px;
        font-size: 21px;
      }
    }

    .intro {
      text-align: center;
      padding: 15px 30px 5px;
    }

    .intro-desc {
      h2 {
        padding-bottom: 0;
        margin-bottom: 10px;
        padding-right: 20px;
      }
    }
  }

  ul {
    padding: 0 0px 0 15px;
    font-size: $text;

    &.horizontal-list {
      padding: 40px 0 5px 5px;
      list-style: none;

      li {
        position: relative;
        padding: 0 0 35px 120px;
        line-height: 26px;
        p {
          line-height: 26px;
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 91px;
          height: 91px;
        }
      }

      h2 {
        padding: 0 15px 5px 0;
      }
    }
  }

  h1 {
    &.invalid-code-msg {
      padding: 30px 0;
    }
  }

  h2 {
    line-height: 28px;
    padding: 2rem 0;
  }

  .container {
    text-align: left;

    hr {
      margin: 2rem 0 0;
    }

    .intro-icon {
      height: auto;
    }

    .slide-fade-enter-active {
      transition: all 0.3s ease;
    }

    .slide-fade-leave-active {
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
      opacity: 0;
    }
  }

  .Note {
    padding: 40px 20px 20px;
  }
}

.AppCatchmentFinder {
  margin-top: 2rem;
}

.link-outline {
  &:focus {
    outline-style: auto;
  }
}

@include desktop {
  .ApplicationWelcome {
    .ApplicationBanner {
      height: 218px;
    }
    .container {
      .intro-desc {
        padding: 0 25px;
      }

      .intro-icon {
        max-width: 180px;
      }
    }

    .Banner {
      h1 {
        font-size: 51px;
        line-height: 50px;
      }

      h3 {
        font-size: 26px;
        padding-top: 8px;
      }
    }
  }
}

@include tablet-wide {
  .ApplicationWelcome {
    .ApplicationBanner {
      height: 218px;
    }
    .container {
      .intro-icon {
        max-width: 170px;
      }
    }
  }
}

@include tablet {
  .ApplicationWelcome {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }
    .content {
      margin-bottom: 20px;
      padding: 1.2rem 1.2rem 2rem;

      .search-address-row.white-card {
        h2,
        p {
          text-align: center;
        }
      }

      .intro-icon {
        max-width: 50%;
      }
    }
  }
}

@include mobile {
  .ApplicationWelcome {
    .content {
      &.container {
        margin-bottom: 20px;
        .WelcomeCopy {
          h2 {
            padding: 2rem 4rem;
            text-align: center;
          }
        }

        .intro-icon {
          min-width: 140px;
          width: 140px;
        }

        .intro-desc {
          padding: 0 20px;

          h2 {
            padding: 2rem 2rem;
            text-align: center;
          }

          p {
            text-align: center;
          }
        }

        .horizontal-list {
          li {
            padding: 120px 20px 35px;
            text-align: center;

            &::before {
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }

        .search-address-row {
          h2,
          p {
            text-align: center;
          }
        }
      }
    }

    .Note {
      padding: 0 20px 20px !important;
    }
  }
}
</style>
