<template>
  <div>
    <loading-view loading-text="processing, one moment..." v-if="showLoading" />
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode'
import LoadingView from '@/components/app/FullScreenLoader'
import ehubUtil from '@/utils/ehub'
import { API_ERROR_SUBTYPES } from '@/constants'

export default {
  name: 'EHubResume',
  components: { LoadingView },
  data() {
    return {
      showLoading: true
    }
  },
  mounted() {
    this.resumeApplication()
  },
  methods: {
    async resumeApplication() {
      let { idToken, refreshToken } = ehubUtil.getNewApplicationData()
      if (idToken && refreshToken) {
        this.$store.commit('set', ['auth.isUserActive', true])
        this.$store.commit('set', [
          'auth.isUserActiveTime',
          new Date().getTime()
        ])

        const email = jwtDecode(idToken).email
        this.setStore(idToken, refreshToken, email)

        this.getApplication()
          .then(() => {
            /*     removed for Defect FUS-1149 fix 
            this.$store.commit('set', [
              'ernEnrolmentOwnerName',
              ernEnrolmentOwnerName
            ])
            const enrolmentOwnerInDraft = this.getEnrolmentOwnerInDraft(
              r.data.body.application.parentCarers,
              email
            )
            if (
              this.hasDifferentNameFromERN(
                enrolmentOwnerInDraft,
                ernEnrolmentOwnerName
              )
            ) {
              this.$store.commit('set', [
                'ernEnrolmentOwnerNameNotMatched',
                true
              ])
              this.$router.history.push('/application')
            } else {
              this.getOffers()
            } */

            this.getOffers()
          })
          .catch((error) => {
            this.$store.dispatch('setAPIError', {
              error,
              fallbackCode: API_ERROR_SUBTYPES.getApplication
            })
            this.$router.history.push('/error')
          })
      } else {
        this.$router.history.push('/error')
      }
    },
    setStore(idToken, refreshToken, email) {
      this.$store.commit('set', ['auth.idToken', idToken])
      this.$store.commit('set', ['auth.idTokenTime', new Date().getTime()])
      this.$store.commit('set', ['auth.refreshToken', refreshToken])
      this.$store.commit('set', ['auth.userId', email])
      this.$store.commit('set', ['resumeViaEmail', true])
    },
    hasDifferentNameFromERN(parent, ernName) {
      if (parent && ernName) {
        return !(
          parent.parentCarerFamilyName === ernName.lastName &&
          parent.parentCarerGivenName === ernName.firstName
        )
      }
      return false
    },
    getEnrolmentOwnerInDraft(parentCarers, email) {
      let enrolmentOwnerInDraft
      if (Array.isArray(parentCarers)) {
        enrolmentOwnerInDraft = parentCarers.find((p) => {
          if (p.parentCarerEmail && email) {
            return (
              String(p.parentCarerEmail).toUpperCase() ===
              String(email).toUpperCase()
            )
          }
          return false
        })
      }
      return enrolmentOwnerInDraft
    }
  }
}
</script>
