import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import storeConfig from './storeConfig'

Vue.use(Vuex)

export default new Store({
  // State stores our application data
  state: storeConfig,
  getters,
  actions,
  mutations
})
