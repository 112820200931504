import { CHOOSE_DATE } from '@/constants'

export function getTerms(terms, calendarYear, lateStart) {
  const validTerms = terms
    .filter((term) => {
      return calendarYear
        ? parseInt(term.calendarYear) === parseInt(calendarYear) &&
            term.calendarLateInd === lateStart
        : true
    })
    .map((term) => ({
      text: term.code,
      value: term.value
    }))
    .slice(0, 4)

  return [
    {
      text: 'Select',
      value: ''
    },
    ...validTerms,
    {
      text: 'Choose a specific start date',
      value: CHOOSE_DATE
    }
  ]
}
