export const SUSPENSIONS = [
  { value: 'actualViolence', text: 'Actual violence to any person' },
  {
    value: 'prossessionOfWeapon',
    text: 'Possession of a weapon or any item used to cause harm or injury'
  },
  {
    value: 'threatOfViolence',
    text: 'Threats of violence or intimidation of staff, students, or others at the school'
  },
  { value: 'illegalDrugs', text: 'Possession or use of illegal drugs' }
]
