// Defines all of the sections used to group fields.
// See README.md for more info

import studentFields from './studentFields'
import medicalFields from './medicalFields'
import learnAndSupportFields from './learnAndSupportFields'
import riskFields from './riskFields'
import siblingFields from './siblingFields'
import parentFields from './parentFields'
import emergencyFields from './emergencyFields'
import additionalFields from './additionalFields'
import permissionFields from './permissionFields'
import supportingDocuments from './supportingDocumentFields'
import supplementaryDetails from './supplementaryDetails'

export default [
  {
    id: 'student',
    label: 'Student details',
    fields: studentFields
  },
  {
    id: 'medical',
    label: 'Medical details',
    fields: medicalFields
  },
  {
    id: 'learning',
    label: 'Learning & support',
    fields: learnAndSupportFields
  },
  {
    id: 'risk',
    label: 'Risk assessment',
    fields: riskFields
  },
  {
    id: 'sibling',
    label: 'Siblings',
    fields: siblingFields
  },
  {
    id: 'parents',
    label: 'Parents/carers',
    fields: parentFields
  },
  {
    id: 'emergency',
    label: 'Emergency contacts',
    fields: emergencyFields
  },
  {
    id: 'additional',
    label: 'Additional information',
    fields: additionalFields
  },
  {
    id: 'declaration',
    label: 'Online services & publishing',
    fields: permissionFields
  },
  {
    id: 'documents',
    label: 'Supporting documents',
    fields: supportingDocuments
  },
  {
    id: 'supplementaryDetails',
    label: 'Supplementary details',
    fields: supplementaryDetails
  },
  {
    id: 'review',
    label: 'Review',
    fields: []
  }
]
