<template>
  <v-expansion-panels
    accordion
    multiple
    v-model="expandedPanels"
    class="SchoolInfoPanels text-left"
  >
    <v-expansion-panel v-for="(school, i) in schools" :key="i">
      <v-expansion-panel-header>
        <b-row no-gutters>
          <b-col
            ><v-icon class="pr-4">mdi-school-outline</v-icon
            >{{ school.schoolName }}</b-col
          >
        </b-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="local-school mt-4 mb-4">
        <div class="d-flex pb-3 pt-2">
          <v-icon class="pr-4">mdi-map-marker-outline</v-icon>
          <a
            class="schoolAddress"
            :class="linkClasses"
            :href="getSchoolAddressURL(school)"
            target="_blank"
            rel="noopener noreferrer"
            >{{ school.address }}</a
          >
        </div>
        <div class="d-flex pb-3">
          <v-icon class="pr-4">mdi-phone-outline</v-icon>
          <div>{{ school.phone }}</div>
        </div>
        <div class="d-flex">
          <v-icon class="pr-4">mdi-web</v-icon>
          <div>
            <a
              class="schoolWebsite"
              :class="linkClasses"
              :href="getSchoolWebsiteURL(school)"
              target="_blank"
              rel="noopener noreferrer"
              >{{ school.website }}</a
            >
          </div>
        </div>
        <b-button
          type="button"
          class="local-school__apply-button mt-5"
          variant="primary"
          v-if="school.oes && includeApplyButton"
          :href="getOESRoute(school)"
          target="_blank"
          rel="noopener noreferrer"
          >Apply</b-button
        >
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'SchoolInfoPanels',
  props: {
    schools: {
      type: Array
    },
    includeApplyButton: {
      type: Boolean,
      default: false
    },
    linkClasses: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      expandedPanels: Array.from(Array(this.schools.length).keys())
    }
  },
  methods: {
    getSchoolAddressURL(school) {
      return school.long && school.lat
        ? `https://www.google.com/maps?q=${school.lat},${school.long}`
        : `https://www.google.com/maps?q=${school.address}`
    },
    getSchoolWebsiteURL(school) {
      return school.website.startsWith('http')
        ? school.website
        : `https://${school.website}`
    },
    getOESRoute(school) {
      return `/?schoolCode=${school.schoolCode}${
        school.catchmentLevel ? `&catchmentLevel=${school.catchmentLevel}` : ''
      }`
    }
  }
}
</script>

<style scoped lang="scss">
.SchoolInfoPanels {
  font-size: 1.6rem;
}

.addressHeader {
  padding: 8px;
  min-height: 72px;
  height: auto;
  background: $color-third;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  color: $color-text-body;
  font-size: 18px;
  font-weight: 500;
}

.v-expansion-panel-content {
  font-weight: 500;
}

.v-expansion-panel-header--active {
  background-color: $color-light-shade;
}

.local-school__apply-button {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
}

@include desktop {
  .addressHeader {
    padding: 0;
  }
}
</style>
