// See README.md for more info about field configuration

import { FIELD_TYPE, PREFILL_NOTIFICATION } from '@/constants'

import { YES_NO } from './droplistData/boolean'

import { getFirstName, getLabelName } from './helper'

import medicalCondition from './helper/medicalCondition'
import medicalConditionSevere from './helper/medicalConditionSevere'
import medicalConditionOther from './helper/medicalConditionOther'
import doctorDetails from './helper/doctorDetails'

export default [
  {
    label() {
      return getLabelName(null, 'Medical details')
    },
    description(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Having as much information as we can about ',
        name,
        "'s medical history will help the school to look after their wellbeing. This section includes:",
        'the student'
      )
    },
    descriptionList: [
      'Medicare details',
      'Severe allergies',
      'Non-severe allergies',
      'Other medical conditions such as asthma, diabetes or epilepsy.'
    ],
    type: FIELD_TYPE.HEADING
  },
  {
    type: FIELD_TYPE.NOTIFICATION,
    notificationProps: {
      inPage: true,
      type: 'info',
      elevation: 0,
      alertClass: 'blue-alert'
    },
    text() {
      return PREFILL_NOTIFICATION
    },
    visible(applicaition) {
      return (
        applicaition.meta &&
        applicaition.meta.prefillFlags &&
        applicaition.meta.prefillFlags.doctorDetails
      )
    }
  },
  {
    apiKey: 'student.medicare',
    type: FIELD_TYPE.COLLECTION,
    heading(contactItem, index, application) {
      const name = getLabelName(
        null,
        getFirstName(application),
        "'s Medicare details"
      )
      return name + (index === 1 ? '' : ' ' + index)
    },
    required: true,
    fields(preschool, index) {
      var apiKeyPrefix = `${this.apiKey}[${index}]`
      return [
        {
          apiKey: `${apiKeyPrefix}.number`,
          label: 'Medicare number',
          placeHolder: 'Enter medicare number',
          maxLength: 12,
          validation(value) {
            if (!/^\d{10}$/.test(value.replace(/ /g, ''))) {
              return 'Please enter 10 digits in the format 1111 11111 1.'
            }
          },
          required: ({ residencyStatus }) => residencyStatus !== 'TMP'
        },
        {
          apiKey: `${apiKeyPrefix}.cardRef`,
          label: 'Reference number',
          placeHolder: 'Enter reference number',
          maxLength: 2,
          validation(value) {
            if (!/^\d{1,2}$/.test(value)) {
              return 'Only numbers can be entered into this field'
            }
          },
          required: ({ residencyStatus }) => residencyStatus !== 'TMP'
        },
        {
          apiKey: `${apiKeyPrefix}.expiryDate`,
          label: 'Expiry date',
          selectMonth: true,
          pastYears: 5,
          futureYears: 6,
          type: FIELD_TYPE.DATE
        }
      ]
    }
  },
  doctorDetails,
  {
    apiKey: 'meta.isAnaphylactic',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Does ',
        name,
        ' have any anaphylactic allergies?',
        'the student'
      )
    },
    required: true,
    description:
      'Anaphylaxis is a severe, potentially life-threatening, allergic reaction.',
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  medicalConditionSevere('allergiesAnaphylactic', 'meta.isAnaphylactic'),
  {
    apiKey: 'meta.isNonAnaphylactic',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Does ',
        name,
        ' have any non-severe allergies?',
        'the student'
      )
    },
    required: true,
    description:
      'Non-severe allergies do not result in anaphylactic reactions and do not require an adrenaline autoinjector.',
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  medicalCondition('allergies', 'meta.isNonAnaphylactic'),
  {
    apiKey: 'meta.isOtherMedicalCondition',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Is ',
        name,
        ' being treated for any other medical conditions?',
        'the student'
      )
    },
    required: true,
    description:
      'Medical conditions could include asthma, epilepsy, diabetes etc',
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  medicalConditionOther('nonAllergies', 'meta.isOtherMedicalCondition')
]
