<template>
  <loading-view v-if="loading" loading-text="processing, one moment..." />
</template>

<script>
import jwtDecode from 'jwt-decode'
import LoadingView from '@/components/app/FullScreenLoader'
import ehubUtil from '@/utils/ehub'
import endPoint from '@/store/endPoint'
import {
  ENROLMENT_TYPES,
  API_ERROR_SUBTYPES,
  CATCHMENT_LEVELS
} from '@/constants'
import { getEhubPrefillFlags } from '@/applicationDefinition/helper.js'
import moment from 'moment'

export default {
  name: 'EHubAcceptEoiOffer',
  components: { LoadingView },
  mounted() {
    this.proceedShsOffer()
  },
  data() {
    return {
      loading: true,
      defaultIntendedStartDate: null
    }
  },
  computed: {
    termDates() {
      return this.$store.state?.termDates
    }
  },
  methods: {
    async proceedShsOffer() {
      const { idToken, refreshToken, shsOffer, pidLinkedChildren } =
        ehubUtil.getShsOfferData()
      if (!idToken || !refreshToken || !shsOffer) {
        this.$router.history.push('/error')
      }
      this.$store.commit('set', ['auth.isUserActive', true])
      this.$store.commit('set', ['auth.isUserActiveTime', new Date().getTime()])

      const decodedToken = jwtDecode(idToken)
      const email = decodedToken['email']
      const parentId = decodedToken['custom:pid']
      this.setStore(idToken, refreshToken, email)

      const schoolCode = shsOffer?.schoolCode || ''
      const shsOfferId = shsOffer?.shsOfferId || ''
      const studentSrn = shsOffer?.srn || ''
      const calendarYear = shsOffer?.startDate.split(' ')[2] || ''

      if (!schoolCode || !shsOfferId) {
        this.$router.history.push('/error')
      }

      try {
        this.getDefaultIntendedStartDate(calendarYear)
        if (shsOffer) {
          const enrolmentType = 'SHS'
          let prefillData = null
          let prefillQuery = ''
          this.loading = false
          const isPidLinkedChildren =
            Array.isArray(pidLinkedChildren) &&
            pidLinkedChildren.some((child) => child?.srn === studentSrn)
          if (parentId && isPidLinkedChildren && !!studentSrn) {
            // only stamped parent will do the ERN data prefilling, because only stamped parent will have court order.
            prefillQuery = prefillQuery + `/${studentSrn}`
          }
          prefillQuery =
            prefillQuery +
            `?enrolmentType=${enrolmentType}&enrolmentId=${shsOfferId}`
          const prefillResponse = await endPoint.getPrefillDataByQuery(
            idToken,
            prefillQuery
          )
          if (prefillResponse?.data) {
            prefillData = prefillResponse.data?.body
          }
          await this.setAppData(
            schoolCode,
            shsOffer,
            prefillData,
            parentId,
            isPidLinkedChildren,
            calendarYear
          )
          this.startApp()
        } else {
          this.$router.history.push('/error')
        }
      } catch (error) {
        this.$store.dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.getApplication
        })
        this.$router.history.push('/error')
      }
    },
    setStore(idToken, refreshToken, email) {
      this.$store.commit('set', ['auth.idToken', idToken])
      this.$store.commit('set', ['auth.idTokenTime', new Date().getTime()])
      this.$store.commit('set', ['auth.refreshToken', refreshToken])
      this.$store.commit('set', ['auth.userId', email])
      this.$store.commit('set', ['resumeViaEmail', true])
    },
    getDefaultIntendedStartDate(calendarYear) {
      this.$store
        .dispatch('getTermDates')
        .then(() => {
          if (this.termDates) {
            const termOne = `Term One (${calendarYear})`
            const startTerm = this.termDates.find(
              (term) => term.code === termOne && term.calendarLateInd === false
            )
            const termStartDate = startTerm ? startTerm.value : null
            const currentDate = moment().format('YYYY-MM-DD')
            this.defaultIntendedStartDate =
              currentDate < termStartDate ? termStartDate : null
          }
        })
        .catch((e) => {
          this.showError(e)
        })
    },
    showError(error) {
      this.$store.dispatch('set', ['error', error])
      this.$router.history.push('/error')
    },
    getCatchmentLevel(school) {
      const catchmentLevelSecondary = CATCHMENT_LEVELS[1].value
      let result = catchmentLevelSecondary
      if (school) {
        const schoolCatchmentLevel =
          school['catchment_level'] || school['catchmentLevel']
        if (schoolCatchmentLevel && schoolCatchmentLevel !== 'central') {
          result = schoolCatchmentLevel
        }
      }
      return result
    },
    buildParentCarer(parent) {
      return {
        parentCarerRelation: parent?.parentCarerRelation,
        parentCarerTitle: parent?.parentCarerTitle,
        parentCarerFamilyName: parent?.parentCarerFamilyName,
        parentCarerGivenName: parent?.parentCarerGivenName,
        parentCarerGenderCode: parent?.parentCarerGenderCode,
        parentCarerEmail: parent?.parentCarerEmail,
        meta: {
          isCompletingApplication: parent?.isCompletingApplication
        },
        contactDetails: parent?.contactDetails,
        residentialAddress: parent?.correspondenceAddress,
        parentCarerCountryBirth: parent?.parentCarerCountryBirth,
        isParentCarerAboriOrTorres: parent?.isParentCarerAboriOrTorres,
        parentCarerOccupationGroup: parent?.parentCarerOccupationGroup,
        parentCarerOccupationName: parent?.parentCarerOccupationName,
        parentCarerSchoolEducationCode: parent?.parentCarerSchoolEducationCode,
        parentCarerEducationQualificationCode:
          parent?.parentCarerEducationQualificationCode,
        isParentCarerSpeakLangOtherEnglish:
          parent?.speakLanguageOtherThanEnglish,
        parentCarerMainLanguage: parent?.parentCarerMainLanguage,
        parentCarerSpeakLang: parent?.otherLanguage,
        isEnrolmentOwner: parent?.liveOnResidentialAddress
      }
    },
    prefillParentCarers(parentCarers, parentId, isPidLinkedChildren) {
      const prefilledParentCarers = []
      if (Array.isArray(parentCarers) && parentCarers.length > 0) {
        parentCarers.forEach((parent) => {
          if (parent) {
            const parentCarer = this.buildParentCarer(parent)
            if (parentId && isPidLinkedChildren && !!parent?.parentId) {
              parentCarer.parentId = parent.parentId
            }
            prefilledParentCarers.push(parentCarer)
          }
        })
      }
      return prefilledParentCarers
    },
    async setAppData(
      schoolCode,
      shsOffer,
      prefillData,
      parentId,
      isPidLinkedChildren,
      calendarYear
    ) {
      const school = await this.$store.dispatch('getSchool', String(schoolCode))
      const catchmentLevel = this.getCatchmentLevel(school)
      const student = prefillData?.student
      const studentSrn = prefillData.student?.srn
      // for unstamped parents, isEhubLinkedStudent should be false, this will decide siblings/supplementaryDetails sections
      const isEhubLinkedStudent = !!(
        studentSrn &&
        studentSrn !== 'new' &&
        parentId
      )
      const intendedStartDate = this.defaultIntendedStartDate
      let visaSubClass = ''
      let tempResVisaClass = ''
      let tempResVisaSubClass = ''
      const residentialAddress = prefillData?.student?.correspondenceAddress
      const { residencyStatus } = prefillData
      if (residencyStatus === 'TMP') {
        tempResVisaClass = prefillData?.visaClass
        tempResVisaSubClass = prefillData?.visaSubClass
      } else {
        visaSubClass = prefillData?.visaSubClass
      }
      const prefilledParentCarers = this.prefillParentCarers(
        prefillData.parentCarers,
        parentId,
        isPidLinkedChildren
      )
      let application = {
        catchmentLevel,
        intendedStartDate,
        meta: {
          calendarYear,
          isEhubLinkedStudent,
          viaEhub: true,
          parentId,
          studentSrn
        },
        residentialAddress,
        scholasticYear: '7',
        schoolCode: school?.schoolCode,
        schoolName: school?.schoolName,
        student: {
          ...student,
          firstName: student.firstName.slice(0, 100),
          familyName: student.familyName.slice(0, 100)
        },
        isEhubLinkedStudent,
        viaEhub: true,
        shsOfferId: shsOffer?.shsOfferId,
        dateArriveAustralia: prefillData?.dateArriveAustralia,
        prevAttendSchool: prefillData?.prevAttendSchool,
        prevSchoolId: prefillData?.prevSchoolId,
        prevSchoolName: prefillData?.prevSchoolName,
        prevSchoolType: prefillData?.prevSchoolType,
        prevSchoolStartDate: prefillData?.prevSchoolStartDate,
        prevSchoolLastDate: prefillData?.prevSchoolLastDate,
        parentCarers: prefilledParentCarers,
        residencyStatus,
        tempResVisaClass,
        tempResVisaSubClass,
        visaSubClass,
        enrolmentType: ENROLMENT_TYPES.CORE_SHS
      }
      if (prefillData?.student?.mainLangOtherThanEnglish) {
        application.student.mainLanguageOtherThanEnglish =
          prefillData?.student?.mainLangOtherThanEnglish
        delete application.student?.mainLangOtherThanEnglish
      }
      if (
        Array.isArray(prefillData?.siblings) &&
        prefillData?.siblings.length
      ) {
        application.siblings = prefillData.siblings
          .sort((a, b) => {
            if (a.siblingsDOB > b.siblingsDOB) {
              return 1
            }
            if (a.siblingsDOB < b.siblingsDOB) {
              return -1
            }
            return 0
          })
          .slice(0, 1)
        application.hasAnySiblings = true
      }
      if (prefillData?.doctorDetails) {
        application.doctorDetails = prefillData.doctorDetails
      }
      if (prefillData?.correspondenceAddress) {
        application.correspondenceAddress = prefillData.correspondenceAddress
        application.meta.hasAdditionalPostalAddress = 'Yes'
      }
      if (prefillData?.emergencyContact) {
        application.meta.additionalEmergencyContacts = ['yes']
        application.emergencyContact = prefillData.emergencyContact
      }
      application.meta.prefillFlags = getEhubPrefillFlags(application)
      if (isEhubLinkedStudent) {
        //Google Analytics
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'Prefill Details',
          action: 'form start',
          label: 'student and parent',
          application: ehubUtil.getGtmApplication()
        })
      }
      this.$store.commit('set', ['isNewApplication', true])
      this.$store.commit('set', ['application', application])
      this.$store.commit('set', ['school', school])
      return application
    },
    startApp() {
      this.$store
        .dispatch('cancelApplication')
        .then(() => {
          this.$store
            .dispatch('createApplication')
            .then(() => {
              //Google Analytics
              this.$gtm.trackEvent({
                event: 'interaction',
                category: 'Form Attempt',
                action: 'start',
                label: 'shsconverted',
                application: ehubUtil.getGtmApplication()
              })
              this.$router.history.push('/application')
            })
            .catch((error) => {
              this.$store.dispatch('hideSpinner')
              this.$store.dispatch('setAPIError', {
                error,
                fallbackCode: API_ERROR_SUBTYPES.getApplication
              })
              this.$router.history.push('/error')
            })
        })
        .catch((error) => {
          //handle API errors
          this.$store.dispatch('setAPIError', {
            error,
            fallbackCode: API_ERROR_SUBTYPES.cancelApplication
          })
          this.$router.history.push('/error')
        })
    }
  }
}
</script>
