import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework' // Changed from "vuetify/lib" to improve build performance. Ref https://stackoverflow.com/questions/58441782/extremely-long-compile-times-with-vuetify-2-1-and-webpack/58749021#58749021

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: '#041e42', // navy in ADS
          lighten1: '#1D428A', // blue 1 in ADS
          lighten2: '#407EC9', // blue 2 in ADS
          lighten3: '#6BACE4', // blue 3 in ADS
          lighten4: '#C7DCF0', // light blue in ADS
          lighten5: '#2E5299' // info blue in ADS
        },
        secondary: {
          base: '#3772b7',
          darken1: '#0E3B6C' //solid color for blue 2 with opacity .3 -  used for school switcher
        },
        accent: '#053b6f',
        error: '#c33021',
        success: '#00A908',
        info: '#2E5299',
        warning: '#d47f00',
        white: '#ffffff',
        black: '#151515',
        grey: {
          base: '#efefef',
          lighten1: '#2E2F30', //grey 1 in ADS
          lighten2: '#666666', //grey 2 in ADS
          lighten3: '#B3B3B3', //grey 3 in ADS
          lighten4: '#D9D9D9', //grey 4 in ADS
          lighten5: '#E5E5E5', //grey 5 in ADS
          lighten6: '#F5F5F5', //grey 6 in ADS
          lighten7: '#FAFAFA', //grey 7 in ADS
          darken1: '#121212'
        },
        red: {
          base: '#CE0037', // red in ADS
          lighten1: '#EE3C48', // red 2 in ADS
          lighten2: '#F3B8B6', // red 3 in ADS
          darken1: '#B81237' // error red in ADS
        },
        green: {
          base: '#69B32D', // green in ADS
          darken1: '#00A908' // Success green in ADS
        },
        purple: {
          base: '#752F8A' //purple in ADS
        },
        orange: {
          base: '#FF7F2F', // orange in ADS
          darken1: '#DC5800' //warning orange in ADS
        },
        yellow: {
          base: '#F9BE00' // yellow in ADS
        }
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})
