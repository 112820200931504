<template>
  <ApplicationLoginCard>
    <template #icon>
      <img src="@/assets/icon-email.svg" alt="Email" />
    </template>
    <template #body>
      <h2>
        {{
          isResume
            ? 'Sign in using your email address'
            : 'Use your email address to begin'
        }}
      </h2>
      <p v-if="isResume">
        Enter the
        <strong>email address</strong> you used to begin your enrolment
        application to resume your progress.
      </p>
      <p v-else>
        Entering your email address will allow you to start the application,
        save it and come back later
      </p>
      <b-form class="email-form" method="post" @submit.prevent="handleLogin">
        <label class="email-form__label" for="email">Email address</label>
        <b-form-input
          id="email"
          v-model="email"
          type="email"
          name="email"
          :readonly="readOnly"
          :aria-disabled="readOnly"
          required
          placeholder="Enter email address"
          :class="{
            'has-success': isEmailValid(),
            'has-error': email !== '' && !isEmailValid()
          }"
          class="email-input"
          ref="enterEmail"
        ></b-form-input>

        <b-row class="ValidationMessage">
          <b-col>
            <transition name="slide-fade">
              <p v-if="errorMessage" class="alert-message">
                <font-awesome-icon
                  focusable="false"
                  class="icon"
                  icon="exclamation-circle"
                />
                {{ errorMessage }}
              </p>
            </transition>
          </b-col>
        </b-row>

        <b-row class="">
          <b-col>
            <vue-recaptcha
              v-if="!bypassRecaptcha"
              :sitekey="recaptchaSiteKey"
              @verify="onRecaptchaVerify"
              @expired="onRecaptchaExpired"
              class="g-recaptcha"
              size=""
              ref="recaptcha"
            ></vue-recaptcha>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              class="float-md-right email-form__continue-btn"
              variant="primary"
              :disabled="
                !isEmailValid() ||
                (recaptchaVerifyToken === null && !bypassRecaptcha)
              "
              @click="handleLogin"
              >Continue</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </template>
  </ApplicationLoginCard>
</template>

<script>
import { NODE_ENVIRONMENTS, API_ERROR_SUBTYPES } from '@/constants'
import ApplicationLoginCard from './ApplicationLoginCard.vue'
import VueRecaptcha from 'vue-recaptcha'
import ehubUtil from '@/utils/ehub'

const reg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export default {
  name: 'ApplicationLoginEmailCard',
  components: {
    ApplicationLoginCard,
    VueRecaptcha
  },
  props: {
    isResume: {
      type: Boolean,
      default: false
    },
    initialEmail: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      API_ERROR_SUBTYPES,
      isEmailSent: false,
      email: this.initialEmail,
      inputHasFocus: false,
      recaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaVerifyToken: null,
      errors: {},
      ERROR_MESSAGES: {
        EMAIL: 'Please enter a valid email',
        RECAPTCHA: 'Recaptcha verification failed. Please try again.'
      }
    }
  },
  mounted() {
    try {
      this.$refs.enterEmail.focus()
    } catch (e) {
      // Unit test shallow mount will cause the above line to fail,
      // hence the try/catch.
    }
  },
  computed: {
    errorMessage() {
      return Object.values(this.errors)
        .filter((error) => error)
        .join(', ')
    },
    bypassRecaptcha() {
      return (
        this.$store.state.oesProperties.bypassRecaptcha &&
        process.env.VUE_APP_ENV_NAME &&
        process.env.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PROD
      )
    }
  },
  methods: {
    initiateAuth(email, recaptchaVerifyToken) {
      this.$store.dispatch('showSpinner')
      this.$store
        .dispatch('initiateAuth', { email, recaptchaVerifyToken })
        .then(() => {
          this.$emit('authInitiated', email)
        })
        .catch(this.handleAPIError)
        .finally(() => {
          this.$store.dispatch('hideSpinner')
        })
    },
    handleAPIError(error) {
      const { response } = error
      //Specifically handle 400s with proper error info inline, else go to error view
      if (
        response &&
        response.status === 400 &&
        response.data &&
        response.data.errors
      ) {
        this.handleInlineErrors(response.data.errors)
      } else {
        this.$store.dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.initiateAuth
        })
        this.$router.history.push('/error')
      }
    },
    handleInlineErrors(errors) {
      this.errors = errors.reduce(
        (errors, error) => ({ ...errors, ...this.getErrorMessage(error) }),
        {}
      )
    },
    getErrorMessage(error) {
      const genericError = { email: this.ERROR_MESSAGES.EMAIL }
      if (error && error.source) {
        switch (error.source.pointer) {
          case '/recaptchaToken':
            return { recaptcha: this.ERROR_MESSAGES.RECAPTCHA }
          case '/userId':
          default:
            return genericError
        }
      } else {
        return genericError
      }
    },
    handleLogin() {
      if (this.bypassRecaptcha) {
        // Hardcoded magic number for non-prod env's.
        this.recaptchaVerifyToken = '111111'
      }
      if (this.isEmailValid() && !!this.recaptchaVerifyToken) {
        this.initiateAuth(this.email, this.recaptchaVerifyToken)
        //Google Analytics
        const gtmApplication = ehubUtil.getGtmApplication()
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'Authentication',
          action: 'Attempt',
          label: 'Email',
          application: gtmApplication
        })
      }
    },
    isEmailValid() {
      return reg.test(this.email)
    },
    onRecaptchaVerify(response) {
      this.errors.recaptcha = null //remove recaptcha error on verify
      this.recaptchaVerifyToken = response
    },
    onRecaptchaExpired() {
      this.recaptchaVerifyToken = null
    }
  },
  watch: {
    errors: {
      deep: true,
      handler(newVal) {
        if (newVal.recaptcha) {
          //If recaptcha has an error then reset the recaptcha
          this.recaptchaVerifyToken = null
          this.$refs.recaptcha.reset()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.email-form {
  margin-top: 3rem;

  input {
    @include vuetify-input;
    display: inline-block;
    margin: 0 0 15px 0;
    padding: 0 2rem;
    line-height: 3.5rem;
    height: 4.8rem;

    &.has-error {
      border-color: $color-red;

      &:focus {
        box-shadow: 0 0 0.5rem 0.2rem rgba($color-red, 0.24);
      }
    }

    &.has-success {
      border-color: green;

      &:focus {
        box-shadow: 0 0 0 0.3rem rgba($color-green, 0.24);
      }
    }
  }

  &__label {
    @include vuetify-label;
  }

  button {
    position: relative;
    top: -4px;
  }
}

::v-deep .login-card__icon-container {
  align-self: flex-start;
}

.ValidationMessage {
  p {
    &.alert-message {
      position: relative;
      padding-left: 35px;
      color: $color-red;

      .icon {
        position: absolute;
        font-size: 24px;
        left: 0;
        top: -1px;
      }

      .paper-enrolment {
        color: inherit;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

@include tablet {
  .email-form {
    flex-direction: column;
    align-items: center;

    &__continue-btn.btn {
      display: block;
      margin-left: auto;
      top: 4px;
    }
  }

  .ValidationMessage {
    height: auto;
    margin: 0;
  }

  .g-recaptcha {
    transform-origin: 0;
    transform: scale(0.82); //82% scales it down to ~250px
    width: 250px;
  }
}

@include mobile {
  ::v-deep .login-card__icon-container {
    align-self: center;
  }
}
</style>
