export const arrayToListObj = (data) => {
  return dropDownSelect(
    data.map((item) => {
      return { value: item, text: item }
    })
  )
}

export const dropDownSelect = (data) => {
  let result = [{ value: '', text: 'Select' }]
  return result.concat(data)
}
export const arrayToListCheckbox = (data) => {
  return data.map((item) => {
    return { value: item, text: item }
  })
}
