<!--
  Custom HTML version of the native JavaScript window.alert() and
  window.confirm() dialogs. Uses a Vue transition for fading in and out.

  options:

  message     - Message text to display
  onConfirm   - Optional callback for the confirm button (this will also cause a cancel button to be displayed)
  onCancel    - Optional callback for the cancel button
  onAlways    - Optional callback when clicking any button (button callback will fire first)
  textConfirm - Optional text for the confirm button (default is "OK")
  textCancel  - Optional text for the cancel button (default is "Cancel")
  type        - Optional type to determine the default icon (either "info", "question" or "warning")
-->

<template>
  <FocusTrap :is-active="visible">
    <transition name="fade">
      <div class="AppMessageBox" v-if="visible" @click.self="escape">
        <div
          :class="'box box-' + type"
          role="dialog"
          :aria-label="type + ' message box'"
          aria-describedby="boxDescription"
          @keyup.esc="escape"
        >
          <div class="body" id="boxDescription">
            <font-awesome-icon
              focusable="false"
              class="icon"
              :icon="iconName"
            />
            <p>{{ state.message }}</p>
          </div>

          <div class="footer">
            <button
              class="primary"
              type="button"
              @click="confirm"
              ref="confirm"
            >
              {{ state.textConfirm || 'OK' }}
            </button>
            <button v-if="state.onConfirm" type="button" @click="cancel">
              {{ state.textCancel || 'Cancel' }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </FocusTrap>
</template>

<script>
import FocusTrap from './FocusTrap.vue'

const TYPES_TO_ICONS = {
  info: 'info-circle',
  question: 'question-circle',
  warning: 'exclamation-circle'
}

export default {
  name: 'AppMessageBox',
  components: { FocusTrap },
  computed: {
    state() {
      return this.$store.state.messageBox
    },
    type() {
      if (this.state.type) return this.state.type
      if (this.state.onConfirm) return 'question'
      return 'info'
    },
    iconName() {
      return TYPES_TO_ICONS[this.type]
    },
    visible() {
      // Check for the existence of any property on the messageBox object
      return Object.keys(this.state).length > 0
    }
  },
  methods: {
    confirm() {
      if (this.state.onConfirm && typeof this.state.onConfirm === 'function') {
        this.state.onConfirm()
      }

      if (this.state.onAlways && typeof this.state.onAlways === 'function') {
        this.state.onAlways()
      }

      this.$store.dispatch('hideMessageBox')
    },
    cancel() {
      if (this.state.onCancel && typeof this.state.onCancel === 'function') {
        this.state.onCancel()
      }

      if (this.state.onAlways && typeof this.state.onAlways === 'function') {
        this.state.onAlways()
      }

      this.$store.dispatch('hideMessageBox')
    },
    escape() {
      this.$store.dispatch('hideMessageBox')
    }
  },
  updated() {
    if (this.$refs.confirm) this.$refs.confirm.focus()
  }
}
</script>

<style scoped lang="scss">
.AppMessageBox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: z('modal');
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);

  .box {
    margin: 1rem;
    padding: $content-padding;
    max-width: 600px;
    min-width: 350px;
    border-radius: 0.5rem;
    background-color: $color-white;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
  }

  .body {
    display: flex;
    margin: 0.5em 0 1.75rem;
    align-items: center;
    color: black;

    .icon {
      margin-right: 0.25rem;
      color: $color-primary;
      font-size: 2.5rem;
    }

    p {
      margin: 0;
    }
  }

  .footer {
    direction: rtl;
    text-align: right;
  }

  button {
    min-width: 6rem;
    border: none;
    font-size: $text;
  }
}

// Classes auto generated by Vue transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
