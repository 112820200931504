export const PREVSCHOOLTYPE = [
  {
    text: 'Select',
    value: ''
  },
  {
    text: 'New South Wales',
    value: 'NSW'
  },
  {
    text: 'Victoria',
    value: 'VIC'
  },
  {
    text: 'Queensland',
    value: 'QLD'
  },
  {
    text: 'South Australia',
    value: 'SA'
  },
  {
    text: 'Western Australia',
    value: 'WA'
  },
  {
    text: 'Tasmania',
    value: 'TAS'
  },
  {
    text: 'Northern Territory',
    value: 'NT'
  },
  {
    text: 'Australian Capital Territory',
    value: 'ACT'
  },
  {
    text: 'Overseas',
    value: 'OVS'
  }
]
