export const PRESCHOOLTYPE = [
  { value: '', text: 'Select' },
  { value: 'PRS', text: 'Preschool' },
  { value: 'LDP', text: 'Long Day Care (with a preschool program)' },
  { value: 'LDW', text: 'Long Day Care (without a preschool program)' },
  { value: 'FDC', text: 'Family Day Care' },
  { value: 'GRN', text: 'Grandparent' },
  {
    value: 'OTC',
    text: 'Other formal or informal care'
  }
]
