<template>
  <div v-if="isVisible(field)" class="ApplicationFieldGroup" :id="field.elemId">
    <div
      class="groupField"
      :class="groupField.className"
      v-for="(groupField, index) in groupfields"
      :key="groupField.apiKey + index"
    >
      <application-field-heading
        v-if="groupField.type === FIELD_TYPE.HEADING"
        :field="groupField"
      />
      <ApplicationField v-else :section="section" :field="groupField" />
    </div>
  </div>
</template>

<script>
import ApplicationField from '@/components/application/ApplicationField.vue'
import ApplicationFieldHeading from '@/components/application/ApplicationFieldHeading.vue'
import { FIELD_TYPE } from '../../constants'

export default {
  name: 'ApplicationFieldGroup',
  components: {
    ApplicationField,
    ApplicationFieldHeading
  },
  data() {
    return {
      FIELD_TYPE
    }
  },
  props: {
    section: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  computed: {
    application() {
      return this.$store.state.application
    },
    groupfields() {
      return this.field.fields().filter((field) => this.isVisible(field))
    }
  },
  methods: {
    isVisible(field) {
      return field.visible ? field.visible(this.application) : true
    }
  }
}
</script>

<style lang="scss">
.ApplicationFieldGroup {
  margin-right: 1rem;
  margin-bottom: 3.5rem;
  margin-left: 1rem;
  padding: 2rem 2rem 0;
  background-color: $color-white;

  .groupField.fieldInline {
    display: inline-block;
    width: auto;
  }
}
</style>
