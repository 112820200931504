<template>
  <div class="ApplicationMenu">
    <nav>
      <ol
        class="status-menu"
        :class="[hasDisabledItems && 'status-menu__line--disabled']"
      >
        <li
          v-for="(section, idx) in sections"
          :key="section.id"
          :id="section.id"
        >
          <button
            class="status-menu__item"
            :class="[
              getCSSClassModifier(section),
              isItemDisabled(section) && 'status-menu__item--disabled'
            ]"
            :disabled="isItemDisabled(section)"
            @click="onPress(section, $event)"
            @keydown="onPress(section, $event)"
          >
            <span class="status-menu__icon">
              <span :class="indexClass(section)">{{ idx + 1 }}</span>
              <font-awesome-icon
                focusable="false"
                v-if="getStatus(section) === MENU_STATE.COMPLETED"
                icon="check"
                class="icon"
              />
              <font-awesome-icon
                focusable="false"
                v-else-if="getStatus(section) === MENU_STATE.FAILED"
                icon="exclamation"
                class="icon"
              />
            </span>
            <span class="status-menu__title">{{ section.label }}</span>
            <span
              v-if="getStatus(section) === MENU_STATE.CURRENT"
              class="sr-only"
            >
              (current)
            </span>
            <span class="sr-only">
              {{ isComplete(section) ? 'Completed' : 'Incomplete' }}
            </span>
          </button>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import Stickyfill from 'stickyfilljs'
import { mapGetters } from 'vuex'
import { clearHiddenFieldsFromSection } from '@/../src/applicationDefinition/helper'
import application from '../mixins/application'
import formFocus from '@/components/mixins/focus'

const MENU_STATE = {
  INITIAL: 'INITIAL',
  CURRENT: 'CURRENT',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
}

export default {
  name: 'ApplicationMenu',
  mixins: [application, formFocus],
  data() {
    return {
      MENU_STATE
    }
  },
  isParentCarerCompleted: false,
  computed: {
    application() {
      return this.$store.state.application
    },
    sections() {
      return this.$store.state.sections
    },
    ...mapGetters([
      'blockNavigation',
      'sectionExpandedId',
      'disableLeftNavItems'
    ]),
    hasDisabledItems() {
      return this.disableLeftNavItems?.length > 1
    }
  },
  mounted() {
    // IE polyfill for position: sticky
    Stickyfill.add(this.$el)
    if (this.application?.residencyStatus === 'TMP') {
      this.$store.dispatch('set', [
        'application.meta.sectionExpandedId',
        'student'
      ])
    }
  },
  watch: {
    sectionExpandedId: {
      handler(newValue) {
        const visitedSections = this.application?.meta?.visitedSections || []
        if (!visitedSections.includes(newValue)) {
          this.$store.commit('set', [
            'application.meta.visitedSections',
            [...visitedSections, newValue]
          ])
        }
      },
      immediate: true
    }
  },
  methods: {
    indexClass(section) {
      return this.getStatus(section) === MENU_STATE.COMPLETED ||
        this.getStatus(section) === MENU_STATE.FAILED
        ? 'sr-only'
        : ''
    },
    isComplete(section) {
      let numCounters = this.$store.state.counters.filter(
        (val) => val.section.id === section.id
      ).length
      return numCounters === 0
    },
    isItemDisabled(section) {
      if (!Array.isArray(this.disableLeftNavItems)) return false
      return this.disableLeftNavItems.includes(section.id)
    },
    isFailed(section) {
      let numAlerts = this.$store.state.alerts.filter(
        (val) => val.section.id === section.id
      ).length
      return numAlerts > 0
    },
    hasBeenVisited(section) {
      return this.application.meta.visitedSections.includes(section.id)
    },
    getStatus(section) {
      if (section.id === this.sectionExpandedId) return MENU_STATE.CURRENT
      // If the Review section isn't the current section, treat it as if it were
      // in an 'initial' state.
      if (section.id === 'review') return MENU_STATE.INITIAL
      if (this.isFailed(section)) return MENU_STATE.FAILED
      //Only set 'documents' or 'supplementaryDetails' as valid if it has been visited
      if (section.id === 'documents' || section.id === 'supplementaryDetails') {
        if (this.hasBeenVisited(section) && this.isComplete(section)) {
          return MENU_STATE.COMPLETED
        }
      } else if (section.id === 'sibling') {
        if (this.hasBeenVisited(section) && this.isComplete(section)) {
          return MENU_STATE.COMPLETED
        }
      } else if (section.id === 'emergency') {
        if (
          this.hasBeenVisited(section) &&
          this.isComplete(section) &&
          this.isParentCarerCompleted
        ) {
          return MENU_STATE.COMPLETED
        }
      } else if (this.isComplete(section)) {
        this.isParentCarerCompleted = section.id === 'parents' ? true : false
        return MENU_STATE.COMPLETED
      }

      return MENU_STATE.INITIAL
    },
    isEmergencySectionCompleted(emergencyContact) {
      const emergencyCompleted = emergencyContact.filter(
        (item) =>
          item.contactFirstName &&
          item.contactFirstName != '' &&
          item.contactFamilyName &&
          item.contactFirstName != '' &&
          item.contactRelationship &&
          item.contactRelationship != ''
      )

      return emergencyCompleted.length >= 1
    },
    getCSSClassModifier(section) {
      const stateToCls = {
        [MENU_STATE.INITIAL]: '',
        [MENU_STATE.CURRENT]: 'status-menu__item--current',
        [MENU_STATE.COMPLETED]: 'status-menu__item--completed',
        [MENU_STATE.FAILED]: 'status-menu__item--failed'
      }

      return stateToCls[this.getStatus(section)]
    },
    onPress(section, ev) {
      if (ev.type !== 'click' && ev.type !== 'keydown') return

      // Check if 'Enter' or 'Space' key
      if (ev.type === 'keydown' && !(ev.keyCode === 13 || ev.keyCode === 32))
        return

      if (this.blockNavigation) {
        ev.preventDefault()
        this.handleBlockNavigation()
        return
      }
      this.onChangeSection(section)
    },
    onChangeSection(section) {
      if (section.id === this.sectionExpandedId) return

      // clear the hidden fields from the previous section
      const prevSection = this.sections.filter(
        (s) => s.id === this.sectionExpandedId
      )[0]
      const applicationWithHiddenFieldsCleared = clearHiddenFieldsFromSection(
        prevSection && prevSection.fields,
        this.application
      )
      this.$store.commit('set', [
        'application',
        applicationWithHiddenFieldsCleared
      ])

      this.$store.dispatch('closeMenu')
      this.$store.dispatch('set', [
        'application.meta.sectionExpandedId',
        section.id
      ])
      this.$store.dispatch('buildCounters')

      this.$scrollTo('#app', 1)

      setTimeout(() => {
        this.focusOnFirst()
      }, 100)

      if (section.id !== 'review') {
        this.$store.commit('set', ['auth.isUserActive', true])
        this.$store.commit('set', [
          'auth.isUserActiveTime',
          new Date().getTime()
        ])

        this.$gtm.trackView('SectionView', `/section/${section.id}`)
      }
    }
  }
}
</script>

<style lang="scss">
.ApplicationMenu {
  $page-top: 75px;

  $menu-item-p: 0.375em;
  $menu-item-h: 3.5em;
  $menu-item-lh: $menu-item-h - ($menu-item-p * 2);

  $menu-icon-cf: $menu-item-lh;

  font-size: 1rem; // Reset to base

  z-index: z('menu');
  position: fixed;
  top: $page-top;
  left: 0;
  padding: 1.5em;
  width: 100%;
  max-width: 33em;
  height: calc(100% - #{$page-top});
  background-color: $color-bg-app;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);

  transform: translateX(-110%);
  transition: transform 0.25s;

  .status-menu {
    position: relative;
    margin: 0;
    padding: 0;
    color: #012040;
    font-size: 1.6rem;
    list-style: none;
  }

  .status-menu::before {
    $w: 0.25em;

    content: '';
    z-index: -1;
    position: absolute;
    top: 50%;
    left: $menu-item-h / 2 - $w / 2;
    width: $w;
    height: calc(100% - (#{$menu-item-p} * 2) - #{$menu-item-lh});
    background-color: #012040;
    transform: translateY(-50%);
  }

  .status-menu__line--disabled::before {
    background-color: #ababab;
  }

  .status-menu__item {
    @include button-reset;
    width: 100%;
    display: flex;
    align-items: center;
    padding: $menu-item-p;
    border-radius: $menu-item-h / 2;
    cursor: pointer;
  }

  .status-menu__icon {
    min-width: $menu-icon-cf;
    min-height: $menu-icon-cf;
    border: 0.1875em solid;
    border-radius: 100%;
    background-color: #fff;
    color: #012040;
    font-weight: 700;
    line-height: $menu-item-lh - $menu-item-p;
    text-align: center;
  }

  .status-menu__title {
    margin-left: 0.75em;
    white-space: nowrap;
  }

  .status-menu__item--current {
    background-color: #fff;
    font-weight: 700;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);
  }

  .status-menu__item--current .status-menu__icon {
    border-color: #012040;
    background-color: #012040;
    color: #fff;
  }

  .status-menu__item--completed {
    font-weight: 700;
  }

  .status-menu__item--completed .status-menu__icon {
    border-color: #289b00;
    background-color: #30b400;
    color: #fff;
  }

  .status-menu__item--failed .status-menu__icon {
    color: #cd0337;
  }

  .status-menu__item--failed .status-menu__title {
    color: #d63c59;
  }
  .status-menu__item.status-menu__item--disabled:disabled {
    color: #ababab;
    cursor: default;
    opacity: 1;
  }
  .status-menu__item--disabled .status-menu__icon {
    color: #ababab;
  }
}

@include desktop {
  .ApplicationMenu {
    $sticky-y: 94px;

    z-index: z('header');
    position: static; // IE11: Fall back to static
    position: sticky;
    top: $sticky-y;
    left: auto;
    padding: 2em 0 2em 2em;
    height: auto;
    background-color: transparent;
    box-shadow: none;

    transform: none;
  }
}

#app.hasMenuOpen {
  overflow: hidden;
  height: 100vh;
}

#app.hasMenuOpen .ApplicationMenu {
  overflow-y: auto;
  transform: translateX(0);
}
</style>
