export const LANGUAGES = [
  { value: '', text: 'Select' },
  { value: 'ABE', text: 'Aboriginal English' },
  { value: 'ACE', text: 'Acehnese' },
  { value: 'ACH', text: 'Acholi' },
  { value: 'ALO', text: 'African Languages (Other)' },
  { value: 'AFR', text: 'Afrikaans' },
  { value: 'AKA', text: 'Akan' },
  { value: 'ALA', text: 'Alawa' },
  { value: 'ALB', text: 'Albanian' },
  { value: 'ALY', text: 'Alyawarr' },
  { value: 'AME', text: 'American Languages' },
  { value: 'AMH', text: 'Amharic' },
  { value: 'ANI', text: 'Anindilyakwa' },
  { value: 'ANM', text: 'Anmatyerr, nfd' },
  { value: 'ARB', text: 'Arabana' },
  { value: 'ARA', text: 'Arabic' },
  { value: 'ARD', text: 'Arandic (Other)' },
  { value: 'ARM', text: 'Armenian' },
  { value: 'ADO', text: 'Arnhem Land and Daly River Region (Other) Languages' },
  { value: 'ARO', text: 'Aromunian (Macedo-Romanian)' },
  { value: 'ARR', text: 'Arrernte, nfd' },
  { value: 'ASM', text: 'Assamese' },
  { value: 'ASN', text: 'Assyrian Neo-Aramaic' },
  { value: 'AUS', text: 'Auslan' },
  { value: 'OAI', text: 'Australian Indigenous Languages (Other)' },
  { value: 'AZE', text: 'Azeri' },
  { value: 'BAL', text: 'Balinese' },
  { value: 'BAC', text: 'Balochi' },
  { value: 'BAN', text: 'Bandjalang' },
  { value: 'BNJ', text: 'Banyjima' },
  { value: 'BAR', text: 'Bardi' },
  { value: 'BAS', text: 'Basque' },
  { value: 'BAT', text: 'Batjala' },
  { value: 'BEL', text: 'Belorussian' },
  { value: 'BEM', text: 'Bemba' },
  { value: 'BEN', text: 'Bengali' },
  { value: 'BID', text: 'Bidjara' },
  { value: 'BIK', text: 'Bikol' },
  { value: 'BIL', text: 'Bilinarra' },
  { value: 'BIS', text: 'Bisaya' },
  { value: 'BSL', text: 'Bislama' },
  { value: 'BOS', text: 'Bosnian' },
  { value: 'BUL', text: 'Bulgarian' },
  { value: 'BUN', text: 'Bunuba' },
  { value: 'BRR', text: 'Burarra' },
  { value: 'BRN', text: 'Burmese' },
  { value: 'BUO', text: 'Burmese (Other)' },
  { value: 'CAN', text: 'Cantonese' },
  { value: 'CAP', text: 'Cape York Peninsula Languages (Other)' },
  { value: 'CAT', text: 'Catalan' },
  { value: 'CEB', text: 'Cebuano' },
  { value: 'CEO', text: 'Celtic (Other)' },
  { value: 'CNM', text: 'Central Anmatyerr' },
  { value: 'CNA', text: 'Chaldean Neo-Aramaic' },
  { value: 'HAK', text: 'Chin Haka' },
  { value: 'CIO', text: 'Chinese Other' },
  { value: 'CRE', text: 'Creole (other)' },
  { value: 'CRF', text: 'Creole French' },
  { value: 'CRO', text: 'Croatian' },
  { value: 'CYP', text: 'Cypriot' },
  { value: 'CZE', text: 'Czech' },
  { value: 'CZS', text: 'Czechoslovakian, so described' },
  { value: 'DAA', text: 'Daatiwuy' },
  { value: 'DAL', text: 'Dalabon' },
  { value: 'DAF', text: 'Dan (Gio-Dan)' },
  { value: 'DAN', text: 'Danish' },
  { value: 'DAR', text: 'Dari' },
  { value: 'DEF', text: 'Deaf' },
  { value: 'DLW', text: 'Dhalwangu' },
  { value: 'DHG', text: 'Dhanggatti' },
  { value: 'DNG', text: 'Dhangu' },
  { value: 'DNO', text: 'Dhangu (Other)' },
  { value: 'DYY', text: "Dhay'yi" },
  { value: 'DYO', text: "Dhay'yi (Other)" },
  { value: 'DHI', text: 'Dhivehi' },
  { value: 'DHA', text: 'Dhuwal' },
  { value: 'DHU', text: 'Dhuwal (Other)' },
  { value: 'DHO', text: 'Dhuwala' },
  { value: 'DHL', text: 'Dhuwala (Other)' },
  { value: 'DHW', text: 'Dhuwaya' },
  { value: 'DIN', text: 'Dinka' },
  { value: 'DIY', text: 'Diyari' },
  { value: 'DJB', text: 'Djabugay' },
  { value: 'DJM', text: 'Djambarrpuyngu' },
  { value: 'DJP', text: 'Djapu' },
  { value: 'DJA', text: 'Djarrwark' },
  { value: 'DJI', text: 'Djinang (Other)' },
  { value: 'DJN', text: 'Djinba' },
  { value: 'DJO', text: 'Djinba (Other)' },
  { value: 'DRO', text: 'Dravidian (Other)' },
  { value: 'DUT', text: 'Dutch' },
  { value: 'DYI', text: 'Dyirbal' },
  { value: 'DZO', text: 'Dzongkha' },
  { value: 'ENM', text: 'Eastern Anmatyerr' },
  { value: 'ERR', text: 'Eastern Arrernte' },
  { value: 'OEA', text: 'Eastern Asian Languages (Other)' },
  { value: 'EEO', text: 'Eastern European Languages (Other)' },
  { value: 'BIN', text: 'Edo' },
  { value: 'ENG', text: 'English' },
  { value: 'EST', text: 'Estonian' },
  { value: 'EWE', text: 'Ewe' },
  { value: 'FIJ', text: 'Fijian' },
  { value: 'FJH', text: 'Fijian Hindustani' },
  { value: 'FIL', text: 'Filipino' },
  { value: 'FIN', text: 'Finnish' },
  { value: 'FIO', text: 'Finnish and Related Languages' },
  { value: 'VLS', text: 'Flemish (alt: Vlaams)' },
  { value: 'FRE', text: 'French' },
  { value: 'FRI', text: 'Frisian' },
  { value: 'GA', text: 'Ga' },
  { value: 'GAE', text: 'Gaelic (Scotland)' },
  { value: 'GAL', text: 'Galpu' },
  { value: 'GAM', text: 'Gamilaraay' },
  { value: 'GAN', text: 'Ganalbingu' },
  { value: 'GAR', text: 'Garrwa' },
  { value: 'GAW', text: 'Garuwali' },
  { value: 'GEO', text: 'Georgian' },
  { value: 'GER', text: 'German' },
  { value: 'GIL', text: 'Gilbertese' },
  { value: 'GIR', text: 'Girramay' },
  { value: 'GIT', text: 'Githabul' },
  { value: 'GOL', text: 'Golumala' },
  { value: 'GOO', text: 'Gooniyandi' },
  { value: 'GRE', text: 'Greek' },
  { value: 'GUD', text: 'Gudanji' },
  { value: 'GUJ', text: 'Gujarati' },
  { value: 'GUM', text: 'Gumatj' },
  { value: 'GMB', text: 'Gumbaynggir' },
  { value: 'GNT', text: 'Gun-nartpa' },
  { value: 'GUN', text: 'Gundjeihmi' },
  { value: 'GUP', text: 'Gupapuyngu' },
  { value: 'GRG', text: 'Gurindji' },
  { value: 'GUK', text: 'Gurindji Kriol' },
  { value: 'GUR', text: 'Gurr-goni' },
  { value: 'GGY', text: 'Guugu Yimidhirr' },
  { value: 'GYM', text: 'Guyamirrilili' },
  { value: 'HKK', text: 'Hakka' },
  { value: 'HAR', text: 'Harari' },
  { value: 'HAU', text: 'Hausa' },
  { value: 'HAW', text: 'Hawaiian English' },
  { value: 'HAZ', text: 'Hazaraghi' },
  { value: 'HEB', text: 'Hebrew' },
  { value: 'HIN', text: 'Hindi' },
  { value: 'HMO', text: 'Hmong' },
  { value: 'HNO', text: 'Hmong-Mien (Other)' },
  { value: 'HOK', text: 'Hokkien' },
  { value: 'HUN', text: 'Hungarian' },
  { value: 'IBA', text: 'Iban' },
  { value: 'IBO', text: 'Iberian Romance (Other)' },
  { value: 'ICE', text: 'Icelandic' },
  { value: 'IGB', text: 'Igbo' },
  { value: 'ILK', text: 'Ilokano' },
  { value: 'ILN', text: 'Ilonggo (Hiligaynon)' },
  { value: 'INO', text: 'Indo-Aryan (Other)' },
  { value: 'IND', text: 'Indonesian' },
  { value: 'IRO', text: 'Iranic (Other)' },
  { value: 'IRI', text: 'Irish' },
  { value: 'ITA', text: 'Italian' },
  { value: 'IWA', text: 'Iwaidja' },
  { value: 'JAM', text: 'Jaminjung' },
  { value: 'JAP', text: 'Japanese' },
  { value: 'JAR', text: 'Jaru' },
  { value: 'JAV', text: 'Javanese' },
  { value: 'JAW', text: 'Jawoyn' },
  { value: 'JIN', text: 'Jingulu' },
  { value: 'KAL', text: 'Kalaw Kawaw Ya/Kalaw Lagaw Ya' },
  { value: 'KAA', text: 'Kanai' },
  { value: 'KAN', text: 'Kannada' },
  { value: 'KRJ', text: 'Karajarri' },
  { value: 'KAR', text: 'Karen' },
  { value: 'KRY', text: 'Kariyarra' },
  { value: 'KAS', text: 'Kashmiri' },
  { value: 'KAU', text: 'Kaurna' },
  { value: 'KYR', text: 'Kayardild' },
  { value: 'KAY', text: 'Kaytetye' },
  { value: 'KHM', text: 'Khmer' },
  { value: 'KIJ', text: 'Kija' },
  { value: 'KIK', text: 'Kikuyu' },
  { value: 'KIO', text: 'Kimberley Area Languages (Other)' },
  { value: 'KIN', text: 'Kinyarwanda (Rwanda)' },
  { value: 'KDI', text: 'Kirundi' },
  { value: 'KIW', text: 'Kiwai' },
  { value: 'KOK', text: 'Koko-Bera' },
  { value: 'KON', text: 'Konkani' },
  { value: 'KOR', text: 'Korean' },
  { value: 'KRO', text: 'Krio' },
  { value: 'KRI', text: 'Kriol' },
  { value: 'KKT', text: 'Kukatha' },
  { value: 'KKJ', text: 'Kukatja' },
  { value: 'KUK', text: 'Kuku Yalanji' },
  { value: 'KBL', text: 'Kunbarlang' },
  { value: 'KNE', text: 'Kune' },
  { value: 'KNJ', text: 'Kuninjku' },
  { value: 'KUN', text: 'Kunwinjku' },
  { value: 'KUR', text: 'Kurdish' },
  { value: 'KUT', text: 'Kuuk Thayorre' },
  { value: 'KUU', text: "Kuuku-Ya'u" },
  { value: 'LAM', text: 'Lamalama' },
  { value: 'LAO', text: 'Lao' },
  { value: 'LAD', text: 'Lardil' },
  { value: 'LAR', text: 'Larrakiya' },
  { value: 'LTI', text: 'Latin' },
  { value: 'LAT', text: 'Latvian' },
  { value: 'LET', text: 'Letzeburgish' },
  { value: 'LIR', text: 'Liberian (Liberian English)' },
  { value: 'LIG', text: 'Light Warlpiri' },
  { value: 'LIT', text: 'Lithuanian' },
  { value: 'LIY', text: 'Liyagalawumirr' },
  { value: 'LUG', text: 'Luganda' },
  { value: 'LUO', text: 'Luo' },
  { value: 'LUR', text: 'Luritja' },
  { value: 'MAC', text: 'Macedonian' },
  { value: 'MAD', text: 'Madarrpa' },
  { value: 'MHI', text: 'Madi' },
  { value: 'MAK', text: 'Makaton' },
  { value: 'MLK', text: 'Malak Malak' },
  { value: 'MAL', text: 'Malay' },
  { value: 'MLY', text: 'Malayalam' },
  { value: 'MLN', text: 'Malngin' },
  { value: 'MLT', text: 'Maltese' },
  { value: 'MAH', text: 'Manchu' },
  { value: 'MDN', text: 'Mandaean (Mandaic)' },
  { value: 'MAN', text: 'Mandarin' },
  { value: 'MNK', text: 'Mandinka' },
  { value: 'MGA', text: 'Mangala' },
  { value: 'MGY', text: 'Mangarrayi' },
  { value: 'MGL', text: 'Manggalili' },
  { value: 'MNY', text: 'Manyjilyjarra' },
  { value: 'MAO', text: 'Maori (Cook Island)' },
  { value: 'MRN', text: 'Maori (New Zealand)' },
  { value: 'MRA', text: 'Marathi' },
  { value: 'MAR', text: 'Maringarr' },
  { value: 'MRR', text: 'Marra' },
  { value: 'MRG', text: 'Marrangu' },
  { value: 'MRT', text: 'Marrithiyel' },
  { value: 'MTW', text: 'Martu Wangka' },
  { value: 'MTN', text: 'Matngala' },
  { value: 'MAG', text: 'Maung' },
  { value: 'MAU', text: 'Mauritian Creole' },
  { value: 'MAY', text: 'Mayali' },
  { value: 'MER', text: 'Meriam Mir' },
  { value: 'MES', text: 'Middle Eastern Semitic Languages (Other)' },
  { value: 'MNN', text: 'Min Nan' },
  { value: 'MIR', text: 'Miriwoong' },
  { value: 'MON', text: 'Mon' },
  { value: 'MKH', text: 'Mon-Khmer (Other)' },
  { value: 'MNG', text: 'Mongolian' },
  { value: 'MOT', text: 'Motu (HiriMotu)' },
  { value: 'MUD', text: 'Mudburra' },
  { value: 'MUR', text: 'Murrinh Patha' },
  { value: 'MRW', text: 'Muruwari' },
  { value: 'NAK', text: 'Na-kara' },
  { value: 'NAR', text: 'Narungga' },
  { value: 'NAU', text: 'Nauruan' },
  { value: 'NDE', text: 'Ndebele' },
  { value: 'NDJ', text: 'Ndjébbana (Gunavidji)' },
  { value: 'NEP', text: 'Nepali' },
  { value: 'NGY', text: 'Ngaanyatjarra' },
  { value: 'NGK', text: 'Ngalakgan' },
  { value: 'NGW', text: 'Ngaliwurru' },
  { value: 'NGG', text: "Ngan'gikurunggurr" },
  { value: 'NGU', text: 'Ngandi' },
  { value: 'NGD', text: 'Ngardi' },
  { value: 'NGR', text: 'Ngarinyin' },
  { value: 'NGN', text: 'Ngarinyman' },
  { value: 'NGL', text: 'Ngarluma' },
  { value: 'NGA', text: 'Ngarrindjeri' },
  { value: 'NHA', text: 'Nhangu' },
  { value: 'NIU', text: 'Niue' },
  { value: 'NFK', text: "Norf'k-Pitcairn" },
  { value: 'NDO', text: 'Northern Desert Fringe Area Languages (Other)' },
  { value: 'NOR', text: 'Norwegian' },
  { value: 'NUE', text: 'Nuer' },
  { value: 'NUG', text: 'Nungali' },
  { value: 'NUN', text: 'Nunggubuyu' },
  { value: 'NYA', text: 'Nyamal' },
  { value: 'NYG', text: 'Nyangumarta' },
  { value: 'NYJ', text: 'Nyanja (Chichewa)' },
  { value: 'NYK', text: 'Nyikina' },
  { value: 'NYU', text: 'Nyungar' },
  { value: 'OCO', text: 'Oceanian Pidgins and Creoles (Other)' },
  { value: 'ORI', text: 'Oriya' },
  { value: 'ORO', text: 'Oromo' },
  { value: 'YYY', text: 'Other (Not elsewhere classified)' },
  { value: 'PAA', text: 'Paakantyi' },
  { value: 'PLO', text: 'Pacific Austronesian Languages (Other)' },
  { value: 'PAL', text: 'Palyku/Nyiyaparli' },
  { value: 'PAM', text: 'Pampangan' },
  { value: 'PNO', text: 'Papua New Guinea Papuan Languages (Other)' },
  { value: 'PAS', text: 'Pashto' },
  { value: 'PER', text: 'Persian (excluding Dari)' },
  { value: 'PID', text: 'Pidgin' },
  { value: 'PIN', text: 'Pintupi' },
  { value: 'PTC', text: 'Pitcairnese' },
  { value: 'PIT', text: 'Pitjantjatjara' },
  { value: 'POL', text: 'Polish' },
  { value: 'POC', text: 'Portugese Creole' },
  { value: 'POR', text: 'Portuguese' },
  { value: 'PUN', text: 'Punjabi' },
  { value: 'REM', text: 'Rembarrnga' },
  { value: 'RJG', text: 'Rirratjingu' },
  { value: 'RIT', text: 'Ritharrngu' },
  { value: 'ROH', text: 'Rohingya' },
  { value: 'ROM', text: 'Romanian' },
  { value: 'RMN', text: 'Romany' },
  { value: 'ROT', text: 'Rotuman' },
  { value: 'RUS', text: 'Russian' },
  { value: 'SAM', text: 'Samoan' },
  { value: 'SCA', text: 'Scandinavian' },
  { value: 'SER', text: 'Serbian' },
  { value: 'SBC', text: 'Serbo-Croatian/Yugoslavian, so described' },
  { value: 'SEY', text: 'Seychelles Creole' },
  { value: 'SHI', text: 'Shilluk' },
  { value: 'SHO', text: 'Shona' },
  { value: 'SIO', text: 'Sign Languages (Other)' },
  { value: 'SNG', text: 'Sindhi' },
  { value: 'SIN', text: 'Sinhalese' },
  { value: 'SLV', text: 'Slovak' },
  { value: 'SLO', text: 'Slovene' },
  { value: 'SOL', text: 'Solomon Islands Pijin' },
  { value: 'SOM', text: 'Somali' },
  { value: 'SOO', text: 'Southeast Asian Languages (Austronesian) Other' },
  { value: 'SNO', text: 'Southeast Asian Languages (Other)' },
  { value: 'SAO', text: 'Southern Asian (Other) Languages' },
  { value: 'STO', text: 'Southern European Languages (Other)' },
  { value: 'SWO', text: 'Southwest and Central Asian Languages (Other)' },
  { value: 'SPA', text: 'Spanish' },
  { value: 'SWA', text: 'Swahili' },
  { value: 'SWE', text: 'Swedish' },
  { value: 'GSW', text: 'Swiss German' },
  { value: 'TAG', text: 'Tagalog' },
  { value: 'TAI', text: 'Tai (Other)' },
  { value: 'TAM', text: 'Tamil' },
  { value: 'TAT', text: 'Tatar' },
  { value: 'TEL', text: 'Telugu' },
  { value: 'TEO', text: 'Teochew' },
  { value: 'TET', text: 'Tetum' },
  { value: 'THA', text: 'Thai' },
  { value: 'TIB', text: 'Tibetan' },
  { value: 'TIG', text: 'Tigre' },
  { value: 'TGY', text: 'Tigrinya' },
  { value: 'TIM', text: 'Timorese' },
  { value: 'TIW', text: 'Tiwi' },
  { value: 'TKP', text: 'Tok Pisin (Neomelanesian)' },
  { value: 'TOK', text: 'Tokelauan' },
  { value: 'TON', text: 'Tongan' },
  { value: 'TOR', text: 'Torres Strait Creole' },
  { value: 'TSW', text: 'Tswana' },
  { value: 'TUL', text: 'Tulu' },
  { value: 'TUO', text: 'Turkic (Other)' },
  { value: 'TUR', text: 'Turkish' },
  { value: 'TRK', text: 'Turkmen' },
  { value: 'TVL', text: 'Tuvaluan' },
  { value: 'UKR', text: 'Ukrainian' },
  { value: 'URD', text: 'Urdu' },
  { value: 'UYG', text: 'Uygur' },
  { value: 'UZB', text: 'Uzbek' },
  { value: 'VIE', text: 'Vietnamese' },
  { value: 'WAJ', text: 'Wajarri' },
  { value: 'WAL', text: 'Walmajarri' },
  { value: 'WAM', text: 'Wambaya' },
  { value: 'WNG', text: 'Wangkajunga' },
  { value: 'WNK', text: 'Wangkatha' },
  { value: 'WAN', text: 'Wangurri' },
  { value: 'WNJ', text: 'Wanyjirra' },
  { value: 'WAR', text: 'Wardaman' },
  { value: 'WRL', text: 'Warlmanpa' },
  { value: 'WLP', text: 'Warlpiri' },
  { value: 'WNM', text: 'Warnman' },
  { value: 'WMG', text: 'Warumungu' },
  { value: 'WEL', text: 'Welsh' },
  { value: 'WRT', text: 'Western Arrarnta' },
  { value: 'WEO', text: 'Western Desert Language (Other)' },
  { value: 'WIK', text: 'Wik Mungkan' },
  { value: 'WIN', text: 'Wik Ngathan' },
  { value: 'WIR', text: 'Wiradjuri' },
  { value: 'WOR', text: 'Worla' },
  { value: 'WRR', text: 'Worrorra' },
  { value: 'WU', text: 'Wu' },
  { value: 'WUB', text: 'Wubulkarra' },
  { value: 'WUN', text: 'Wunambal' },
  { value: 'WUR', text: 'Wurlaki' },
  { value: 'XHO', text: 'Xhosa' },
  { value: 'YAK', text: 'Yakuy (Other)' },
  { value: 'YAN', text: 'Yankunytjatjara' },
  { value: 'YNY', text: 'Yanyuwa' },
  { value: 'YAP', text: 'Yapese' },
  { value: 'YAW', text: 'Yawuru' },
  { value: 'YID', text: 'Yiddish' },
  { value: 'YDY', text: 'Yidiny' },
  { value: 'YIN', text: 'Yindjibarndi' },
  { value: 'YBH', text: 'Yinhawangka' },
  { value: 'OYM', text: 'Yolngu Matha (Other)' },
  { value: 'YOR', text: 'Yorta Yorta' },
  { value: 'YRB', text: 'Yoruba' },
  { value: 'YUL', text: 'Yulparija' },
  { value: 'ZUL', text: 'Zulu' }
]
