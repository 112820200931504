<template>
  <loading-view v-if="loading" loading-text="processing, one moment..." />
</template>

<script>
import jwtDecode from 'jwt-decode'
import LoadingView from '@/components/app/FullScreenLoader'
import ehubUtil from '@/utils/ehub'
import endPoint from '@/store/endPoint'
import { API_ERROR_SUBTYPES } from '@/constants'

export default {
  name: 'EHubAcceptOoaOffer',
  components: { LoadingView },
  mounted() {
    this.acceptOoaOffer()
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    async acceptOoaOffer() {
      const { idToken, refreshToken, ooaOffer } = ehubUtil.getOoaOfferData()
      if (!idToken || !refreshToken || !ooaOffer) {
        this.$router.history.push('/error')
      }
      this.$store.commit('set', ['auth.isUserActive', true])
      this.$store.commit('set', ['auth.isUserActiveTime', new Date().getTime()])

      const email = jwtDecode(idToken).email
      const parentId = jwtDecode(idToken)['custom:pid']
      this.setStore(idToken, refreshToken, email)

      const schoolCode = ooaOffer.schoolCode || ''
      const srn = ooaOffer.srn || ''

      try {
        const promises = []
        promises.push(endPoint.getOOAOffers(idToken, schoolCode))
        if (srn && parentId) {
          promises.push(endPoint.getErnRecordBySRN(idToken, srn))
        }
        const response = await Promise.all(promises)
        if (response && response.length) {
          this.loading = false
          const ooaOfferResponse = response[0]
          const ooaApplication = this.getOoaApplicationFromResponse(
            ooaOfferResponse,
            ooaOffer
          )
          let ernData = null
          if (response.length > 1) {
            const ernResponse = response[1]
            if (ernResponse && ernResponse.data) {
              ernData = ernResponse.data.body
            }
          }
          await this.setAppData(
            schoolCode,
            ooaApplication,
            ernData,
            idToken,
            parentId
          )
          this.startApp()
        } else {
          this.$router.history.push('/error')
        }
      } catch (error) {
        this.$store.dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.getApplication
        })
        this.$router.history.push('/error')
      }
    },
    getOoaApplicationFromResponse(ooaOfferResponse, ooaOffer) {
      let ooaApplication = null
      if (ooaOfferResponse && ooaOfferResponse.data) {
        const ooaOfferResultApplicationWraps =
          ooaOfferResponse.data.studentApplications
        if (
          Array.isArray(ooaOfferResultApplicationWraps) &&
          ooaOfferResultApplicationWraps.length
        ) {
          const ooaApplicationWrap = ooaOfferResultApplicationWraps.find(
            (appWrap) => {
              return (
                appWrap.application.ooaApplicationID === ooaOffer.applicationId
              )
            }
          )
          if (ooaApplicationWrap) {
            ooaApplication = ooaApplicationWrap.application
          }
        }
      }
      return ooaApplication
    },
    setStore(idToken, refreshToken, email) {
      this.$store.commit('set', ['auth.idToken', idToken])
      this.$store.commit('set', ['auth.idTokenTime', new Date().getTime()])
      this.$store.commit('set', ['auth.refreshToken', refreshToken])
      this.$store.commit('set', ['auth.userId', email])
      this.$store.commit('set', ['resumeViaEmail', true])
    },
    setApplicationMega(ooaApplication) {
      if (ooaApplication) {
        // add meta data for each parent and carer as required by the Core UI
        ooaApplication.meta = {}
        // add calendar year meta data for student age validation in PI using the intended start date
        if (
          ooaApplication.intendedStartDate === 'Choose a specific start date'
        ) {
          if (ooaApplication.enterStartDate) {
            ooaApplication.meta.calendarYear =
              ooaApplication.enterStartDate.split('-')[0]
          }
        } else {
          if (ooaApplication.intendedStartDate) {
            ooaApplication.meta.calendarYear =
              ooaApplication.intendedStartDate.split('-')[0]
          }
        }
        if (
          Array.isArray(ooaApplication.parentCarers) &&
          ooaApplication.parentCarers.length
        ) {
          ooaApplication.parentCarers.forEach((parentCarer) => {
            parentCarer.meta = {
              isCompletingApplication: parentCarer.isCompletingApplication
            }
          })
        }
      }
      return ooaApplication
    },
    setApplicationByErnData(ooaApplication, ernData, parentId) {
      if (ooaApplication) {
        ooaApplication.meta = {
          ...ooaApplication.meta,
          isEhubLinkedStudent: false, // FUS-1233 only true when there is a
          viaEhub: true,
          parentId
        }
        if (ernData) {
          // prefill with ern parent data
          if (
            Array.isArray(ooaApplication.parentCarers) &&
            ooaApplication.parentCarers.length
          ) {
            const firstParent = ooaApplication.parentCarers[0]
            const parentCarerFromErn = ernData.parentCarer
            if (firstParent && parentCarerFromErn) {
              firstParent.parentCarerCountryBirth =
                parentCarerFromErn.countryBirth
              firstParent.isParentCarerAboriOrTorres =
                parentCarerFromErn.isAboriOrTorres
              firstParent.parentCarerOccupationName =
                parentCarerFromErn.occupationName
              firstParent.parentCarerOccupationGroup =
                parentCarerFromErn.occupationGroup
              firstParent.parentCarerSchoolEducationCode =
                parentCarerFromErn.schoolEducationCode
              firstParent.parentCarerEducationQualificationCode =
                parentCarerFromErn.educationQualificationCode
              firstParent.isParentCarerSpeakLangOtherEnglish =
                parentCarerFromErn.speakLanguageOtherThanEnglish
              firstParent.parentCarerMainLanguage =
                parentCarerFromErn.mainLangOtherThanEnglish
              firstParent.parentCarerSpeakLang =
                parentCarerFromErn.otherLanguage
              firstParent.parentCarerGenderCode = parentCarerFromErn.gender
            }
          }
          // prefill with ern student data
          const studentFromErn = ernData.student
          if (studentFromErn) {
            if (!ooaApplication.student) {
              ooaApplication.student = {}
            }
            ooaApplication.meta.isEhubLinkedStudent = true //// FUS-1233 only true when there is a SRN available
            ooaApplication.student.countryBorn = studentFromErn.countryBorn
            ooaApplication.student.isAboriTorStraitIslander =
              studentFromErn.isAboriTorStraitIslander
            ooaApplication.student.homeLangOtherThanEnglish =
              studentFromErn.homeLangOtherThanEnglish
            ooaApplication.student.mainLanguageOtherThanEnglish =
              studentFromErn.mainLangOtherThanEnglish
            ooaApplication.student.otherLanguage = studentFromErn.otherLanguage
            ooaApplication.dateArriveAustralia =
              studentFromErn.arrivedInAustrliaDate
            ooaApplication.visaSubClass = studentFromErn.visaSubclassCode
            ooaApplication.genderCode = studentFromErn.gender
          }
        }
      }
      return ooaApplication
    },
    async setAppData(schoolCode, ooaApplication, ernData, idToken, parentId) {
      const school = await this.$store.dispatch(
        'getSchool',
        new String(schoolCode)
      )
      let filledOoaApplication = this.setApplicationMega(ooaApplication)
      filledOoaApplication = this.setApplicationByErnData(
        filledOoaApplication,
        ernData,
        parentId
      )
      this.$store.commit('set', ['isNewApplication', true])
      this.$store.commit('set', ['application', filledOoaApplication])
      this.$store.commit('set', ['school', school])
    },
    startApp() {
      this.$store
        .dispatch('cancelApplication')
        .then(() => {
          this.$store
            .dispatch('createApplication')
            .then(() => {
              //Google Analytics
              this.$gtm.trackEvent({
                event: 'interaction',
                category: 'Form Attempt',
                action: 'start',
                label: 'ooaconverted',
                application: ehubUtil.getGtmApplication()
              })
              this.$router.history.push('/application')
            })
            .catch((error) => {
              this.$store.dispatch('hideSpinner')
              this.$store.dispatch('setAPIError', {
                error,
                fallbackCode: API_ERROR_SUBTYPES.getApplication
              })
              this.$router.history.push('/error')
            })
        })
        .catch((error) => {
          //handle API errors
          this.$store.dispatch('setAPIError', {
            error,
            fallbackCode: API_ERROR_SUBTYPES.cancelApplication
          })
          this.$router.history.push('/error')
        })
    }
  }
}
</script>
