<template>
  <div class="ApplicationLoginTimeout">
    <b-container class="content">
      <div class="icon-row">
        <img class="error-icon" src="@/assets/icon-timeout.png" />
      </div>
      <b-row>
        <b-col>
          <h2>Your session has timed out</h2>
          <button
            v-if="fromEhub"
            class="btn-link"
            type="button"
            @click="ehubLogin"
          >
            Login
          </button>
          <button v-else class="btn-link" type="button" @click="resumeApp">
            Resume application
          </button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ehubUtil from '@/utils/ehub'

export default {
  name: 'ApplicationLoginTimeout',
  computed: {
    fromEhub() {
      return ehubUtil.isFromEhub()
    }
  },
  methods: {
    resumeApp() {
      this.$emit('clicked')
    },
    ehubLogin() {
      window.onbeforeunload = null
      window.location.href = `${window.location.origin}/login`
    }
  }
}
</script>

<style lang="scss" scoped>
.ApplicationLoginTimeout {
  position: relative;
  top: $header-height;

  .content {
    margin: 0 auto 3rem;
    padding: 1rem 2rem 3rem;
    text-align: center;
    .icon-row {
      position: relative;
      width: 230px;
      height: 230px;
      background: $color-white;
      border-radius: 50%;
      margin: 5rem auto;
      .error-icon {
        width: 134px;
        height: auto;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
    }
    h2 {
      font-size: 28px;
    }
    .btn-link {
      font-size: 1.6rem;
      &:focus {
        outline: 3px solid #005fcc;
      }
    }
  }
}
</style>
