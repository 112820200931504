import { isValidGUID } from './isValidGuid'
import { extractContentBetweenParentheses } from './extractContentBetweenParentheses'

export default function (sreSeeString) {
  if (
    typeof sreSeeString === 'undefined' ||
    sreSeeString === null ||
    sreSeeString === ''
  ) {
    return null
  }

  const selectedOption = sreSeeString.trim().split(':')
  const sreSeeAmaSelectionLabel =
    selectedOption && selectedOption.length > 0 ? selectedOption[0] : null

  if (sreSeeAmaSelectionLabel === '') {
    return null
  }

  const selectedOptionContent =
    selectedOption && selectedOption.length > 1 ? selectedOption[1] : ''
  const optionSplitResult = selectedOptionContent
    ? selectedOptionContent.split('(')
    : []
  const sreSeeAmaSelectionName =
    optionSplitResult && optionSplitResult.length > 0
      ? optionSplitResult[0].trim()
      : null

  if (sreSeeAmaSelectionName === null) {
    return null
  }

  const contentBetweenParentheses = extractContentBetweenParentheses(
    selectedOptionContent
  )
  const providerSplitResult = contentBetweenParentheses
    ? contentBetweenParentheses.split(',')
    : []
  const sreSeeAmaSelectionProviders =
    providerSplitResult && providerSplitResult.length > 0
      ? providerSplitResult.join('|')
      : null

  if (sreSeeAmaSelectionProviders === null) {
    return null
  }

  const splitSelectedOptionContent = selectedOptionContent.split('|')
  const sreSeeAmaOptionSetGuid =
    splitSelectedOptionContent.length > 0
      ? isValidGUID(
          splitSelectedOptionContent[
            splitSelectedOptionContent.length - 1
          ].trim()
        )
        ? splitSelectedOptionContent[
            splitSelectedOptionContent.length - 1
          ].trim()
        : null
      : null

  return {
    sreSeeAmaSelectionLabel,
    sreSeeAmaSelectionName,
    sreSeeAmaSelectionProviders,
    sreSeeAmaOptionSetGuid
  }
}
