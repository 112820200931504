<template>
  <header
    class="AppHeader"
    :class="{
      'AppHeader--training': isTraining,
      'AppHeader--resume': showResume
    }"
  >
    <b-container>
      <b-row>
        <b-col>
          <div
            class="site-header"
            :class="{ 'site-header--has-menu': inApplication }"
          >
            <button
              @click="toggleMenu"
              v-if="inApplication || isAuthenticated"
              class="site-header__menu-trigger"
              aria-label="Steps"
              :aria-expanded="(!!hasMenuOpen).toString()"
            >
              <font-awesome-icon
                v-if="!hasMenuOpen"
                class="icon fa-2x"
                icon="bars"
              />
              <font-awesome-icon v-else class="icon fa-2x" icon="times" />
            </button>
            <a :href="nswEducationUrl" class="logo" target="_blank">
              <img
                class="site-header__logo"
                src="@/assets/logo.svg"
                alt="NSW Government"
              />
            </a>

            <h1 class="site-header__title site-header__title--full">
              {{ titleFull }}
            </h1>
            <h1 class="site-header__title site-header__title--short">
              {{ titleShort }}
            </h1>

            <div class="site-header__actions">
              <ChipDropdown
                v-if="isTraining"
                :menu-items="menuItems"
                sub-header="Training Options"
                chip-class="training-dropdown"
              >
                <template #title>Training - PI</template>
              </ChipDropdown>

              <div
                v-if="inApplication"
                class="site-header__status"
                :class="[
                  { 'site-header__status--saving': isSaving },
                  { 'site-header__status--saved': isSaved }
                ]"
              >
                <template v-if="isSaving">
                  <font-awesome-icon
                    class="site-header__status-icon"
                    icon="circle-notch"
                  />
                  <span class="site-header__status-text">Saving&hellip;</span>
                </template>

                <template v-if="isSaved">
                  <font-awesome-icon
                    class="site-header__status-icon"
                    :icon="['far', 'check-circle']"
                  />
                  <span class="site-header__status-text">Saved</span>
                </template>
              </div>

              <div v-if="showResume" class="site-header__resume">
                <router-link
                  to="/login/resume"
                  class="site-header__resume-link"
                >
                  <img
                    aria-hidden="true"
                    class="site-header__resume-icon"
                    src="@/assets/icon-edit.svg"
                  />
                  Resume application
                </router-link>
              </div>
            </div>
            <!-- Ehub Menu -->
            <IconDropdown
              v-if="fromEhub"
              :menu-items="ehubSettingMenuItems"
              icon-label="Setting"
              icon="mdi-cog-outline"
              icon-class="settings-menu"
              color="blue lighten-4"
              :icon-click="onSettingIconClick"
            >
              <template #footer>
                <v-divider v-if="settingMenuItems.length"></v-divider>
                <v-list-item
                  class="settings-menu__item--logout"
                  @click="logoutToEhub"
                >
                  <v-icon size="20" color="primary" class="mr-2"
                    >mdi-logout</v-icon
                  >
                  <span class="text">{{
                    fromEhub ? 'Log out' : 'Sign out'
                  }}</span>
                </v-list-item>
              </template>
            </IconDropdown>

            <!-- Core menu -->
            <IconDropdown
              v-else-if="(inApplication || isAuthenticated) && !fromEhub"
              :menu-items="settingMenuItems"
              sub-header="Enrolment options"
              icon-label="Setting"
              icon="mdi-cog-outline"
              icon-class="settings-menu"
              color="blue lighten-4"
              :icon-click="onSettingIconClick"
            >
              <template #footer>
                <v-divider v-if="settingMenuItems.length"></v-divider>
                <v-list-item
                  class="settings-menu__item--logout"
                  @click="openLogoutModal"
                >
                  <v-icon size="20" color="primary" class="mr-2"
                    >mdi-logout</v-icon
                  >
                  <span class="text">Sign out</span>
                </v-list-item>
              </template>
            </IconDropdown>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
const ChipDropdown = () => import('@/components/app/ChipDropdown')
import IconDropdown from '@/components/app/IconDropdown'
import { mapGetters } from 'vuex'
import application from '../mixins/application'
import { NSW_EDUCATION_URL } from '@/constants'
import ehubUtil from '@/utils/ehub'

export default {
  name: 'AppHeader',
  components: { ChipDropdown, IconDropdown },
  mixins: [application],
  data() {
    return {
      defaultTitle: 'School Enrolment Application',
      siURL: process.env.VUE_APP_SI_BASE_URL,
      nswEducationUrl: NSW_EDUCATION_URL
    }
  },
  computed: {
    ...mapGetters(['blockNavigation']),
    isSaving() {
      return this.$store.state.saveStatus === 'saving'
    },
    isSaved() {
      return this.$store.state.saveStatus === 'saved'
    },
    isTraining() {
      return this.$store.state.inTrainingMode
    },
    hasMenuOpen() {
      return this.$store.state.showMenu
    },
    inApplication() {
      return this.$route.name === 'ApplicationView'
    },
    // display settings menu on PendingOffers and Privacy page
    isAuthenticated() {
      return (
        this.$route.name === 'PendingOffers' ||
        (this.$route.name === 'PilotView' && this.$store.state.auth.idToken)
      )
    },
    showResume() {
      return (
        this.$route.name === 'WelcomeView' ||
        this.$route.name === 'SchoolReminderView'
      )
    },
    currentApplication() {
      return this.$store.state.application
    },
    schoolName() {
      const appSchoolName =
        this.currentApplication && this.currentApplication.schoolName
      const schoolName =
        this.$store.state.school && this.$store.state.school.schoolName
      return appSchoolName || schoolName
    },
    titleFull() {
      return this.schoolName && !this.showResume
        ? `${this.defaultTitle} - ${this.schoolName}`
        : this.defaultTitle
    },
    titleShort() {
      return this.schoolName && !this.showResume
        ? this.schoolName
        : this.defaultTitle
    },
    menuItems() {
      return [
        {
          text: 'Training - PI (Parent Interface)',
          selected: true,
          disabled: true
        },
        {
          text: 'Training - SI (School Interface)',
          externalLink: this.siURL
        }
      ]
    },
    settingMenuItems() {
      if (this.inApplication) {
        const self = this
        return [
          {
            icon: 'mdi-minus-circle-outline',
            text: 'Cancel this application',
            class: 'settings-menu__item--cancel-application',
            onClick() {
              //Google Analytics
              const gtmApplication = ehubUtil.getGtmApplication()
              self.$gtm.trackEvent({
                event: 'interaction',
                category: 'Settings',
                action: 'Cancel',
                label: 'Cancel intent',
                application: gtmApplication
              })

              if (self.blockNavigation) {
                self.handleBlockNavigation()
                return
              }

              if (
                (self.currentApplication &&
                  self.currentApplication.ooaApplicationID) ||
                self.currentApplication.eoiID
              ) {
                // cancelling ooa app routes to ooaOffers page
                self.$store.dispatch('openModal', {
                  componentName: 'ModalCancel',
                  logout: false,
                  isOoa: self.currentApplication.ooaApplicationID,
                  isY67T: self.currentApplication.eoiID,
                  onCancelCallback: self.goToOoaOffers,
                  size: 'small'
                })
                return
              }

              self.$store.dispatch('openModal', {
                componentName: 'ModalCancel',
                logout: true,
                size: 'small'
              })
            }
          }
        ]
      } else {
        return []
      }
    },
    ehubSettingMenuItems() {
      const self = this
      return [
        {
          icon: 'mdi-arrow-left',
          text: 'Dashboard',
          class: 'settings-menu__item--cancel-application',
          onClick() {
            self.gotoEhub()
          }
        }
      ]
    },
    fromEhub() {
      return ehubUtil.isFromEhub()
    }
  },
  methods: {
    goToOoaOffers() {
      this.$store.dispatch('set', ['isNewApplication', false])
      this.$store.dispatch('set', ['resumeViaEmail', false])
      this.getOffers(this.getStudentName(this.currentApplication.student)) // login mixin
    },
    getStudentName(student) {
      if (student) {
        const studentNames = [
          student.firstName || '',
          student.otherName || '',
          student.familyName || ''
        ]
          .map((s) => s.trim())
          .filter((s) => s !== '')
        return studentNames.length ? studentNames.join(' ') : 'No name provided'
      }
      return 'No name provided'
    },
    openMenu() {
      this.$emit('open-menu')
    },
    closeMenu() {
      this.$emit('close-menu')
    },
    toggleMenu() {
      this.hasMenuOpen ? this.closeMenu() : this.openMenu()
    },
    onSettingIconClick() {
      //Google Analytics
      const gtmApplication = ehubUtil.getGtmApplication()
      this.$gtm.trackEvent({
        event: 'interaction',
        category: 'Settings',
        action: 'Menu',
        application: gtmApplication
      })
    },
    openLogoutModal() {
      if (this.blockNavigation) {
        this.handleBlockNavigation()
      } else {
        this.$store.dispatch('openModal', {
          componentName: 'ModalLogout',
          size: 'small'
        })
      }
    },
    async logoutToEhub() {
      await this.$store.dispatch('logout')
      //window.location.href = `${window.location.origin}/logout`
      const cognitoLogoutFullUrl = ehubUtil.getCognitoLogoutUrl()
      window.location.href = cognitoLogoutFullUrl
    },
    gotoEhub() {
      window.onbeforeunload = null
      window.location.href = `${window.location.origin}/dashboard`
    }
  }
}
</script>

<style lang="scss">
.AppHeader {
  z-index: z('header');
  position: fixed;
  width: 100%;
  border-bottom: solid 1px $color-white;
  background-color: $color-primary;
  color: $color-white;
  font-size: 1rem; // Reset to base
  line-height: 1;

  a.logo {
    display: inline-block;
    &:focus,
    &:active {
      box-shadow: 0 0 0 1px white;
    }
  }

  &.AppHeader--training {
    background-color: $color-training;
    border-color: $color-training;
    border-bottom-color: white;

    .site-header--has-menu .site-header__actions {
      flex: 1;
    }

    .site-header__resume {
      background-color: $color-training-secondary;
    }
  }

  .site-header {
    display: flex;
    align-items: center;
    padding-top: 1.3em;
    padding-bottom: 1.3em;
  }

  .site-header__actions {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .site-header--has-menu {
    .dropdown__button {
      margin-left: 1.5em;
    }
  }

  .site-header__logo {
    height: 4em;
  }

  .site-header--has-menu .site-header__logo {
    margin-left: auto;
  }

  .site-header__title {
    margin-bottom: 0;
    margin-left: 1em;
    margin-right: 1em;
    font-weight: $text-medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.site-header__title--full {
      display: none;
    }
  }

  .site-header__menu-trigger {
    // Set a fixed width so as to avoid the text of the title jumping when the
    // icon is swapped between open and close states.
    @include button-reset;
    width: 18px;
  }

  .site-header__status {
    display: none;
    align-items: center;
    margin-left: auto;
    font-weight: $text-bold;
  }

  .site-header__status-icon {
    font-size: 2.5rem;
  }

  .site-header__status--saving .site-header__status-icon {
    animation: statusSpin 3s infinite linear;
  }

  .site-header__status-text {
    margin-left: 1rem;
    font-size: 1.5em;
  }

  .site-header__resume {
    margin-left: auto;
  }

  .site-header__resume-link {
    padding: 0.7rem 1rem;
    border-radius: 4px;
    background-color: $color-red;
    font-weight: $text-bold;
    font-size: $text-small;
    outline: none;
    cursor: pointer;
    transition: $basic-transition;
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: $color-white;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 0 2px $color-white;
    }
  }

  .site-header__resume-icon {
    margin-right: 0.8rem;
    width: 2rem;
  }
}

.text {
  color: $ads-grey-01 !important;
}
// #DSE-1196: Custom MQ, deliberately kept out of the wider styles as it is
// unique to this component alone.
@media (min-width: 720px) {
  .AppHeader .site-header__title {
    display: block;
  }
}

@include desktop {
  .AppHeader {
    z-index: z('menu');
    height: 74px;
    .logo {
      max-width: 42px;
      margin-right: 1rem;
    }
    .title {
      font-size: 17px;
      line-height: normal;
    }

    .site-header__status {
      display: flex;
    }

    .site-header--has-menu {
      flex-direction: row-reverse;

      .dropdown__button {
        margin-left: 0em;
      }

      .site-header__actions {
        flex: 1;
      }
    }

    .site-header {
      flex-direction: row;
    }

    .site-header--has-menu .site-header__logo {
      margin-left: 0;
    }

    .site-header__title {
      font-size: 2.1em;

      &.site-header__title--full {
        display: block;
      }

      &.site-header__title--short {
        display: none;
      }
    }

    .site-header__menu-trigger {
      display: none;
    }
  }
}

@include tablet {
  .dropdown__button {
    margin-left: 1.5rem;
  }

  .site-header--has-menu {
    .site-header__logo {
      display: none;
    }
  }
}

@include tablet-wide {
  .site-header--has-menu {
    .site-header__logo {
      display: none;
    }
  }
}

@include mobile {
  .AppHeader {
    .site-header__title {
      font-size: 1.7em;
      letter-spacing: -1px;
    }

    &--training {
      &.AppHeader--resume .site-header {
        align-items: flex-start;
      }

      .site-header {
        &__logo {
          height: 32px;
        }

        &--has-menu .site-header__actions {
          flex-direction: row;
          align-items: center;
          margin-left: 0;

          .dropdown__button {
            margin-left: 1.5rem;
          }
        }

        &__actions {
          flex-direction: column;
          align-items: flex-start;
          margin-left: 1.5rem;
        }

        &__resume {
          margin-top: 1.5rem;
          margin-left: 0;
        }

        .dropdown__button {
          margin-left: 0;
          margin-top: 0;
        }
      }
    }
  }
}

@media print {
  .AppHeader {
    display: none;
  }
}

@keyframes statusSpin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
