import { FIELD_TYPE, ERROR_MESSAGES } from '@/constants'

import { getFirstName, getHeading, getLabelName } from '../helper'
import address from '@/mock/helper/address'

export default {
  apiKey: 'doctorDetails',
  elemId: 'doctorDetailsScroll',
  type: FIELD_TYPE.COLLECTION,
  description(application) {
    return getLabelName(
      'Please add all doctor or medical specialists who may currently be treating ',
      getFirstName(application),
      ':',
      'the student'
    )
  },
  heading(contactItem, index) {
    return getHeading(`Doctor ${index}`, contactItem.doctorName)
  },
  headingAdd: 'Add another doctor/medical centre',
  newItem: {
    doctorRecordNo: null,
    doctorName: '',
    doctorAddress: address,
    doctorPhoneNumber: ''
  },
  fields(doctorItem, doctorIndex) {
    var apiKeyPrefix = `${this.apiKey}[${doctorIndex}]`
    const address = doctorItem.doctorAddress

    return [
      {
        type: FIELD_TYPE.NOTIFICATION,
        notificationProps: {
          inPage: true,
          type: 'error',
          customStyle: true
        },
        text() {
          return 'Please provide all doctor/medical centre information'
        },
        visible() {
          if (
            !doctorItem.doctorPhoneNumber &&
            !doctorItem.doctorName &&
            !(address && address.countryCode)
          ) {
            return false
          }
          if (
            doctorItem.doctorPhoneNumber &&
            doctorItem.doctorName &&
            address.countryCode === 'AUS'
          ) {
            return !(
              address.street &&
              address.stateCode &&
              address.suburbName &&
              address.postCode
            )
          }
          if (
            doctorItem.doctorPhoneNumber &&
            doctorItem.doctorName &&
            address.countryCode &&
            address.countryCode !== 'AUS'
          ) {
            return !address.addressLine1
          }
          return true
        }
      },
      {
        apiKey: apiKeyPrefix + '.doctorName',
        label: `Doctor's name/medical centre`,
        placeHolder: "Enter doctor's name/medical centre",
        description: 'The name of a medical centre is also accepted.',
        required() {
          return !!(
            doctorItem.doctorPhoneNumber ||
            doctorItem.doctorName ||
            (address && address.countryCode)
          )
        },
        validation(value) {
          if (!/^[a-zA-Z ()'-]+$/.test(value)) {
            return ERROR_MESSAGES.NAME
          }
        },
        maxLength: 132
      },
      {
        apiKey: apiKeyPrefix + '.doctorAddress',
        label: "Doctor's address",
        type: FIELD_TYPE.ADDRESS,
        withPoBox: true,
        required() {
          return !!(
            doctorItem.doctorPhoneNumber ||
            doctorItem.doctorName ||
            (address && address.countryCode)
          )
        }
      },
      {
        apiKey: apiKeyPrefix + '.doctorPhoneNumber',
        label: 'Phone number',
        placeHolder: 'Enter phone number',
        description(application) {
          const name = getFirstName(application)
          return getLabelName(
            'In special circumstances the school may ask permission to contact ',
            name,
            "'s doctor.",
            'the student'
          )
        },
        required() {
          const address = doctorItem.doctorAddress
          return !!(
            doctorItem.doctorPhoneNumber ||
            doctorItem.doctorName ||
            (address && address.countryCode)
          )
        },
        type: FIELD_TYPE.PHONE
      }
    ]
  }
}
