export default {
  arrayObjectToObject(data) {
    let result = {}
    data.map((item) => {
      result[item.name] = item.value
    })
    return result
  },

  clone(obj) {
    var clone = JSON.stringify(obj)
    return JSON.parse(clone)
  },

  getMaxCharacter(field) {
    if (field.maxCharacter) {
      return field.maxCharacter
    }
  },

  getRows(field) {
    if (field.maxRows) {
      return field.maxRows
    }
  },

  titleCase(value) {
    const string = value.toLowerCase()
    return string.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase())
  },

  addressToTitleCaseString(address) {
    // Only assign if successful
    if (address.addressLine1 && address.suburbName && address.stateCode) {
      return (
        this.titleCase(address.addressLine1) +
        ', ' +
        (address.addressLine2
          ? this.titleCase(address.addressLine2) + ', '
          : '') +
        this.titleCase(address.suburbName) +
        ' ' +
        address.stateCode +
        ' ' +
        address.postCode
      )
    } else if (address.addressLine1) {
      return (
        this.titleCase(address.addressLine1()) +
        (address.addressLine2
          ? ', ' + this.titleCase(address.addressLine2)
          : '')
      )
    } else {
      return ''
    }
  }
}
