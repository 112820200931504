const browserStorageUtils = (storageType = 'session') => {
  // Setup
  const storageKey = `${storageType}Storage`
  const storage = window[storageKey]

  // Functions
  // Setters
  const setItem = (key, value) => storage.setItem(key, JSON.stringify(value))
  const setItems = (obj) =>
    Object.entries(obj).forEach(([key, value]) => setItem(key, value))

  // Getters
  const getItem = (key) => {
    if (storage.getItem(key)) {
      return JSON.parse(storage.getItem(key))
    }
    return null
  }

  const getItems = (keys) =>
    keys.reduce((acc, key) => ({ ...acc, [key]: getItem(key) }), {})

  // Remove
  const removeItem = (key) => storage.removeItem(key)
  const removeItems = (keys) => keys.forEach((key) => removeItem(key))

  return {
    setItem,
    setItems,
    getItem,
    getItems,
    removeItem,
    removeItems
  }
}

const sessionStorageUtils = browserStorageUtils('session')
const localStorageUtils = browserStorageUtils('local')

export { sessionStorageUtils, localStorageUtils }
