<!--
  Provides an input field and droplist, where the caller populates the droplist options
  as per their requirements. Ideally suited to displaying a list of matches from an api.

  Works in a similar way to HTML5 Datalist (which was unusable because the droplist would
  not always show current state when being set dynamically).

  PROPS:
    id <string> - Element ID to be used to associate it with a label for accessiblity etc.
    value <string> - Initial text value for the input
    options <array> - Array of strings to appear in the droplist
    alternates <array> - Array of strings to appear next to options as secondary/alternate text
    placeholder <string> - Placeholder text for the input
    uppercase <boolean> - If true, input displays and returns uppercase
    showSpinner <boolean> - If true, show spinner while typing (until options are populated)
    maxlength <integer> - If provided, this is the max length of the type ahead input field
    minLength <integer> - If provided, this is the number of characters required to trigger a search
    maxDroplistHeight <string> - If provided, this is the maximum height of the droplist with units, e.g. "10em"

  EVENTS:
    input(value) - Fires when the input value changes
    search(value) - Fires when ready to search (i.e. when there is a slight pause in the user's typing)
    select(index) - Fires when a droplist item is selected, sending the index of the selected item
-->

<template>
  <div class="AppTypeAhead">
    <!--
    `v-model` is avoided here to make the input work properly for Chrome on Andriod Devices, see:
    forum.vuejs.org/t/v-model-not-working-on-chrome-browser-on-android-device/36364
    -->
    <div class="accessibility-results-text sr-only" aria-live="polite">
      {{ resultsFoundAccessibilityText }}
    </div>

    <input
      :id="id"
      :aria-required="!!required"
      :aria-describedby="ariaDescribedBy"
      type="text"
      ref="input"
      :value="currentValue"
      :class="getInputClasses"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :disabled="disabled"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.down.prevent="moveSelectionDown"
      @keydown.up.prevent="moveSelectionUp"
      @keydown.enter.prevent="onSelect(currentIndex)"
    />

    <div v-if="showDroplist" class="AppTypeAhead_options" ref="droplist">
      <div class="spinner" :class="{ isLoading }">
        <font-awesome-icon
          class="icon"
          icon="circle-notch"
          focusable="false"
        />Searching&hellip;
      </div>

      <div
        v-for="(option, idx) in options"
        :key="idx"
        @mousedown="onSelect(idx)"
        :class="
          'AppTypeAhead_option' + (idx === currentIndex ? ' selected' : '')
        "
      >
        <span class="AppTypeAhead_option_text">
          {{ option }}
        </span>
        <span
          v-if="alternates && alternates[idx]"
          class="AppTypeAhead_option_alternate"
          >{{ alternates[idx] }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypeAhead',
  props: {
    id: {
      type: String,
      required: false
    },
    value: {
      type: String,
      default: '',
      required: false
    },
    options: {
      type: Array,
      required: false
    },
    alternates: {
      type: Array,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    uppercase: {
      type: String,
      required: false
    },
    showSpinner: {
      type: Boolean,
      default: false,
      required: false
    },
    maxLength: {
      type: [Number, String],
      required: false
    },
    minLength: {
      type: [Number, String],
      required: false
    },
    maxDroplistHeight: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    ariaDescribedBy: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isLoading: false,
      currentValue: '',
      currentIndex: null,
      isLookupFocused: false,
      isItemSelected: false,
      keystrokeTimer: null,
      loadingTimer: null,
      resultsFoundAccessibilityText: null,
      keyboardDelay: 500 // This interval was chosen based on advice from backend to reduce pressure on APIs
    }
  },
  computed: {
    showDroplist() {
      // For debugging purposes set "AppTypeAhead_option" return as 'true'
      return this.isLookupFocused && (this.options.length || this.isLoading)
    },
    transformedValue() {
      try {
        return this.uppercase
          ? this.currentValue.toUpperCase()
          : this.currentValue
      } catch (e) {
        return ''
      }
    },
    getInputClasses() {
      let classes = ['AppTypeAhead_input']

      if (this.uppercase) classes.push('uppercase')
      if (this.isLoading) classes.push('isLoading')

      return classes.join(' ')
    }
  },
  methods: {
    //Determines if there are enough characters to trigger a search
    isAboveSearchThreshold(inputString, minThreshold) {
      return (
        typeof inputString === 'string' &&
        inputString.trim().length >= Number(minThreshold)
      )
    },
    onInput(e) {
      this.currentValue = e.target.value

      this.$emit('input', this.transformedValue)

      // keystrokeTimer is used to detect a pause in the user's typing so that a
      // search() event can be fired. This is designed to prevent an api from being
      // overloaded with search requests.
      clearTimeout(this.keystrokeTimer)

      // loadingTimer hides the loading spinner after 3 seconds. This prevents it
      // staying on screen if no droplist results are returned.
      clearTimeout(this.loadingTimer)

      if (!this.isAboveSearchThreshold(this.currentValue, this.minLength)) {
        this.isLoading = false
      } else {
        if (this.showSpinner) {
          this.isLoading = true
          this.loadingTimer = setTimeout(() => {
            this.isLoading = false
          }, 3000)
        }

        this.keystrokeTimer = setTimeout(() => {
          this.$emit('search', this.transformedValue)
        }, this.keyboardDelay)
      }
    },
    moveSelectionUp() {
      if (this.options.length) {
        this.currentIndex--

        if (this.currentIndex < 0) {
          this.currentIndex = this.options.length - 1
        }

        this.scrollSelectionIntoView()
      }
    },
    moveSelectionDown() {
      if (this.options.length) {
        this.currentIndex++

        if (this.currentIndex >= this.options.length) {
          this.currentIndex = 0
        }

        this.scrollSelectionIntoView()
      }
    },
    onSelect(idx) {
      // Only proceed if selecting a droplist option, or clearing the
      // value completely.
      if (this.options.length || !this.currentValue) {
        this.isItemSelected = true
        this.$emit('select', idx)

        // Return keyboard focus to text input after selecting from the droplist
        // Timeout allows click event to complete before focus is set (otherwise
        // focus won't be set).
        const inputEl = this.$refs.input
        setTimeout(() => {
          inputEl.focus()
        }, 0)
      }
    },
    onFocus() {
      this.isLookupFocused = true
    },
    onBlur(e) {
      if (e.target.value === '') {
        this.$emit('blur', '')
        return
      }

      this.isItemSelected = false
      this.isLookupFocused = false

      // Must be called last to ensure we set the most up-to-date value
      this.$emit('blur', this.transformedValue)
    },
    scrollSelectionIntoView() {
      // Ensures the currently selected item in the droplist is visble,
      // i.e. is not scrolled out of view.
      let droplistEl = this.$refs.droplist
      let hasScrollBar =
        droplistEl && droplistEl.scrollHeight !== droplistEl.clientHeight

      if (hasScrollBar) {
        // Timeout ensures UI has rendered the new currentIndex (set when
        // moving the selection up or down)
        setTimeout(() => {
          let selectedEl = droplistEl.getElementsByClassName('selected')[0]
          if (selectedEl.offsetTop < droplistEl.scrollTop) {
            droplistEl.scrollTop = selectedEl.offsetTop
          } else {
            let lastVisibleRowPos =
              droplistEl.scrollTop +
              droplistEl.clientHeight -
              selectedEl.clientHeight

            if (selectedEl.offsetTop > lastVisibleRowPos) {
              droplistEl.scrollTop =
                selectedEl.offsetTop -
                droplistEl.clientHeight +
                selectedEl.clientHeight
            }
          }
        }, 0)
      }
    },
    setDroplistHeight() {
      // Sets droplist max-height whenever droplist content changes.
      let el = this.$refs.droplist

      if (el) {
        // Sets droplist min-height to fit in at lease one option.
        el.style.minHeight = `45px`

        if (this.maxDroplistHeight) {
          el.style.maxHeight = this.maxDroplistHeight
        } else {
          // If no maxHeight prop set, ensure droplist does not extend below viewport
          let pos = el.getBoundingClientRect()
          let maxHeight = window.innerHeight - pos.top

          el.style.maxHeight = `${maxHeight}px`
        }
      }
    }
  },
  mounted() {
    this.currentValue = this.value
  },
  watch: {
    value(val) {
      // Watch to see if parent sets new value for the typeahead input
      this.currentValue = val
    },
    options(val) {
      // Clear 'loading' spinner when results are returned
      if (val.length) {
        this.currentIndex = 0
        this.isLoading = false
      }
      if (this.isAboveSearchThreshold(this.currentValue, this.minLength)) {
        this.resultsFoundAccessibilityText = `${val.length} results have been found`
      } else {
        this.resultsFoundAccessibilityText = null
      }
    },
    showDroplist() {
      // Timeout ensures droplist has rendered before setting height
      setTimeout(() => this.setDroplistHeight(), 0)
    }
  }
}
</script>

<style lang="scss" scoped>
input.uppercase {
  text-transform: uppercase;
}

input::placeholder {
  text-transform: none;
}

.AppTypeAhead {
  position: relative;
}

.AppTypeAhead_input {
  margin: 0;
  padding: 0 1rem;
  height: 4rem;
  border-radius: 0.4rem;
  border: 1px solid $color-grey-15;
  font-size: $text;
  transition: $basic-transition;

  &:focus,
  &:active {
    outline: none;
    border-color: $color-primary;
  }

  @include placeholder {
    color: $color-placeholders;
  }
}

.AppTypeAhead_options {
  font-size: $text;
  max-height: 260px;

  .selected {
    // keydown (arrows keys action) style
    background-color: $color-third;
    border-color: $color-third;
    color: $color-black;
  }
}

.AppTypeAhead_options {
  z-index: z('dropdown');
  overflow-y: auto;
  width: 100%;
  position: absolute; // Necessary for all the calculations to work in scrollSelectionIntoView()
  padding: 0.1em 0;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  background-color: $color-white;
  color: $color-black;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}

.AppTypeAhead_option {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 2em 0.5em 1em;
  cursor: pointer;
  &:hover {
    display: flex !important; // that allow to make dropdown clickable on IE10=>UP
    background-color: $color-primary;
    border-color: $color-primary;
    outline: none;
    color: $color-white;
  }
}

.spinner {
  font-weight: bold;
  padding: 1em;
  display: none;

  &.isLoading {
    display: inline-block;
  }

  .icon {
    animation: spin 3s infinite linear;
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@include mobile {
  .AppTypeAhead_option {
    flex-direction: column;

    &_alternate {
      font-size: 1.2rem;
    }
  }
}
</style>
