<template>
  <v-app
    id="app"
    :class="[viewCSSClass(), { hasMenuOpen: $store.state.showMenu }]"
  >
    <AppHeader @open-menu="openMenu" @close-menu="closeMenu" />
    <div class="main" role="main">
      <router-view />
    </div>
    <NotificationQueue />
    <AppFooter />
    <AppSpinner />
    <AppModal />
    <AppMessageBox />
    <AppOverlay />
    <AppEnvironmentInfo />
    <BrowserSupportNotification />
  </v-app>
</template>

<script>
import AppFooter from '@/components/app/AppFooter.vue'
import AppHeader from '@/components/app/AppHeader.vue'
import AppMessageBox from '@/components/app/AppMessageBox.vue'
import AppModal from '@/components/app/AppModal.vue'
import AppOverlay from '@/components/app/AppOverlay.vue'
import AppSpinner from '@/components/app/AppSpinner.vue'
import AppEnvironmentInfo from '@/components/app/AppEnvironmentInfo'
import BrowserSupportNotification from '@/components/app/BrowserSupportNotification'
import NotificationQueue from './components/app/NotificationQueue.vue'
import { sessionStorageUtils } from '@/utils/browserStorage'
import ehubUtil from '@/utils/ehub'

export default {
  name: 'App',
  components: {
    AppFooter,
    AppHeader,
    AppMessageBox,
    AppModal,
    AppOverlay,
    AppSpinner,
    AppEnvironmentInfo,
    BrowserSupportNotification,
    NotificationQueue
  },
  methods: {
    viewCSSClass() {
      if (!this.$router.currentRoute.name) return ''
      return 'view-' + this.$router.currentRoute.name.replace(/View$/, '')
    },
    openMenu() {
      this.$store.dispatch('openMenu')
      document.addEventListener('click', this.closeMenuListener, true)
    },
    closeMenu() {
      this.$store.dispatch('closeMenu')
      document.removeEventListener('click', this.closeMenuListener, true)
    },
    closeMenuListener(ev) {
      const overlayEl = document.getElementsByClassName('AppOverlay')[0]

      if (overlayEl === ev.target) {
        this.$store.dispatch('closeMenu')
        document.removeEventListener('click', this.closeMenuListener, true)
      }
    }
  },
  created() {
    if (
      !this.$route.query.schoolCode &&
      !this.$route.query.catchmentLevel &&
      !this.$store.state.school
    ) {
      // redirect to welcome page if coming back from another domain (e.g. clicking back from ooa)
      const lastSchool = sessionStorageUtils.getItem('lastSchool')
      const isEHubRoute = this.$route.matched.some(
        (route) => route.name === 'eHubRouteWrapper'
      )
      // if comesFrom is set ehub in sessionStorage, no redirect is needed here for ehub users
      const fromEhub = ehubUtil.isFromEhub()

      if (lastSchool && !isEHubRoute && !fromEhub) {
        const { schoolCode, catchmentLevel } = lastSchool
        this.$router.replace({
          name: 'WelcomeView',
          query: { schoolCode, catchmentLevel }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/app.scss';
</style>
