<template>
  <ApplicationFieldCollection
    v-if="field.type === 'COLLECTION'"
    :section="section"
    :field="field"
  />
  <ApplicationFieldPanels
    v-else-if="field.type === 'PANELS'"
    :section="section"
    :field="field"
  />
  <ApplicationFieldGroup
    v-else-if="field.type === 'GROUP'"
    :section="section"
    :field="field"
  />
  <ApplicationFieldHeading
    v-else-if="field.type === 'HEADING'"
    :field="field"
  />
  <ApplicationField v-else :field="field" :section="section" />
</template>

<script>
import ApplicationField from '@/components/application/ApplicationField.vue'
import ApplicationFieldCollection from '@/components/application/ApplicationFieldCollection.vue'
import ApplicationFieldGroup from '@/components/application/ApplicationFieldGroup.vue'
import ApplicationFieldHeading from '@/components/application/ApplicationFieldHeading.vue'
import ApplicationFieldPanels from '@/components/application/ApplicationFieldPanels'

export default {
  name: 'ApplicationFieldRow',
  props: {
    field: {
      type: Object
    },
    section: {
      type: Object
    }
  },
  components: {
    ApplicationField,
    ApplicationFieldCollection,
    ApplicationFieldGroup,
    ApplicationFieldHeading,
    ApplicationFieldPanels
  }
}
</script>
