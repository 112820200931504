<template>
  <div v-if="school" class="ApplicationWelcome">
    <a :href="school.website" target="_blank" data-testid="school-website">
      <ApplicationBanner
        :content="
          '<h1>' +
          school.schoolName +
          '</h1><h3>Local enrolment application</h3>'
        "
        :logo="school.logo"
        :show-logo="true"
      />
    </a>

    <b-container class="content">
      <b-row>
        <b-col lg="4">
          <div class="intro" aria-hidden="true">
            <img
              class="intro-icon"
              alt="What will I need?"
              src="@/assets/icon-whats-online.svg"
            />
          </div>

          <div class="intro-desc">
            <h2>What is this online application for?</h2>
            <ul>
              <li v-if="isShowFeederSchool" data-testid="feeder-schools-text">
                Students transitioning from {{ feederSchoolsText }}
              </li>
              <li>Local enrolment into NSW Government schools only</li>
              <li>
                Children living in NSW who are Australian or New Zealand
                citizens or Australian permanent residents
              </li>
            </ul>
          </div>
        </b-col>

        <b-col lg="4">
          <div class="intro" aria-hidden="true">
            <img
              class="intro-icon"
              alt="What will I need?"
              src="@/assets/icon-temporary-residents.svg"
            />
          </div>

          <div class="intro-desc">
            <h2>Can temporary residents apply using this form?</h2>
            <p>
              No. Please go to our website for
              <a href="https://www.deinternational.nsw.edu.au" target="_blank"
                >temporary residents and international students</a
              >
              <br />for this type of enrolment
            </p>
          </div>
        </b-col>

        <b-col lg="4">
          <div class="intro" aria-hidden="true">
            <img
              class="intro-icon"
              alt="What will I need?"
              src="@/assets/icon-complete-app.svg"
            />
          </div>

          <div class="intro-desc">
            <h2>What will I need to complete this application?</h2>
            <ul>
              <li>
                Your child's medical details - Medicare and doctor details
              </li>
              <li>Parent contact details</li>
              <li>Emergency contact details</li>
            </ul>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <hr />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <ul class="horizontal-list">
            <li class="prefill" v-if="prefillData">
              <h2 data-testid="prefilled-notification">
                We have prefilled details to save you time
              </h2>
              <p>
                We have completed as many details as possible based on
                information you have already provided. This will help you
                complete the enrolment application as quickly as possible.
              </p>
            </li>
            <li class="come-back">
              <h2>Resume your application within 30 days</h2>
              <p>
                If you don’t have all the information you need right now, that’s
                okay - the application will be auto-saved as you go. Any saved
                progress will be kept for 30 days.
              </p>
            </li>
            <li class="what-happens">
              <h2>What happens once you apply?</h2>
              <p>
                After completing the online application part of the process, you
                may need to visit the school to provide further documentation
                and progress the application.
              </p>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row class="d-flex">
        <v-btn
          class="px-4 ml-8 ads-secondary-button"
          outlined
          @click="onCancel"
        >
          <v-icon class="mr-2" size="20">mdi-close-circle-outline</v-icon>
          <span class="h4 mb-0"> Cancel </span>
        </v-btn>
        <v-btn class="px-4 ml-auto mr-8" @click="onProceed" :disabled="loading">
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="20"
            class="mr-2"
          />
          <v-icon v-else color="white" size="20" class="mr-2"
            >mdi-logout-variant</v-icon
          >
          <span class="h4 mb-0"> Proceed </span>
        </v-btn>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import { cloneDeep } from 'lodash'
import ehubUtil from '@/utils/ehub.js'

export default {
  name: 'ApplicationEhubWelcome',
  components: {
    ApplicationBanner
  },
  props: {
    loading: Boolean
  },
  data() {
    return {
      feederSchools: null,
      isSCTApp: false
    }
  },
  created() {
    const formData = ehubUtil.getFormData()
    if (formData) {
      this.feederSchools = formData.feederSchools
    }
    this.isSCTApp = ehubUtil.isSCTApplication()
  },
  computed: {
    school() {
      return this.$store.state.school
    },
    student() {
      return (
        this.$store.state.application && this.$store.state.application.student
      )
    },
    prefillData() {
      const application = this.$store.state.application
      if (application && application.meta && application.meta.prefillFlags) {
        if (Object.values(application.meta.prefillFlags).includes(true)) {
          return true
        }
      }
      return false
    },
    isShowFeederSchool() {
      return (
        this.isSCTApp &&
        Array.isArray(this.feederSchools) &&
        this.feederSchools.length > 0
      )
    },
    feederSchoolsText() {
      if (this.feederSchools?.length === 1) {
        return this.feederSchools[0]
      }
      if (this.feederSchools?.length > 1) {
        const clonedFeederSchools = cloneDeep(this.feederSchools)
        const lastSchoolName = clonedFeederSchools.pop()
        const feederSchoolsString = clonedFeederSchools.join(', ')
        return `${feederSchoolsString} or ${lastSchoolName}`
      }
      return ''
    }
  },
  methods: {
    onProceed() {
      this.$emit('proceed')
    },
    onCancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
$sideBarWidth: 200px;

.ApplicationWelcome {
  padding: 0 0 30px;
  .content.container {
    padding: 94px 15px 0;
    .WelcomeCopy {
      h2 {
        line-height: 28px;
      }
    }
    .search-address-row {
      position: relative;

      form {
        padding: 6px 0 0;
      }

      h2 {
        padding: 0 0 5px;
        font-size: 21px;
      }
    }

    .intro {
      text-align: center;
      padding: 15px 30px 5px;
    }

    .intro-desc {
      h2 {
        padding-bottom: 0;
        margin-bottom: 10px;
        padding-right: 20px;
      }
    }
  }

  ul {
    padding: 0 0px 0 15px;
    font-size: $text;

    &.horizontal-list {
      padding: 40px 0 5px 5px;
      list-style: none;

      li {
        position: relative;
        padding: 0 0 35px 120px;
        line-height: 26px;
        p {
          line-height: 26px;
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 91px;
          height: 91px;
        }
      }

      h2 {
        padding: 0 15px 5px 0;
      }
    }
  }

  h1 {
    &.invalid-code-msg {
      padding: 30px 0;
    }
  }

  h2 {
    line-height: 28px;
    padding: 2rem 0;
  }

  .container {
    text-align: left;

    hr {
      margin: 2rem 0 0;
    }

    .intro-icon {
      height: auto;
    }

    .slide-fade-enter-active {
      transition: all 0.3s ease;
    }

    .slide-fade-leave-active {
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
      opacity: 0;
    }
  }

  .Note {
    padding: 40px 20px 20px;
  }
}

.AppCatchmentFinder {
  margin-top: 2rem;
}

.ads-secondary-button {
  color: #041e42;
  border-width: 2px;
}

::v-deep .link-outline {
  &:focus {
    outline-style: auto;
  }
}

@include desktop {
  .ApplicationWelcome {
    .ApplicationBanner {
      height: 218px;
    }
    .container {
      .intro-desc {
        padding: 0 25px;
      }

      .intro-icon {
        max-width: 180px;
      }
    }

    .Banner {
      h1 {
        font-size: 51px;
        line-height: 50px;
      }

      h3 {
        font-size: 26px;
        padding-top: 8px;
      }
    }
  }
}

@include tablet-wide {
  .ApplicationWelcome {
    .ApplicationBanner {
      height: 218px;
    }
    .container {
      .intro-icon {
        max-width: 170px;
      }
    }
  }
}

@include tablet {
  .ApplicationWelcome {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }
    .content {
      margin-bottom: 20px;
      padding: 1.2rem 1.2rem 2rem;

      .search-address-row.white-card {
        h2,
        p {
          text-align: center;
        }
      }

      .intro-icon {
        max-width: 50%;
      }
    }
  }
}

@include mobile {
  .ApplicationWelcome {
    .content {
      &.container {
        margin-bottom: 20px;
        .WelcomeCopy {
          h2 {
            padding: 2rem 4rem;
            text-align: center;
          }
        }

        .intro-icon {
          min-width: 140px;
          width: 140px;
        }

        .intro-desc {
          padding: 0 20px;

          h2 {
            padding: 2rem 2rem;
            text-align: center;
          }

          p {
            text-align: center;
          }
        }

        .horizontal-list {
          li {
            padding: 120px 20px 35px;
            text-align: center;

            &::before {
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }

        .search-address-row {
          h2,
          p {
            text-align: center;
          }
        }
      }
    }

    .Note {
      padding: 0 20px 20px !important;
    }
  }
}
</style>
