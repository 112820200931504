import moment from 'moment'
import _get from 'lodash/get'

import { FIELD_TYPE, REFDATA_MAP } from '@/constants'

import { YES_NO } from '../droplistData/boolean'

import { dropDownSelect } from '../droplistData/helper'
import { getFirstName, getLabelName, sanitize } from '../helper'
import doctorDetails from './doctorDetails'

import allergies from '@/mock/helper/allergies'

export default function getMedicalCondition(key, visibleCondition) {
  return {
    apiKey: key,
    type: FIELD_TYPE.COLLECTION,
    description(application) {
      return getLabelName(
        'Please add all of ',
        getFirstName(application),
        "'s non-severe allergies:",
        'the student'
      )
    },
    heading(contactItem, index) {
      return 'Non-severe allergy ' + index
    },
    visible(application) {
      return _get(application, visibleCondition)
    },
    headingAdd: 'Add another non-severe allergy',
    newItem: allergies(false),
    fields(allergyItem, allergyIndex) {
      var apiKeyPrefix = `${this.apiKey}[${allergyIndex}]`

      return [
        {
          apiKey: apiKeyPrefix + '.medicalConditionType',
          label(application) {
            const studentName = getFirstName(application)
            return getLabelName(
              null,
              studentName,
              ' is allergic to...',
              'the student'
            )
          },
          required: true,
          type: FIELD_TYPE.DROPDOWN,
          options() {
            return dropDownSelect(
              this.getReferenceDataSet(REFDATA_MAP.MEDICAL_CONDITION_ALLERGIES)
            )
          }
        },
        {
          apiKey: apiKeyPrefix + '.notes',
          label: 'Other',
          type: FIELD_TYPE.TEXTAREA,
          maxLength: 2000,
          visible() {
            return allergyItem.medicalConditionType === 'ALO'
          },
          required: true
        },
        {
          apiKey: apiKeyPrefix + '.hasHospitalised',
          label(application) {
            const studentName = getFirstName(application)
            return getLabelName(
              'Has ',
              studentName,
              ' been hospitalised for this allergy?',
              'the student'
            )
          },
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + '.hospitalName',
          label: 'Hospital name',
          placeHolder: 'Enter hospital name',
          visible(application) {
            return _get(application, `${apiKeyPrefix}.hasHospitalised`)
          },
          required: true,
          maxLength: 100
        },
        {
          apiKey: apiKeyPrefix + '.doctorDiagnosed',
          label: 'Has a doctor diagnosed this allergy?',
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + '.doctorRecordNo',
          label: 'Please select the doctor that is treating this allergy',
          type: FIELD_TYPE.DROPDOWN,
          visible(application) {
            return _get(application, `${apiKeyPrefix}.doctorDiagnosed`)
          },
          required: true,
          options(application) {
            const base = [
              {
                value: '',
                text: 'Select'
              }
            ]

            const options = application.doctorDetails
              .filter((item) => item.doctorName)
              .map((item, itemIndex) => {
                let name = `Doctor ${itemIndex + 1}`

                if (item.doctorName) {
                  name = item.doctorName
                }

                return {
                  value: item.collectionItemUniqueId,
                  text: name
                }
              })

            return base.concat(options)
          }
        },
        {
          label: '+ Add another doctor/medical centre',
          visible(application) {
            return _get(application, `${apiKeyPrefix}.doctorDiagnosed`)
          },
          type: FIELD_TYPE.BUTTON,
          onClick() {
            this.addNewCollectionItem(doctorDetails)
          }
        },
        {
          apiKey: apiKeyPrefix + '.actionPlan',
          label(application) {
            const studentName = getFirstName(application)
            return getLabelName(
              'Does ',
              studentName,
              ' have an ASCIA Action Plan for this allergy?',
              'the student'
            )
          },
          description: 'If yes, a copy of this will be required',
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + '.hasAutoinjector',
          label(application) {
            const studentName = getFirstName(application)
            return getLabelName(
              'Has ',
              studentName,
              ' been prescribed an adrenaline autoinjector?',
              'the student'
            )
          },
          vHtmlDescriptionList(application) {
            const itemDescription = getLabelName(
              'Each time ',
              sanitize(getFirstName(application)),
              ' is prescribed a new adrenaline autoinjector the doctor should issue an updated ASCIA Action Plan for Anaphylaxis.',
              'the student'
            )
            return [
              'ie EpiPen<sup>&reg;</sup>',
              'If yes, an adrenaline autoinjector will need to be provided to the school and renewed prior to the expiry date.',
              itemDescription,
              'It is important that any updated plan is provided to the school.'
            ]
          },
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + '.autoinjectorExpiryDate',
          label: 'Expiry date of adrenaline autoinjector',
          required: true,
          visible(application) {
            return _get(application, `${apiKeyPrefix}.hasAutoinjector`)
          },
          pastYears: 0,
          futureYears: 20,
          type: FIELD_TYPE.DATE,
          validation(value) {
            if (moment(value) < moment().startOf('day')) {
              return 'Cannot be a past date'
            }
          }
        },
        {
          apiKey: apiKeyPrefix + '.meta.isOtherMedication',
          label(application) {
            const name = getFirstName(application)
            return getLabelName(
              'Has ',
              name,
              ' been prescribed medication for this allergy?',
              'the student'
            )
          },
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + '.medications',
          type: FIELD_TYPE.COLLECTION,
          // eslint-disable-next-line
          heading(contactItem, index) {
            return 'Medication ' + index
          },
          visible(application) {
            return _get(application, `${apiKeyPrefix}.meta.isOtherMedication`)
          },
          headingAdd: 'Add another medication',
          newItem: {
            medicationName: ''
          },
          fields(preschool, index) {
            var apiKeyPrefix = `${this.apiKey}[${index}]`
            return [
              {
                apiKey: `${apiKeyPrefix}.medicationName`,
                required: true,
                label: 'Name',
                placeHolder: 'Enter medication name',
                maxLength: 128
              }
            ]
          }
        }
      ]
    }
  }
}
