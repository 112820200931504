export default function findSreSeeOption({
  tokenisedSreSeeSelection,
  schoolCustomisationFile
}) {
  if (tokenisedSreSeeSelection === null) {
    return null
  }

  const { sre, see, ama } = schoolCustomisationFile.local || {}

  if ([sre, see, ama].every((i) => i === undefined)) {
    return null
  }

  const providerArray = tokenisedSreSeeSelection.sreSeeAmaSelectionProviders
    .split('|')
    .map((i) => i.trim())

  const [foundSreSeeAmaOption] = { sre, see, ama }[
    tokenisedSreSeeSelection.sreSeeAmaSelectionLabel.toLowerCase()
  ]
    .filter(
      (i) =>
        i.name.toLowerCase().trim() ===
        tokenisedSreSeeSelection.sreSeeAmaSelectionName.toLowerCase().trim()
    )
    .filter((i) => {
      const sortedSelectedOptionProviders = [...providerArray]
        .map((j) => j.toLowerCase())
        .sort()
      const sortedOptionProviders = [...i.providers]
        .map((j) => j.name.toLowerCase())
        .sort()

      return (
        sortedSelectedOptionProviders.every(
          (j, jdx) => sortedOptionProviders[jdx] === j
        ) &&
        sortedSelectedOptionProviders.length === sortedOptionProviders.length
      )
    })
  return foundSreSeeAmaOption
    ? {
        ...foundSreSeeAmaOption,
        sreSeeAmaOptionAcronym:
          tokenisedSreSeeSelection.sreSeeAmaSelectionLabel.toLowerCase()
      }
    : null
}
