export const RELATIONSHIPS = [
  {
    value: '',
    text: 'Select'
  },
  {
    value: 'MUM',
    text: 'Mother'
  },
  {
    value: 'DAD',
    text: 'Father'
  },
  {
    value: 'GMO',
    text: 'Grandmother'
  },
  {
    value: 'GFA',
    text: 'Grandfather'
  },
  {
    value: 'GFM',
    text: 'Grandfather / Grandmother'
  },
  {
    value: 'SMO',
    text: 'Stepmother'
  },
  {
    value: 'SFA',
    text: 'Stepfather'
  },
  {
    value: 'SIS',
    text: 'Sister'
  },
  {
    value: 'BRO',
    text: 'Brother'
  },
  {
    value: 'STUF',
    text: 'The Student (herself)'
  },
  {
    value: 'STUM',
    text: 'The Student (himself)'
  },
  {
    value: 'AUN',
    text: 'Aunt'
  },
  {
    value: 'AUUN',
    text: 'Aunt / Uncle'
  },
  {
    value: 'CASE',
    text: 'Caseworker'
  },
  {
    value: 'FRI',
    text: 'Friend'
  },
  {
    value: 'DON',
    text: 'Godfather'
  },
  {
    value: 'GDM',
    text: 'Godmother'
  },
  {
    value: 'ORG',
    text: 'Guardian (organisation)'
  },
  {
    value: 'GUF',
    text: 'Guardian Father (organisation)'
  },
  {
    value: 'GHS',
    text: 'Guardian Homestay (organisation)'
  },
  {
    value: 'GUM',
    text: 'Guardian Mother (organisation)'
  },
  {
    value: 'GDF',
    text: 'Guardian/ Defacto (Female)'
  },
  {
    value: 'GDA',
    text: 'Guardian/ Defacto (Male)'
  },
  {
    value: 'NAN',
    text: 'Nanny'
  },
  {
    value: 'NEI',
    text: 'Neighbour'
  },
  {
    value: 'OTR',
    text: 'Other relative'
  },
  {
    value: 'UNC',
    text: 'Uncle'
  },
  {
    value: 'YYY',
    text: 'Other (Not elsewhere classified)'
  }
]
