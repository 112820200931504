<template>
  <div v-if="$store.state.showOverlay" class="AppOverlay"></div>
</template>

<script>
export default {
  name: 'AppOverlay'
}
</script>

<style lang="scss">
.AppOverlay {
  z-index: z('overlay');
  position: fixed;
  top: $header-height;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(87, 101, 119, 0.58);
}
</style>
