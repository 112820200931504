import endPoint from '@/store/endPoint'
import { ERROR_CODES, API_ERROR_SUBTYPES } from '@/constants'
import ehubUtil from '@/utils/ehub'

//Taken from ApplicationLoginOTP to also be usable in OAuth flow
export default {
  methods: {
    getApplicationAndStart() {
      let vm = this
      endPoint
        .getFormData(vm.$store.state.auth.idToken)
        .then((resp) => {
          if (
            // If resuming and no pending applications found, let user
            // start a new application or enter a different email...

            resp.data.message ===
              'No pending applications found for this user' &&
            !vm.$store.state.isNewApplication
          ) {
            vm.$store.dispatch('setError', { type: ERROR_CODES.NO_PENDING })
            vm.$router.history.push('/error')
          } else if (
            // If no application returned & application in state
            !resp.data.body &&
            vm.$store.state.application
          ) {
            // First time user saves email or changed school needs to reset form or return user
            vm.$store
              .dispatch('createApplication')
              .then(() => {
                //Google Analytics
                this.$gtm.trackEvent({
                  event: 'interaction',
                  category: 'Form Attempt',
                  action: 'start',
                  label: 'core',
                  application: ehubUtil.getGtmApplication()
                })
                vm.$router.history.push('/application')
              })
              .catch(() => {
                vm.$store.dispatch('hideSpinner')
                this.$router.history.push('/error?type=500')
              })
          } else if (
            vm.$store.state.application &&
            resp.data.body.application.schoolCode ===
              vm.$store.state.application.schoolCode &&
            resp.data.body.application.catchmentLevel !==
              vm.$store.state.application.catchmentLevel
          ) {
            // Copy updated information to application
            resp.data.body.application.catchmentLevel =
              vm.$store.state.application.catchmentLevel
            resp.data.body.application.residentialAddress =
              vm.$store.state.application.residentialAddress

            // Clear selected school year
            // Do not clear scholasticYear if the application is y67t offer
            if (!resp.data.body.application.eoiID) {
              delete resp.data.body.application.scholasticYear
            }
            // Save it to application
            vm.$store.commit('set', ['application', resp.data.body.application])

            vm.$store.dispatch('saveFullFormInOTP').then(() => {
              vm.$router.history.push('/pendingApplications')
            })
          } else {
            if (
              vm.$store.state.resumeViaEmail ||
              vm.$store.state.resumeViaButton
            ) {
              vm.$router.history.push('/application')
            } else {
              // Save it to application
              vm.$store.commit('set', [
                'application',
                resp.data.body.application
              ])
              vm.$router.history.push('/pendingApplications')
            }
          }
        })
        .catch((error) => {
          vm.$store.dispatch('hideSpinner')
          vm.$store.dispatch('setAPIError', {
            error,
            fallbackCode: API_ERROR_SUBTYPES.getApplication
          })
          vm.$router.history.push('/error')
        })
    },
    getOffers(cancelledApplicationStudentName) {
      let vm = this
      // APO-349: Reset the Vuex Store before we start a new application or resume an existing one.
      // TODO: Review and determine whether further details should be reset &
      // we should wrap this into a generic action in the 3.4 release codebase!
      vm.$store.commit('set', ['ooaStudentApplications', []])
      vm.$store.commit('set', ['ooaApplicationID', null])
      vm.$store.commit('set', ['studentName', null])
      vm.$store.commit('set', ['isOoaApplication', false])

      vm.$store.commit('set', ['y67tApplications', []])
      vm.$store.commit('set', ['y67tApplicationID', null])
      vm.$store.commit('set', ['isY67TApplication', false])

      if (
        vm.$store.state.isNewApplication ||
        vm.$store.state.resumeViaEmail ||
        !(vm.$store.state.school && vm.$store.state.school.schoolCode)
      ) {
        vm.getApplicationAndStart()
      } else {
        const promises = []
        promises.push(vm.$store.dispatch('getOoaOffers'))
        promises.push(vm.$store.dispatch('getY67TOffers'))
        Promise.all(promises).then((responses) => {
          const ooaApplications = responses[0]
          const y67tApplications = responses[1]
          if (
            ooaApplications &&
            ooaApplications.length === 0 &&
            y67tApplications &&
            y67tApplications.length === 0
          ) {
            vm.getApplicationAndStart()
          } else {
            vm.$router.history.push({
              name: 'PendingOffers',
              params: { cancelledApplicationStudentName }
            })
          }
        })
      }
    },
    getApplication() {
      let vm = this
      return endPoint.getFormData(vm.$store.state.auth.idToken)
    }
  }
}
