import Vue from 'vue'
import addGlyphs from './fontAwesome'
import bv from 'bootstrap-vue'
import GTM from '@gtm-support/vue2-gtm'
import { focus } from 'vue-focus'
import cssVars from 'css-vars-ponyfill'
import vuetify from './plugins/vuetify'

import App from './App.vue'
import router from './router'
import store from './store/store'

import OAuth from './plugins/OAuth/OAuth'
import jwtDecode from 'jwt-decode'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import endPoint from './store/endPoint'
import isExpired from './store/helper/isExpired'
import hasNearlyExpired from './store/helper/hasNearlyExpired'
import { HEARTBEAT_EXEMPTIONS, NODE_ENVIRONMENTS } from './constants'
import VueMoment from 'vue-moment'
import axios from 'axios'

import scrollTo from 'vue-scrollto'
import vueBootstrapAhead from 'vue-bootstrap-typeahead'

const TOKEN_REFRESH_RATE = process.env.VUE_APP_TOKEN_REFRESH_RATE

addGlyphs()

//calling cssVars ponyfill to provide client-side support for CSS custom properties (aka "CSS variables") in legacy and modern browsers.
cssVars({})

Vue.use(bv)
Vue.use(vueBootstrapAhead)
Vue.use(scrollTo) // Handles scrolling to elements
Vue.use(VueMoment)
Vue.directive('focus', focus) // Adds third party focus directive to handle keyboard focus

Vue.use(OAuth, {
  router,
  authProviderConfig: {
    SNSW: {
      urls: {
        authorise: process.env.VUE_APP_SNSW_AUTH_URL,
        token: process.env.VUE_APP_SNSW_TOKEN_URL
      },
      params: {
        client_id: process.env.VUE_APP_SNSW_AUTH_CLIENT_ID,
        response_type: 'code',
        identity_provider: 'SNSWConnect',
        scope: 'openid profile email'
      }
    }
  },
  appStateToPersist: () => ({
    application: store.state.application,
    school: store.state.school,
    isNewApplication: store.state.isNewApplication,
    currentApplication: store.state.currentApplication,
    resumeViaButton: store.state.resumeViaButton,
    resumeViaEmail: store.state.resumeViaEmail
  }),
  storagePrefix: 'pi.auth',
  onAuthorisedRedirect: (tokens, persistedState) => {
    const idDecoded = jwtDecode(tokens.id)

    store.commit('set', [
      'auth',
      {
        ...store.state.auth,
        idToken: tokens.id,
        refreshToken: tokens.refresh,
        idTokenTime: new Date().getTime(),
        userId: idDecoded.email,
        authProvider: persistedState.authProvider
      }
    ])
    store.commit('set', ['application', persistedState.application])
    store.commit('set', [
      'currentApplication',
      persistedState.currentApplication
    ])

    store.commit('set', ['school', persistedState.school])
    store.commit('set', ['isNewApplication', persistedState.isNewApplication])
    store.commit('set', ['resumeViaButton', persistedState.resumeViaButton])
    store.commit('set', ['resumeViaEmail', persistedState.resumeViaEmail])

    //Google Analytics
    if (persistedState.authProvider === 'SNSW') {
      Vue.prototype.$gtm.trackEvent({
        event: 'interaction',
        category: 'Authentication',
        action: 'Success',
        label: 'ServiceNSW'
      })
    }
  },
  onRedirectError: (error) => {
    store.dispatch('set', ['error', error])
    router.history.push('/error')
  }
})

const gtmQueryParams =
  process.env.VUE_APP_ENV_NAME === NODE_ENVIRONMENTS.PROD
    ? {}
    : {
        gtm_auth: 'VvFRnunnDibaeEsFyLpetw',
        gtm_preview: 'env-5',
        gtm_cookies_win: 'x'
      }

// Adds Google Tag Manager tracking
Vue.use(GTM, {
  id: process.env.VUE_APP_GTM_ID,
  debug:
    process.env.VUE_APP_ENV_NAME === NODE_ENVIRONMENTS.TEST ||
    process.env.VUE_APP_ENV_NAME === NODE_ENVIRONMENTS.PREPROD,
  vueRouter: router,
  queryParams: gtmQueryParams
})

Vue.config.productionTip = false

// Axios Request / Response Interceptor for handling Refresh & Auth tokens.
// Axios helpers
let isRefreshing = false
let requestsAwaitingRefresh = []

function addToRefreshWaitQueue(callback) {
  requestsAwaitingRefresh.push(callback)
}
function onRefreshed() {
  requestsAwaitingRefresh.map((callback) => callback())
}

// Refresh token response interceptor
axios.interceptors.response.use(
  // return a successful response with no processing
  (response) => response,
  (error) => {
    const originalRequest = error.config
    if (originalRequest.disableDefaultErrorHandling) {
      // pass on the error to be handled externally
      return Promise.reject(error)
    }
    const { status } = { ...error.response } // avoiding destructuring undefined

    // No auth header or invalid auth header, AWS API Gateway may not respond with a CORS Header
    // https://forums.aws.amazon.com/message.jspa?messageID=763752
    // https://github.com/axios/axios/issues/883
    if (!error.response || error.response.status !== 401) {
      if (error.response) {
        router.push('/error')
      }
      return Promise.reject(error)
    }

    // Check if its a 401 & invoke refreshToken action, before retrying the request
    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true
        store
          .dispatch('refreshToken')
          .then(({ status }) => {
            if (status === 200 || status === 204) {
              isRefreshing = false
              // Refreshing complete and new token set. Re-run failed requests and empty the queue.
              onRefreshed()
              requestsAwaitingRefresh = []
            }
          })
          .catch((error) => {
            router.push('/error')
            throw error
          })
      }

      return new Promise((resolve) => {
        addToRefreshWaitQueue(() => {
          resolve(axios(originalRequest))
        })
      })
    }

    // Should really get here... But ESLint wants this.
    return Promise.reject(error)
  }
)

new Vue({
  router,
  store,
  vuetify,
  mounted() {
    //Set if we're in training environment or not.
    this.$store.dispatch('set', [
      'inTrainingMode',
      process.env.VUE_APP_ENV_NAME &&
        process.env.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PROD &&
        process.env.VUE_APP_TRAINING_MODE === 'true'
    ])
  },
  created() {
    window.addEventListener('beforeunload', this.onBeforeUnload)

    this.addListeners()

    window.setInterval(this.heartbeat, TOKEN_REFRESH_RATE)

    this.$store
      .dispatch('getOESProperties')
      .catch(() => this.$router.history.push('/error')) //Fail silently
  },
  beforeDestroy() {
    this.removeListeners()
  },
  methods: {
    addListeners() {
      document.addEventListener('keydown', this.setActive)
      document.addEventListener('mousedown', this.setActive)
    },
    removeListeners() {
      document.removeEventListener('keydown', this.setActive)
      document.removeEventListener('mousedown', this.setActive)
    },
    setActive() {
      if (!this.$store.state.auth.isUserActive) {
        this.$store.commit('set', ['auth.isUserActive', true])
        this.$store.commit('set', [
          'auth.isUserActiveTime',
          new Date().getTime()
        ])
      }
    },
    heartbeat() {
      // Are we in a view that requires checking?
      if (
        !HEARTBEAT_EXEMPTIONS.find(
          (item) => item === this.$router.history.current.name
        )
      ) {
        this.checkAuthExpiry()
        this.checkUserActivity()
      }

      // Always reset every `TOKEN_REFRESH_RATE` seconds to "track" activity
      this.$store.commit('set', ['auth.isUserActive', false])
    },
    checkAuthExpiry() {
      // The least amount of auth variables needed to count a user as authenticated
      if (
        !this.$store.state.auth.userId &&
        !this.$store.state.auth.idToken &&
        !this.$store.state.auth.idTokenTime &&
        !this.$store.state.auth.refreshToken
      ) {
        this.$router.history.push('/error')
      }

      // We issue a new token if the user has been active within the last hour
      // (checked by `checkUserActivity()`), and will expire in less than a
      // minute.
      if (
        hasNearlyExpired(this.$store.state.auth.idTokenTime) &&
        this.$store.state.auth.userId &&
        this.$store.state.auth.refreshToken
      ) {
        endPoint
          .getAuthRefreshToken(
            this.$store.state.auth.userId,
            this.$store.state.auth.refreshToken
          )
          .then((res) => {
            this.$store.commit('set', ['auth.idToken', res.data.body.idToken])
            this.$store.commit('set', [
              'auth.idTokenTime',
              new Date().getTime()
            ])
          })
          .catch(() => {
            this.$store.dispatch('hideSpinner')
            this.$router.history.push('/error?type=500')
          })
      }

      if (isExpired(this.$store.state.auth.idTokenTime)) {
        this.resetState()
        this.$router.history.push('/login/timeout')
      }
    },
    checkUserActivity() {
      if (
        this.$store.state.auth.isUserActive ||
        !this.$store.state.auth.isUserActiveTime
      )
        return

      if (isExpired(this.$store.state.auth.isUserActiveTime)) {
        this.resetState()
        this.$router.history.push('/login/timeout')
      }
    },
    resetState() {
      this.$store.commit('set', ['application', null])
      this.$store.commit('set', ['modal', {}])
      this.$store.commit('set', [
        'auth',
        { userId: this.$store.state.auth.userId, isUserActive: false }
      ])
    }
  },
  render: (h) => h(App)
}).$mount('#app')
