export const TITLE = [
  { value: '', text: 'Select' },
  { value: 'Mr', text: 'Mr' },
  { value: 'Miss', text: 'Miss' },
  { value: 'Mrs', text: 'Mrs' },
  { value: 'Ms', text: 'Ms' },
  { value: 'Mx', text: 'Mx' },
  { value: 'Ass Prof', text: 'Associate Professor' },
  { value: 'Br', text: 'Brother' },
  { value: 'Dr', text: 'Doctor' },
  { value: 'Fr', text: 'Father' },
  { value: 'Hon', text: 'Honourable' },
  { value: 'Lady', text: 'Lady' },
  { value: 'Lord', text: 'Lord' },
  { value: 'Mdm', text: 'Madam' },
  { value: 'Mstr', text: 'Master' },
  { value: 'YYY', text: 'Other (Not listed elsewhere)' },
  { value: 'Prof', text: 'Professor' },
  { value: 'Rabbi', text: 'Rabbi' },
  { value: 'Rev', text: 'Reverend' },
  { value: 'Sir', text: 'Sir' },
  { value: 'Sr', text: 'Sister' }
]
