<template>
  <form class="ApplicationSection" @submit.prevent @reset.prevent>
    <section
      v-for="section in sections.filter(
        (item) => item.id === sectionExpandedId
      )"
      :key="section.id"
      class="section"
    >
      <div
        v-if="isSectionExpanded(section) && !isSectionDisabled(section)"
        class="sectionFields"
        :id="section.id + '-fields'"
        aria-live="polite"
      >
        <ApplicationFieldRow
          v-for="field in section.fields"
          :key="field.apiKey"
          :section="section"
          :field="field"
        />
      </div>
      <div v-else>
        <div type="warning" class="message">
          <font-awesome-icon
            focusable="false"
            class="icon"
            icon="exclamation-circle"
          />
          <p>
            <span
              >In the case of an emergency, the school will contact
              {{ studentName }} parents/carers, followed by their additional
              emergency contacts.</span
            >
            <span>
              Before adding emergency contacts, you need to provide at least one
              Parent/Carer for this student.
              <span>
                <button
                  class="additionalButton"
                  type="button"
                  @click="toggleSection(parentSection)"
                >
                  Add parents/carers now
                </button>
              </span>
            </span>
          </p>
        </div>
      </div>
    </section>

    <ApplicationSaveCancel v-if="isApplication" />
  </form>
</template>

<script>
import { getFirstName, getLabelName } from '@/applicationDefinition/helper'

import ApplicationFieldRow from '@/components/application/ApplicationFieldRow.vue'
import ApplicationSaveCancel from '@/components/application/ApplicationSaveCancel.vue'

const getSectionExpandedId = (data) => {
  // Prevents null of undefined
  if (data && data.meta) {
    return data.meta.sectionExpandedId
  }
  return ''
}

export default {
  name: 'ApplicationSection',
  components: {
    ApplicationFieldRow,
    ApplicationSaveCancel
  },
  props: {
    sections: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    studentName() {
      const name = getFirstName(this.$store.state.application)
      return getLabelName(' ', name, "'s", 'the student')
    },
    sectionExpandedId() {
      return getSectionExpandedId(this.$store.state.application)
    },
    fields() {
      return this.$store.state.fields
    },
    isApplication() {
      return this.$store.state.application !== null
    },
    parentSection() {
      return this.sections.find((item) => item.id === 'parents')
    }
  },
  methods: {
    toggleSection(section) {
      this.$store.dispatch('set', [
        'application.meta.sectionExpandedId',
        section.id
      ])
    },
    isSectionExpanded(section) {
      return getSectionExpandedId(this.$store.state.application) === section.id
    },
    isSectionDisabled(section) {
      if (section.id === 'emergency') {
        let numParents = this.$store.state.counters.filter((val) => {
          return val.section.id === 'parents'
        }).length

        if (numParents > 0) return true
      }

      return false
    }
  },
  mounted() {
    this.$store.commit('set', ['auth.isUserActive', true])
    this.$store.commit('set', ['auth.isUserActiveTime', new Date().getTime()])
    this.$store.dispatch('buildAlerts')

    this.$gtm.trackView('SectionView', `/section/${this.sectionExpandedId}`)
  }
}
</script>

<style scoped lang="scss">
.ApplicationSection {
  padding: $content-padding;

  word-wrap: break-word;
  overflow-wrap: break-word;

  .sectionFields {
    padding: 0 0 $content-padding;
  }

  .message {
    display: flex;
    align-items: top;
    margin: $content-padding;
    padding: $content-padding;
    border: 1px solid $color-warning-border;
    border-radius: $field-border-radius;
    background-color: $color-warning;
    font-size: 1.6em;

    .icon {
      margin: 1.8rem 0.4em 0 0.6rem;
      padding: 0;
      width: 100%;
      max-width: 46px;
      font-size: 28px;
      color: $color-red;
    }

    span {
      display: block;
      padding: 1.4rem 0 0;

      button {
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
        color: $color-secondary;
        font-weight: normal;
        outline: none;

        &.additionalButton {
          font-weight: $text-medium;
        }

        &:focus {
          outline-style: auto;
        }
      }
    }
  }
}
</style>
