export default (isSevere) => {
  return {
    meta: {
      isOtherMedication: null
    },
    medicalConditionType: '',
    hasHospitalised: null,
    hospitalName: '',
    doctorDiagnosed: null,
    severeAllergy: isSevere,
    actionPlan: null,
    actionPlanAttached: null,
    notes: '',
    doctorRecordNo: null,
    hasAutoinjector: null,
    autoinjectorExpiryDate: null,
    medications: [
      {
        medicationName: ''
      }
    ]
  }
}
