<template>
  <div class="LocalSchoolReminderCard">
    <div class="addressHeader">
      <v-icon
        class="material-icons-outlined"
        :class="{ 'ml-6 pl-9': $vuetify.breakpoint.mdAndUp }"
      >
        place
      </v-icon>
      <span
        class="ml-4 address"
        :class="{ 'pl-12': $vuetify.breakpoint.mdAndUp }"
        >{{ address }}</span
      >
    </div>

    <v-card class="mb-6">
      <ContentBox
        v-if="school"
        class="pt-8"
        img-file-name="icon-local-school.svg"
        image-class="largeImage"
      >
        <template #title>
          <h2 class="pb-2">
            {{ `${school.schoolName} is` }}
            <span class="red--text"> not</span>
            your local school
          </h2>
        </template>
        <div slot="copyContent">
          <p>
            You can apply for local enrolment at the school(s) listed below, as
            your child is entitled a place there.
          </p>

          <SchoolInfoCard
            v-if="localSchoolsToDisplay.length === 1"
            :school="localSchoolsToDisplay[0]"
            :include-apply-button="true"
            link-classes="localSchool"
          />
          <SchoolInfoPanels
            v-if="localSchoolsToDisplay.length > 1"
            :schools="localSchoolsToDisplay"
            :include-apply-button="true"
            link-classes="localSchool"
          />
        </div>
      </ContentBox>
    </v-card>
  </div>
</template>

<script>
import ContentBox from './ContentBox.vue'
import UTILS from '@/store/utils'
import SchoolInfoPanels from './SchoolInfoPanels.vue'
import SchoolInfoCard from './SchoolInfoCard.vue'
import endPoint from '@/store/endPoint'

export default {
  name: 'LocalSchoolReminderCard',
  components: { ContentBox, SchoolInfoPanels, SchoolInfoCard },
  data() {
    return {
      localSchoolsToDisplay: []
    }
  },
  computed: {
    address() {
      const application = this.$store.state.application
      return (
        application &&
        UTILS.addressToTitleCaseString(application.residentialAddress)
      )
    },
    school() {
      return this.$store.state.school
    },
    localSchools() {
      return this.$store.state.localSchools
    },
    schoolFinderURLs() {
      const endPoints = this.$store.state.oesProperties.endpoints
      return endPoints && endPoints.schoolFinder && endPoints.schoolFinder.urls
    }
  },
  created() {
    const { catchmentLevel, meta } = this.$store.state.application

    if (
      meta &&
      meta.calendarYear &&
      meta.calendarYear != new Date().getFullYear()
    ) {
      // This is edge case processing
      const schoolCodeList = this.localSchools.map((s) => s.schoolCode)

      endPoint
        .getFutureCatchmentData(
          this.schoolFinderURLs,
          schoolCodeList,
          catchmentLevel
        )
        .then(({ data: { rows } }) => {
          try {
            // The below school code's future catchment area modified
            const schoolCodeToCheck = this.localSchools
              .filter((s) => {
                if (s.calendar_year !== 0) {
                  const i = rows.findIndex(
                    (r) => r.school_code === s.schoolCode
                  )
                  if (i !== -1) {
                    return true
                  }
                }
                return false
              })
              .map((s) => s.schoolCode)

            // Remove the schools whose future catchment boundary changed
            this.localSchoolsToDisplay = this.localSchools.filter(
              (ls) => !schoolCodeToCheck.includes(ls.schoolCode)
            )
          } catch (error) {
            // if filtering failed better to show all local schools
            this.localSchoolsToDisplay = this.localSchools
          }
        })
        .catch(() => {
          // if filtering failed better to show all local schools
          this.localSchoolsToDisplay = this.localSchools
        })
    } else {
      // For current year, no need for filtering
      this.localSchoolsToDisplay = this.localSchools
    }
  }
}
</script>

<style scoped lang="scss">
.addressHeader {
  padding: 8px;
  min-height: 72px;
  height: auto;
  background: $color-third;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  color: $color-text-body;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12); //vuetify copy
}

.v-card {
  border-top-left-radius: 0 !important; //!important to override vuetify styles
  border-top-right-radius: 0 !important;
}

@include desktop {
  .addressHeader {
    padding: 0;
  }
}
</style>
