import _get from 'lodash/get'

import {
  FIELD_TYPE,
  SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL,
  NO_SRE_OPTIONS_ALERT
} from '@/constants'
import { getTerms } from '@/applicationDefinition/droplistData/termDates'
import UTILS from '@/store/utils'

export default {
  methods: {
    isVisible({ field, application, isRemovedSelecedOption }) {
      if (field && field.apiKey === 'SreSeeAmaAlert') {
        return field.visible({ application, isRemovedSelecedOption })
      }
      return field && field.visible ? field.visible(application) : true
    },

    isDisabled(field, application) {
      if (typeof field.disabled === 'function') {
        return !!field.disabled(application)
      } else if (field.disabled) {
        return !!field.disabled
      }
    },

    getDisabledFields(field, application) {
      if (typeof field.disabledFields === 'function') {
        return field.disabledFields(application)
      } else if (Array.isArray(field.disabledFields)) {
        return field.disabledFields
      }
    },

    startYear(field, application) {
      if (typeof field.startYear === 'function') {
        return field.startYear(application)
      } else if (field.startYear) {
        return field.startYear
      }
    },

    endYear(field, application) {
      if (typeof field.endYear === 'function') {
        return field.endYear(application)
      } else if (field.endYear) {
        return field.endYear
      }
    },

    getValue(field) {
      // Gets field value from state. NOTE: ID might be nested
      // (e.g. 'student.familyName') so an eval is required to
      // resolve the value.
      try {
        return _get(this, `$store.state.application.${field.apiKey}`)
      } catch (ex) {
        return ''
      }
    },

    getAttrFromField(field, attribute, defaultValue = null) {
      const attr = field[attribute]
      if (typeof attr === 'function') {
        return attr.call(this)
      } else if (attr) {
        return attr
      } else {
        return defaultValue
      }
    },

    getLabel(field, application) {
      // to get simple text label set in the field
      if (typeof field.label === 'function') {
        return field.label.call(this, application)
      } else if (field.label) {
        return field.label
      } else {
        return false
      }
    },

    getVHtmlLabel(field, application) {
      // to get html label set in the field
      if (typeof field.vHtmlLabel === 'function') {
        return field.vHtmlLabel.call(this, application)
      } else if (field.vHtmlLabel) {
        return field.vHtmlLabel
      } else {
        return false
      }
    },

    getOptions(field, application) {
      if (typeof field.options === 'function') {
        return field.options.call(this, application, this.$store)
      } else if (field.options) {
        return field.options
      }
    },

    getReferenceDataSet(key) {
      let refData = this.$store.state.refData[key]
      return refData
    },

    getTermDropdownData(calendarYear) {
      return getTerms(
        this.$store.state.termDates,
        calendarYear,
        this.$store.state.school.calendarLateInd
      )
    },

    getSupportDocCategoryConfig(categoryName) {
      const oesProperties = this.$store.state.oesProperties
      return (
        oesProperties.supportingDocuments &&
        oesProperties.supportingDocuments[categoryName]
      )
    },

    getRequired(field, application) {
      if (typeof field.required === 'function') {
        return field.required(application)
      } else if (field.required) {
        return field.required
      }
    },

    getScholasticYears(year, application) {
      const scholasticYears = this.$store.state.scholasticYears[year]
      const { catchmentLevel } = application
      const scholasticYearsForCatchmentLevel =
        SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL[catchmentLevel.toUpperCase()] || []
      return scholasticYears.filter((year) =>
        scholasticYearsForCatchmentLevel.includes(year.value)
      )
    },

    getDescription(field, application) {
      if (!field.description) {
        return false
      } else if (typeof field.description === 'function') {
        return field.description.call(this, application, this.$store)
      } else if (field.description) {
        return field.description
      }
    },

    getVHtmlDescription(field, application) {
      if (typeof field.vHtmlDescription === 'function') {
        return field.vHtmlDescription.call(this, application, this.$store)
      } else if (field.vHtmlDescription) {
        return field.vHtmlDescription
      }
    },

    getOptionSubtext(field, application) {
      if (typeof field.optionSubtext === 'function') {
        return field.optionSubtext.call(this, application, this.$store)
      } else if (field.optionSubtext) {
        return field.optionSubtext
      }
    },

    setValue(section, field, val, force) {
      const oVal = _get(this, `$store.state.application.${field.apiKey}`)
      if (oVal !== val || force) {
        // medicare number format adjust
        if (field.apiKey === 'student.medicare[0].number') {
          val = val.replace(/\W/gi, '').replace(/(.{4})(.{5})/g, '$1 $2 ')
        }
        this.$store.dispatch('set', [`application.${field.apiKey}`, val])

        if (field.onFieldSet && !field.onFieldSetAfterAlerts) {
          this.onFieldSet(field, val)
        }
        this.$store.dispatch('addAlert', { section, field })

        this.$store.dispatch('buildCounters')
        this.$store.dispatch('buildAlerts')

        if (field.onFieldSet && field.onFieldSetAfterAlerts) {
          this.onFieldSet(field, val)
        }

        this.$store
          .dispatch('saveApplicationSection', { section, type: '' })
          .catch(() => {
            this.$store.dispatch('set', ['saveStatus', null])
            this.$store.dispatch('set', [
              'error',
              new Error('Error when auto-saving: Could not reach server')
            ])

            this.$router.history.push('/error')
          })
      }
    },

    getCollection(apiKey) {
      try {
        return _get(this, `$store.state.application.${apiKey}`) || []
      } catch (ex) {
        return []
      }
    },

    addNewCollectionItem(field) {
      let collection = this.getCollection(field.apiKey)
      let collectionCounter = 1
      // make sure index is largest current index
      collection.map((item) => {
        if (
          item.collectionItemUniqueId &&
          parseInt(item.collectionItemUniqueId) >= collectionCounter
        ) {
          collectionCounter = parseInt(item.collectionItemUniqueId) + 1
        }
      })
      // make sure all existing item in array has index
      collection = collection.map((item) => {
        if (!item.collectionItemUniqueId) {
          item.collectionItemUniqueId = collectionCounter++
        }
        return item
      })

      // add to counter for unique ID
      let newItem = UTILS.clone(field.newItem)
      newItem.collectionItemUniqueId = collectionCounter++

      // attached new item to array
      collection.push(newItem)

      // save updated array to store
      return this.$store.dispatch('set', [
        'application.' + field.apiKey,
        collection
      ])
    },

    getCurriculumDescription({ description, curriculumLink }) {
      if (curriculumLink != null && curriculumLink.trim().length > 0) {
        // As the below html content used in conjuction with Vue v-html directive,
        // inline style without variable being used
        // also icon is represented with span & mdi class instead of v-icon
        // If curriculumLink isn't a http:// or https:// or ftp://, then we add a protocol

        // eslint-disable-next-line no-useless-escape
        if (!/^(?:f|ht)tps?\:\/\//.test(curriculumLink)) {
          curriculumLink = '//' + curriculumLink
        }
        return (
          description +
          `
          <p class="my-0">
            <a href="${curriculumLink}" target="_blank" style="font-size: 1.4rem;">View Curriculum</a>
            <span class="mdi mdi-large mdi-open-in-new" style="color: #041e42; font-weight: 800; font-size: x-large;"></span>
          </p>
        `
        )
      } else {
        return description
      }
    },

    getSREOptions() {
      if (!this.$store.state.validSREOptions) {
        return []
      }
      const heading = { heading: 'Special Religious Education' }
      if (this.$store.state.validSREOptions.length === 0) {
        const notification = {
          notification: FIELD_TYPE.NOTIFICATION,
          notificationProps: {
            inPage: true,
            type: 'info',
            elevation: 0,
            alertClass: 'blue-alert'
          },
          notificationText: NO_SRE_OPTIONS_ALERT
        }
        return [heading, notification]
      }
      let options = this.$store.state.validSREOptions.map((opt) => {
        let providerList = []
        if (Array.isArray(opt.providers) && opt.providers.length > 0) {
          providerList = opt.providers.map((provider) => provider.name)
        }
        return {
          value:
            'SRE: ' +
            opt.name +
            ` (${providerList.join(', ')})` +
            '|' +
            `${opt.guid}`,
          text: {
            name: opt.name,
            providers: providerList.join(', '),
            description: this.getCurriculumDescription(opt)
          },
          active: 'A'
        }
      })
      options.unshift(heading)
      return options
    },

    getSEEOptions() {
      if (!this.$store.state.validSEEOptions) {
        return []
      }
      const heading = {
        heading:
          'If you do not wish for your child to attend Special Religious Education, please select below:'
      }
      if (this.$store.state.validSEEOptions.length === 0) {
        return [heading]
      }

      let options = this.$store.state.validSEEOptions.map((opt) => {
        let providerList = []
        if (Array.isArray(opt.providers) && opt.providers.length > 0) {
          providerList = opt.providers.map((provider) => provider.name)
        }
        return {
          value:
            'SEE: ' +
            opt.name +
            ` (${providerList.join(', ')})` +
            '|' +
            `${opt.guid}`,
          text: {
            name: opt.name,
            providers: providerList.join(', '),
            description: this.getCurriculumDescription(opt)
          },
          active: 'A'
        }
      })
      options.unshift(heading)
      return options
    },

    getAMAOptions() {
      if (!this.$store.state.validAMAOptions) {
        return []
      }
      let options = this.$store.state.validAMAOptions.map((opt) => {
        let providerList = []
        if (Array.isArray(opt.providers) && opt.providers.length > 0) {
          providerList = opt.providers.map((provider) => provider.name)
        }
        return {
          value:
            'AMA: ' +
            opt.name +
            ` (${providerList.join(', ')})` +
            '|' +
            `${opt.guid}`,
          text: {
            name: opt.name,
            providers: providerList.join(', '),
            description: opt.description
          },
          active: 'A'
        }
      })
      return options
    }
  }
}
