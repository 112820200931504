<!-- Display pending out of area offered applications-->
<template>
  <div class="new-application">
    <ApplicationBanner
      data-testid="application-banner"
      content="<h1>An application already exists<br /> for this email address</h1>"
    />
    <div class="pending-application">
      <b-container class="content">
        <div
          v-if="hasInProgressApplication && inProgressApplication"
          class="white-card in-progress-application"
          data-testid="inProgressApplication-section"
        >
          <b-row>
            <b-col cols="3" md="2" class="text-center">
              <v-avatar size="60" color="primary lighten-4">
                <v-icon
                  color="primary"
                  size="40"
                  alt="In progress applications"
                  aria-label="In progress applications"
                >
                  restore
                </v-icon>
              </v-avatar>
            </b-col>
            <b-col cols="9" md="10">
              <h2>Resume application in progress</h2>
              <p>
                Select “Resume” to continue the application you have in progress
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3" lg="2" md="2" sm="3" class="text-center">
              <v-icon class="material-icons-outlined person-icon"
                >person_outline</v-icon
              >
            </b-col>
            <b-col cols="9" lg="10" md="10" sm="9" class="application-details">
              <b-row>
                <b-col cols="12" md="7">
                  <div>
                    <h3>
                      <strong>
                        {{ studentName }}
                      </strong>
                    </h3>
                  </div>
                  <p>{{ inProgressApplication.schoolName }}</p>
                </b-col>
                <b-col cols="12" md="5">
                  <b-button
                    variant="primary"
                    class="float-lg-right"
                    @click="resumeApplication"
                    >Resume</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div class="pending-application__card-separator">OR</div>
        <div class="white-card">
          <b-row>
            <b-col cols="3" lg="2" md="2" sm="3" class="text-center">
              <v-avatar size="60" color="primary lighten-4">
                <v-icon
                  color="primary"
                  size="40"
                  alt="New application"
                  aria-label="new applications"
                >
                  mdi-launch
                </v-icon>
              </v-avatar>
            </b-col>
            <b-col cols="9" lg="10" md="10" sm="9">
              <b-row>
                <b-col cols="12" md="7">
                  <h2>Start a new application</h2>
                  <p>
                    <b>Remove</b> the application you have in progress and start
                    a new one
                  </p>
                </b-col>
                <b-col cols="12" md="5">
                  <b-button
                    variant="outline-primary"
                    @click="newApplication"
                    class="float-lg-right"
                    >New Application
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'

export default {
  name: 'PendingApplicationsView',
  components: {
    ApplicationBanner
  },
  data() {
    return {
      hasInProgressApplication: true,
      inProgressApplication: this.$store.state.application
    }
  },
  computed: {
    studentName() {
      const studentDetails = this.inProgressApplication.student
      if (
        studentDetails &&
        (studentDetails.firstName ||
          studentDetails.otherName ||
          studentDetails.familyName)
      ) {
        const studentFirstName = studentDetails.firstName
          ? studentDetails.firstName.trim()
          : ''
        const studentOtherName = studentDetails.otherName
          ? studentDetails.otherName.trim()
          : ''
        const studentFamilyName = studentDetails.familyName
          ? studentDetails.familyName.trim()
          : ''
        return (
          studentFirstName +
          ' ' +
          studentOtherName +
          ' ' +
          studentFamilyName
        ).trim()
      }
      return 'Your Child'
    }
  },
  methods: {
    resumeApplication() {
      this.$router.history.push('/application')
    },
    newApplication() {
      this.$store.commit('set', ['studentName', this.studentName])
      this.$store.commit('set', [
        'schoolName',
        this.inProgressApplication.schoolName
      ])

      this.$store.dispatch('openModal', {
        componentName: 'ModalProceedNewApplication'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pending-application {
  position: relative;
  top: $header-height;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  button {
    font-size: 1.6rem;
    width: 20rem;
  }
  .content {
    margin: 0 auto;
    &.container {
      padding: 40px 15px 15px;
    }
  }
  &__card-separator {
    color: $color-primary;
    font-weight: 700;
    line-height: 1.2;
    margin: 3rem 0;
    text-align: center;
    font-size: 2.2rem;
  }
}

.person-icon {
  font-size: 4rem;
  padding: 5px 0 0;
}

.in-progress-application {
  margin-bottom: 40px;
}

@include desktop {
  .new-application {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }
}
@include tablet-wide {
  .new-application {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }
}
@include tablet {
  .new-application {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }
  }
}
</style>
