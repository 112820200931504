<template>
  <div class="FileUpload">
    <v-file-input
      :label="field.label"
      :accept="acceptedMIMETypes"
      class="file-input"
      ref="fileInput"
      multiple
      chips
      outlined
      :clearable="false"
      :placeholder="field.placeholder"
      prepend-icon=""
      :value="files"
      :messages="uploadingMessage"
      :title="uploadingMessage"
      @change="onChange"
    >
      <template #prepend-inner>
        <div class="v-input__icon v-input__icon--prepend-inner">
          <v-icon
            @click="$refs.fileInput.$refs.input.click()"
            class="file-input__upload-icon"
            aria-label="upload file"
            icon
          >
            mdi-paperclip
          </v-icon>
        </div>
      </template>
      <template #selection="{ text, file }">
        <v-chip
          class="file-chip"
          :class="{ 'file-chip--removable': !isFileUploading(file) }"
          small
          :color="isFileUploading(file) ? 'transparent' : 'grey lighten-5'"
        >
          {{ shortenChipText(text) }}
          <v-icon
            v-if="!isFileUploading(file)"
            @click.stop="removeFile(file.id)"
            class="file-chip__close-btn"
            :aria-label="`remove file ${file.name}`"
            small
          >
            mdi-close
          </v-icon>
        </v-chip>
      </template>
    </v-file-input>

    <div class="progress-wrapper">
      <v-progress-linear
        v-if="isUploading || isRemoving || isInvalid"
        class="mb-0"
        color="primary lighten-1"
        background-color="primary lighten-4"
        :indeterminate="(isInvalid || isRemoving) && !isUploading"
        :value="progress"
      ></v-progress-linear>
    </div>

    <div
      class="description"
      v-if="field.description || field.vHtmlDescription"
      :id="field.apiKey"
    >
      <div class="smallMessage" v-if="field.description">
        {{ getDescription(field, application) }}
      </div>
      <div
        class="smallMessage"
        v-if="field.vHtmlDescription"
        v-html="getVHtmlDescription(field, application)"
      ></div>
    </div>
  </div>
</template>

<script>
import endPoint from '@/store/endPoint'
import fieldsMixin from '@/components/mixins/fieldsMixin'
import {
  CATEGORY_UPLOAD_STATUSES,
  UPLOAD_CATEGORY_GTM_LABELS
} from '@/constants'
import ehubUtil from '@/utils/ehub'

export default {
  name: 'FileUpload',
  props: {
    value: Array,
    field: Object,
    section: Object,
    disabled: Boolean
  },
  mixins: [fieldsMixin],
  data() {
    let files = this.value ? [...this.value].sort((a, b) => a.id - b.id) : []
    let idCount = 1
    if (files && files.length) {
      idCount = files[files.length - 1].id + 1
    }
    return {
      files,
      acceptedMIMETypes: this.getAttrFromField(this.field, 'accept'),
      idCount,
      invalidCount: 0,
      statusUploading: 'uploading',
      statusRemoving: 'removing',
      statusAwaitingGroup: 'awaitingGroup',
      statusComplete: 'complete',
      oneMBinBytes: 1048576,
      fileNameRegEx: /^[0-9a-zA-Z!\-_*.'() .]+$/,
      s3Upload: 'UPLOAD',
      s3Delete: 'REMOVE',
      notificationDelay: 1500,
      documentGroupId: new Date().getTime()
    }
  },
  computed: {
    application() {
      return this.$store.state.application || {}
    },
    isInvalid() {
      return this.invalidCount > 0
    },
    maxFileSize() {
      const maxSize = this.getAttrFromField(this.field, 'sizeLimitMB')
      return Number.isNaN(maxSize) ? null : maxSize * this.oneMBinBytes
    },
    maxFileCount() {
      return this.getAttrFromField(this.field, 'fileLimit', 0)
    },
    isUploading() {
      return this.filesUploading.length > 0
    },
    isRemoving() {
      return this.filesRemoving.length > 0
    },
    status() {
      return this.isUploading
        ? CATEGORY_UPLOAD_STATUSES.UPLOADING
        : this.isRemoving
        ? CATEGORY_UPLOAD_STATUSES.REMOVING
        : CATEGORY_UPLOAD_STATUSES.COMPLETE
    },
    filesInUploadGroup() {
      return this.files.filter((file) => this.isFileInUploadGroup(file)) || []
    },
    filesUploading() {
      return (
        this.filesInUploadGroup.filter((file) => this.isFileUploading(file)) ||
        []
      )
    },
    filesRemoving() {
      return this.files.filter((file) => this.isFileRemoving(file)) || []
    },
    totalUploaded() {
      return this.filesInUploadGroup.reduce(
        (total, file) => total + (file.loaded || 0),
        0
      )
    },
    totalFileSize() {
      return this.filesInUploadGroup.reduce(
        (total, file) => total + (file.size || 0),
        0
      )
    },
    progress() {
      return this.totalFileSize
        ? Math.ceil((this.totalUploaded * 100) / this.totalFileSize)
        : 0
    },
    uploadingMessage() {
      const numFilesInGroup = this.filesInUploadGroup.length
      const numFilesUploading = this.filesUploading.length
      const numUploadedFiles = this.files.length
      const numFile = numFilesInGroup - numFilesUploading + 1
      if (this.isUploading) {
        return `File ${numFile} of ${numFilesInGroup} uploading...`
      } else if (numUploadedFiles) {
        return `${numUploadedFiles} file${
          numUploadedFiles > 1 ? 's' : ''
        } uploaded`
      } else {
        return ''
      }
    }
  },
  mounted() {
    this.$refs.fileInput.$refs.input.setAttribute(
      'aria-describedby',
      this.field.apiKey
    )
  },
  watch: {
    isUploading(newValue) {
      //If we're done uploading, set the status of all uploading files to 'complete'
      if (!newValue) {
        this.files.forEach((file, index) => {
          if (this.isFileInUploadGroup(file)) {
            file.uploadStatus = this.statusComplete
            this.$set(this.files, index, file)
          }
        })
      }
    },
    status(newValue) {
      this.$store.dispatch('setUploadingStatus', {
        [this.field.category]: newValue
      })
    }
  },
  methods: {
    getFileIndexByName(fileName) {
      return this.files.findIndex(
        (uploadedFile) => uploadedFile.name === fileName
      )
    },
    getFileIndexByID(id) {
      return this.files.findIndex((uploadedFile) => uploadedFile.id === id)
    },
    shortenChipText(text) {
      return this.$vuetify.breakpoint.xsOnly && text.length > 10
        ? `${text.substring(0, 2)}...${text.substring(text.length - 5)}`
        : text
    },
    isFileInUploadGroup(file) {
      return (
        file.uploadStatus === this.statusAwaitingGroup ||
        file.uploadStatus === this.statusUploading
      )
    },
    isFileUploading(file) {
      return file.uploadStatus === this.statusUploading
    },
    isFileRemoving(file) {
      return file.uploadStatus === this.statusRemoving
    },
    onChange(files) {
      //v-file-input emits a 'change' event with all files as the payload if you try uploading a file while files are still uploading.
      //So, we must ignore this event to ensure we don't copy all the files.
      if (files !== this.files) {
        //Some of the files may already be uploaded and so will be replacing the pre-existing file.
        //In this case that file will not be adding to the total count (as it's a replacement).
        const newFileCount = files.filter(
          (file) => this.getFileIndexByName(file.name) === -1
        ).length
        if (this.files.length + newFileCount > this.maxFileCount) {
          this.changeNoOp()
          files.map((file) => this.pushFileCountNotification(file.name))
        } else {
          files.map((file) => {
            this.attemptUpload(file)
          })
        }
      }
    },
    addOrUpdateFile(file) {
      let id
      const existingFileIndex = this.getFileIndexByName(file.name)

      if (existingFileIndex !== -1) {
        //If file of the same name exists, just update existing file
        const existingFile = this.files[existingFileIndex]
        id = existingFile.id
        existingFile.uploadStatus = this.statusUploading
        existingFile.type = file.type
        existingFile.size = file.size
        this.$set(this.files, existingFileIndex, existingFile)
      } else {
        //Else file is new and we add it to our file list
        id = this.idCount++
        this.files.push({
          uploadStatus: this.statusUploading,
          id,
          size: file.size,
          name: file.name,
          type: file.type,
          objectKey: `${this.$store.state.auth.userId.toLowerCase()}/${
            this.field.category
          }/${this.documentGroupId}/${file.name}`
        })
      }
      return id
    },
    createUploadNotification(fileName, reason) {
      return {
        title: 'File did not upload',
        type: 'error',
        vHtmlBody: `<strong>${fileName}</strong> did not upload because ${reason}`
      }
    },
    createRemoveNotification(fileName, reason) {
      return {
        title: `File could not be removed`,
        type: 'error',
        vHtmlBody: `<strong>${fileName}</strong> could not be removed because ${reason}`
      }
    },
    pushNotification(notification, delay) {
      //Notifications added immediately after file explorer closes need a delay in order for some assistive technologies to pick up the notification.
      //In order to show some responsiveness during this delay we will show an indeterminate loading bar while invalidCount > 0 (i.e. while we wait on the messages to appear).
      if (delay) {
        this.invalidCount++
        setTimeout(() => {
          this.$store.dispatch('pushNotification', notification)
          this.invalidCount--
        }, delay)
      } else {
        this.$store.dispatch('pushNotification', notification)
      }
    },
    pushServerErrorNotification(fileName) {
      this.pushNotification(
        this.createUploadNotification(
          fileName,
          'of a server error. Please try uploading the file again.'
        ),
        this.notificationDelay
      )
    },
    pushFileSizeNotification(fileName) {
      this.pushNotification(
        this.createUploadNotification(
          fileName,
          'it exceeds the maximum file size limit.'
        ),
        this.notificationDelay
      )
    },
    pushRemoveFileNotification(fileName) {
      this.pushNotification(
        this.createRemoveNotification(
          fileName,
          'of a server error. Please try removing the file again.'
        )
      )
    },
    pushFileTypeNotification(fileName) {
      this.pushNotification(
        this.createUploadNotification(fileName, 'it is an invalid file type.'),
        this.notificationDelay
      )
    },
    pushFileNameNotification(fileName) {
      this.pushNotification(
        this.createUploadNotification(
          fileName,
          'this is not a valid file name.'
        ),
        this.notificationDelay
      )
    },
    pushFileCountNotification(fileName) {
      this.pushNotification(
        this.createUploadNotification(
          fileName,
          'you have exceeded the maximum number of documents for this category.'
        ),
        this.notificationDelay
      )
    },
    handleServerError(id, fileName) {
      this.pushServerErrorNotification(fileName)
      setTimeout(() => this.removeFileFromComponent(id), this.notificationDelay)
    },
    changeNoOp() {
      this.files = [...this.files] //We must cause a change in the value or else the v-file-input component will change its internal value to something other than our supplied value
    },
    isValidFileSize(file) {
      return !(this.maxFileSize && file.size > this.maxFileSize)
    },
    isValidFileName(file) {
      return this.fileNameRegEx.test(file.name)
    },
    isValidFileType(file) {
      return (
        !this.acceptedMIMETypes ||
        !!(file.type && this.acceptedMIMETypes.includes(file.type))
      )
    },
    validateFile(file) {
      if (!this.isValidFileSize(file)) {
        this.pushFileSizeNotification(file.name)
        return false
      } else if (!this.isValidFileType(file)) {
        this.pushFileTypeNotification(file.name)
        return false
      } else if (!this.isValidFileName(file)) {
        this.pushFileNameNotification(file.name)
        return false
      } else {
        return true
      }
    },
    removeFileFromComponent(id) {
      const fileIndex = this.getFileIndexByID(id)
      if (fileIndex !== -1) {
        this.files.splice(fileIndex, 1)
      }
    },
    removeFileFromApplicationPayload(id) {
      const appFiles = [...this.value]
      const fileIndex = appFiles.findIndex((file) => file.id === id)
      if (fileIndex !== -1) {
        appFiles.splice(fileIndex, 1)
        this.$emit('change', appFiles)
      }
    },
    async removeFromS3(id) {
      const file = this.files.find((file) => file.id === id)
      const url = await this.generateS3URL(file, this.s3Delete)
      if (!url) {
        throw new Error('Error while generating S3 URL')
      } else {
        return endPoint.deleteFile(url)
      }
    },
    async removeFile(id) {
      //Set status to 'removing'
      const fileIndex = this.getFileIndexByID(id)
      const file = this.files[fileIndex]
      if (file) {
        file.uploadStatus = this.statusRemoving
        this.$set(this.files, fileIndex, file)
      }
      try {
        await this.removeFromS3(id)
        this.triggerGTMEvent('Removed')
        this.removeFileFromComponent(id)
        this.removeFileFromApplicationPayload(id)
      } catch (e) {
        //Failed: revert status and add notification.
        const fileIndex = this.getFileIndexByID(id)
        const file = this.files[fileIndex]
        if (file) {
          file.uploadStatus = this.statusComplete
          this.$set(this.files, fileIndex, file)
        }
        this.pushRemoveFileNotification(file.name)
      }
    },
    async attemptUpload(file) {
      if (!this.validateFile(file)) {
        this.changeNoOp()
        return
      }
      //We must immediately update `this.files` otherwise the internal value of the component will kick in and cause strange display issues
      const id = this.addOrUpdateFile(file)

      try {
        const url = await this.generateS3URL(file, this.s3Upload)
        if (!url) {
          this.handleServerError(id, file.name)
        } else {
          await this.upload(file, id, url)
          this.triggerGTMEvent('Added')
          return this.saveToApplication(id)
        }
      } catch (e) {
        this.handleServerError(id, file.name)
      }
    },
    async upload(file, id, url) {
      await endPoint.uploadFile(url, file, this.progressUpdateFn(id))
    },
    progressUpdateFn(id) {
      const vm = this
      return function ({ loaded }) {
        const index = vm.files.findIndex((file) => file.id === id)
        const file = vm.files[index]
        if (file) {
          file.loaded = loaded
          vm.$set(vm.files, index, file)
        }
      }
    },
    async generateS3URL(file, action) {
      const resp = await endPoint.generateSignedURL({
        authToken: this.$store.state.auth.idToken,
        file,
        category: this.field.category,
        action,
        // Add timestamp to the uploaded file url for fixing missing document
        // issues for multiple application submission, see FUS-894
        path: this.documentGroupId
      })
      return resp && resp.data && resp.data.body ? resp.data.body.Url : null
    },
    triggerGTMEvent(action) {
      const gtmApplication = ehubUtil.getGtmApplication()
      this.$gtm.trackEvent({
        event: 'interaction',
        category: 'Document Upload',
        action: action,
        label: UPLOAD_CATEGORY_GTM_LABELS[this.field.category],
        application: gtmApplication
      })
    },
    saveToApplication(fileID) {
      //Update status of file in local component
      const index = this.getFileIndexByID(fileID)
      const file = this.files[index]
      const { id, name, size, objectKey, type } = file
      file.uploadStatus = this.statusAwaitingGroup
      this.$set(this.files, index, file)

      //Update the application JSON structure with the successfully uploaded file
      if (this.value) {
        const applicationDocs = [...this.value]
        let existingFileIndex = applicationDocs.findIndex(
          (appFile) => appFile.id === id
        )
        if (existingFileIndex !== -1) {
          applicationDocs[existingFileIndex] = {
            id,
            name,
            size,
            objectKey,
            type
          }
        } else {
          applicationDocs.push({ id, name, size, objectKey, type })
        }
        this.$emit('change', applicationDocs)
      } else {
        this.$emit('change', [{ id, name, size, objectKey, type }])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.FileUpload {
  margin-top: 1rem;
  background-color: $color-white;
  color: $color-text-body;

  ::v-deep button {
    border: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.file-chip {
  padding-right: 31px;

  &--removable {
    padding-right: 0;
  }
}

.progress-wrapper {
  height: 4px;
}

.description {
  margin-bottom: 1rem;
}

//Vuetify components
::v-deep {
  .v-messages__wrapper {
    margin-left: -10px;
    font-size: 14px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .v-text-field__details {
    height: 22px;
  }

  // /*This CSS is to resolve the bug with the upload component in safari:
  //  https://github.com/vuetifyjs/vuetify/issues/10832
  //  This bug was resolved in this fix:
  //  https://github.com/vuetifyjs/vuetify/commit/d5800aad7dc9e62e7d398c890b7af6580e6060ce
  //  as part of v2.3.11. However, due to circumstances at the time the bug was found
  //  we were unable to do a vuetify update and so are implementing the fix ourselves.
  //  This should be removed once vuetify is updated to this version or higher.
  //  */
  .v-file-input input[type='file'] {
    pointer-events: none;
  }
}
</style>
