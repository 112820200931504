<template>
  <div v-if="isReady" class="ApplicationView isEditMode">
    <ApplicationBanner :content="'<h1>' + setHeading + '</h1>'" />

    <b-container class="content">
      <b-row v-if="this.showBanner" data-testid="system-alert">
        <b-col
          class="mt-8 mb-0 mr-md-10 ml-md-10 pl-2 pr-2 pl-sm-6 pr-sm-6 pa-md-0 banner"
        >
          <v-alert
            border="left"
            colored-border
            color="orange darken-1 mr-1"
            icon="mdi-alert"
            class="mb-0"
          >
            <h3
              class="banner__title grey--text text--darken-1 font-weight-bold"
            >
              {{ this.banner.heading }}
            </h3>
            <p>
              {{ this.banner.body }}
            </p>
          </v-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <div :class="[{ 'mt-md-3 p-0': !showBanner }, 'sideContent']">
            <ApplicationMenu />
            <label v-if="devEnv">
              <input
                type="checkbox"
                v-model="devTools"
                aria-label="Show dev tools"
              />
              Show Dev Tools ({{ devTools }})
            </label>
          </div>
        </b-col>

        <b-col lg="8" :class="[{ 'mt-md-3': !showBanner }, 'mainContent']">
          <DevTools v-if="devTools && devEnv" />
          <ContainerApplicationReview v-if="sectionExpandedId === 'review'" />
          <ApplicationSection :sections="sections" />
        </b-col>
      </b-row>

      <v-dialog v-model="attempToLeave" width="500" class="dialog">
        <v-card class="pa-4">
          <span class="dialog__close-btn">
            <v-btn
              fab
              color="white"
              x-small
              depressed
              @click="cancelNavigation"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </span>

          <div class="pa-2 dialog__title">
            <v-avatar class="primary lighten-4 mr-2" size="40">
              <v-icon size="22"> mdi-backspace-outline </v-icon>
            </v-avatar>
            Sign out of online enrolment?
          </div>

          <div class="text--primary mb-5 pa-2 dialog__content">
            If you proceed to a different website, you will be signed out of
            online enrolment, and will need to sign in again to resume.
          </div>
          <v-card-actions class="dialog__actions">
            <v-btn
              class="btn-outline-primary text-h5"
              depressed
              large
              @click="cancelNavigation"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="text-h5"
              depressed
              large
              @click="proceedNavigation"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showNameChangeNotice"
        data-testid="name-change-notification"
        max-width="700"
        persistent
      >
        <v-card class="pa-6">
          <span class="dialog__close-btn">
            <v-btn
              fab
              color="white"
              x-small
              depressed
              @click="closeNameChangeNotice"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </span>

          <div class="pa-2 dialog__title">
            <v-avatar class="primary lighten-4 mr-2" size="40">
              <v-icon size="22"> mdi-swap-vertical-variant </v-icon>
            </v-avatar>
            A parent/carer name has been updated
          </div>

          <div class="text--primary mb-5 pa-2">
            <p>
              <strong>Please note:</strong> The name of the parent/carer
              completing this application has been updated on the form to match
              your verified details used with online enrolment.
            </p>
            <p>
              Please review all parent carer details before submitting the
              application.
            </p>
          </div>
          <v-card-actions class="d-flex justify-end">
            <v-btn
              color="primary"
              class="text-h5"
              depressed
              large
              @click="closeNameChangeNotice"
            >
              <v-icon color="white" class="mr-2">
                mdi-check-circle-outline
              </v-icon>
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </b-container>
  </div>
</template>

<script>
import base64url from 'base64url'
import jwtDecode from 'jwt-decode'

import ContainerApplicationReview from '@/containers/application/ContainerApplicationReview.vue'

import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import ApplicationMenu from '@/components/application/ApplicationMenu.vue'
import ApplicationSection from '@/components/application/ApplicationSection.vue'
import DevTools from '@/components/app/DevTools.vue'
import { ERROR_CODES, API_ERROR_SUBTYPES } from '@/constants'
import moment from 'moment'
import _capitalize from 'lodash/capitalize'
import { sessionStorageUtils } from '@/utils/browserStorage'
import { mapGetters } from 'vuex'

import { upgradeApplication } from '@/store/helper/upgrade'
import generateVerificationArtifacts from '@/store/helper/verify'

export default {
  name: 'ApplicationView',
  props: {
    isUnitTesting: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ApplicationBanner,
    ApplicationMenu,
    ApplicationSection,
    ContainerApplicationReview,
    DevTools
  },
  data() {
    return {
      devEnv: process.env.NODE_ENV === 'development',
      devTools: false,
      attempToLeave: false,
      navigateTo: null,
      navigateFrom: null,
      confirmToLeave: false,
      showNameChangeNotice: this.$store.state.ernEnrolmentOwnerNameNotMatched
    }
  },
  watch: {
    scholasticYear(newVal, oldVal) {
      if (
        this.$store.state.school &&
        this.$store.state.school.sre_see &&
        this.$store.state.schoolCustomisation &&
        this.$store.state.schoolCustomisation.data
      ) {
        let param = {
          sre_see: this.$store.state.school.sre_see,
          scholasticYear: newVal || oldVal
        }
        this.$store.dispatch('prepareSchoolOfferSREAndSEE', param)
      }
    },
    intendedStartDate(newVal) {
      if (
        this.$store.state.school &&
        this.$store.state.school.sre_see &&
        this.$store.state.schoolCustomisation &&
        this.$store.state.schoolCustomisation.data
      ) {
        let param = {
          sre_see: this.$store.state.school.sre_see,
          scholasticYear: newVal ? this.scholasticYear : null
        }
        this.$store.dispatch('prepareSchoolOfferSREAndSEE', param)
      }
    },
    //If the user selected sre/see/ama option, but the school has removed sre/see/ama configuration
    // However, the user's last sectionExpandedId is supplementaryDetails, we should allocate to the student detail section
    // when the user resume the application.
    sectionExpandedId(newVal) {
      if (newVal === 'supplementaryDetails' && !this.isSchoolOfferSreSee) {
        this.$store.commit('set', [
          'application.meta.sectionExpandedId',
          'student'
        ])
      }
    }
  },
  methods: {
    async getAllData() {
      this.$store.dispatch('showSpinner')
      await this.$store.dispatch('clearSupplementaryDetailsCacheData')

      this.getReferenceData()
      this.getTermDates()
      await this.getApplication()
      this.getSupportingDocConfig()

      //getSections needs to be dispatched after getApplication in order to get the application meta data to render sections (with/without sibling section. Details in EHUB-129)
      await this.$store.dispatch('getSections')

      // this build counters needs to be dispatched after getSections as it depends on the sections data
      await this.$store.dispatch('buildCounters')
    },

    getReferenceData() {
      this.$store
        .dispatch('getReferenceData')
        .then(() => {
          this.proceedIfReady()
        })
        .catch((e) => {
          this.showError(e)
        })
    },

    getTermDates() {
      this.$store
        .dispatch('getTermDates')
        .then(() => {
          this.proceedIfReady()
        })
        .catch((e) => {
          this.showError(e)
        })
    },

    getSupportingDocConfig() {
      const oesProperties = this.$store.state.oesProperties
      if (oesProperties && oesProperties.supportingDocuments) {
        this.proceedIfReady()
      } else {
        this.$store
          .dispatch('getOESProperties')
          .then(() => {
            this.proceedIfReady()
          })
          .catch((e) => {
            this.showError(e)
          })
      }
    },
    getApplication() {
      /*
        A saved application should always be available at this point. Even for a new
        application, the landing pages will have already saved initial data (school,
        catchment area, residential address). HOWEVER...

        Dynamo DB is currently using "Eventually consistent" reads, meaning that
        when PUTting an application, the success response may arrive before tables have
        finished writing. This means that when the landing pages PUT the initial application
        data, this getApplication() might be called before the data has been written, and
        therefore will return no application data.

        Currently the best solution (which doesn't involve rewriting half of PI) is to
        wait and try again if no application data is returned (hence the setTimeout).
      */
      return (
        this.$store
          .dispatch('getApplication')
          .then(async () => {
            // reference application in store.state directly instead of this.application as Vue may not have updated
            // this.application in time, causing a race condition.
            if (!this.$store.state.application) {
              // Wait and retry if no application data (see essay above)
              setTimeout(async () => {
                await this.getApplication()
              }, 250)
            } else {
              this.getScholasticYears()
              await this.getSchoolInfo()
            }
            return Promise.resolve()
          })
          /**
           * Upgrade data
           *
           * This section addresses the actions that need to be taken to upgrade data between
           * version upgrades. This is to ensure that the application data is in the correct
           * format for the current version of the application.
           *
           * 7.4 - 8.0 - Upgrade SRE/SEE/AMA options
           */
          .then(async () => {
            if (this.$store.state.application) {
              const upgradeArtifacts = upgradeApplication({
                application: this.$store.state.application,
                schoolCustomisationFile: this.$store.state.schoolCustomisation
                  ? this.$store.state.schoolCustomisation.data
                  : null
              })
              if (upgradeArtifacts.length > 0) {
                const errors = upgradeArtifacts.filter(
                  (artifact) => artifact.error
                )
                // TODO this shouldn't be here, it is specific to SRE/SEE, should be in the upgrade file
                if (errors.length > 0) {
                  this.$store.commit('set', ['isRemovedSelecedOption', true])
                }
                const artifactPatches = upgradeArtifacts.filter(
                  (artifact) => artifact.patch
                )
                await Promise.all(
                  artifactPatches.map((artifactPatch) => {
                    for (const applicationProp in artifactPatch.patch) {
                      this.$store.commit('set', [
                        `application.${applicationProp}`,
                        artifactPatch.patch[applicationProp]
                      ])
                    }
                    const section = this.$store.state.sections.find(
                      (section) => section.id === artifactPatch.section
                    )
                    return this.$store
                      .dispatch('saveApplicationSection', { section })
                      .catch(() => {
                        this.$store.dispatch('set', ['saveStatus', null])
                        this.$store.dispatch('set', [
                          'error',
                          new Error('Error when saving: Could not reach server')
                        ])
                        this.$router.history.push('/error')
                      })
                  })
                )
              }
            }
          })
          /**
           * Verify data
           *
           * This section is to be used to verify data in the application. Sometimes reference
           * data will change and the application data will be out of sync. This section
           * addresses the actions that need to be taken to fix or remove the data.
           */
          .then(async () => {
            if (this.$store.state.application) {
              const verifyPatches = generateVerificationArtifacts({
                store: this.$store
              })

              await Promise.all(
                verifyPatches.map((artifactPatch) => {
                  for (const applicationProp in artifactPatch.patch) {
                    this.$store.commit('set', [
                      `application.${applicationProp}`,
                      artifactPatch.patch[applicationProp]
                    ])
                  }
                  const section = this.$store.state.sections.find(
                    (section) => section.id === artifactPatch.section
                  )
                  return this.$store
                    .dispatch('saveApplicationSection', { section })
                    .catch(() => {
                      this.$store.dispatch('set', ['saveStatus', null])
                      this.$store.dispatch('set', [
                        'error',
                        new Error('Error when saving: Could not reach server')
                      ])
                      this.$router.history.push('/error')
                    })
                })
              )
            }
          })
          .catch((error) => {
            this.$store.dispatch('hideSpinner')
            if (error.type === ERROR_CODES.NO_PENDING) {
              //to stop recursive calls to getApplication function
              this.$store.dispatch('set', [
                'application',
                this.$store.state.currentApplication
              ])
              this.showError(error)
            } else {
              //handle API errors
              this.$store.dispatch('setAPIError', {
                error,
                fallbackCode: API_ERROR_SUBTYPES.getApplication
              })
              this.$router.history.push('/error')
            }
          })
      )
    },
    async getSchoolInfo() {
      const pilotSchools = await this.$store.dispatch('getPilotSchools')
      let schoolInApplication = pilotSchools.data.filter(
        (s) => s.schoolCode === this.$store.state.application.schoolCode
      )[0]
      this.$store.dispatch('set', ['school', schoolInApplication])
      this.$store.dispatch('set', ['oesSchools', pilotSchools.data])
      // We have to call the schoolCustomisation api here as we need to consider the scenarios:
      // The user landed one school, but resume application for another school, we need to make sure we get the right school customisation data
      if (this.$store.state.school && this.$store.state.school.sre_see) {
        await this.$store.dispatch(
          'getSchoolCustomisation',
          this.$store.state.school.schoolCode
        )
      }
      if (
        this.$store.state.school &&
        this.$store.state.school.sre_see &&
        this.$store.state.schoolCustomisation &&
        this.$store.state.schoolCustomisation.data
      ) {
        let param = {
          sre_see: this.$store.state.school.sre_see,
          scholasticYear: this.$store.state.application.scholasticYear
        }

        this.$store.dispatch('prepareSchoolOfferSREAndSEE', param)
      }
      // For resuming application, the user selected option but it is removed in the SI
      if (
        this.$store.state.school &&
        !this.$store.state.school.sre_see &&
        this.$store.state.application.selectedSreOrSeeOrAmaOption
      ) {
        this.$store.commit('set', [
          'application.selectedSreOrSeeOrAmaOption',
          ''
        ])
      }
      this.proceedIfReady()
    },

    getScholasticYears() {
      this.$store
        .dispatch('getScholasticYears')
        .then(() => {
          this.proceedIfReady()
        })
        .catch((e) => {
          this.showError(e)
        })
    },

    proceedIfReady() {
      // Once all application, scholastic year and reference data are
      // present we can proceed with some final form operations...
      if (this.isReady) {
        this.$store.dispatch('hideSpinner')

        if (this.application.meta && this.application.meta.hasAttemptToSubmit) {
          this.$store.dispatch('buildAlerts')
        }
        // hardcode intendedStartDate as a term one start date of the next year for y67t offers
        // added this logic in the application view because the getApplication is getting called before execution of getTermDates
        // need refactoring here, load all the referenece data before coming on the application page.
        // if (this.termDates && this.application && this.application.eoiID) {
        //   const calendarYear = moment(this.application.dateReceived).year() + 1
        //   const termOne = 'Term One (' + calendarYear + ')'
        //   const startTerm = this.termDates.find(
        //     (term) =>
        //       term.code === termOne &&
        //       term.calendarLateInd === this.$store.state.school.calendarLateInd
        //   )
        //   this.application.intendedStartDate = startTerm.value
        //   this.application.scholasticYear = '7'
        // }
      }
    },

    getLabel(field, application) {
      if (field && typeof field.label === 'function') {
        return field.label(application)
      } else if (field && field.label) {
        return field.label
      } else {
        return ''
      }
    },

    showError(error) {
      // eslint-disable-next-line no-console
      console.log(error)
      this.$store.dispatch('set', ['error', error])
      this.$router.history.push('/error')
      this.$store.dispatch('hideSpinner')
    },
    proceedNavigation() {
      this.attempToLeave = false
      this.confirmToLeave = true
      this.$router.history.push(this.navigateTo.fullPath)
    },
    cancelNavigation() {
      this.attempToLeave = false
    },
    leaveWarning(e) {
      // Cancel the event
      e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = ''
    },
    closeNameChangeNotice() {
      this.showNameChangeNotice = false
    },
    setTokens(idToken, refreshToken) {
      this.$store.commit('set', ['auth.idToken', idToken])
      this.$store.commit('set', ['auth.idTokenTime', new Date().getTime()])
      this.$store.commit('set', ['auth.refreshToken', refreshToken])
      this.$store.commit('set', ['auth.userId', jwtDecode(idToken).email])
    }
  },
  computed: {
    ...mapGetters([
      'scholasticYear',
      'isSchoolOfferSreSee',
      'sections',
      'intendedStartDate'
    ]),
    application() {
      return this.$store.state.application
    },
    banner() {
      if (
        !this.$store.state.oesProperties.banner ||
        !this.$store.state.oesProperties.banner.pi
      ) {
        return
      }
      return this.$store.state.oesProperties.banner.pi
    },
    showBanner() {
      if (
        !this.banner ||
        !this.banner.displayStartTime ||
        !this.banner.displayEndTime
      ) {
        return false
      }

      const now = moment().valueOf()
      let startTime = moment(
        this.banner.displayStartTime,
        'DD/MM/YYYY HH:mm:ss'
      ).valueOf()
      let endTime = moment(
        this.banner.displayEndTime,
        'DD/MM/YYYY HH:mm:ss'
      ).valueOf()
      return now >= startTime && now <= endTime
    },
    isReady() {
      let state = this.$store.state
      return (
        this.application &&
        state.refData &&
        state.scholasticYears &&
        state.termDates &&
        state.oesProperties &&
        state.oesProperties.supportingDocuments &&
        state.school
      )
    },
    sectionExpandedId() {
      try {
        return this.application.meta.sectionExpandedId || ''
      } catch (e) {
        return ''
      }
    },

    setHeading() {
      if (this.sectionExpandedId !== 'review') {
        let section = this.$store.state.sections.filter(
          (val) => val.id === this.sectionExpandedId
        )[0]

        let field = section && section.fields[0]

        let sectionTitle = this.getLabel(field, this.application)
        window.document.title =
          sectionTitle.replace(/\w+/g, _capitalize) + ' | Online Enrolment'
        return sectionTitle
      } else {
        window.document.title = 'Review | Online Enrolment'
        return 'Review'
      }
    },
    termDates() {
      return this.$store.state.termDates
    }
  },

  mounted() {
    // set the event listener to pop up a leave page warning if users refresh or manually type in an URL in the address bar.
    window.onbeforeunload = this.leaveWarning

    //If an user is coming from EHUB, the tokens will be saved in the session storage to retrieve the application data
    // here we are using those tokens when ehub users are refreshing the application view.
    const idTokenFromSessionStorage = sessionStorageUtils.getItem('idToken')
    const refreshTokenFromSessionStorage =
      sessionStorageUtils.getItem('refreshToken')

    if (idTokenFromSessionStorage && refreshTokenFromSessionStorage) {
      // if users are from EHUB and they are refreshing application view, don't show the leave warning
      window.onbeforeunload = null
      // set the tokens fromm session storage to be used to retrieve application data
      this.setTokens(idTokenFromSessionStorage, refreshTokenFromSessionStorage)
    }

    // Make sure there is an auth session
    if (
      (!this.$store.state.auth || !this.$store.state.auth.idToken) &&
      !idTokenFromSessionStorage
    ) {
      this.$router.history.push('/login/resume')
      //hide the leave warning
      this.attempToLeave = false
      return false
    }

    /*
      Once we are in the form, isNewApplication should be cleared. This is necessary
      to prevent issues if we go back to the OTP page (using the back button) and
      enter a new email. We need this scenario to operate with the "resume" flow,
      whereby this new unknown email would show the "No application found" page (allowing
      the user to start a new application or enter a different email).

      If we persisted with the "new application" flow when going back to the OTP page,
      a new application would be created with the wrong data (because it would proceed
      with application data already in state).
    */
    this.$store.dispatch('set', ['isNewApplication', false])

    // Gets all our form data...
    if (!this.isUnitTesting) {
      this.getAllData()
    }

    // Unique tracking for GTM
    // This creates a unique ID based on the user ID
    if (this.$store.state.auth.userId) {
      const uniqueId = base64url.encode(this.$store.state.auth.userId)
      const midpoint = uniqueId.length / 2

      let gtmDataLayer = (window.dataLayer = window.dataLayer || [])

      if (uniqueId && uniqueId.length) {
        let trackingId = uniqueId.substring(0, midpoint)
        trackingId += process.env.VUE_APP_GTM_SALT
        trackingId += uniqueId.substring(midpoint)

        gtmDataLayer.push({ userId: trackingId })
      }
    }
  },

  destroyed() {
    // remove the event listener to pop up a leave page warning if users refresh or manually type in an URL in the address bar.
    window.onbeforeunload = null
  },
  beforeRouteLeave(to, from, next) {
    const isEHubRoute = to.matched.some(
      (route) => route.name === 'eHubRouteWrapper'
    )
    if (
      this.$store.state.auth.idToken &&
      to.name !== 'PendingOffers' &&
      to.name !== 'PendingApplicationsView' &&
      !isEHubRoute
    ) {
      this.attempToLeave = true
    } else {
      //handle the case where users click back button on cancel application page
      this.attempToLeave = false
      if (isEHubRoute) {
        // if users are on application view and they are clicking the browser back button, redirect them to ehub dashboard
        window.location.href = `${window.location.origin}/dashboard`
      } else {
        next()
      }
      return
    }
    this.navigateTo = to
    this.navigateFrom = from
    const routesToIgnoreLeaveWarning = [
      'OoaDeclineView',
      'ConfirmationView',
      'ErrorView',
      'CancelView',
      'LogoutView',
      'ApplicationView'
    ]

    if (this.confirmToLeave || routesToIgnoreLeaveWarning.includes(to.name)) {
      if (
        to.name === 'PendingOffers' ||
        to.name === 'PendingApplicationsView'
      ) {
        // leave warning won't show for these two views and we don't want to log users out
        next()
      } else {
        this.$store.dispatch('logout')
        next()
      }
    } else {
      //stop users navigate away further by clicking the browser back button if the pop up is on and users haven't take actions.
      next(this.navigateFrom.fullPath)
    }
  }
}
</script>

<style lang="scss">
.ApplicationView {
  margin: 0;
  .banner__title {
    font-size: 1.6rem;
  }
  &.isEditMode {
    .ApplicationBanner {
      margin-bottom: $header-height;
      height: 102px;
      max-height: 102px;
      position: relative;
      padding: 15px 15px 10px 15px;
      .headline {
        .bannerTitle {
          position: absolute;
          transform: none;
          bottom: 0;
          top: auto;
        }
        .bgLeftElement {
          height: 360px;
          width: 360px;
          left: -300px;
          bottom: -190px;
        }
        .bgRightElement {
          height: 196px;
          width: 523px;
          right: -190px;
          bottom: -90px;
        }
      }
    }
  }
}

.dialog {
  &__title {
    font-size: 20px;
    font-weight: 700;
  }
  &__close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &__content {
    font-size: 16px;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
  }
}
@include desktop {
  #app {
    .isEditMode {
      .ApplicationBanner {
        .headline {
          .bannerTitle {
            position: absolute;
            transform: none;
            bottom: 0;
            top: auto;
          }
          h1 {
            font-size: 40px;
          }
          .bgLeftElement {
            left: -344px;
            bottom: -292px;
          }
          .bgRightElement {
            right: -210px;
            bottom: -120px;
          }
        }
      }
      .content {
        width: 100%;
        height: 100%;
      }

      .sideContent {
        position: relative;
        top: 2rem;
        display: block;
        min-height: 100%;
        min-width: 325px;
      }

      .mainContent {
        width: calc(100% - #{$desktop-sidebar-width});
      }

      .isEditMode .sideContent {
        // Extra padding for when save bar is visible, so bottom content can be reached
        padding-bottom: $save-bar-height;
      }

      .isEditMode .mainContent {
        padding-bottom: $save-bar-height;
      }
    }
  }
}

@include tablet-wide {
  #app {
    .isEditMode {
      .ApplicationBanner {
        .headline {
          .bannerTitle {
            position: absolute;
            transform: none;
            bottom: 0;
            top: auto;
          }
          .bgLeftElement {
            left: -240px;
            bottom: -180px;
          }
          .bgRightElement {
            right: -330px;
            bottom: -50px;
          }
        }
      }
    }
    .container {
      .sideContent {
        padding: 4rem 0 0;
      }
      .mainContent {
        padding: 0;
      }
    }
  }
}

@include tablet {
  #app {
    .isEditMode {
      .ApplicationBanner {
        height: 82px;
        max-height: 82px;
        .headline {
          .bannerTitle {
            position: absolute;
            transform: none;
            bottom: 0;
            top: auto;
            h1 {
              font-size: 28px;
            }
          }
          .bgRightElement {
            right: -350px;
            bottom: -70px;
          }
          .bgLeftElement {
            left: -180px;
            bottom: -160px;
          }
        }
      }
      .container {
        .sideContent {
          padding: 3rem 0 0 2rem;
        }
        .mainContent {
          padding: 0 2rem;
        }
      }

      .banner {
        padding-left: 24px !important;
        padding-right: 24px !important;
      }
    }
  }
}

@include mobile {
  #app {
    .isEditMode {
      .ApplicationBanner {
        height: 68px;
        max-height: 68px;
        margin: 0 -15px 75px -15px;
        .headline {
          .bannerTitle {
            left: 10px;
            transform: none;
            h1 {
              font-size: 21px;
              padding: 0 10px;
              overflow-wrap: break-word;
              word-wrap: break-word;
            }
          }
          .bgLeftElement {
            height: 130px;
            left: -115px;
            bottom: -90px;
          }
          .bgRightElement {
            height: 90px;
            right: -400px;
            bottom: -48px;
          }
        }
      }

      margin: 0 15px;
      .container {
        .sideContent {
          padding: 0;
        }

        .mainContent {
          form {
            &.ApplicationSection {
              & ::v-deep .ApplicationSaveCancel {
                padding: 0;

                button {
                  &.primary {
                    margin: 0.5rem 0 0;
                  }
                }
              }
            }
          }
        }
      }
      .banner {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
  }
}
</style>
