<template>
  <div class="notification-queue">
    <div
      class="sr-only sr-notification-container"
      aria-live="polite"
      aria-atomic="true"
    >
      <div class="sr-notification" v-if="notificationForSR">
        <span
          v-if="notificationForSR && notificationForSR.vHtmlTitle"
          v-html="notificationForSR.vHtmlTitle"
        >
        </span>
        <span v-else-if="notificationForSR && notificationForSR.title">
          {{ notificationForSR && notificationForSR.title }}
        </span>
        <p
          v-if="notificationForSR && notificationForSR.vHtmlBody"
          v-html="notificationForSR.vHtmlBody"
        ></p>
        <p v-else-if="notificationForSR && notificationForSR.body">
          {{ notificationForSR && notificationForSR.body }}
        </p>
      </div>
      <div class="sr-notification--empty" v-else></div>
    </div>
    <transition-group class="notification-container" tag="div" name="list">
      <Notification
        v-for="(notification, i) in notifications"
        :key="notification.id"
        :notification="notification"
        @dismiss="dismiss(i)"
      />
    </transition-group>
  </div>
</template>

<script>
import Notification from './Notification.vue'

export default {
  name: 'NotificationQueue',
  data() {
    return {
      screenReaderQueue: [],
      screenReaderQueueTimeoutID: null,
      screenReaderQueueTimeout: 1000
    }
  },
  components: {
    Notification
  },
  computed: {
    notifications() {
      return this.$store.state.notificationQueue.filter((n) => n)
    },
    notificationForSR() {
      return this.screenReaderQueue[0]
    }
  },
  watch: {
    notifications(newValue, oldValue) {
      if (newValue.length > oldValue.length) {
        let newNotifications = newValue.slice(oldValue.length)
        this.screenReaderQueue = [
          ...this.screenReaderQueue,
          ...newNotifications
        ]
      }
    },
    screenReaderQueue(newValue) {
      if (newValue.length > 0 && !this.screenReaderQueueTimeoutID) {
        //notifications still in queue
        this.screenReaderQueueTimeoutID = setTimeout(() => {
          //We must clear the queue temporarily so the SR will pick up duplicate messages. The aria-live region
          //won’t pick up a new notification if its content is the same. This is an issue if you upload file A
          // and it fails, the you upload it again and it fails again. Without this setup the second failure
          // won’t be read out.
          this.$set(this.screenReaderQueue, 0, null)
          setTimeout(() => {
            this.screenReaderQueueTimeoutID = null
            this.screenReaderQueue = this.screenReaderQueue.slice(1)
          }, this.screenReaderQueueTimeout / 2)
        }, this.screenReaderQueueTimeout / 2)
      }
    }
  },
  methods: {
    dismiss(i) {
      this.$store.dispatch('dismissNotification', i)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.notification {
  position: relative;
  margin-bottom: 0.8rem;

  &-container {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    z-index: 3000;
    bottom: 0;
    left: 1rem;
    margin-right: 1rem;
  }

  ::v-deep .v-snack__content {
    border-color: transparent;
  }

  &--error ::v-deep .v-snack__content {
    border-color: #b81237;
  }

  &--success ::v-deep .v-snack__content {
    border-color: #00a908;
  }

  &--info ::v-deep .v-snack__content {
    border-color: #2e5299;
  }

  &--warning ::v-deep .v-snack__content {
    border-color: #dc5800;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header,
  &__body {
    display: flex;
    font-size: 1.6rem;
  }

  &__body {
    margin-right: 2rem;
  }

  &__header {
    align-items: center;
    min-height: 36px;
  }

  &__close-btn {
    border: none;
    margin-left: auto;
  }

  &__actions {
    font-size: 1.6rem;
    display: flex;

    a {
      text-decoration: none;
    }

    > :last-child {
      margin-left: 3rem;
    }
  }

  &__icon {
    margin-top: 0.7rem;
    margin-right: 1.6rem;
  }
}
::v-deep .v-snack {
  &__content {
    align-items: flex-start;
    padding-bottom: 1rem;
    border-left: 8px solid;
    border-radius: 4px;
  }
}

h1 {
  font-size: 1.6rem;
  margin-bottom: 0;
}

@media (max-width: 580px) {
  .notification__actions {
    flex-direction: column;
    > :last-child {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

::v-deep .v-snack__content .notification__header .notification__close-btn {
  margin-left: auto;
}
::v-deep .v-snack__content .notification__actions button {
  margin-left: 0;
}
</style>
