<!--
Provides a date picker using day, month and year droplists. It returns
a YYYY-MM-DD date format. Note that date validation (of invalid or incomplete
dates) is not handled by the picker, but by the component hosting the picker.
-->
<template>
  <div>
    <div class="AppDatePicker">
      <select
        v-if="!selectMonth"
        v-model="state.day"
        :id="id"
        :title="label + ' day'"
        :disabled="isFieldDisabled('day')"
        :class="state.day == '' ? 'isPlaceholder' : ''"
        @change="updateDate('day', $event.target.value)"
        @focus="onFocus"
        @blur="onBlur"
        :data-instance-id="currentInstanceId"
        :aria-required="required"
      >
        <option v-for="day in getDays()" :key="day" :value="day">
          {{ getText(day, 'DD') }}
        </option>
      </select>
      <select
        v-model="state.month"
        :title="label + ' month'"
        :disabled="isFieldDisabled('month')"
        :class="state.month == '' ? 'isPlaceholder' : ''"
        @change="updateDate('month', $event.target.value)"
        @focus="onFocus"
        @blur="onBlur"
        :data-instance-id="currentInstanceId"
        :aria-required="required"
      >
        <option
          v-for="month in getMonths()"
          :key="month.value"
          :value="month.value"
        >
          {{ getText(month.name, 'MM') }}
        </option>
      </select>
      <select
        v-model="state.year"
        :title="label + ' year'"
        :disabled="isFieldDisabled('year')"
        :class="state.year == '' ? 'isPlaceholder' : ''"
        @change="updateDate('year', $event.target.value)"
        @focus="onFocus"
        @blur="onBlur"
        :data-instance-id="currentInstanceId"
        :aria-required="required"
      >
        <option v-for="year in getYears()" :key="year" :value="year">
          {{ getText(year, 'YYYY') }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDatePicker',
  props: {
    required: Boolean,
    id: String,
    value: String,
    label: String,
    disabled: Boolean,
    disabledFields: Array,
    selectMonth: Boolean,
    dateCurrent: String,
    //startDateOverride and endYearOverride can be used to set the specific start/end year. If they are not set then it will use the future/pastYears calculation
    startYearOverride: Number,
    endYearOverride: Number,
    futureYears: { type: Number, default: 10 },
    pastYears: { type: Number, default: 100 },
    //specialYears property is to add extra years on top of the year dropdown list to cater for special cases e.g end date for addresses can be 2999
    specialYears: { type: Array, default: () => [''] },
    //ascending year is to control the order of years in the year dropdown list. if not set, the default order is descending.
    ascendingYears: { type: Boolean, default: false }
  },
  data() {
    let curDate = new Date()
    if (this.dateCurrent) {
      curDate = new Date(this.dateCurrent)
    }
    return {
      state: {
        day: this.getValue('day'),
        month: this.getValue('month'),
        year: this.getValue('year'),
        curDate
      },
      currentInstanceId: this._uid // Gets the unique Vue id for this template instance
    }
  },
  computed: {
    startYear() {
      return (
        this.startYearOverride || new Date().getFullYear() + this.futureYears
      )
    },
    endYear() {
      return this.endYearOverride || new Date().getFullYear() - this.pastYears
    }
  },
  methods: {
    isFieldDisabled(fieldName) {
      return (
        this.disabled ||
        (this.disabledFields && this.disabledFields.includes(fieldName))
      )
    },
    getText(data, alt) {
      if (data === '') {
        return alt
      }
      return data
    },
    getValue(type) {
      if (type === 'day') {
        return this.value ? this.value.split('-')[2] : ''
      } else if (type === 'month') {
        return this.value ? this.value.split('-')[1] : ''
      } else if (type === 'year') {
        return this.value ? this.value.split('-')[0] : ''
      }
    },
    getDays() {
      var days = ['']
      for (var d = 1; d <= 31; d++) {
        days.push(d.toString().padStart(2, '0'))
      }
      return days
    },
    getMonths() {
      return [
        { name: 'MM', value: '' },
        { name: 'January', value: '01' },
        { name: 'February', value: '02' },
        { name: 'March', value: '03' },
        { name: 'April', value: '04' },
        { name: 'May', value: '05' },
        { name: 'June', value: '06' },
        { name: 'July', value: '07' },
        { name: 'August', value: '08' },
        { name: 'September', value: '09' },
        { name: 'October', value: '10' },
        { name: 'November', value: '11' },
        { name: 'December', value: '12' }
      ]
    },
    getYears() {
      var specialYears = this.specialYears.slice(0) // clone specialYears array from prop as Array is a reference type
      var years = []
      for (var y = this.startYear; y >= this.endYear; y = y - 1) {
        years.push(y.toString())
      }
      if (this.ascendingYears) {
        years = years.reverse()
      }

      return specialYears.concat(years)
    },
    updateDate(part, value) {
      if (part === 'day') {
        this.state.day = value
      }
      if (part === 'month') {
        this.state.month = value
      }
      if (part === 'year') {
        this.state.year = value
      }

      // If we have a complete date or an empty date, save immediately.
      // Partial dates are not saved until leaving the date picker. This
      // avoids unwanted validation messages when the parent enters a new
      // date.
      if (!value || (this.state.day && this.state.month && this.state.year)) {
        this.saveValue()
      }
    },
    onFocus() {
      this.$emit('focus', this)
    },
    onBlur(e) {
      // Partial dates are not saved until leaving the date picker. This
      // avoids unwanted validation messages when the parent enters a new
      // date.
      let isLeavingDatePicker
      let nextField = e.relatedTarget
      try {
        // Determines if we are leaving the date picker by checking the component
        // instance id of the next field. Instance ids have to be used in case the
        // next field is a date picker, allowing us to teall the difference.
        let nextFieldInstanceId = Number(
          nextField.attributes['data-instance-id'].value
        )
        isLeavingDatePicker = nextFieldInstanceId !== this.currentInstanceId
      } catch (e) {
        isLeavingDatePicker = true
      }
      if (isLeavingDatePicker) {
        this.saveValue()
      }
    },
    saveValue() {
      // always default to the first day for month selectors
      let day = this.selectMonth ? '01' : this.state.day
      let val = `${this.state.year}-${this.state.month}-${day}`
      if (val === '--' || (this.selectMonth && val === '--01')) {
        val = ''
      }
      this.$emit('change', val)
    }
  }
}
</script>

<style scoped lang="scss">
.AppDatePicker {
  border: $field-border;
  background-color: $color-field-background;
  margin-top: 0.25rem;
  border-radius: $field-border-radius;
  font-size: $text-small;
  display: inline-block;
  select {
    border: 0;
    &.isPlaceholder {
      color: $color-text-light;
    }
  }
}
</style>
