// See README.md for more info about field configuration
import _get from 'lodash/get'

import { FIELD_TYPE, REFDATA_MAP, PREFILL_NOTIFICATION } from '@/constants'

import {
  getFirstName,
  getLabelName,
  getParentContactText,
  getEmergencyNumberFields,
  getHeading
} from './helper'

import { dropDownSelect } from '@/applicationDefinition/droplistData/helper'

export default [
  {
    label: 'Additional emergency contacts',
    vHtmlDescription(application) {
      const getName = (parents) => {
        return parents
          .filter((item) => {
            if (item.parentCarerGivenName && item.parentCarerFamilyName) {
              return true
            }
          })
          .map((item) => {
            return `${item.parentCarerGivenName} ${item.parentCarerFamilyName}`
          })
      }

      const parentList = getName(application.parentCarers)

      let parentNamesInOrder = ''

      // return parent(s) names in 4 variants: itself,  with comma ',',  with 'and', with 'in that order'

      if (parentList.length === 1) {
        parentNamesInOrder = parentList[0]
      } else if (parentList.length > 1) {
        for (let i = 0; i < parentList.length; i++) {
          if (i === 0) {
            parentNamesInOrder += parentList[i]
          } else if (i === parentList.length - 1) {
            parentNamesInOrder += ' and ' + parentList[i]
          } else {
            parentNamesInOrder += ', ' + parentList[i]
          }
        }
        parentNamesInOrder += ' in that order'
      }

      return (
        '<p><b>' +
        getLabelName(
          'In the case of an emergency, the school will contact ',
          parentNamesInOrder,
          '.'
        ) +
        '</b></p><p>The school needs 2 additional emergency contacts in case the parents/carers listed above are uncontactable.</p>'
      )
    },
    descriptionList: [
      'Please enter in the order you would like them contacted.',
      'Emergency contacts must be over 18.',
      'Please ensure that you have discussed with these people their willingness to be emergency contacts.'
    ],
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'meta.additionalEmergencyContacts',
    type: FIELD_TYPE.CHECKBOX,
    label: 'Provide additional contact details',
    trueValue: ['yes'],
    falseValue: ['no']
  },

  {
    type: FIELD_TYPE.NOTIFICATION,
    notificationProps: {
      inPage: true,
      type: 'info',
      elevation: 0,
      alertClass: 'blue-alert'
    },
    text() {
      return PREFILL_NOTIFICATION
    },
    visible(applicaition) {
      return (
        applicaition.meta &&
        applicaition.meta.prefillFlags &&
        applicaition.meta.prefillFlags.emergencyContact
      )
    }
  },
  {
    apiKey: 'emergencyContact',
    type: FIELD_TYPE.COLLECTION,
    showLine: true,
    visible(application) {
      const aec = application.meta.additionalEmergencyContacts
      if (aec) {
        return aec[0] === 'yes'
      }
    },
    description: 'Please add emergency contacts:',
    heading(collectionItem, index) {
      return getHeading(
        `Additional emergency contact ${index}`,
        `${collectionItem.contactFirstName} ${collectionItem.contactFamilyName}`
      )
    },
    headingAdd: 'Add another emergency contact',
    newItem: {
      contactFirstName: '',
      contactFamilyName: '',
      contactRelationship: '',
      contactDetails: [
        {
          contactType: '',
          contactValue: '',
          comments: ''
        }
      ]
    },
    fields(collectionItem, index) {
      let apiKeyPrefix = `${this.apiKey}[${index}].`
      return [
        {
          apiKey: apiKeyPrefix + 'contactFirstName',
          label: 'Given name',
          placeHolder: 'Enter given name',
          type: FIELD_TYPE.NAME,
          maxLength: 100,
          required: true
        },
        {
          apiKey: apiKeyPrefix + 'contactFamilyName',
          label: 'Family name',
          placeHolder: 'Enter family name',
          type: FIELD_TYPE.NAME,
          maxLength: 100,
          required: true
        },
        {
          apiKey: apiKeyPrefix + 'contactRelationship',
          label(application) {
            const studentName = getFirstName(application)

            let contactName = 'emergency contact'

            if (collectionItem.contactFirstName) {
              contactName = collectionItem.contactFirstName
            }

            if (
              collectionItem.contactFirstName &&
              collectionItem.contactFamilyName
            ) {
              contactName += ` ${collectionItem.contactFamilyName}`
            }

            return `Please provide ${contactName}'s relationship ${getLabelName(
              'to ',
              studentName,
              '',
              'the student'
            )}`
          },
          description: 'e.g. uncle, grandparent, friend or neighbour',
          type: FIELD_TYPE.DROPDOWN,
          options() {
            return dropDownSelect(
              this.getReferenceDataSet(REFDATA_MAP.RELATIONSHIP)
            )
          },
          required: true
        },
        {
          apiKey: apiKeyPrefix + 'contactDetails',
          type: FIELD_TYPE.COLLECTION,
          heading(contactItem, contactItemIndex, application, field) {
            let name = getParentContactText(contactItem)
            let data = _get(application, field.apiKey)
            data = data.slice(0, contactItemIndex)
            let index = contactItemIndex
            if (name === null) {
              name = 'Enter contact number'
            } else {
              index = data.filter(
                (item) => getParentContactText(item) === name
              ).length
            }
            return name + (index === 1 ? '' : ' ' + index)
          },
          headingAdd: 'Add another contact number',
          newItem: {
            contactType: '',
            contactValue: '',
            comments: ''
          },
          fields(contactItem, contactItemIndex, store) {
            let contactNumberFields = getEmergencyNumberFields(
              this.apiKey,
              contactItem,
              contactItemIndex,
              store
            )

            // Remove 'EMAIL' option as it is not a valid emergency contact type
            contactNumberFields[0].options =
              contactNumberFields[0].options.filter(
                (val) => val.value !== 'EMAIL'
              )

            return contactNumberFields
          }
        }
      ]
    }
  }
]
