export const COUNTRIES = [
  {
    value: '',
    text: 'Select'
  },
  {
    value: 'AUS',
    text: 'Australia'
  },
  {
    value: null,
    text: '---------------------------------',
    disabled: true
  },
  {
    value: 'ADF',
    text: 'Adelie Land (France)'
  },
  {
    value: 'AFG',
    text: 'Afghanistan'
  },
  {
    value: 'ALI',
    text: 'Aland Islands'
  },
  {
    value: 'ALB',
    text: 'Albania'
  },
  {
    value: 'DZA',
    text: 'Algeria'
  },
  {
    value: 'AND',
    text: 'Andorra'
  },
  {
    value: 'AGO',
    text: 'Angola'
  },
  {
    value: 'AIA',
    text: 'Anguilla'
  },
  {
    value: 'ATG',
    text: 'Antigua and Barbuda'
  },
  {
    value: 'ARG',
    text: 'Argentina'
  },
  {
    value: 'AAT',
    text: 'Argentinian Antarctic Territory'
  },
  {
    value: 'ARM',
    text: 'Armenia'
  },
  {
    value: 'ABW',
    text: 'Aruba'
  },
  {
    value: 'AUS',
    text: 'Australia'
  },
  {
    value: 'AST',
    text: 'Australian Antarctic Territory'
  },
  {
    value: 'AET',
    text: 'Australian External Territories, nec'
  },
  {
    value: 'AUT',
    text: 'Austria'
  },
  {
    value: 'AZE',
    text: 'Azerbaijan'
  },
  {
    value: 'BHS',
    text: 'Bahamas'
  },
  {
    value: 'BHR',
    text: 'Bahrain'
  },
  {
    value: 'BGD',
    text: 'Bangladesh'
  },
  {
    value: 'BRB',
    text: 'Barbados'
  },
  {
    value: 'BLR',
    text: 'Belarus'
  },
  {
    value: 'BEL',
    text: 'Belgium'
  },
  {
    value: 'BLZ',
    text: 'Belize'
  },
  {
    value: 'BEN',
    text: 'Benin'
  },
  {
    value: 'BMU',
    text: 'Bermuda'
  },
  {
    value: 'BTN',
    text: 'Bhutan'
  },
  {
    value: 'BOL',
    text: 'Bolivia, Plurinational State of'
  },
  {
    value: 'BES',
    text: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    value: 'BIH',
    text: 'Bosnia and Herzegovina'
  },
  {
    value: 'BWA',
    text: 'Botswana'
  },
  {
    value: 'BRA',
    text: 'Brazil'
  },
  {
    value: 'BAT',
    text: 'British Antarctic Territory'
  },
  {
    value: 'BRN',
    text: 'Brunei Darussalam'
  },
  {
    value: 'BGR',
    text: 'Bulgaria'
  },
  {
    value: 'BFA',
    text: 'Burkina Faso'
  },
  {
    value: 'MMR',
    text: 'Burma (Republic of the Union of Myanmar)'
  },
  {
    value: 'BDI',
    text: 'Burundi'
  },
  {
    value: 'KHM',
    text: 'Cambodia'
  },
  {
    value: 'CMR',
    text: 'Cameroon'
  },
  {
    value: 'CAN',
    text: 'Canada'
  },
  {
    value: 'CPV',
    text: 'Cape Verde'
  },
  {
    value: 'CYM',
    text: 'Cayman Islands'
  },
  {
    value: 'CAF',
    text: 'Central African Republic'
  },
  {
    value: 'TCD',
    text: 'Chad'
  },
  {
    value: 'CHL',
    text: 'Chile'
  },
  {
    value: 'CAT',
    text: 'Chilean Antarctic Territory'
  },
  {
    value: 'CHN',
    text: 'China (excludes SARs and Taiwan)'
  },
  {
    value: 'COL',
    text: 'Colombia'
  },
  {
    value: 'COM',
    text: 'Comoros'
  },
  {
    value: 'COD',
    text: 'Congo, Democratic Republic of'
  },
  {
    value: 'COG',
    text: 'Congo, Republic of'
  },
  {
    value: 'COK',
    text: 'Cook Islands'
  },
  {
    value: 'CRI',
    text: 'Costa Rica'
  },
  {
    value: 'CIV',
    text: "Cote d'Ivoire"
  },
  {
    value: 'HRV',
    text: 'Croatia'
  },
  {
    value: 'CUB',
    text: 'Cuba'
  },
  {
    value: 'CRC',
    text: 'Curacao'
  },
  {
    value: 'CYP',
    text: 'Cyprus'
  },
  {
    value: 'CZE',
    text: 'Czech Republic'
  },
  {
    value: 'DNK',
    text: 'Denmark'
  },
  {
    value: 'DJI',
    text: 'Djibouti'
  },
  {
    value: 'DMA',
    text: 'Dominica'
  },
  {
    value: 'DOM',
    text: 'Dominican Republic'
  },
  {
    value: 'TLS',
    text: 'East Timor'
  },
  {
    value: 'ECU',
    text: 'Ecuador'
  },
  {
    value: 'EGY',
    text: 'Egypt'
  },
  {
    value: 'SLV',
    text: 'El Salvador'
  },
  {
    value: 'ENG',
    text: 'England'
  },
  {
    value: 'GNQ',
    text: 'Equatorial Guinea'
  },
  {
    value: 'ERI',
    text: 'Eritrea'
  },
  {
    value: 'EST',
    text: 'Estonia'
  },
  {
    value: 'ETH',
    text: 'Ethiopia'
  },
  {
    value: 'FLK',
    text: 'Falkland Islands'
  },
  {
    value: 'FRO',
    text: 'Faroe Islands'
  },
  {
    value: 'FJI',
    text: 'Fiji'
  },
  {
    value: 'FIN',
    text: 'Finland'
  },
  {
    value: 'FRA',
    text: 'France'
  },
  {
    value: 'GUF',
    text: 'French Guiana'
  },
  {
    value: 'PYF',
    text: 'French Polynesia'
  },
  {
    value: 'GAB',
    text: 'Gabon'
  },
  {
    value: 'GMB',
    text: 'Gambia'
  },
  {
    value: 'GEO',
    text: 'Georgia'
  },
  {
    value: 'DEU',
    text: 'Germany'
  },
  {
    value: 'GHA',
    text: 'Ghana'
  },
  {
    value: 'GIB',
    text: 'Gibraltar'
  },
  {
    value: 'GRC',
    text: 'Greece'
  },
  {
    value: 'GRL',
    text: 'Greenland'
  },
  {
    value: 'GRD',
    text: 'Grenada'
  },
  {
    value: 'GLP',
    text: 'Guadeloupe'
  },
  {
    value: 'GUM',
    text: 'Guam'
  },
  {
    value: 'GTM',
    text: 'Guatemala'
  },
  {
    value: 'GUE',
    text: 'Guernsey'
  },
  {
    value: 'GIN',
    text: 'Guinea'
  },
  {
    value: 'GNB',
    text: 'Guinea-Bissau'
  },
  {
    value: 'GUY',
    text: 'Guyana'
  },
  {
    value: 'HTI',
    text: 'Haiti'
  },
  {
    value: 'VAT',
    text: 'Holy See (Vatican City State)'
  },
  {
    value: 'HND',
    text: 'Honduras'
  },
  {
    value: 'HKG',
    text: 'Hong Kong (SAR of China)'
  },
  {
    value: 'HUN',
    text: 'Hungary'
  },
  {
    value: 'ISL',
    text: 'Iceland'
  },
  {
    value: 'IND',
    text: 'India'
  },
  {
    value: 'IDN',
    text: 'Indonesia'
  },
  {
    value: 'IRN',
    text: 'Iran'
  },
  {
    value: 'IRQ',
    text: 'Iraq'
  },
  {
    value: 'IRL',
    text: 'Ireland'
  },
  {
    value: 'IMN',
    text: 'Isle of Man'
  },
  {
    value: 'ISR',
    text: 'Israel'
  },
  {
    value: 'ITA',
    text: 'Italy'
  },
  {
    value: 'JAM',
    text: 'Jamaica'
  },
  {
    value: 'JPN',
    text: 'Japan'
  },
  {
    value: 'JER',
    text: 'Jersey'
  },
  {
    value: 'JOR',
    text: 'Jordan'
  },
  {
    value: 'KAZ',
    text: 'Kazakhstan'
  },
  {
    value: 'KEN',
    text: 'Kenya'
  },
  {
    value: 'KIR',
    text: 'Kiribati'
  },
  {
    value: 'PRK',
    text: "Korea, Democratic People's Republic of (North)"
  },
  {
    value: 'KOR',
    text: 'Korea, Republic of (South)'
  },
  {
    value: 'KOS',
    text: 'Kosovo'
  },
  {
    value: 'KWT',
    text: 'Kuwait'
  },
  {
    value: 'KGZ',
    text: 'Kyrgyzstan'
  },
  {
    value: 'LAO',
    text: 'Laos'
  },
  {
    value: 'LVA',
    text: 'Latvia'
  },
  {
    value: 'LBN',
    text: 'Lebanon'
  },
  {
    value: 'LSO',
    text: 'Lesotho'
  },
  {
    value: 'LBR',
    text: 'Liberia'
  },
  {
    value: 'LBY',
    text: 'Libya'
  },
  {
    value: 'LIE',
    text: 'Liechtenstein'
  },
  {
    value: 'LTU',
    text: 'Lithuania'
  },
  {
    value: 'LUX',
    text: 'Luxembourg'
  },
  {
    value: 'MAC',
    text: 'Macau (SAR of China)'
  },
  {
    value: 'MKD',
    text: 'Macedonia, The former Yugoslav Republic of'
  },
  {
    value: 'MDG',
    text: 'Madagascar'
  },
  {
    value: 'MWI',
    text: 'Malawi'
  },
  {
    value: 'MYS',
    text: 'Malaysia'
  },
  {
    value: 'MDV',
    text: 'Maldives'
  },
  {
    value: 'MLI',
    text: 'Mali'
  },
  {
    value: 'MLT',
    text: 'Malta'
  },
  {
    value: 'MHL',
    text: 'Marshall Islands'
  },
  {
    value: 'MTQ',
    text: 'Martinique'
  },
  {
    value: 'MRT',
    text: 'Mauritania'
  },
  {
    value: 'MUS',
    text: 'Mauritius'
  },
  {
    value: 'MYT',
    text: 'Mayotte'
  },
  {
    value: 'MEX',
    text: 'Mexico'
  },
  {
    value: 'FSM',
    text: 'Micronesia, Federated States of'
  },
  {
    value: 'MDA',
    text: 'Moldova'
  },
  {
    value: 'MCO',
    text: 'Monaco'
  },
  {
    value: 'MNG',
    text: 'Mongolia'
  },
  {
    value: 'MON',
    text: 'Montenegro'
  },
  {
    value: 'MSR',
    text: 'Montserrat'
  },
  {
    value: 'MAR',
    text: 'Morocco'
  },
  {
    value: 'MOZ',
    text: 'Mozambique'
  },
  {
    value: 'NAM',
    text: 'Namibia'
  },
  {
    value: 'NRU',
    text: 'Nauru'
  },
  {
    value: 'NPL',
    text: 'Nepal'
  },
  {
    value: 'NLD',
    text: 'Netherlands'
  },
  {
    value: 'NCL',
    text: 'New Caledonia'
  },
  {
    value: 'NZL',
    text: 'New Zealand'
  },
  {
    value: 'NIC',
    text: 'Nicaragua'
  },
  {
    value: 'NER',
    text: 'Niger'
  },
  {
    value: 'NGA',
    text: 'Nigeria'
  },
  {
    value: 'NIU',
    text: 'Niue'
  },
  {
    value: 'NFK',
    text: 'Norfolk Island'
  },
  {
    value: 'NIR',
    text: 'Northern Ireland'
  },
  {
    value: 'MNP',
    text: 'Northern Mariana Islands'
  },
  {
    value: 'NOR',
    text: 'Norway'
  },
  {
    value: 'OMN',
    text: 'Oman'
  },
  {
    value: 'YYY',
    text: 'Other (Not Elsewhere classified)'
  },
  {
    value: 'PAK',
    text: 'Pakistan'
  },
  {
    value: 'PLW',
    text: 'Palau'
  },
  {
    value: 'PSE',
    text: 'Palestine (Gaza Strip and West Bank)'
  },
  {
    value: 'PAN',
    text: 'Panama'
  },
  {
    value: 'PNG',
    text: 'Papua New Guinea'
  },
  {
    value: 'PRY',
    text: 'Paraguay'
  },
  {
    value: 'PER',
    text: 'Peru'
  },
  {
    value: 'PHL',
    text: 'Philippines'
  },
  {
    value: 'PII',
    text: 'Pitcairn Islands'
  },
  {
    value: 'POL',
    text: 'Poland'
  },
  {
    value: 'PHN',
    text: 'Polynesia (excludes Hawaii), nec'
  },
  {
    value: 'PRT',
    text: 'Portugal'
  },
  {
    value: 'PRI',
    text: 'Puerto Rico'
  },
  {
    value: 'QAT',
    text: 'Qatar'
  },
  {
    value: 'QML',
    text: 'Queen Maud Land (Norway)'
  },
  {
    value: 'REU',
    text: 'Reunion'
  },
  {
    value: 'ROU',
    text: 'Romania'
  },
  {
    value: 'RDZ',
    text: 'Ross Dependency (New Zealand)'
  },
  {
    value: 'RUS',
    text: 'Russian Federation'
  },
  {
    value: 'RWA',
    text: 'Rwanda'
  },
  {
    value: 'WSM',
    text: 'Samoa'
  },
  {
    value: 'ASM',
    text: 'Samoa, American'
  },
  {
    value: 'SMR',
    text: 'San Marino'
  },
  {
    value: 'STP',
    text: 'Sao Tome and Principe'
  },
  {
    value: 'SAU',
    text: 'Saudi Arabia'
  },
  {
    value: 'SCO',
    text: 'Scotland'
  },
  {
    value: 'SEN',
    text: 'Senegal'
  },
  {
    value: 'SER',
    text: 'Serbia'
  },
  {
    value: 'SYC',
    text: 'Seychelles'
  },
  {
    value: 'SLE',
    text: 'Sierra Leone'
  },
  {
    value: 'SGP',
    text: 'Singapore'
  },
  {
    value: 'SMD',
    text: 'Sint Maarten (Dutch part)'
  },
  {
    value: 'SVK',
    text: 'Slovakia'
  },
  {
    value: 'SVN',
    text: 'Slovenia'
  },
  {
    value: 'SLB',
    text: 'Solomon Islands'
  },
  {
    value: 'SOM',
    text: 'Somalia'
  },
  {
    value: 'ZAF',
    text: 'South Africa'
  },
  {
    value: 'SAN',
    text: 'South America, nec'
  },
  {
    value: 'SSD',
    text: 'South Sudan'
  },
  {
    value: 'SEA',
    text: 'Southern and East Africa, nec'
  },
  {
    value: 'ESP',
    text: 'Spain'
  },
  {
    value: 'SNA',
    text: 'Spanish North Africa'
  },
  {
    value: 'LKA',
    text: 'Sri Lanka'
  },
  {
    value: 'STB',
    text: 'St Barthelemy'
  },
  {
    value: 'SHN',
    text: 'St Helena'
  },
  {
    value: 'KNA',
    text: 'St Kitts and Nevis'
  },
  {
    value: 'LCA',
    text: 'St Lucia'
  },
  {
    value: 'STM',
    text: 'St Martin (French part)'
  },
  {
    value: 'SPM',
    text: 'St Pierre and Miquelon'
  },
  {
    value: 'VCT',
    text: 'St Vincent and the Grenadines'
  },
  {
    value: 'SDN',
    text: 'Sudan'
  },
  {
    value: 'SUR',
    text: 'Suriname'
  },
  {
    value: 'SWZ',
    text: 'Swaziland'
  },
  {
    value: 'SWE',
    text: 'Sweden'
  },
  {
    value: 'CHE',
    text: 'Switzerland'
  },
  {
    value: 'SYR',
    text: 'Syria'
  },
  {
    value: 'TWN',
    text: 'Taiwan'
  },
  {
    value: 'TJK',
    text: 'Tajikistan'
  },
  {
    value: 'TZA',
    text: 'Tanzania'
  },
  {
    value: 'THA',
    text: 'Thailand'
  },
  {
    value: 'TGO',
    text: 'Togo'
  },
  {
    value: 'TKL',
    text: 'Tokelau'
  },
  {
    value: 'TON',
    text: 'Tonga'
  },
  {
    value: 'TTO',
    text: 'Trinidad and Tobago'
  },
  {
    value: 'TUN',
    text: 'Tunisia'
  },
  {
    value: 'TUR',
    text: 'Turkey'
  },
  {
    value: 'TKM',
    text: 'Turkmenistan'
  },
  {
    value: 'TCA',
    text: 'Turks and Caicos Islands'
  },
  {
    value: 'TUV',
    text: 'Tuvalu'
  },
  {
    value: 'UGA',
    text: 'Uganda'
  },
  {
    value: 'UKR',
    text: 'Ukraine'
  },
  {
    value: 'ARE',
    text: 'United Arab Emirates'
  },
  {
    value: 'GBR',
    text: 'United Kingdom'
  },
  {
    value: 'USA',
    text: 'United States of America'
  },
  {
    value: 'URY',
    text: 'Uruguay'
  },
  {
    value: 'UZB',
    text: 'Uzbekistan'
  },
  {
    value: 'VUT',
    text: 'Vanuatu'
  },
  {
    value: 'VEN',
    text: 'Venezuela, Bolivarian Republic of'
  },
  {
    value: 'VNM',
    text: 'Vietnam'
  },
  {
    value: 'VGB',
    text: 'Virgin Islands, British'
  },
  {
    value: 'VIR',
    text: 'Virgin Islands, United States'
  },
  {
    value: 'WAL',
    text: 'Wales'
  },
  {
    value: 'WLF',
    text: 'Wallis and Futuna'
  },
  {
    value: 'ESH',
    text: 'Western Sahara'
  },
  {
    value: 'YEM',
    text: 'Yemen'
  },
  {
    value: 'ZMB',
    text: 'Zambia'
  },
  {
    value: 'ZWE',
    text: 'Zimbabwe'
  }
]
