<template>
  <div class="ApplicationSaveCancel">
    <div v-if="!isReview()" class="bar">
      <span class="sr-only" role="status">{{ savedStatus }}</span>
      <button
        class="primary"
        type="submit"
        :disabled="blockNavigation"
        @click="save('next')"
      >
        Save &amp; continue to next section
      </button>
    </div>

    <div v-if="isReview()" class="bar">
      <button
        class="primary"
        type="button"
        :disabled="blockNavigation || !canSubmit"
        @click="submit"
      >
        Submit application
      </button>
    </div>
  </div>
</template>

<script>
import { CHOOSE_DATE, API_ERROR_SUBTYPES } from '@/constants'

import endPoint from '@/store/endPoint'
import UTILS from '@/store/utils'

import { auth } from '@/store/fixture'

// import helpers
import objectToPrint from '@/store/helper/objectToPrint'
import arrayValueToBoolean from '@/store/helper/arrayValueToBoolean'
import allergiesToSubmitState from '@/store/helper/allergiesToSubmitState'

import { DISABILITY } from '@/applicationDefinition/droplistData/disability'
import { LEARNING_ADJUSTMENT } from '@/applicationDefinition/droplistData/learningAdjustment'
import droplistData from '@/applicationDefinition/droplistData/index'

import fieldsMixin from '@/components/mixins/fieldsMixin'
import formFocus from '@/components/mixins/focus'
import ehubUtil from '@/utils/ehub'

import getSreSeeAmaVarsFromString from '@/store/helper/getSreSeeAmaVarsFromString'

import {
  purgeEmptyFromObject,
  purgeHiddenFromFields,
  purgeMetaFromObject,
  clone
} from '@/../src/applicationDefinition/helper'

import { mapGetters } from 'vuex'

export default {
  name: 'ApplicationSaveCancel',
  mixins: [fieldsMixin, formFocus],
  data() {
    return {
      savedStatus: null
    }
  },
  computed: {
    canSubmit() {
      return !!this.$store.state.application.parentCarerName_1
    },
    application() {
      return this.$store.state.application || {}
    },
    ...mapGetters(['blockNavigation'])
  },
  methods: {
    isReview() {
      try {
        return this.$store.state.application.meta.sectionExpandedId === 'review'
      } catch (e) {
        return false
      }
    },
    convertSupportingDocsForPDF(
      supportingDocumentsData,
      supportingDocumentFields
    ) {
      return supportingDocumentFields.map((field) => {
        const label = this.getVHtmlLabel(field, this.application)
        const data =
          (supportingDocumentsData &&
            supportingDocumentsData[field.category]) ||
          {}
        return { ...data, label }
      })
    },

    formatName(p) {
      return `${p.parentCarerTitle} ${p.parentCarerGivenName
        .charAt(0)
        .toUpperCase()} ${p.parentCarerFamilyName}`
    },

    setCorrspondenceName(parents, maxLength) {
      let parentsLiveWithStudent = parents.filter((p) => p.isEnrolmentOwner)
      if (parentsLiveWithStudent.length > 1) {
        return `${this.formatName(
          parentsLiveWithStudent[0]
        )} & ${this.formatName(parentsLiveWithStudent[1])}`.substring(
          0,
          maxLength
        )
      }
      return `${this.formatName(parentsLiveWithStudent[0])}`.substring(
        0,
        maxLength
      )
    },
    save(type) {
      const sectionExpandedId =
        this.$store.state.application.meta.sectionExpandedId
      const section = this.$store.state.sections.find(
        (item) => item.id === sectionExpandedId
      )

      this.$store.commit('set', ['auth.isUserActive', true])
      this.$store.commit('set', ['auth.isUserActiveTime', new Date().getTime()])
      // SPECIAL CASE: Disallow save/continue if student is not a citizen/resident
      if (
        sectionExpandedId === 'student' &&
        this.$store.state.application.residencyStatus === 'OTHER'
      ) {
        this.$store.dispatch('openModal', { componentName: 'ModalResidency' })
      } else {
        // section can have errors when saving to database
        this.$store
          .dispatch('saveApplicationSection', { section, type })
          .catch(() => {
            this.$store.dispatch('set', ['saveStatus', null])
            this.$store.dispatch('set', [
              'error',
              new Error('Error when saving: Could not reach server')
            ])

            this.$router.history.push('/error')
          })
          .finally(() => {
            this.$store.dispatch('hideSpinner')
            setTimeout(() => {
              this.focusOnFirst()
            }, 100)
          })

        this.$scrollTo('#app', 1)

        if (type === 'next') {
          // set the Sreen reader message as "Saved", as screen readers are not announcing the same status,
          // we have to clear the message and repopulate it for sr to work using timers.
          setTimeout(() => {
            this.savedStatus = null
            setTimeout(() => {
              this.savedStatus = 'Saved'
            }, 10)
          }, 10)

          const curSectionIdx = this.$store.state.sections.findIndex(
            (item) => item.id === sectionExpandedId
          )
          const nextSectionId = this.$store.state.sections[curSectionIdx + 1].id

          this.$store.commit('set', ['auth.isUserActive', true])
          this.$store.commit('set', [
            'auth.isUserActiveTime',
            new Date().getTime()
          ])

          this.$gtm.trackView('SectionView', `/section/${nextSectionId}`)
        }
        let pdfData = this.$store.state.application
        if (pdfData && pdfData.meta.hasAttemptToSubmit) {
          this.$store.dispatch('buildAlerts')
        }
        this.$store.dispatch('buildCounters')
      }
    },
    submit() {
      // validate form first and stop if errors
      if (this.$store.state.application) {
        this.$store.dispatch('set', [
          'application.meta.hasAttemptToSubmit',
          true
        ])
      }
      this.$store.dispatch('buildAlerts')
      this.$store.dispatch('buildCounters')

      if (this.$store.state.alerts && this.$store.state.alerts.length > 0) {
        let firstSectionWithAlerts = this.$store.state.alerts.map(
          (a) => a.section.id
        )[0]
        this.$store.dispatch('setFocus', firstSectionWithAlerts)
        return false
      }

      // JW: pdfData contains application data for rendering the confirmation
      // pdf. It receives additional "print" values that the pdf uses to show
      // looked-up values (instead of their underlying codes). This design
      // is a big maintenance issue and will hopefully be replaced soon.
      let pdfData = purgeEmptyFromObject(this.$store.state.application, true)

      // purge hidden fields
      this.$store.state.sections.map(
        (section) =>
          (pdfData = purgeHiddenFromFields(
            section.fields,
            pdfData,
            this.$store
          ))
      )

      //Defect FUS-1001

      // The declarer will be whoever is defined as completing the application. We also only want
      // to use them if they have their family and given name defined
      let declarer = pdfData.parentCarers.find(
        (pc) =>
          pc.meta.isCompletingApplication &&
          pc.parentCarerGivenName &&
          pc.parentCarerFamilyName
      )

      pdfData.parentCarerName_1 = `${declarer.parentCarerGivenName} ${declarer.parentCarerFamilyName}`

      let postData = clone(pdfData)

      // purge country name
      if (postData.residentialAddress.countryName) {
        delete postData.residentialAddress.countryName
      }

      // populate first name
      postData.student.prefFirstName =
        postData.student.prefFirstName || postData.student.firstName

      // ### Student details

      // Reformat Medicare date to mm/yyyy
      if (postData.student.medicare) {
        let medicareExpiryDate = postData.student.medicare[0].expiryDate

        if (medicareExpiryDate) {
          medicareExpiryDate = medicareExpiryDate.split('-')

          postData.student.medicareExpiryDate = `${medicareExpiryDate[1]}/${medicareExpiryDate[0]}`
        }

        // Move Medicare to student
        postData.student.medicareNumber = postData.student.medicare[0].number
        postData.student.medicareCardRef = postData.student.medicare[0].cardRef

        delete postData.student.medicare
      }

      // Process intendedStartDate
      if (postData.intendedStartDate === CHOOSE_DATE) {
        postData.intendedStartDate = postData.enterStartDate
      }

      // Default visa details for NZ residents
      if (postData.residencyStatus === 'NZC') {
        postData.visaSubClass = '444'
        postData.countryCodeTravelDocumentIssued = 'NZL'
      }

      // default residencyStatus for AUS
      if (postData.residencyStatus === 'AUC') {
        // born overseas
        if (postData.student.countryBorn !== 'AUS') {
          postData.residencyStatus = 'AUCC1'
        } else if (postData.meta.hasReturnAustralia) {
          // born in AUS but been overseas for more then 2 years
          postData.residencyStatus = 'AUCC2'
        }
      }

      // residence status for temporary residents
      if (postData.residencyStatus === 'TMP') {
        // merge tmpResData
        postData.visaClass = postData.tempResVisaClass
        postData.visaExpiryDate = postData.tempResVisaExpDate
        postData.visaSubClass = postData.tempResVisaSubClass
        postData.countryCodeTravelDocumentIssued =
          postData.tempResPassportCountryOfIssue
        postData.travelDocumentNo = postData.passportNumber

        // remove tmpRes props
        delete postData.tempResVisaSubClass
        delete postData.tempResVisaExpDate
        delete postData.tempResVisaClass
        delete postData.passportNumber
        delete postData.tempResPassportCountryOfIssue
      }

      // ### Medical details

      // merge allergies if it exist
      if (postData.allergiesAnaphylactic && postData.allergies) {
        postData.allergies = postData.allergiesAnaphylactic.concat(
          postData.allergies
        )
      } else if (postData.allergiesAnaphylactic && !postData.allergies) {
        postData.allergies = postData.allergiesAnaphylactic
      }

      // remove allergiesAnaphylactic
      delete postData.allergiesAnaphylactic

      // process doctor id
      if (postData.doctorDetails) {
        postData.doctorDetails = postData.doctorDetails.map((item) => {
          item.doctorRecordNo = item.collectionItemUniqueId.toString()
          return item
        })
      }

      // convert allergies for submission
      if (postData.allergies) {
        postData.allergies = postData.allergies.map((item) =>
          allergiesToSubmitState(item)
        )
      }

      if (postData.nonAllergies) {
        postData.nonAllergies = postData.nonAllergies.map((item) =>
          allergiesToSubmitState(item)
        )
      }

      // ### Learning & support

      // map learningSupportNeeds if it exist
      if (postData.learningAndSupport.learningSupportNeeds) {
        if (
          postData.learningAndSupport.learningSupportNeeds.find(
            (item) => item === 'None'
          )
        ) {
          delete postData.learningAndSupport.learningSupportNeeds
        } else {
          // map support data
          postData.learningSupportNeeds = UTILS.arrayObjectToObject(
            arrayValueToBoolean(
              DISABILITY,
              postData.learningAndSupport.learningSupportNeeds
            )
          )
        }
      }

      // map learningAdjustments if it exist
      if (postData.learningAndSupport.learningAdjustments) {
        if (
          postData.learningAndSupport.learningAdjustments.find(
            (item) => item === 'None'
          )
        ) {
          delete postData.learningAndSupport.learningAdjustments
        } else {
          // map support data
          postData.learningAdjustments = UTILS.arrayObjectToObject(
            arrayValueToBoolean(
              LEARNING_ADJUSTMENT,
              postData.learningAndSupport.learningAdjustments
            )
          )
        }
      }

      // copy otherAdjustmentDetails as required by API
      if (
        postData.learningAdjustments &&
        postData.learningAdjustments.otherAdjustment
      ) {
        postData.learningAdjustments.otherAdjustmentDetails =
          postData.learningAndSupport.otherAdjustmentDetails
      }
      // copy otherDisabilityDetails as required by API
      if (
        postData.learningSupportNeeds &&
        postData.learningSupportNeeds.otherDisability
      ) {
        postData.learningSupportNeeds.otherDisabilityDetails =
          postData.learningAndSupport.otherDisabilityDetails
      }

      // ### Risk assessment

      if (postData.healthProfHaveKnowledge && postData.healthProfContact) {
        const docName = postData.healthProfContact[0].doctorName
        const docPhoneNo = postData.healthProfContact[0].doctorPhoneNumber

        // Default to `doctorName` if no other relevant data
        let healthProfContact = docName

        if (docName && docPhoneNo) {
          healthProfContact = `${docName} - ${docPhoneNo}`
        } else if (docPhoneNo) {
          healthProfContact = docPhoneNo
        }

        postData.healthProfContact = healthProfContact
      }

      // ### reasonForSuspension

      // map reasonForSuspension if it exist
      if (postData.meta.reasonForSuspension) {
        // map support data
        postData.reasonForSuspension = UTILS.arrayObjectToObject(
          arrayValueToBoolean(
            droplistData.SUSPENSIONS,
            postData.meta.reasonForSuspension
          )
        )
      }

      // ### Siblings

      // ### Parents/carers

      // copy altparent to parent

      // merge alt parents
      if (postData.meta.isAltParent && postData.parentCarersAlt) {
        postData.parentCarers = postData.parentCarers.concat(
          postData.parentCarersAlt
        )
        delete postData.parentCarersAlt
      }

      // convert email address to contactDetails[]
      postData.parentCarers.forEach((val) => {
        if (!val.parentCarerEmail) return

        val.contactDetails.push({
          contactType: 'EMAIL',
          contactValue: val.parentCarerEmail
        })
      })

      //Issues with ERN if the enrolment owner has a residential address
      postData.parentCarers.forEach((parent) => {
        if (parent.isEnrolmentOwner) {
          delete parent.residentialAddress
        }
      })

      postData.parentCarers.forEach((parent) => {
        if (parent.meta) {
          parent.isCompletingApplication = parent.meta.isCompletingApplication
        }
      })
      // map primary address to residentialAddress

      // ### Emergency contacts

      // ### Additional information

      // ### Permissions

      // ### Supporting documents

      // Remove categories where includeFiles IS NOT true
      // AND convert categories where includeFiles IS true to just the files array
      if (postData.supportingDocuments) {
        postData.supportingDocuments = Object.entries(
          postData.supportingDocuments
        ).reduce((accumulator, [category, { includeFiles, files }]) => {
          if (includeFiles && files) {
            //Convert files to only include objectKey
            accumulator[category] = files.map(({ objectKey }) => ({
              objectKey
            }))
          }
          return accumulator
        }, {})
      }

      //Add labels defined in refData to pdfData and convert to array
      const supportingDocumentsSection = this.$store.state.sections.find(
        (section) => section.id === 'documents'
      )
      const supportingDocumentsCategories =
        supportingDocumentsSection.fields[1].panels

      pdfData.supportingDocuments = this.convertSupportingDocsForPDF(
        pdfData.supportingDocuments,
        supportingDocumentsCategories
      )

      // ### Review

      // ALP-76 Display and edit correspondence name
      postData.correspondenceName = this.setCorrspondenceName(
        postData.parentCarers,
        64
      )

      // purge meta from object
      postData = purgeMetaFromObject(postData)

      // Convert school type (from 'NSW' etc.) to either 'A' or 'O' (Australian or Overseas)
      if (postData.prevSchoolType) {
        postData.prevSchoolType = postData.prevSchoolType === 'OVS' ? 'O' : 'A'
      }

      // JW: TEMPORARY TRANSFORMATION UNTIL DSE-2191 IS COMPLETED!
      // Moves previous school fields into previousSchool object, including
      // the conversion from otherSchoolAttendedName to prevSchoolName.
      // This reflects SI's data structure and is necessary for
      // data to flow correctly.
      if (postData.prevAttendSchool && !postData.previousSchool) {
        postData.previousSchool = {
          prevSchoolType: postData.prevSchoolType,
          prevSchoolId: postData.prevSchoolId,
          prevSchoolName: postData.otherSchoolAttendedName,
          otherSchoolAttendedLocation: postData.otherSchoolAttendedLocation,
          prevSchoolStartDate: postData.prevSchoolStartDate,
          prevSchoolLastDate: postData.prevSchoolLastDate
        }
        delete postData.prevSchoolType
        delete postData.prevSchoolId
        delete postData.otherSchoolAttendedName
        delete postData.otherSchoolAttendedLocation
        delete postData.prevSchoolStartDate
        delete postData.prevSchoolLastDate
      }

      // ### Supplementary details -- Converting as per backend fields
      const isSchoolOfferSreSee = this.$store.state.isSchoolOfferSreSee
        ? this.$store.state.isSchoolOfferSreSee
        : false
      pdfData.isSchoolOfferSreSee = isSchoolOfferSreSee

      const sreSeeAmaOptionData = getSreSeeAmaVarsFromString(
        postData.selectedSreOrSeeOrAmaOption
      )

      if (
        this.$store.state.school &&
        this.$store.state.school.sre_see &&
        postData.selectedSreOrSeeOrAmaOption &&
        isSchoolOfferSreSee &&
        sreSeeAmaOptionData !== null
      ) {
        postData.sreSeeAmaSelectionLabel =
          sreSeeAmaOptionData.sreSeeAmaSelectionLabel
        postData.sreSeeAmaSelectionName =
          sreSeeAmaOptionData.sreSeeAmaSelectionName
        postData.sreSeeAmaSelectionProviders =
          sreSeeAmaOptionData.sreSeeAmaSelectionProviders
        postData.sreSeeSchoolOptionSetGUID =
          sreSeeAmaOptionData.sreSeeAmaOptionSetGuid

        // PDF Purpose -- remove GUID in end at selectedSreOrSeeOrAmaOption that required to use in PDF
        const splitSelectedOptionContent =
          postData.selectedSreOrSeeOrAmaOption.split('|')
        const selectedSreOrSeeOrAmaOption =
          Array.isArray(splitSelectedOptionContent) &&
          splitSelectedOptionContent.length > 0
            ? splitSelectedOptionContent[0].trim()
            : 'No option has been specified.'
        postData.selectedSreOrSeeOrAmaOption = selectedSreOrSeeOrAmaOption // This helps while retrieve the Applicaiton on dashboard view page
        pdfData.selectedSreOrSeeOrAmaOption =
          postData.selectedSreOrSeeOrAmaOption
      } else {
        // SET empty SRE/See Backend fields and selection field -- as school not offer SRE/SEE or if any draft application which selected Option but not offer now.
        // If there is no selection but with sre/see configured, we set it as no option
        postData.sreSeeSchoolOptionSetGUID = null
        postData.sreSeeAmaSelectionLabel = isSchoolOfferSreSee
          ? 'No option'
          : null
        postData.sreSeeAmaSelectionName = isSchoolOfferSreSee
          ? 'No option'
          : null
        postData.sreSeeAmaSelectionProviders = isSchoolOfferSreSee
          ? 'No option'
          : null
        postData.selectedSreOrSeeOrAmaOption = isSchoolOfferSreSee
          ? 'No option'
          : null

        // PDF Data -- If school offers Supplementary details, but applicant not selected any options then need to show below message else set empty value
        pdfData.selectedSreOrSeeOrAmaOption = isSchoolOfferSreSee
          ? 'No option has been specified.'
          : null
      }

      pdfData.meta.lateStartSchool = this.$store.state.school.calendarLateInd
      // ##### map text value for print
      pdfData = objectToPrint(pdfData)
      pdfData.student.prefFirstNamePrint = postData.student.prefFirstName
      pdfData.schoolCodePrint = postData.schoolName

      // This is to remove offered application status for the submiited ooa converted core application
      if (
        postData.ooaApplicationID &&
        postData.ooaApplicationID.includes('OOA-') &&
        postData.applicationStatus &&
        postData.applicationStatus === 'Offered'
      ) {
        delete postData.applicationStatus
      }

      this.$store.dispatch('showSpinner')

      endPoint
        .submitApplication(this.$store.state.auth.idToken, postData, pdfData)
        .then((resp) => {
          this.$store.dispatch('hideSpinner')

          var response = JSON.stringify(resp.data.body.applicationId)

          // save application id in store
          this.$store.commit('set', [
            'submittedApplicationId',
            response.replace(/"/g, '')
          ])

          // clear form data
          this.$store.commit('set', ['auth', auth])

          //Google Analytics
          const gaLabel = ehubUtil.getGaOoaOfferLabel()
          this.$gtm.trackEvent({
            event: 'interaction',
            category: 'Form Attempt',
            action: 'submit',
            label: gaLabel,
            application: ehubUtil.getGtmApplication()
          })

          // direct to confirmation page
          this.$scrollTo('#app', 1)
          this.$router.history.push('/confirmation')
        })
        .catch((error) => {
          this.$store.dispatch('hideSpinner')
          this.$store.dispatch('setAPIError', {
            error,
            fallbackCode: API_ERROR_SUBTYPES.submit
          })
          this.$router.history.push('/error')
        })
    },
    goto(type) {
      const section = this.$store.state.sections.find(
        (item) =>
          item.id === this.$store.state.application.meta.sectionExpandedId
      )
      this.$store.dispatch('gotoApplicationSection', { section, type })
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationSaveCancel {
  width: 100%;
  text-align: right;
  padding: 0 $content-padding;
  font-size: 1.6rem;

  .bar {
    border-top: 2px solid $color-primary;
    padding: 0.75rem 0;
  }

  button {
    margin: 0 0 0 0.5rem;
    padding: 1rem 2rem;
    border: 0;
    min-width: 7rem;
    background-color: $color-primary !important;

    &:focus {
      outline: 3px solid $ads-blue-2 !important;
    }
  }
}
</style>
