export const FIELD_TYPE = {
  ADDRESS: 'ADDRESS',
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_GROUP: 'CHECKBOX_GROUP',
  COLLECTION: 'COLLECTION',
  DATE: 'DATE',
  DROPDOWN: 'DROPDOWN',
  EMAIL: 'EMAIL',
  GROUP: 'GROUP',
  HEADING: 'HEADING',
  LINKTO: 'LINKTO',
  LOOKUP: 'LOOKUP',
  MONTH_YEAR: 'MONTH_YEAR',
  NAME: 'NAME',
  SAFE_TEXT: 'SAFE_TEXT',
  PHONE: 'PHONE',
  POSTCODE: 'POSTCODE',
  RADIO: 'RADIO',
  TEXTAREA: 'TEXTAREA',
  CUSTOM: 'CUSTOM',
  DOCUMENTS: 'DOCUMENTS',
  PANELS: 'PANELS',
  BUTTON: 'BUTTON',
  NOTIFICATION: 'NOTIFICATION',
  RADIO_GROUP: 'RADIOGROUP'
}

export const HEARTBEAT_EXEMPTIONS = [
  'ConfirmationView',
  'ErrorView',
  'LoginView',
  'PilotView',
  'WelcomeView',
  'SchoolReminderView',
  'LogoutView',
  'eHubRouteWrapper' // removed when ehub landing page dev is finished
]

export const ALERT_TYPE = {
  MISSING: 'MISSING',
  INVALID: 'INVALID',
  CONFLICT: 'CONFLICT'
}

export const ADDRESS_VALIDATION = {
  UNKNOWN: 'N',
  VALID: 'V'
}
export const ERROR_CODES = {
  NO_PENDING: 'NO_PENDING',
  NO_SCHOOL_CODE: 'NO_SCHOOL_CODE',
  NETWORK_ERROR: 'NETWORK_ERROR',
  API: 'API'
}

export const API_ERROR_SUBTYPES = {
  initiateAuth: 'PILIA',
  submit: 'PILSU',
  getApplication: 'PILRE',
  cancelApplication: 'PILCA',
  proceedApplication: 'PILPRO',
  oesPilotSchoolJson: 'CPS',
  oesPropertiesJson: 'COP'
}

export const ADDRESS_STATE = {
  INITIAL: 'INITIAL',
  WITHIN_AREA: 'WITHIN_AREA',
  OUT_OF_AREA: 'OUT_OF_AREA',
  NONE_FOUND: 'NONE_FOUND',
  CONFIRMING_CATCHMENT: 'CONFIRMING_CATCHMENT',
  INPUTTING_ADDRESS: 'INPUTTING_ADDRESS',
  ADDRESS_SELECTED: 'ADDRESS_SELECTED',
  LOOKUP_NOT_RESPONDING: 'LOOKUP_NOT_RESPONDING',
  CATCHMENT_VAL_ERROR: 'CATCHMENT_VAL_ERROR'
}

export const REFDATA_MAP = {
  ABORIGINALITY: 'Aboriginality',
  CONTACT_TYPE: 'ContactType',
  COUNTRIES: 'Countries',
  COUNTRIES_POPULAR: 'CountriesPopular',
  DISABILITY: 'Disability',
  LANGUAGES: 'Languages',
  LANGUAGES_POPULAR: 'LanguagesPopular',
  LEARNING_ADJUSTMENTS: 'LearningAdjustments',
  MEDICAL_CONDITION_ALLERGIES: 'MedicalConditionAllergies',
  MEDICAL_CONDITION_NAME: 'MedicationConditionName',
  OCCUPATION_GROUP: 'OccupationGroup',
  PARENT_SCHOOL_EDUCATION: 'ParentSchoolEducation',
  PARENT_TERTIARY_EDUCATION: 'ParentTertiaryEducation',
  PRESCHOOL_HOURS_TYPE: 'PreschoolHoursType',
  PRESCHOOL_TYPE: 'PreschoolType',
  RELATIONSHIP: 'Relationship',
  RESIDENCY_STATUS: 'ResidencyStatus',
  TITLE: 'Title',
  STATES: 'States',
  VISA_SUB_CLASS: 'VisaSubClass',
  TEMP_VISA_CLASS: 'TempVisaClass',
  TEMP_VISA_SUBCLASS: 'TempVisaSubClass',
  TEMP_VISA_SUB_CLASS_MAP: 'TempVisaClassAndSubClass'
}

// Default api date format
export const DATE_FORMAT = 'YYYY-MM-DD'

// Used as the value for intendedStartDate when enterStartDate is specified
export const CHOOSE_DATE = 'Choose a specific start date'

export const SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL = {
  PRIMARY: ['P', 'K', '1', '2', '3', '4', '5', '6'],
  INFANTS: ['K', '1', '2'],
  SECONDARY: ['7', '8', '9', '10', '11', '12']
}

export const NODE_ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  TEST: 'TEST',
  PREPROD: 'PRE',
  PROD: 'PROD',
  TRAIN: 'TRAIN'
}

export const ENROLMENT_TYPES = {
  CORE_OOA: 'CORE_OOA',
  CORE_Y67T: 'CORE_Y67T',
  CORE_SHS: 'CORE_SHS',
  CORE_SCT: 'CORE_SCT'
}

export const CATCHMENT_LEVELS = [
  {
    text: 'Primary school',
    value: 'primary'
  },
  {
    text: 'Secondary school',
    value: 'secondary'
  },
  {
    text: 'Infant school',
    value: 'infants'
  }
]

export const CATEGORY_UPLOAD_STATUSES = {
  UPLOADING: 'UPLOADING',
  REMOVING: 'REMOVING',
  COMPLETE: 'COMPLETE'
}

export const UPLOAD_CATEGORY_GTM_LABELS = {
  studentIdentity: 'Student Identity',
  residentialAddress: 'Residential Address',
  immunisation: 'Immunisation History Statement',
  travel: 'Travel Documents',
  medical: 'Healthcare Medical Plans',
  disability: 'Disability Support Needs',
  familyLaw: 'Family Law Court Orders'
}

export const NONE_OPTION = {
  value: 'none',
  text: 'None'
}

export const NSW_EDUCATION_URL = 'https://education.nsw.gov.au/'

export const ALLOWED_RESIDENCIES = ['PER', 'NZC', 'AUC']
export const NOT_ALLOWED_RESIDENCIES = ['NFI', 'RES', 'TMP']

export const ERROR_MESSAGES = {
  NAME: "<span>Can only contain letters, spaces, hyphens, left parenthesis, right parenthesis and apostrophes - ( ) '</span>",
  PHONE_NUM: '<span>Can only contain 0-9, spaces and - ( ) +</span>',
  SAFE_TEXT:
    "<span>Can only contain digits, spaces, hyphens, left parenthesis, right parenthesis, apostrophes and slashes - ( ) / '</span>"
}

export const EHUB = 'ehub'

export const CORE = 'core'

export const PREFILL_NOTIFICATION = `<p class="primary--text" id="notification-label">We have pre-filled these details based on information you have already provided to NSW Department of Education.</p>
<p id="notification-description"><strong>Please ensure the pre-filled information below is accurate before continuing to the next section</strong></p>`

export const TMP_NOTIFICATION = `<p class="primary--text" id="notification-label">This online application can only be used for Australian or NZ citizens or Permanent residents.</p>
<p id="notification-description">This online service is no longer available for temporary residents. Please visit the website for <a class="link-outline" href="https://www.deinternational.nsw.edu.au/" target="_blank"> temporary residents and international students</a>.</p>`

export const SUPPLEMENTARY_DETAILS_NOTIFICATION = `<p class="primary--text">Completion of this question is not required to submit your online enrolment application. If you skip this step, the school will contact you to confirm your preference.</p>`

export const SUPPLEMENTARY_DETAILS_ALERT = `<p class="primary--text"><b>Your SRE/SEE option is no longer available</b></p><p class="primary--text">Please choose from the updated list of options below.</p>`

export const NO_SRE_OPTIONS_ALERT = `<p class="primary--text">There are currently no Special Religious Education providers available for the selected school/scholastic year.</p>`

export const EHUB_PREFILL_SECTION_KEYS = [
  'student',
  'siblings',
  'parentCarers',
  'doctorDetails',
  'emergencyContact',
  'correspondenceAddress'
]
