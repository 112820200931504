// See README.md for more info about field configuration

import { FIELD_TYPE } from '@/constants'

import { YES_NO } from './droplistData/boolean'
import { DISABILITY } from './droplistData/disability'
import { LEARNING_ADJUSTMENT } from './droplistData/learningAdjustment'
import { isSupportForLearningRequired } from './helper/learnAndSupport'
import { getFirstName, getLabelName, concatNone } from './helper'

const setIsSupportForLearningRequired = (_, application, store) => {
  store.dispatch('set', [
    'application.isSupportForLearningRequired',
    isSupportForLearningRequired(application)
  ])
}

export default [
  {
    label() {
      return getLabelName(null, 'Learning & support')
    },
    description(application) {
      const name = getFirstName(application)
      return (
        getLabelName(
          'It is important that the school understands any learning and support needs including disability that ',
          name,
          ' may have. '
        ) +
        getLabelName(
          'Having as much information as we can will help the school to look after ',
          name,
          "'s wellbeing."
        )
      )
    },
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'learningAndSupport.learningSupportNeeds',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'From the below list, select any that apply to ',
        name,
        ':',
        'the student'
      )
    },
    description: 'Select as many as you like.',
    required: true,
    type: FIELD_TYPE.CHECKBOX_GROUP,
    options() {
      return concatNone(DISABILITY)
    },
    onFieldSet: setIsSupportForLearningRequired
  },
  {
    apiKey: 'learningAndSupport.otherDisabilityDetails',
    label: 'Other disability details',
    placeHolder: 'Enter other disability details',
    type: FIELD_TYPE.TEXTAREA,
    maxCharacter: 128,
    required: true,
    maxRows: 2,
    visible(application) {
      const learning = application.learningAndSupport.learningSupportNeeds
      if (learning && learning.find((item) => item === 'otherDisability')) {
        return learning.find((item) => item === 'otherDisability').length > 0
      }
    }
  },
  {
    apiKey: 'hasPrevLearningSupportPlan',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Has any previous education provider prepared a documented plan to support ',
        name,
        "'s additional learning needs?",
        'the student'
      )
    },
    description:
      'If yes, a copy of this will need to be provided to the school.',
    type: FIELD_TYPE.RADIO,
    options: YES_NO,
    onFieldSet: setIsSupportForLearningRequired
  },
  {
    apiKey: 'prevLearningSupportPlanDetails',
    label: 'Provide details',
    placeHolder: 'Enter details',
    type: FIELD_TYPE.TEXTAREA,
    required: true,
    visible(application) {
      return application.hasPrevLearningSupportPlan
    }
  },

  {
    apiKey: 'learningAndSupport.learningAdjustments',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'From the below list, select any learning adjustments that may be required for ',
        name,
        ':',
        'the student'
      )
    },
    description: 'Select as many as you like.',
    type: FIELD_TYPE.CHECKBOX_GROUP,
    options() {
      return concatNone(LEARNING_ADJUSTMENT)
    },
    onFieldSet: setIsSupportForLearningRequired
  },
  {
    apiKey: 'learningAndSupport.otherAdjustmentDetails',
    label: 'Other adjustments details',
    placeHolder: 'Enter other adjustments details',
    type: FIELD_TYPE.TEXTAREA,
    maxCharacter: 128,
    maxRows: 2,
    required: true,
    visible(application) {
      const learning = application.learningAndSupport.learningAdjustments
      if (learning && learning.find((item) => item === 'otherAdjustment')) {
        return learning.find((item) => item === 'otherAdjustment').length > 0
      }
    }
  },
  {
    apiKey: 'areThereModifiStratUsedHome',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Are there any modifications or strategies used at home that may help the school to meet ',
        name,
        "'s educational needs?",
        'the student'
      )
    },
    type: FIELD_TYPE.RADIO,
    options: YES_NO,
    onFieldSet: setIsSupportForLearningRequired
  },
  {
    apiKey: 'modifiStratUsedHome',
    label: 'Describe modifications or strategies',
    placeHolder: 'Enter modifications or strategies',
    type: FIELD_TYPE.TEXTAREA,
    required: true,
    visible(application) {
      return application.areThereModifiStratUsedHome
    }
  }
]
