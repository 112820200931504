export const SCHOLASTIC_YEARS = [
  { value: '', text: 'Select' },
  { value: 'K', text: 'Kindergarten' },
  { value: '1', text: 'Year 1' },
  { value: '2', text: 'Year 2' },
  { value: '3', text: 'Year 3' },
  { value: '4', text: 'Year 4' },
  { value: '5', text: 'Year 5' },
  { value: '6', text: 'Year 6' },
  { value: '7', text: 'Year 7' },
  { value: '8', text: 'Year 8' },
  { value: '9', text: 'Year 9' },
  { value: '10', text: 'Year 10' },
  { value: '11', text: 'Year 11' },
  { value: '12', text: 'Year 12' }
]
