<template>
  <footer class="AppFooter pt-5 pb-2">
    <b-container>
      <b-row>
        <b-col class="footer-logo">
          <a :href="nswEducationUrl" target="_blank">
            <img class="logo" src="@/assets/logo.svg" alt="NSW Government" />
          </a>
        </b-col>
        <b-col class="links">
          <div class="links__privacy">
            <h4 class="links__title">Privacy</h4>
            <ul class="links__list">
              <li>
                <a
                  class="list-link"
                  :href="legislationLink"
                  target="_blank"
                  rel="noopener"
                >
                  <img class="list-link__icon" src="@/assets/icon-lock.svg" />
                  Privacy Legislation
                </a>
              </li>
            </ul>
          </div>
          <div class="links__support">
            <h4 class="links__title">Technical Support</h4>
            <ul class="links__list">
              <li>
                <a :href="translationServicesLink" target="_blank">
                  <v-icon color="white" class="pr-3">language</v-icon
                  >Translation services
                </a>
              </li>
              <li>
                <a
                  class="list-link"
                  :href="formLink"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    class="list-link__icon"
                    src="@/assets/icon-description.svg"
                  />
                  Use our contact form
                </a>
              </li>
              <li>
                <a class="list-link" :href="phoneNumberLink">
                  <img class="list-link__icon" src="@/assets/icon-phone.svg" />
                  Phone {{ phoneNumber }}
                  <br />
                </a>
                <span class="list-link__additional-info">
                  {{ phoneHours }}
                </span>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import { NSW_EDUCATION_URL } from '@/constants'

export default {
  name: 'AppFooter',
  data() {
    return {
      nswEducationUrl: NSW_EDUCATION_URL
    }
  },
  computed: {
    linkData() {
      return this.$store.state.oesProperties.footer
    },
    legislationLink() {
      return (
        this.linkData &&
        this.linkData.privacy &&
        this.linkData.privacy.legislation &&
        this.linkData.privacy.legislation.link
      )
    },
    phoneNumber() {
      return (
        this.linkData &&
        this.linkData.support &&
        this.linkData.support.phone &&
        this.linkData.support.phone.number
      )
    },
    phoneHours() {
      return (
        this.linkData &&
        this.linkData.support &&
        this.linkData.support.phone &&
        this.linkData.support.phone.hours
      )
    },
    phoneNumberLink() {
      return this.phoneNumber && `tel:${this.phoneNumber.replace(/\s/g, '')}`
    },
    formLink() {
      return (
        this.linkData &&
        this.linkData.support &&
        this.linkData.support.form &&
        this.linkData.support.form.link
      )
    },
    translationServicesLink() {
      return (
        this.linkData &&
        this.linkData.support &&
        this.linkData.support.translations &&
        this.linkData.support.translations.link
      )
    }
  }
}
</script>

<style scoped lang="scss">
.AppFooter {
  background-color: $color-primary;
  height: $footer-height;
  display: flex;
  align-items: center;

  .footer-logo {
    flex-grow: 0;
    padding: 0 0 0 15px;
    margin: 0;
    min-width: 67px;

    .logo {
      height: 8.4rem;
    }
  }

  .links {
    display: flex;
    justify-content: flex-end;
    color: $color-white;
    font-size: $text;
    text-decoration: none;

    > :not(:last-child) {
      margin-right: 8rem;
    }

    &__title {
      font-weight: bold;
      text-transform: uppercase;
      font-size: $text;
      line-height: $text-h4;
      padding-bottom: 1.2rem;
    }

    &__list {
      margin-bottom: 0;

      > :not(:last-child) {
        margin-bottom: 1.2rem;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .list-link {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 1rem;
    }

    &__additional-info {
      padding-left: 3.4rem;
      display: block;
    }
  }

  a {
    text-decoration: none;
    color: $color-white;
    font-size: $text;
    font-weight: 500;
    display: inline-block;
    &:focus,
    &:active {
      box-shadow: 0 0 0 1px white;
    }
  }

  @include tablet {
    & {
      height: auto;
    }
    .container {
      padding: 2rem;
    }
    .footer-logo {
      margin-right: 2rem;
    }
  }

  @include mobile {
    & {
      .footer-logo {
        margin-top: 2rem;
      }

      .row {
        display: block;
      }
      .links {
        display: block;
        &__privacy,
        &__support {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
</style>
