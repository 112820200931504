var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.inPage ? 'alert--element' : 'alert--snackbar'},[(!_vm.inPage)?_c('v-snackbar',_vm._g(_vm._b({class:_vm.snackbarClass,attrs:{"light":_vm.$vuetify.theme.dark ? false : true,"bottom":_vm.positionElement.bottom,"left":_vm.positionElement.left,"top":_vm.positionElement.top,"right":_vm.positionElement.right,"timeout":_vm.sbTimeout,"id":_vm.generatedId,"multi-line":""},model:{value:(_vm.displayAlert),callback:function ($$v) {_vm.displayAlert=$$v},expression:"displayAlert"}},'v-snackbar',_vm.$attrs,false),_vm.$listeners),[_vm._t("content",function(){return [_c('div',{staticClass:"d-flex align-start"},[_vm._t("icon",function(){return [_c('v-icon',{staticClass:"mr-4",attrs:{"color":_vm.$vuetify.theme.dark
                ? _vm.typeProperties.darkColor
                : _vm.typeProperties.color,"size":"27"}},[_vm._v(" "+_vm._s(_vm.sbIcon)+" ")])]}),_vm._t("text",function(){return [_c('div',{staticClass:"align-start flex-column"},[(_vm.htmlText)?_c('div',{staticClass:"alert--text",domProps:{"innerHTML":_vm._s(_vm.htmlText)}}):_vm._e(),(!_vm.htmlText)?_c('div',{staticClass:"alert--text"},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),_vm._t("optional",function(){return [(_vm.subtext)?_c('div',{staticClass:"pt-1 alert--textsimple"},[_vm._v(" "+_vm._s(_vm.subtext)+" ")]):_vm._e(),(_vm.actionText)?_c('div',{staticClass:"pt-3 alert--text",style:({
                  color: _vm.$vuetify.theme.dark
                    ? _vm.typeProperties.darkColor
                    : _vm.typeProperties.color
                })},[_c('span',{class:{
                    'alert--clickable-action': _vm.actionHandler != null
                  },on:{"click":function($event){_vm.actionHandler ? _vm.actionHandler() : _vm.noop()}}},[_vm._v(_vm._s(_vm.actionText)+" ")])]):_vm._e()]})],2)]}),_c('div',[(_vm.allowDismiss)?_c('v-btn',{staticClass:"alert--text",attrs:{"text":"","icon":""},on:{"click":_vm.dismiss}},[_c('v-icon',{attrs:{"small":"","color":_vm.$vuetify.theme.dark
                  ? _vm.typeProperties.darkColor
                  : _vm.typeProperties.color}},[_vm._v(" cancel ")])],1):_vm._e()],1)],2)]})],2):_vm._e(),(_vm.inPage)?_c('v-alert',_vm._g(_vm._b({class:[_vm.alertClass, { customAlertStyle: _vm.customStyle }],attrs:{"type":_vm.type,"color":_vm.$vuetify.theme.dark ? _vm.typeProperties.darkColor : _vm.typeProperties.color,"id":_vm.generatedId,"border":_vm.border,"icon":_vm.sbIcon,"elevation":_vm.elevation,"colored-border":""}},'v-alert',_vm.$attrs,false),_vm.$listeners),[_vm._t("content",function(){return [_c('div',{staticClass:"d-flex align-center"},[_vm._t("text",function(){return [_c('div',{staticClass:"align-start flex-column"},[(_vm.htmlText)?_c('div',{class:[
                'snackbar--text',
                'font-weight-bold',
                { customStyle: _vm.customStyle }
              ],domProps:{"innerHTML":_vm._s(_vm.htmlText)}}):_vm._e(),(!_vm.htmlText)?_c('div',{staticClass:"snackbar--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),_vm._t("optional",function(){return [(_vm.subtext)?_c('div',{staticClass:"pt-1 alert--textsimple"},[_vm._v(" "+_vm._s(_vm.subtext)+" ")]):_vm._e(),(_vm.htmlContent)?_c('div',{staticClass:"pt-1 alert--textsimple",domProps:{"innerHTML":_vm._s(_vm.htmlContent)}}):_vm._e()]}),(_vm.actionText)?_c('div',{staticClass:"pt-3 alert--text font-weight-bold",style:({
                color: _vm.$vuetify.theme.dark
                  ? _vm.typeProperties.darkColor
                  : _vm.typeProperties.color
              })},[_c('span',{class:{ 'alert--clickable-action': _vm.actionHandler != null },on:{"click":function($event){_vm.actionHandler ? _vm.actionHandler() : _vm.noop()}}},[_vm._v(_vm._s(_vm.actionText))])]):_vm._e()],2)]}),_c('v-spacer'),_vm._t("close",function(){return [(_vm.allowDismiss)?_c('div',{staticClass:"pl-5"},[_c('v-btn',{attrs:{"icon":"","color":_vm.$vuetify.theme.dark
                  ? _vm.typeProperties.darkColor
                  : _vm.typeProperties.color},on:{"click":_vm.dismiss}},[_c('v-icon',[_vm._v("cancel")])],1)],1):_vm._e()]})],2)]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }