import { dropDownSelect } from './helper'

const condition = [
  { value: 'ASH', text: 'Asthma' },
  { value: 'AST', text: 'Asthma - Severe' },
  { value: 'DIA', text: 'Diabetes' },
  { value: 'EPI', text: 'Epilepsy' },
  { value: 'OTH', text: 'Other Health Condition' },
  { value: 'OHR', text: 'Other High Risk Health Condition' }
]

const medicalConditionName = dropDownSelect(condition)
export default medicalConditionName
