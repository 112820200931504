<template>
  <div class="FocusTrap">
    <div class="focus-trapper" tabindex="0"></div>
    <slot></slot>
    <div class="focus-trapper" tabindex="0"></div>
  </div>
</template>

<script>
export default {
  name: 'FocusTrap',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    elementToFocusOnStop: {
      type: Object,
      default: null
    },
    returnFocusOnStop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lastFocus: null,
      ignoreFocus: false,
      previouslyFocused: null
    }
  },
  watch: {
    isActive(value) {
      if (value) {
        this.previouslyFocused = document.activeElement
        this.addListeners()
      } else {
        this.removeListeners()
        if (this.elementToFocusOnStop) {
          this.elementToFocusOnStop.focus()
        } else if (this.returnFocusOnStop && this.previouslyFocused) {
          this.previouslyFocused.focus()
        }
      }
    }
  },
  methods: {
    addListeners() {
      document.addEventListener('focus', this.trapFocus, true)
    },
    removeListeners() {
      document.removeEventListener('focus', this.trapFocus, true)
    },
    //Adapted from https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/js/dialog.js
    trapFocus(event) {
      if (this.$slots.default[0].elm && !this.ignoreFocus) {
        if (this.$slots.default[0].elm.contains(event.target)) {
          // Focusing on an element within the focusTrap
          this.lastFocus = event.target
        } else {
          // Focusing on an element outside the focusTrap
          this.focusFirstDescendant(this.$slots.default[0].elm)
          if (this.lastFocus === document.activeElement) {
            this.focusLastDescendant(this.$slots.default[0].elm)
          }
          this.lastFocus = document.activeElement
        }
      }
    },

    focusFirstDescendant(element) {
      for (let i = 0; i < element.childNodes.length; i++) {
        let child = element.childNodes[i]
        if (this.attemptFocus(child) || this.focusFirstDescendant(child)) {
          return true
        }
      }
      return false
    },

    focusLastDescendant(element) {
      for (let i = element.childNodes.length - 1; i >= 0; i--) {
        let child = element.childNodes[i]
        if (this.focusLastDescendant(child) || this.attemptFocus(child)) {
          return true
        }
      }
      return false
    },

    isFocusable(element) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
      if (!element.matches) {
        element.matches =
          element.msMatchesSelector || element.webkitMatchesSelector
      }

      return (
        element.matches &&
        element.matches(
          // Selectors taken from Intopia https://codepen.io/intopia/pen/oWJpyQ
          'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]'
        )
      )
    },

    attemptFocus(element) {
      if (!this.isFocusable(element)) {
        return false
      }

      this.ignoreFocus = true
      try {
        element.focus()
      } catch (e) {} // eslint-disable-line no-empty
      this.ignoreFocus = false
      return document.activeElement === element
    }
  },
  created() {
    const childNodes = this.$slots.default
    if (!childNodes || !childNodes.length || childNodes.length > 1) {
      throw new Error('FocusTrap requires exactly one child')
    }
  },
  beforeDestroy() {
    this.removeListeners()
  }
}
</script>

<style scoped lang="scss">
.focus-trapper {
  position: fixed;
}
</style>
