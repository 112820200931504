// See README.md for more info about field configuration

import { FIELD_TYPE, PREFILL_NOTIFICATION } from '@/constants'
import { getStudentDisplayName } from './helper'
import getParent, { createParent } from './helper/parent'
import { YES_NO } from './droplistData/boolean'
import Vue from 'vue'

export default [
  {
    label: 'Parents/Carers',
    description(application) {
      const name = getStudentDisplayName(application.student, 'this student')
      return `This section requires you to provide details of all parent/carers associated with ${name}. Please note: at least one Parent/Carer must be living at the student's residential address.`
    },
    type: FIELD_TYPE.HEADING
  },
  {
    type: FIELD_TYPE.NOTIFICATION,
    notificationProps: {
      inPage: true,
      type: 'info',
      alertClass: 'blue-alert',
      elevation: 0
    },
    text() {
      return PREFILL_NOTIFICATION
    },
    visible(applicaition) {
      return (
        applicaition.meta &&
        applicaition.meta.prefillFlags &&
        applicaition.meta.prefillFlags.parentCarers
      )
    }
  },
  getParent('parentCarers'),
  {
    type: FIELD_TYPE.HEADING,
    vHtmlLabel() {
      return `<strong>Additional Parent/Carers</strong>`
    },
    showLabel: true,
    showInReview: true
  },
  {
    apiKey: 'meta.toAddMoreParent',
    label(application) {
      const name = getStudentDisplayName(application.student, 'this student')
      return `Are there any additional parent/carers associated with ${name}?`
    },
    required: true,
    type: FIELD_TYPE.RADIO,
    options: YES_NO,
    onFieldSet(val, field, store) {
      if (val) {
        let parent = createParent()
        if (
          store.state.ernEnrolmentOwnerName ||
          store.state.application.meta.parentId ||
          !!store.state.application.parentCarers.some((p) => p.parentId)
        ) {
          parent.meta.isCompletingApplication = false
        }
        let parents = [...store.state.application.parentCarers, parent]
        store.dispatch('set', [`application.parentCarers`, parents])
        // uncheck the yes option after a new parent has been added
        // eslint-disable-next-line import/no-named-as-default-member
        Vue.nextTick(() => {
          store.dispatch('set', [`application.meta.toAddMoreParent`, null])
        })
      }
    }
  }
]
