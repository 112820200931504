import droplistData from '@/applicationDefinition/droplistData/index'
import { getTerms } from '@/applicationDefinition/droplistData/termDates'
import store from '@/store/store'
import { REFDATA_MAP } from '@/constants'

import {
  getAddressStringFromLine1,
  getAddressString,
  concatNone,
  getSchoolNameFromId
} from '@/applicationDefinition/helper'

// dataRaw - original array to be map
// dataList - reference data to be retrieve
// dataAttribute - matching attribute for the list
// dataTest - attribute value to check against
// dataResult - attribute value to return
const CheckArrayHasValue = (
  dataRaw,
  dataList,
  dataTest,
  dataAttribute,
  dataResult
) => {
  // sanity check first
  if (!dataAttribute) {
    dataAttribute = 'value'
  }
  if (!dataResult) {
    dataResult = 'text'
  }
  if (dataRaw) {
    dataRaw = dataRaw.map((item) => {
      // make sure item is not null and that dataTest resolves
      if (
        item &&
        item != undefined &&
        item[dataTest] &&
        item[dataTest] != undefined
      ) {
        item[dataTest + 'Print'] = CheckAttributeHasValue(
          dataList,
          item[dataTest],
          dataAttribute,
          dataResult
        )
      }
      return item
    })
  }

  return dataRaw
}

// dataRaw - original array to be map
// dataList - reference data to be retrieve
// dataAttribute - matching attribute for the list
// dataTest - attribute value to check against
// dataResult - attribute value Array to return
const CheckArrayMapValueArray = (dataRaw, dataList) => {
  if (dataRaw) {
    var data = dataRaw.map((item) => {
      if (item) {
        item = CheckAttributeHasValue(dataList, item)
      }
      return item
    })
    return data
  } else {
    return dataRaw
  }
}

// compares .text and returns .value by default
const CheckAttributeHasValue = (
  dataList,
  dataTest,
  dataAttribute,
  dataResult
) => {
  // sanity check first
  if (!dataAttribute) {
    dataAttribute = 'value'
  }
  if (!dataResult) {
    dataResult = 'text'
  }
  if (dataList) {
    const dataPrint = dataList.find((item) => item[dataAttribute] === dataTest)
    if (dataPrint) {
      return dataPrint[dataResult]
    }
  }
}

export default (userData) => {
  /*
    Server-side PDF generation cannot perform lookups on codes, so
    any fields that use codes (e.g. droplists) need to set a "print"
    value containing the display text. E.G.

      countryBorn = 'AU'
      countryBornPrint = 'Australia'
  */

  // get reference data from store
  let refData = store.state.refData

  // get term dates from store
  let termDates = getTerms(
    store.state.termDates,
    userData.meta.calendarYear,
    userData.meta.lateStartSchool
  )

  // prevSchoolIdPrint is being used by the PDF for previous school name
  // irrespective of whether it is an overseas school (OVS) or not.
  if (userData.prevSchoolType === 'OVS') {
    userData.prevSchoolIdPrint = userData.otherSchoolAttendedName
    userData.prevSchoolTypePrint = 'Overseas'
  } else {
    userData.prevSchoolIdPrint = getSchoolNameFromId(userData.prevSchoolId)
    if (userData.prevSchoolType) {
      userData.prevSchoolTypePrint = CheckAttributeHasValue(
        refData[REFDATA_MAP.STATES],
        userData.prevSchoolType
      )
    }
  }

  // append 'Print' to the new attribute
  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    refData[REFDATA_MAP.ABORIGINALITY],
    'isParentCarerAboriOrTorres'
  )

  userData.residentialAddressPrint = getAddressString(
    userData.residentialAddress,
    store
  )

  if (userData.correspondenceAddress) {
    userData.correspondenceAddressPrint = getAddressStringFromLine1(
      userData.correspondenceAddress,
      store
    )
  }

  userData.siblings = CheckArrayHasValue(
    userData.siblings,
    droplistData.GENDERCODE,
    'siblingsGenderCode'
  )

  userData.student.genderCodePrint = CheckAttributeHasValue(
    droplistData.GENDERCODE,
    userData.student.genderCode
  )

  userData.student.countryBornPrint = CheckAttributeHasValue(
    refData[REFDATA_MAP.COUNTRIES],
    userData.student.countryBorn
  )

  userData.student.isAboriTorStraitIslanderPrint = CheckAttributeHasValue(
    refData[REFDATA_MAP.ABORIGINALITY],
    userData.student.isAboriTorStraitIslander
  )

  userData.student.mainLanguageOtherThanEnglishPrint = CheckAttributeHasValue(
    refData[REFDATA_MAP.LANGUAGES],
    userData.student.mainLanguageOtherThanEnglish
  )

  userData.student.otherLanguagePrint = CheckAttributeHasValue(
    refData[REFDATA_MAP.LANGUAGES],
    userData.student.otherLanguage
  )

  userData.scholasticYearPrint = CheckAttributeHasValue(
    //hard coded as not available from ref API
    droplistData.SCHOLASTIC_YEARS,
    userData.scholasticYear
  )

  userData.intendedStartDatePrint = CheckAttributeHasValue(
    //hard coded as not available from ref API
    termDates,
    userData.intendedStartDate
  )

  userData.residencyStatusPrint = CheckAttributeHasValue(
    refData[REFDATA_MAP.RESIDENCY_STATUS],
    userData.residencyStatus
  )

  userData.visaSubClassPrint = CheckAttributeHasValue(
    refData[REFDATA_MAP.VISA_SUB_CLASS],
    userData.visaSubClass
  )

  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    refData[REFDATA_MAP.TITLE],
    'parentCarerTitle'
  )

  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    droplistData.GENDERCODE,
    'parentCarerGenderCode'
  )

  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    refData[REFDATA_MAP.COUNTRIES],
    'parentCarerCountryBirth'
  )

  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    refData[REFDATA_MAP.ABORIGINALITY],
    'isParentCarerAboriOrTorres'
  )

  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    refData[REFDATA_MAP.OCCUPATION_GROUP],
    'parentCarerOccupationGroup'
  )

  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    refData[REFDATA_MAP.PARENT_SCHOOL_EDUCATION],
    'parentCarerSchoolEducationCode'
  )

  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    refData[REFDATA_MAP.PARENT_TERTIARY_EDUCATION],
    'parentCarerEducationQualificationCode'
  )

  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    refData[REFDATA_MAP.LANGUAGES],
    'parentCarerMainLanguage'
  )

  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    refData[REFDATA_MAP.LANGUAGES],
    'parentCarerSpeakLang'
  )

  if (userData.parentCarers) {
    userData.parentCarers = userData.parentCarers.map((item) => {
      if (item && item.contactDetails) {
        item.contactDetails = CheckArrayHasValue(
          item.contactDetails,
          refData[REFDATA_MAP.CONTACT_TYPE],
          'contactType'
        )
      }
      if (item && item.residentialAddress) {
        item.residentialAddressPrint = getAddressStringFromLine1(
          item.residentialAddress,
          store
        )
      }
      if (item && item.correspondenceAddress) {
        item.correspondenceAddressPrint = getAddressStringFromLine1(
          item.correspondenceAddress,
          store
        )
      }
      return item
    })
  }

  if (userData.doctorDetails) {
    userData.doctorDetails = userData.doctorDetails.map((item) => {
      if (item && item.doctorAddress) {
        item.doctorAddressPrint = getAddressStringFromLine1(
          item.doctorAddress,
          store
        )
      }
      return item
    })
  }

  userData.parentCarers = CheckArrayHasValue(
    userData.parentCarers,
    refData[REFDATA_MAP.RELATIONSHIP],
    'parentCarerRelation'
  )

  userData.siblings = CheckArrayHasValue(
    userData.siblings,
    droplistData.GENDERCODE,
    'genderCode'
  )

  userData.allergies = CheckArrayHasValue(
    userData.allergies,
    refData[REFDATA_MAP.MEDICAL_CONDITION_ALLERGIES],
    'medicalConditionType'
  )

  userData.allergiesAnaphylactic = CheckArrayHasValue(
    userData.allergiesAnaphylactic,
    refData[REFDATA_MAP.MEDICAL_CONDITION_ALLERGIES],
    'medicalConditionType'
  )

  userData.nonAllergies = CheckArrayHasValue(
    userData.nonAllergies,
    refData[REFDATA_MAP.MEDICAL_CONDITION_NAME],
    'medicalConditionType'
  )

  userData.preschools = CheckArrayHasValue(
    userData.preschools,
    refData[REFDATA_MAP.PRESCHOOL_TYPE],
    'preschoolType'
  )

  userData.preschools = CheckArrayHasValue(
    userData.preschools,
    refData[REFDATA_MAP.PRESCHOOL_HOURS_TYPE],
    'preschoolHoursType'
  )

  userData.emergencyContact = CheckArrayHasValue(
    userData.emergencyContact,
    refData[REFDATA_MAP.RELATIONSHIP],
    'contactRelationship'
  )

  userData.learningAndSupport.learningSupportNeedsPrint =
    CheckArrayMapValueArray(
      userData.learningAndSupport.learningSupportNeeds,
      //use hard coded dataset as the keys need to be cleaned up at API
      concatNone(droplistData.DISABILITY)
    )

  userData.learningAndSupport.learningAdjustmentPrint = CheckArrayMapValueArray(
    //use hard coded dataset as the keys need to be cleaned up at API
    userData.learningAndSupport.learningAdjustments,
    concatNone(droplistData.LEARNING_ADJUSTMENT)
  )
  userData.meta.reasonForSuspensionPrint = CheckArrayMapValueArray(
    userData.meta.reasonForSuspension,
    //hard coded list as not availabe from refData Api
    droplistData.SUSPENSIONS
  )

  if (userData.emergencyContact) {
    userData.emergencyContact = userData.emergencyContact.map((item) => {
      if (item && item.contactDetails) {
        item.contactDetails = CheckArrayHasValue(
          item.contactDetails,
          refData[REFDATA_MAP.CONTACT_TYPE],
          'contactType'
        )
      }
      return item
    })
  }
  return userData
}
