export default (arrayData, arrayName) => {
  if (arrayData) {
    return arrayData.map((item) => {
      if (arrayName.find((data) => data === item.value)) {
        return { name: item.value, value: true }
      }
      return { name: item.value, value: false }
    })
  }
}
