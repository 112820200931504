import getSreSeeAmaVarsFromString from './getSreSeeAmaVarsFromString'
import findSreSeeOption from './findSreSeeOption'

export const UNMATCHED_SRE_SEE_AMA_OPTION = 'UNMATCHED_SRE_SEE_AMA_OPTION'
export const UNDEFINED_SRE_SEE_AMA_OPTION = 'UNDEFINED_SRE_SEE_AMA_OPTION'

export function isSreSeeSelectionPhaseTwo(selectedSreOrSeeOrAmaOption) {
  const { sreSeeAmaOptionSetGuid } =
    getSreSeeAmaVarsFromString(selectedSreOrSeeOrAmaOption) || {}
  return Boolean(sreSeeAmaOptionSetGuid)
}

function updatePhaseOneSreSeeAmaSelection({
  schoolCustomisationFile,
  selectedSreOrSeeOrAmaOption
}) {
  if (
    selectedSreOrSeeOrAmaOption !== null &&
    selectedSreOrSeeOrAmaOption !== ''
  ) {
    const tokenisedSreSeeSelection = getSreSeeAmaVarsFromString(
      selectedSreOrSeeOrAmaOption
    )
    if (
      !isSreSeeSelectionPhaseTwo(selectedSreOrSeeOrAmaOption) &&
      tokenisedSreSeeSelection !== null
    ) {
      const phaseTwoSreSeeAmaOption = findSreSeeOption({
        tokenisedSreSeeSelection,
        schoolCustomisationFile
      })
      if (phaseTwoSreSeeAmaOption === null) {
        return {
          error: UNMATCHED_SRE_SEE_AMA_OPTION
        }
      }
      if (
        Object.values(phaseTwoSreSeeAmaOption).length > 1 &&
        ['name', 'guid', 'providers'].every((i) =>
          Object.prototype.hasOwnProperty.call(phaseTwoSreSeeAmaOption, i)
        )
      ) {
        return {
          patch: {
            selectedSreOrSeeOrAmaOption: `${phaseTwoSreSeeAmaOption.sreSeeAmaOptionAcronym.toUpperCase()}: ${
              phaseTwoSreSeeAmaOption.name
            } (${phaseTwoSreSeeAmaOption.providers
              .map((i) => i.name)
              .join(', ')})|${phaseTwoSreSeeAmaOption.guid}`
          }
        }
      }
      return {
        error: UNMATCHED_SRE_SEE_AMA_OPTION
      }
    }
    if (
      !isSreSeeSelectionPhaseTwo(selectedSreOrSeeOrAmaOption) &&
      tokenisedSreSeeSelection === null
    ) {
      return {
        error: UNMATCHED_SRE_SEE_AMA_OPTION
      }
    }
  }
  return {}
}

export function upgradeApplication({
  application = {},
  schoolCustomisationFile = {}
}) {
  // return an array of patches or upgrade errors

  const updatePhaseOneSreSeeAmaSelectionResult =
    updatePhaseOneSreSeeAmaSelection({
      selectedSreOrSeeOrAmaOption: application.selectedSreOrSeeOrAmaOption,
      schoolCustomisationFile
    })
  return [
    ...(Object.keys(updatePhaseOneSreSeeAmaSelectionResult).length
      ? [
          {
            ...updatePhaseOneSreSeeAmaSelectionResult,
            section: 'supplementaryDetails'
          }
        ]
      : [])
  ]
}
