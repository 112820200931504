<template>
  <div class="AppSchoolLookup">
    <div class="ApplicationField">
      <div class="inputGroup" :class="[{ hasAlert: errorMessage }]">
        <span class="fieldLabel">
          <span v-if="required" class="required-mark" aria-hidden="true"
            >*</span
          >
          <span v-if="label">{{ label }}</span>
          <span v-if="vHtmlLabel" v-html="vHtmlLabel"></span>
        </span>

        <div class="fieldContent fieldText">
          <TypeAhead
            :required="required"
            :disabled="disabled"
            min-length="3"
            max-length="100"
            placeholder="Start typing the school name..."
            :options="typeAheadOptions"
            :alternates="typeAheadAlternates"
            :value="typeAheadValue"
            @input="onInput"
            @search="onSearch"
            @select="onSelect"
            @blur="onBlur"
            ref="typeAhead"
          />
        </div>

        <div class="alertRow" v-if="errorMessage">
          <font-awesome-icon icon="exclamation-circle"></font-awesome-icon>
          <span class="alertMessage">{{ this.errorMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import endPoint from '@/store/endPoint'
import TypeAhead from './TypeAhead.vue'
import { getSchoolNameFromId } from '@/applicationDefinition/helper'

const LOOKUP_STATE = {
  INITIAL: 'INITIAL',
  FILTERING: 'FILTERING',
  SELECTED: 'SELECTED'
}

let __TMP_SCHOOL_LIST = []

export default {
  name: 'AppSchoolLookup',
  props: {
    id: String,
    field: Object,
    section: Object,
    value: String,
    label: [String, Boolean],
    vHtmlLabel: [String, Boolean],
    disabled: Boolean,
    required: [Boolean, Function]
  },
  components: {
    TypeAhead
  },
  data() {
    return {
      apiData: [],
      apiValue: null,
      typeAheadOptions: [],
      typeAheadAlternates: [],
      typeAheadValue: '',
      isDirty: false,
      lookupState: LOOKUP_STATE.INITIAL,
      errorMessage: null
    }
  },
  computed: {
    currentStateCode() {
      return this.$store.state.application.prevSchoolType
    },
    prevSchoolId() {
      return this.$store.state.application.prevSchoolId
    }
  },
  created() {
    this.typeAheadValue = getSchoolNameFromId(this.value)

    endPoint.getPredictiveSchools().then((res) => {
      const bodyData = res.data
      if (Array.isArray(bodyData)) __TMP_SCHOOL_LIST = bodyData
    })
  },
  methods: {
    setValue() {
      let val = this.apiValue ? this.apiValue.uniqueRecord : null
      this.$emit('change', val)
    },
    onInput(val) {
      // Need to explicitly set the value that TypeAhead is watching. This fixes
      // an issue where the value was not being updated when selecting the same
      // item from the dropdown in succession.
      this.typeAheadValue = val

      this.lookupState = LOOKUP_STATE.FILTERING

      if (val.length < 3) {
        this.close()
      }
    },
    onSearch(val) {
      // Case-insensitive filter
      let filteredArr = __TMP_SCHOOL_LIST
        .filter((item) => item.stateCode === this.currentStateCode)
        .filter((item) =>
          item.orgUnitName.toLowerCase().startsWith(val.toLowerCase())
        )

      this.apiData = filteredArr
      this.typeAheadOptions = filteredArr.map((item) => item.orgUnitName)
      this.typeAheadAlternates = filteredArr.map((item) => item.suburb)
    },
    onSelect(idx) {
      this.apiValue = this.apiData[idx]
      this.typeAheadValue = this.typeAheadOptions[idx]

      this.lookupState = LOOKUP_STATE.SELECTED

      this.setValue()
      this.close()
    },
    onBlur() {
      this.errorMessage = ''

      if (this.typeAheadValue === '') {
        this.errorMessage = 'Please make a selection'
      } else if (this.lookupState === LOOKUP_STATE.FILTERING) {
        this.errorMessage =
          'We cannot verify this school. Please clear the field and search for the school again.'
      }

      this.setValue()
      this.close()
    },
    close() {
      this.typeAheadOptions = this.typeAheadAlternates = []
    }
  },
  watch: {
    prevSchoolId(newVal) {
      // If "state.application.prevSchoolId" is cleared by another process (outside of this component)
      // we must clear our type-ahead text (typeAheadValue). "prevSchoolId" is currently cleared when
      // the "prevSchoolType" droplist is changed.
      if (newVal === '') this.typeAheadValue = ''
    }
  }
}
</script>

<style lang="scss">
.AppSchoolLookup {
  .ApplicationField {
    .fieldText {
      width: 100% !important;
    }
  }

  .fieldContent {
    input {
      width: 100%;
    }
  }

  .alertRow {
    display: flex;
    padding-top: 0.5rem;

    svg {
      position: relative;
      top: -0.5rem;
      height: 3em;
      color: $color-red;
    }

    .alertMessage {
      display: inline-block;
      padding-left: 0.25rem;
      color: $color-red;
      font-size: 1.4rem;
    }
  }
}
</style>
