// this array maps will be converted to object attribute for submit

const disability = [
  { value: 'autism', text: 'Autism' },
  { value: 'hearingImpairment', text: 'A hearing impairment' },
  { value: 'languageDisorder', text: 'A language disorder' },
  { value: 'physicalDisability', text: 'A physical disability' },
  { value: 'difficultiesInLearning', text: 'Difficulties in learning' },
  { value: 'brainInjury', text: 'Acquired brain injury' },
  { value: 'behaviourDisorder', text: 'Behaviour disorder' },
  { value: 'intellectualDisability', text: 'Intellectual Disability' },
  { value: 'mentalHealthDisorder', text: 'Mental health disorder' },
  { value: 'visionImpairment', text: 'A vision impairment' },
  { value: 'otherDisability', text: 'Other' }
]

export const DISABILITY = disability.map((item) => {
  item.group = 'disability'
  return item
})
