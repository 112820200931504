<template>
  <div class="ApplicationLoginOtp">
    <b-container class="content">
      <b-row class="verify-code-row white-card">
        <b-col>
          <p>
            We have sent an email to
            <span class="typed-email-headline">{{ email }}</span>
            containing a 6-digit verification code.
          </p>
          <br />
          <b-form class="code-form" @submit.prevent="verifyOtpCode">
            <label class="code-form__label" for="otpInput"
              >Verification code</label
            >
            <b-form-input
              id="otpInput"
              class="code-input"
              type="text"
              :maxlength="maxChar"
              :disabled="attemptsLeft === 0"
              required
              placeholder="Enter your 6-digit code"
              inputmode="numeric"
              v-model="otpCode"
              ref="enterCode"
            ></b-form-input>
            <b-button
              :disabled="
                attemptsLeft === 0 || otpCode.length < 6 || isVerifying
              "
              variant="primary"
              @click="verifyOtpCode"
              >Verify</b-button
            >
          </b-form>

          <transition name="fade">
            <p
              v-if="attemptsLeft > -1 && attemptsLeft < 3"
              class="incorrect-code"
            >
              <font-awesome-icon
                focusable="false"
                class="icon"
                icon="exclamation-circle"
              />
              The entered code is incorrect.
              <span v-if="attemptsLeft === 0"
                >To receive a new code, please
                <button class="btn-link" @click="reEnterEmail">
                  re-enter your email address
                </button></span
              >
              <span v-else>Please check the code and try again</span>.
            </p>
          </transition>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <p class="no-code">
            <span>
              Didn’t receive a code in your email? you can
              <button
                class="resendCode btn-link"
                type="button"
                @click="reEnterEmail"
              >
                re-enter your email address
              </button>
            </span>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import endPoint from '@/store/endPoint'
import ehubUtil from '@/utils/ehub'

export default {
  name: 'ApplicationLoginOtp',
  props: {
    curEmail: String
  },
  data() {
    return {
      email: this.curEmail,
      attemptsLeft: 3,
      maxChar: 6,
      otpCode: process.env.NODE_ENV === 'development' ? '111111' : '',
      isVerifying: false
    }
  },
  mounted() {
    //Focusing immediately breaks the transition in LoginView when the components being transitioned don't all fit in the viewport
    setTimeout(() => {
      try {
        this.$refs.enterCode.focus()
      } catch (e) {
        /*eslint-disable */
        console.log(e)
        /*eslint-disable */
      }
    }, 300)
  },
  methods: {
    reEnterEmail() {
      this.$emit('resetEmail', this.email)
    },
    verifyOtpCode() {
      //Disable Verify button before API call to avoid duplicated API requests
      this.isVerifying = true
      // Check code form
      let self = this

      return (
        endPoint
          .postAuthOTP(
            self.email,
            self.$store.state.auth.cognitoSession,
            self.otpCode
          )
          .then((resp) => {
            // Handle incorrect code first
            if (resp.data.message === 'Wrong OTP, Try again') {
              this.isVerifying = false

              self.$store.commit('set', [
                'auth.cognitoSession',
                resp.data.body.cognitoSession
              ])
              self.$data.attemptsLeft = Number(resp.data.body.attemptsLeft)
            } else {
              // Save userID and cognitoSession to store
              self.$store.commit('set', [
                'auth.idToken',
                resp.data.body.idToken
              ])
              self.$store.commit('set', [
                'auth.idTokenTime',
                new Date().getTime()
              ])
              self.$store.commit('set', [
                'auth.refreshToken',
                resp.data.body.refreshToken
              ])
              self.$store.commit('set', ['auth.authProvider', 'OTP'])

              //Google Analytics
              const gtmApplication = ehubUtil.getGtmApplication()
              this.$gtm.trackEvent({
                event: 'interaction',
                category: 'Authentication',
                action: 'Success',
                label: 'Email',
                application: gtmApplication
              })

              // get OOA and y67t pending offers
              this.getOffers()
            }
          })
          // When idToken is incorrect
          .catch((err) => {
            // reeable Verify button for retry
            this.isVerifying = false
            if (
              err.message === 'Network Error' ||
              err.response.status !== 400
            ) {
              this.$router.history.push('/error?type=500')
            } else if (err.response.status === 400) {
              // Code 400 indicates that there are no attempts left on the token
              // and the user will have to request a new one.
              if (self.$store.state.auth) {
                self.$data.attemptsLeft = 0
              }
            }
          })
      )
    }
  },
  // Hiding error message when editing OTP code
  watch: {
    otpCode() {
      this.attemptsLeft = 3
    }
  }
}
</script>

<style lang="scss" scoped>
.ApplicationLoginOtp {
  position: relative;
  top: $header-height;
  margin-bottom: 3rem;
  padding-bottom: 3rem;

  .content {
    margin: 0 auto;
    padding: 30px;

    &.container {
      padding: 40px 15px 15px;
    }
    h2 {
      &.code-msg {
        padding: 1rem 0 2rem;
        font-weight: $text-medium;
      }
    }
    .no-code {
      padding: 20px 30px 10px;
      .resendCode {
        padding: 0;
      }
    }
    .typed-email-headline {
      color: $color-red;
      line-height: normal;
    }
    .code-form {
      width: 100%;
      .code-input {
        @include vuetify-input;
        width: 100%;
        display: inline-block;
        max-width: 230px;
        margin: 0 20px 0 0;
        &:disabled {
          border-color: $color-red;
          cursor: not-allowed;
          &:focus {
            box-shadow: 0 0 0.5rem 0.2rem rgba($color-red, 0.24);
          }
        }
      }
      button {
        top: -3px;
        position: relative;
        margin: 0;
        &.disabled {
          border-color: $color-grey-60;
          background-color: $color-grey-60;
          cursor: not-allowed;
          &:hover,
          &:focus {
            border-color: $color-grey-60;
            background-color: $color-grey-60;
          }
        }
      }

      &__label {
        @include vuetify-label;
      }
    }
    & ::v-deep .btn-link {
      margin: 0;
    }
  }
  .incorrect-code {
    display: block;
    padding-left: 35px;
    position: relative;
    top: 20px;
    color: $color-red;
    .icon {
      position: absolute;
      font-size: 24px;
      left: 0;
      top: -1px;
    }
    .btn-link {
      color: $color-red;
      padding: 0;
      &:hover {
        color: $color-primary;
      }
    }
  }
  .fade-enter-active {
    transition: all 0.8s ease-in;
  }
  .fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter {
    transform: translateY(-5px);
  }
  .fade-leave-to {
    transform: translateY(5px);
  }
}
@include tablet {
  .ApplicationWelcomeOtp {
    .typed-email-headline {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
@include mobile {
  .content {
    .typed-email-headline {
      line-height: normal;
    }
    .code-form {
      display: block;
      width: 100%;
      .code-input.form-control {
        max-width: 100%;
        margin: 0 0 15px;
      }
      button.btn {
        top: 0;
        width: 100%;
      }
    }
    .verify-code-row {
      .typed-email-headline {
        overflow-wrap: break-word;
        word-wrap: break-word;
      }
    }
    .no-code {
      padding: 10px 30px 0;
    }
  }
}
</style>
