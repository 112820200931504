var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible(_vm.field, _vm.application))?_c('div',{class:'ApplicationFieldCollection' +
    (_vm.getHeadingAdd(_vm.field, _vm.application) ? '' : ' no-footer'),attrs:{"id":_vm.field.elemId}},[(_vm.field.description)?_c('h2',[_vm._v(" "+_vm._s(_vm.getDescription(_vm.field, _vm.application))+" "),(_vm.field.note)?_c('span',{staticClass:"smallMessage"},[_vm._v(_vm._s(_vm.field.note))]):_vm._e(),(_vm.field.descriptionList)?_c('ul',{staticClass:"smallMessage"},_vm._l((_vm.field.descriptionList(_vm.field, _vm.application)),function(item){return _c('li',{key:item,domProps:{"innerHTML":_vm._s(item)}})}),0):_vm._e()]):_vm._e(),(_vm.field.collapsible)?_c('button',{staticClass:"collapse-all-button",class:{ 'collapse-all-button--active': _vm.anyPanelsExpanded },on:{"click":function($event){_vm.anyPanelsExpanded ? _vm.collapseAll() : _vm.expandAll()}}},[_vm._v(" "+_vm._s(_vm.anyPanelsExpanded ? 'Collapse all' : 'Expand all')+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),_c(_vm.field.collapsible ? 'v-expansion-panels' : 'div',_vm._b({tag:"component",model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},'component',{ multiple: _vm.field.collapsible },false),_vm._l((_vm.collection),function(collectionItem,index){return _c(_vm.field.collapsible ? 'v-expansion-panel' : 'div',{key:_vm.field.apiKey + index,tag:"component",staticClass:"collectionItemFields"},[_c('ApplicationFieldCollectionHeading',{ref:"collectionHeading",refInFor:true,attrs:{"field":_vm.field,"application":_vm.application,"collection-item":collectionItem,"collection-item-index":index,"collection-size":_vm.collection.length},on:{"remove":function($event){return _vm.remove(collectionItem, index, _vm.application, _vm.field, _vm.section)}}}),_c(_vm.field.collapsible ? 'v-expansion-panel-content' : 'div',{tag:"component"},_vm._l((_vm.field.fields(
            collectionItem,
            index,
            _vm.$store
          )),function(collectionItemField){return _c('div',{key:collectionItemField.apiKey},[(collectionItemField.type == 'COLLECTION')?_c('ApplicationFieldCollection',{attrs:{"section":_vm.section,"field":collectionItemField}}):(
              collectionItemField.type == 'HEADING' &&
              _vm.isVisible(collectionItemField, _vm.application)
            )?_c('ApplicationFieldHeading',{attrs:{"field":collectionItemField,"section":_vm.section}}):(
              _vm.isVisible(collectionItemField, _vm.application) && !_vm.isReviewing
            )?_c('ApplicationField',{attrs:{"field":collectionItemField,"section":_vm.section}}):_vm._e()],1)}),0)],1)}),1),((_vm.getAlert().type || _vm.field.newItem) && !_vm.isReviewing)?_c('div',{class:'collectionFooter' +
      this.alertContainerCssClasses +
      (_vm.field.headingAddFull ? ' full' : '')},[(_vm.field.newItem && !_vm.field.hideAddButton)?_c('button',{staticClass:"add",class:{
        bold: _vm.field.showLine,
        primary: !_vm.field.headingAddOutline,
        'btn-outline-primary': _vm.field.headingAddOutline
      },attrs:{"type":"button"},on:{"click":_vm.add}},[_vm._v(" + "+_vm._s(_vm.getHeadingAdd(_vm.field, _vm.application))+" ")]):_vm._e()]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }