<template>
  <div class="ErrorContent container">
    <div class="error__heading">
      <slot name="heading">
        <p class="heading__error-code">
          <slot name="error-code">
            <template v-if="errorCode && !isFromResumeRoute">
              Error Code: {{ errorCode }}
            </template>
          </slot>
        </p>
        <p class="heading__error-id">
          <slot name="error-id">
            <template v-if="errorId && !isFromResumeRoute">
              Error ID: {{ errorId }}
            </template>
          </slot>
        </p>
      </slot>
    </div>
    <div v-if="!isFromResumeRoute" class="error__body">
      <slot name="body">
        <p>
          {{
            errorText ||
            'An error has occurred and we’re trying to fix the problem.'
          }}
        </p>
      </slot>
    </div>
    <div class="error__footer">
      <slot name="footer">
        <div v-if="!fromEhub">
          <p>
            Please
            <a
              href="https://enrolmentassist.freshdesk.com/support/tickets/new"
              rel="external noopener"
              target="_blank"
              >contact our support team</a
            >
            for further assistance.
          </p>
        </div>
        <div v-else-if="!isFromResumeRoute">
          <p>
            Please
            <a class="error__link" href="/dashboard">return to the dashboard</a>
            to continue.
          </p>
        </div>
      </slot>
    </div>
    <pre
      class="error__stack-trace"
      v-if="includeStackTrace && stackTrace && !isFromResumeRoute"
      >{{ stackTrace }}</pre
    >
  </div>
</template>

<script>
import ehubUtil from '@/utils/ehub'

export default {
  name: 'ErrorContent',
  props: {
    errorCode: {
      type: String
    },
    errorId: {
      type: String
    },
    errorText: {
      type: String
    },
    includeStackTrace: {
      type: Boolean,
      default: false
    },
    stackTrace: {
      type: String
    },
    previousRoute: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    fromEhub() {
      return ehubUtil.isFromEhub()
    },
    isFromResumeRoute() {
      if (this.previousRoute && 'name' in this.previousRoute) {
        return this.previousRoute.name === 'EHubResume'
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ErrorContent {
  .error {
    &__heading {
      margin-bottom: 2rem;
    }

    &__heading,
    &__heading * {
      font-weight: bold;
      color: $color-primary;
    }

    &__body {
      margin-bottom: 2rem;
    }

    &__heading,
    &__heading *,
    &__body,
    &__body *,
    &__footer,
    &__footer * {
      font-size: 2rem;
    }

    &__stack-trace {
      border: 1px solid $color-grey-15;
      border-radius: $card-border-radius;
      color: $color-primary;
      background-color: white;
      padding: 1.6rem 1.6rem 0.6rem;
      margin-top: 3rem;
      font-size: $text;
    }

    &__link {
      &:focus,
      &:active {
        box-shadow: 0 0 0 1px black;
      }
    }
  }
}
</style>
