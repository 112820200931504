import { render, staticRenderFns } from "./APIError.vue?vue&type=template&id=5a8338f3&scoped=true&"
import script from "./APIError.vue?vue&type=script&lang=js&"
export * from "./APIError.vue?vue&type=script&lang=js&"
import style0 from "./APIError.vue?vue&type=style&index=0&id=5a8338f3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a8338f3",
  null
  
)

export default component.exports