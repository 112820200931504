<template>
  <div v-if="swsFormData && swsFormData.isLandFromSwsSite">
    <loading-view
      data-testid="ehub-welcome-spinner"
      loading-text="processing, one moment..."
    />
  </div>
  <div v-else>
    <ApplicationEhubWelcome
      ref="ehubWelcome"
      @proceed="startApp"
      @cancel="cancelApp"
      :loading="loading"
    />
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode'

import ApplicationEhubWelcome from '@/components/application/ApplicationEhubWelcome'
import ehubUtil from '@/utils/ehub'
import { API_ERROR_SUBTYPES, ENROLMENT_TYPES } from '@/constants'
import LoadingView from '@/components/app/FullScreenLoader'
import {
  ehubPrefill,
  removeSrnFromPrefillData
} from '@/applicationDefinition/helper'

export default {
  name: 'EHubNew',
  components: { ApplicationEhubWelcome, LoadingView },
  data() {
    return {
      loading: true,
      swsFormData: null
    }
  },
  created() {
    this.swsFormData = ehubUtil.getFormData()
  },
  mounted() {
    this.createApp()
      .then(() => {
        this.loading = false
        if (this.swsFormData && this.swsFormData.isLandFromSwsSite) {
          this.startApp()
        }
      })
      .catch(() => {
        this.$router.history.push('/error')
      })
  },
  methods: {
    async createApp() {
      const { idToken, refreshToken, schoolData, formData, isSCTApp } =
        ehubUtil.getNewApplicationData()
      if (!idToken || !refreshToken) {
        this.$router.history.push('/error')
        return
      }

      this.$store.commit('set', ['auth.isUserActive', true])
      this.$store.commit('set', ['auth.isUserActiveTime', new Date().getTime()])

      this.setTokens(idToken, refreshToken)
      await this.setAppData(schoolData, formData, idToken, isSCTApp)
    },
    setTokens(idToken, refreshToken) {
      this.$store.commit('set', ['auth.idToken', idToken])
      this.$store.commit('set', ['auth.idTokenTime', new Date().getTime()])
      this.$store.commit('set', ['auth.refreshToken', refreshToken])
      this.$store.commit('set', ['auth.userId', jwtDecode(idToken).email])
    },
    async setAppData(schoolData, formData, idToken, isSCTApp) {
      const catchmentLevel =
        schoolData['catchment_level'] || schoolData['catchmentLevel']
      const schoolCode = schoolData['school_code'] || schoolData['schoolCode']
      const school = await this.$store.dispatch('getSchool', String(schoolCode))

      const residentialAddress = formData.address
      const calendarYear = formData.calendarYear
      const scholasticYear = formData.scholasticYear
      const student = formData.child
        ? {
            firstName: formData.child.firstName,
            familyName: formData.child.lastName
          }
        : null
      //if a student from ehub with a SRN, it should be a ehub-linked-Student. we will use this flag to call the prefill application by SRN API
      let isEhubLinkedStudent = !!(
        formData.child &&
        formData.child.srn &&
        formData.child.srn !== 'new'
      )

      // this flag is used to call prefill API where an application has been submitted for the student but no SRN has been created for the student.

      const hasStudentNames = student && student.firstName && student.familyName

      const decodedToken = jwtDecode(idToken)
      const parentId = decodedToken['custom:pid']
      const emailFromToken = decodedToken['email']
      const studentSrn = formData.child && formData.child.srn
      const enrolmentType = isSCTApp ? ENROLMENT_TYPES.CORE_SCT : null

      let application = {
        catchmentLevel,
        meta: {
          calendarYear,
          isEhubLinkedStudent,
          viaEhub: true,
          parentId,
          studentSrn
        },
        residentialAddress,
        scholasticYear: scholasticYear,
        schoolCode: school.schoolCode,
        schoolName: school.schoolName,
        student,
        isEhubLinkedStudent,
        viaEhub: true,
        enrolmentType
      }

      if (isSCTApp) {
        application.isInArea = formData.inCatchment
      }

      if (formData && formData.prevAttendSchool === true) {
        application.prevAttendSchool = formData.prevAttendSchool
        application.prevSchoolId = formData.prevSchoolId
        application.prevSchoolType = formData.prevSchoolType
      }

      let prefillAPIData
      // if the student is a linked one from EHUB, populate fields with ERN data
      if (isEhubLinkedStudent) {
        //Google Analytics
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'Prefill Details',
          action: 'form start',
          label: 'student and parent',
          application: ehubUtil.getGtmApplication()
        })
        prefillAPIData = await this.$store.dispatch(
          'getPrefillDataBySRN',
          studentSrn
        )
      } else if (hasStudentNames) {
        //for new child, pass 0 as srn, the API will only return parent data
        //Google Analytics
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'Prefill Details',
          action: 'form start',
          label: 'parent',
          application: ehubUtil.getGtmApplication()
        })
        prefillAPIData = await this.$store.dispatch(
          'getPrefillDataByNames',
          student
        )
        // if the student is not a linked student, removed the SRN
        removeSrnFromPrefillData(prefillAPIData)
      } else {
        //for new child
        prefillAPIData = await this.$store.dispatch(
          'getPrefillDataForNewStudent'
        )
      }

      application = ehubPrefill(application, prefillAPIData, emailFromToken)
      this.$store.commit('set', ['isNewApplication', true])
      this.$store.commit('set', ['application', application])
      this.$store.commit('set', ['school', school])
      return application
    },
    startApp() {
      this.$store
        .dispatch('cancelApplication')
        .then(() => {
          this.$store
            .dispatch('createApplication')
            .then(() => {
              //Google Analytics
              this.$gtm.trackEvent({
                event: 'interaction',
                category: 'Form Attempt',
                action: 'start',
                label: 'core',
                application: ehubUtil.getGtmApplication()
              })
              this.$router.history.push('/application')
            })
            .catch(() => {
              this.$store.dispatch('hideSpinner')
              this.$router.history.push('/error?type=500')
            })
        })
        .catch((error) => {
          //handle API errors
          this.$store.dispatch('setAPIError', {
            error,
            fallbackCode: API_ERROR_SUBTYPES.proceedApplication
          })
          this.$router.history.push('/error')
        })
    },
    cancelApp() {
      window.location.href = `${window.location.origin}/enrol/new`
    }
  }
}
</script>
