<template>
  <div class="CancelView">
    <ApplicationBanner :content="'<h1>Year 7 placement offer declined</h1>'" />
    <b-container>
      <b-row>
        <div class="Content">
          <p>
            <strong
              >You have declined the year 7 placement offer from
              {{ schoolName }}.</strong
            >
          </p>
          <p>
            The school has been notified, and a confirmation email has been sent
            to <strong>{{ parentEmail || 'your registered email' }}</strong
            >.
          </p>
          <RestartAppActions
            :school-code="schoolCode"
            :catchment-level="catchmentLevel"
          />
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import RestartAppActions from '@/components/app/RestartAppActions'

export default {
  name: 'Y67TDeclineView',
  props: {
    parentEmail: {
      type: String,
      default: null
    }
  },
  components: {
    ApplicationBanner,
    RestartAppActions
  },
  data() {
    const schoolData = this.$store.state.school
    return {
      schoolName: schoolData.schoolName || '',
      schoolCode: schoolData.schoolCode || '',
      catchmentLevel: schoolData.catchmentLevel || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.CancelView {
  height: 100%;
  .ApplicationBanner {
    margin-bottom: 10rem;
  }
  ::v-deep .container {
    max-width: 97rem;
  }
  .Content {
    margin: 2rem auto;
    padding: 2rem 2rem;
    p,
    span {
      font-size: 2rem;
    }
    span {
      margin: 1rem;
    }
  }
}

@include desktop {
  .CancelView {
    .ApplicationBanner {
      height: 20.4rem;
      max-height: 20.4rem;
      .container {
        max-width: 97rem;
      }
    }
    .Content {
      max-width: 100rem;
      margin: 0 auto;
    }
  }
}

@include tablet-wide {
  .CancelView {
    .ApplicationBanner {
      height: 20.4rem;
      max-height: 20.4rem;
      ::v-deep .container {
        max-width: 72rem;
      }
    }
    .error-row {
      max-width: 94rem;
    }
  }
  .Content {
    max-width: 72rem;
    span {
      display: block;
    }
  }
}

@include tablet {
  .CancelView {
    .ApplicationBanner {
      height: 12.2rem;
      max-height: 12.2rem;
      ::v-deep .container {
        max-width: 54rem;
      }
    }

    .Content {
      padding-top: 2rem;
      max-width: 54rem;
      span {
        display: block;
      }
      & ::v-deep h1 {
        font-size: 3.2rem;
      }
    }
  }
}

@include mobile {
  .CancelView {
    .Content {
      padding: 2rem;
      max-width: 42rem;
      span {
        display: block;
      }
      & ::v-deep h1 {
        font-size: 2.6rem;
      }
    }
  }
}
</style>
