import { NONE_OPTION } from '@/constants'

export function isSupportForLearningRequired(application) {
  if (
    application.hasPrevLearningSupportPlan ||
    application.areThereModifiStratUsedHome
  ) {
    return true
  }

  const hasSelection = (selections) => {
    if (selections && selections.length) {
      return selections[0] !== NONE_OPTION.value
    }
    return false
  }

  return (
    hasSelection(application.learningAndSupport.learningSupportNeeds) ||
    hasSelection(application.learningAndSupport.learningAdjustments)
  )
}
