<!--
Validates addressLine1 and expands it into other fields
-->
<template>
  <fieldset class="AppAddressFinder error-style">
    <legend class="fieldLabel">
      <span
        class="required-mark"
        aria-hidden="true"
        v-if="getRequired(field, application)"
        >*</span
      >
      <span v-if="field.label">{{ getLabel(field) }}</span>
      <span v-else-if="vHtmlLabel" v-html="vHtmlLabel"></span>
    </legend>

    <div
      v-if="disabled"
      tabindex="0"
      class="boxGrey"
      :aria-required="required"
      aria-disabled="true"
    >
      {{ getAddressString() }}
    </div>

    <div v-else>
      <div class="ApplicationField">
        <label class="fieldLabel" :for="field.apiKey + '-country'"
          >Country</label
        >

        <select
          title="Country"
          :id="field.apiKey + '-country'"
          v-model="country"
          @change="setCountry"
          :aria-required="required"
        >
          <option
            :disabled="item.disabled"
            :value="item.value"
            :key="item.value + index"
            v-for="(item, index) in countries"
          >
            {{ item.text }}
          </option>
        </select>
      </div>

      <div class="ApplicationField">
        <!-- Australian Address Inputs -->
        <div v-if="country === 'AUS'" class="inputGroup">
          <label class="fieldLabel" :for="field.apiKey + '-street'"
            >Street</label
          >

          <div class="fieldContent fieldText">
            <TypeAhead
              :id="field.apiKey + '-street'"
              :required="required"
              min-length="4"
              max-length="100"
              uppercase="true"
              :options="optionList"
              :value="auAddress.street"
              @blur="onAutoCompBlur"
              @search="findAddress"
              @select="onSelect"
              :aria-described-by="field.apiKey + '-street-hint-text'"
            />

            <div class="smallMessage" :id="field.apiKey + '-street-hint-text'">
              {{
                withPoBox
                  ? 'e.g. 10 High Street or PO Box 100'
                  : 'e.g. 10 High Street'
              }}
            </div>
          </div>

          <b-container class="addressFields">
            <b-row>
              <b-col lg="6">
                <div class="fieldContent">
                  <label class="fieldLabel" :for="field.apiKey + '-suburb'"
                    >Suburb</label
                  >
                  <input
                    type="text"
                    maxLength="100"
                    v-model="auAddress.suburbName"
                    @change="onAuAddressChange"
                    :id="field.apiKey + '-suburb'"
                    :aria-required="required"
                    :aria-describedby="field.apiKey + '-suburb-hint-text'"
                  />
                  <div
                    class="smallMessage"
                    :id="field.apiKey + '-suburb-hint-text'"
                  >
                    e.g. Sydney
                  </div>
                </div>
              </b-col>
              <b-col lg="3">
                <div>
                  <label class="fieldLabel" :for="field.apiKey + '-state'"
                    >State</label
                  >
                  <select
                    v-model="auAddress.stateCode"
                    @change="onAuAddressChange"
                    :id="field.apiKey + '-state'"
                    :aria-required="required"
                  >
                    <option
                      :value="state.value"
                      :key="state.value"
                      v-for="state in states"
                    >
                      {{ state.value }}
                    </option>
                  </select>
                </div>
              </b-col>
              <b-col lg="3">
                <div>
                  <label class="fieldLabel" :for="field.apiKey + '-postcode'"
                    >Postcode</label
                  >
                  <input
                    :aria-required="required"
                    type="text"
                    maxlength="4"
                    inputmode="decimal"
                    v-model="auAddress.postCode"
                    @keydown="isNumberKey"
                    @change="onAuAddressChange"
                    :id="field.apiKey + '-postcode'"
                  />
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <!-- Foreign Address Inputs -->
        <div class="inputGroup" v-else-if="country !== 'AUS' && country !== ''">
          <label class="fieldLabel">Address</label>

          <div class="fieldContent fieldContent-withMarginBottom fieldText">
            <input
              :aria-required="required"
              type="text"
              placeholder="Address line 1: Start typing here"
              maxLength="100"
              v-model="foreignAddress1"
              @blur="setValue"
              aria-label="Address line 1"
            />
          </div>

          <div class="fieldContent fieldText">
            <input
              type="text"
              placeholder="Address line 2"
              maxLength="100"
              v-model="foreignAddress2"
              @blur="setValue"
              aria-label="Address line 2"
            />
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import _unset from 'lodash/unset'

import { ADDRESS_VALIDATION, REFDATA_MAP } from '@/constants'

import endPoint from '@/store/endPoint'

import {
  getAddressLine1,
  isNumberKey,
  getAddressStringFromLine1
} from '@/applicationDefinition/helper'

import { STATES } from '@/applicationDefinition/droplistData/states'
import { dropDownSelect } from '@/applicationDefinition/droplistData/helper'

import TypeAhead from './TypeAhead.vue'
import fieldsMixin from '@/components/mixins/fieldsMixin'

import _uniqBy from 'lodash/uniqBy'

export default {
  name: 'AppAddressFinder',
  props: {
    id: String,
    field: Object,
    section: Object,
    value: Object,
    label: String,
    vHtmlLabel: [String, Boolean],
    disabled: Boolean,
    required: [Boolean, Function],
    withPoBox: Boolean //To control the API endpoint with/without PoBoxes and the hint text for street addresses
  },
  components: {
    TypeAhead
  },
  mixins: [fieldsMixin],
  computed: {
    countries() {
      return dropDownSelect(this.getReferenceDataSet(REFDATA_MAP.COUNTRIES))
    },
    application() {
      return this.$store.state.application || {}
    }
  },
  data() {
    let address = this.value || {}

    return {
      states: STATES,
      country: address.countryCode || '',
      address: address.addressLine1 || '',
      auAddress: {
        street: address.street || '',
        suburbName: address.suburbName || '',
        stateCode: address.stateCode || '',
        postCode: address.postCode || '',
        validationFlag: address.validationFlag || ADDRESS_VALIDATION.UNKNOWN
      },
      isDirty: null,
      foreignAddress1: address.foreignAddress1 || '',
      foreignAddress2: address.foreignAddress2 || '',
      optionListApi: [],
      optionList: [],
      skipValidation: false
    }
  },
  watch: {
    value: {
      handler(newValue) {
        // If we're clearing the address in VueX clear it in this component too.
        // Bug fix for FUS-970, when the first doctor is removed, the address of the 2nd doctor should be the new first doctor's address
        if (newValue.addressRecordNo == null) {
          this.country = newValue.countryCode || ''
          this.address = newValue.addressLine1 || ''
          this.auAddress = {
            street: newValue.street || '',
            suburbName: newValue.suburbName || '',
            stateCode: newValue.stateCode || '',
            postCode: newValue.postCode || '',
            validationFlag:
              newValue.validationFlag || ADDRESS_VALIDATION.UNKNOWN
          }
          this.foreignAddress1 = newValue.foreignAddress1 || ''
          this.foreignAddress2 = newValue.foreignAddress2 || ''
        }
      },
      deep: true
    }
  },
  methods: {
    isNumberKey,
    setCountry() {
      // #DSE-1245: The requirements and time crunch for v1.1 have meant it's
      // been necessary to introduce the concept of 'skipping' validation for
      // the ADDRESS field based on swapping between the Country field. This is
      // super messy and a quick fix. Need to refactor / rewrite the
      // validation/buildAlerts code.

      this.skipValidation = true
      this.setValue()
      this.skipValidation = false

      _unset(
        this,
        `$store.state.application.${this.field.apiKey}.skipValidation`
      )
    },
    setValue() {
      // Set Australian address
      if (this.country === 'AUS') {
        let addressObj = {
          addressLine1: this.auAddress.street,
          countryCode: this.country,
          street: this.auAddress.street,
          suburbName: this.auAddress.suburbName,
          stateCode: this.auAddress.stateCode,
          postCode: this.auAddress.postCode,
          skipValidation: this.skipValidation
        }

        // addressSelected is set to false when users start change the input
        // values of street, suburb, state and postCode
        if (!this.addressSelected) {
          addressObj.validationFlag = ADDRESS_VALIDATION.UNKNOWN
          this.$emit('change', addressObj)
        } else if (this.addressSelected) {
          addressObj.validationFlag = ADDRESS_VALIDATION.VALID
          this.$emit('change', addressObj)
        }
        // Set a foreign address
      } else if (this.country !== 'AUS' && this.country !== '') {
        this.$emit('change', {
          addressLine1: this.foreignAddress1,
          addressLine2: this.foreignAddress2,
          countryCode: this.country,
          foreignAddress1: this.foreignAddress1,
          foreignAddress2: this.foreignAddress2,
          skipValidation: this.skipValidation
        })
      } else {
        this.$emit('change', {})
      }
    },
    onAuAddressChange() {
      this.addressSelected = false
      this.setValue()
    },
    onAutoCompBlur(val) {
      // Only continue if the value has changed - this prevents invalidating an
      // address already validated by the API
      if (val && val === this.auAddress.street) return

      // Handle receiving an empty value
      if (val === '') {
        this.optionList = this.optionListApi = []
      }

      this.auAddress.street = val
      this.onAuAddressChange()
    },
    findAddress(searchString) {
      this.$store.dispatch('showSpinner')
      let self = this
      let getAddresses
      if (this.withPoBox) {
        getAddresses = endPoint.getPredictiveAddressesWithPoBox(searchString)
      } else {
        getAddresses = endPoint.getPredictiveAddresses(searchString)
      }

      getAddresses
        .then((resp) => {
          if (Array.isArray(resp.data)) {
            //NSW Point V2 API does return duplicated addresses strings as multiple datasets are being used. As a result, we need to remove the duplicates and Hemanth is going to ask whether they can be removed at the API level.
            let addressesWithoutDuplicates = _uniqBy(
              resp.data,
              (i) => i.address
            )
            self.optionListApi = addressesWithoutDuplicates
            self.optionList = addressesWithoutDuplicates.map(
              (item) => item.address
            )
          }
          this.$store.dispatch('hideSpinner')
        })
        .catch(() => {
          this.$store.dispatch('hideSpinner')
        })
    },
    onSelect(index) {
      this.$store.dispatch('showSpinner')
      endPoint
        .getFullAddressInfo(this.optionListApi[index])
        .then((resp) => {
          let address = resp.data.data.addressDetails
          let street = getAddressLine1(address)

          this.auAddress.street = street
          this.auAddress.suburbName = address.localityName
          this.auAddress.stateCode = address.stateTerritory
          // #DSE-685: postcode deliberately lower-case due to APIs
          this.auAddress.postCode = address.postcode
          this.auAddress.validationFlag = ADDRESS_VALIDATION.VALID

          // Clear the dropdown list
          this.optionList = []
          this.addressSelected = true

          this.setValue()
          this.$store.dispatch('hideSpinner')
        })
        .catch(() => {
          this.$store.dispatch('hideSpinner')
        })
    },
    getAddressString() {
      return getAddressStringFromLine1(this.value, this.$store)
    }
  }
}
</script>

<style scoped lang="scss">
.AppAddressFinder {
  margin-top: 0.25rem;
  &::v-deep .labelDescription {
    font-size: 1.6rem;
    font-weight: normal;
  }

  .ApplicationField:first-child {
    margin-bottom: 3.5rem;
  }

  .ApplicationField {
    margin: 0 1rem 1rem 1rem;
    .fieldText {
      width: 100%;
    }
  }

  .fieldContent {
    input {
      width: 100%;
    }

    .smallMessage {
      display: inline-block;
      padding-left: 0.25rem;
      padding-top: 0.5rem;
      font-size: 1.4rem;
    }
  }

  .fieldContent-withMarginBottom {
    margin-bottom: 1.5rem;
  }

  .addressFields {
    &.container {
      padding: 0;
      margin-top: 1.5rem;
    }

    select,
    input {
      width: 100%;
    }
  }

  &::v-deep .AppTypeAhead_input {
    width: 100%;
  }

  .alertRow {
    display: flex;
    padding-top: 0.5rem;

    svg {
      color: $color-red;
      position: relative;
      top: -0.5rem;
      height: 3em;
    }

    .alertMessage {
      display: inline-block;
      padding-left: 0.25rem;
      font-size: 1.4rem;
      color: $color-red;
    }
  }
}

.boxGrey {
  background: $color-grey-10;
  padding: 1rem;
  border-radius: 0.2rem;
  color: $color-primary;
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 500;
}
</style>
