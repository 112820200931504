<template>
  <v-card color="warning" class="pa-6">
    <!-- Create an v-expansion-panel with a text area inside it -->
    <v-btn block @click="prefillApplication">Prefill application data</v-btn>
    <v-expansion-panels class="mt-6">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Change application object
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-textarea
            :value="JSON.stringify(application, null, 2)"
            label="Application object"
            hint="This is the application object that will be used to prefill the application"
            persistent-hint
            outlined
            @input="application = JSON.parse($event)"
            rows="30"
          ></v-textarea>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header> Notes </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            This is a little bit broken but it works for now. There is some
            oddness with the process here I can't afford to fix in this
            unrelated ticket.
          </p>
          <p>
            There are some randomised features here like student name, feel free
            to extend this random filling if you like.
          </p>
          <p>
            Please feel free to use and update with new model features, cheers.
          </p>
          <p><strong>How to use:</strong></p>
          <ul>
            <li>
              Alter the object in the expansion panel and click prefill the
              application. Then you should be on the supporting documents page
            </li>
            <li>Click 'Save & continue'.</li>
            <li>Finally refresh the page and 'resume' the application.</li>
          </ul>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { createDraftApplicationId } from '@/applicationDefinition/helper'
export default {
  name: 'DevTools',
  data() {
    return {
      application: {
        schoolCode: '4056',
        schoolName: 'Rooty Hill Public School',
        catchmentLevel: 'primary',
        meta: {
          hasReturnAustralia: false,
          isAnaphylactic: false,
          isNonAnaphylactic: false,
          isOtherMedicalCondition: false,
          sectionExpandedId: 'documents',
          visitedSections: [
            'student',
            'sibling',
            'risk',
            'learning',
            'medical',
            'parents',
            'emergency',
            'additional',
            'declaration'
          ],
          additionalEmergencyContacts: ['yes'],
          hasAdditionalPostalAddress: false,
          toAddMoreParent: false,
          calendarYear: 2024,
          lastSavedAsVersion: '8.3.0'
        },
        student: {
          ...this.nameGenderGenerator(),
          dateOfBirth: '2019-04-04',
          medicare: [
            {
              expiryDate: '2029-01-01',
              number: '1234 12344 1',
              cardRef: '1'
            }
          ],
          countryBorn: 'AUS',
          isAboriTorStraitIslander: '4',
          homeLangOtherThanEnglish: false
        },
        residentialAddress: {
          addressLine1: 'ROOTY HILL HIGH SCHOOL 50 NORTH PDE',
          suburbName: 'ROOTY HILL',
          stateCode: 'NSW',
          postCode: '2766',
          countryCode: 'AUS',
          validationFlag: 'V'
        },
        scholasticYear: 'K',
        intendedStartDate: '2024-01-01',
        prevAttendSchool: false,
        hasAttendedPreSchool: false,
        hasAnySiblings: false,
        residencyStatus: 'AUC',
        anyAdditionalInforPriorEnrol: false,
        isSupportForLearningRequired: false,
        hasPrevLearningSupportPlan: false,
        riskOfAnyTypeToStudentsORStaff: false,
        anyHistoryViolentBehaviour: false,
        hasOtherIncident: false,
        permissionToPublish: false,
        permissionToOnlineServices: true,
        isInArea: true,
        parentCarers: [
          {
            collectionItemUniqueId: 1,
            isEnrolmentOwner: true,
            parentCarerRelation: 'MUM',
            parentCarerTitle: 'Mrs',
            parentCarerFamilyName: 'Nutmeg',
            parentCarerGivenName: 'Like',
            parentCarerGenderCode: 'F',
            parentCarerEmail: 'asdf@asdf.com',
            isParentCarerAboriOrTorres: '4',
            parentCarerOccupationGroup: '1',
            parentCarerSchoolEducationCode: '0',
            parentCarerEducationQualificationCode: '0',
            contactDetails: [
              {
                contactType: 'PHMOB',
                contactValue: '0404 040 404'
              }
            ],
            meta: {
              isCompletingApplication: true
            },
            parentCarerCountryBirth: 'AUS',
            isParentCarerSpeakLangOtherEnglish: false,
            residentialAddress: {
              addressLine1: 'ROOTY HILL HIGH SCHOOL 50 NORTH PDE',
              suburbName: 'ROOTY HILL',
              stateCode: 'NSW',
              postCode: '2766',
              countryCode: 'AUS',
              validationFlag: 'V'
            }
          }
        ],
        learningAndSupport: {
          learningSupportNeeds: ['none'],
          learningAdjustments: ['none']
        },
        emergencyContact: [
          {
            contactFirstName: 'Grant',
            contactFamilyName: 'Little',
            contactRelationship: 'GFA',
            contactDetails: [
              {
                contactType: 'PHMOB',
                contactValue: '0404 040 404'
              }
            ]
          }
        ],
        draftApplicationId: createDraftApplicationId(
          this.$store.state.auth.userId
        )
      }
    }
  },
  methods: {
    nameGenderGenerator() {
      const names = [
        { firstName: 'Adam', genderCode: 'M' },
        { firstName: 'Alex', genderCode: 'M' },
        { firstName: 'Ashley', genderCode: 'F' },
        { firstName: 'Cameron', genderCode: 'M' },
        { firstName: 'Chris', genderCode: 'M' },
        { firstName: 'Drew', genderCode: 'M' },
        { firstName: 'Jamie', genderCode: 'F' },
        { firstName: 'Jessie', genderCode: 'F' },
        { firstName: 'Jordan', genderCode: 'F' },
        { firstName: 'Kris', genderCode: 'M' },
        { firstName: 'Leslie', genderCode: 'F' },
        { firstName: 'Pat', genderCode: 'M' },
        { firstName: 'Ryan', genderCode: 'M' },
        { firstName: 'Stevie', genderCode: 'F' },
        { firstName: 'Taylor', genderCode: 'F' }
      ]

      // generate an array of 15 common last names
      const lastNames = [
        'Smith',
        'Johnson',
        'Williams',
        'Brown',
        'Jones',
        'Miller',
        'Davis',
        'Garcia',
        'Rodriguez',
        'Wilson',
        'Martinez',
        'Anderson',
        'Taylor',
        'Thomas',
        'Hernandez'
      ]

      return {
        ...names[Math.floor(Math.random() * 15)],
        familyName: lastNames[Math.floor(Math.random() * 15)]
      }
    },
    async prefillApplication() {
      await this.$store.dispatch('set', ['application', this.application])
    }
  }
}
</script>
