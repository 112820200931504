<template>
  <div class="ApplicationWelcomeBack">
    <b-container class="content">
      <b-row class="ApplyToEnroll">
        <b-col>
          <b-button variant="primary" type="button" @click="resumeApp"
            >Resume application</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'ApplicationWelcomeBack',
  data() {
    return {
      inputHasFocus: false,
      email: this.curEmail
    }
  },
  props: {
    curEmail: String
  },
  methods: {
    resumeApp() {
      this.$store.commit('set', ['resumeViaEmail', true])
      this.$emit('clicked')
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationWelcomeBack {
  .content {
    position: relative;
    top: 140px;
    .heading-msg {
      h1 {
        padding: 4rem 0;
        text-align: center;
      }
    }
    .row.ApplyToEnroll {
      text-align: center;
    }
  }
}
</style>
