<template>
  <div class="login-options">
    <b-container class="content">
      <ApplicationLoginEmailCard
        @authInitiated="(email) => $emit('authInitiated', email)"
        :is-resume="isResume"
        :initial-email="initialEmail"
        :read-only="readOnly"
      />
      <div class="login-options__card-separator">OR</div>
      <ApplicationLoginSNSWCard :is-resume="isResume" />
    </b-container>
  </div>
</template>

<script>
import ApplicationLoginEmailCard from './ApplicationLoginEmailCard.vue'
import ApplicationLoginSNSWCard from './ApplicationLoginSNSWCard.vue'

export default {
  name: 'ApplicationLoginOptions',
  components: {
    ApplicationLoginEmailCard,
    ApplicationLoginSNSWCard
  },
  props: {
    isResume: {
      type: Boolean,
      default: false
    },
    initialEmail: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.login-options {
  position: relative;
  top: $header-height;
  margin-bottom: 3rem;
  padding-bottom: 3rem;

  &__card-separator {
    color: $color-primary;
    font-weight: 700;
    line-height: 1.2;
    margin: 3rem 0;
    text-align: center;
    font-size: 2.2rem;
  }

  .content {
    margin: 0 auto;

    &.container {
      padding: 40px 15px 15px;
    }
  }
}
</style>
