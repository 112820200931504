export default {
  methods: {
    focusOnFirst: function () {
      if (this.$store.state.application.meta.sectionExpandedId === 'review') {
        return
      }
      const errorSelectors = [
        '.ApplicationField.hasAlert input',
        '.ApplicationField.hasAlert select',
        '.v-expansion-panel.hasAlert button' // review section alert
      ]
      const errorElement = document.querySelector(errorSelectors.join(', '))
      if (errorElement) {
        errorElement.focus()
      } else {
        const focusSelectors = ['input', 'select'] // if no error we dont need to focus on expansion panels
        const focusElement = document.querySelector(focusSelectors.join(', '))
        if (focusElement) {
          focusElement.focus()
        }
      }
    }
  }
}
