<template>
  <div class="LoginView">
    <ApplicationBanner
      v-if="status !== 'auth'"
      :content="'<h1>' + setHeading + '</h1>'"
    />

    <transition name="fade">
      <ApplicationLoginOptions
        v-if="status === 'login'"
        @authInitiated="handleAuthInitiated"
        :is-resume="isResume"
        :initial-email="initialEmail"
        :read-only="readOnly"
      />
      <ApplicationLoginOtp
        v-if="status === 'OTP'"
        :cur-email="curEmail"
        @resetEmail="resetEmail"
      />
      <ApplicationLoginReturn
        v-if="status === 'return'"
        :cur-email="curEmail"
        @clicked="resumeApp"
      />
      <ApplicationLoginTimeout
        v-if="status === 'timeout'"
        @clicked="resumeApp"
      />
    </transition>
  </div>
</template>

<script>
import base64url from 'base64url'

import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import ApplicationLoginOtp from '@/components/application/ApplicationLoginOtp.vue'
import ApplicationLoginReturn from '@/components/application/ApplicationLoginReturn.vue'
import ApplicationLoginTimeout from '@/components/application/ApplicationLoginTimeout.vue'
import ApplicationLoginOptions from '../components/application/ApplicationLoginOptions.vue'

export default {
  name: 'LoginView',
  components: {
    ApplicationBanner,
    ApplicationLoginOtp,
    ApplicationLoginReturn,
    ApplicationLoginTimeout,
    ApplicationLoginOptions
  },
  data() {
    let curEmail = ''
    let curStatus = 'login'
    let isResume = false
    let readOnly = false

    // Return journey: Decode email and send OTP to it
    if (this.$route.query.userId) {
      curEmail = base64url.decode(this.$route.query.userId)
      curStatus = 'return'
      readOnly = true
    }

    // Timeout journey: Return and send OTP to it
    if (this.$route.params.type === 'timeout') {
      curEmail = this.$store.state.auth.userId
      curStatus = 'timeout'
      readOnly = true
    }

    // OAuth journey: Start loading application
    if (this.$route.params.type === 'auth') {
      curStatus = 'auth'
      // get OOA and y67t pending offers
      this.getOffers()
    }

    // Resume route
    if (this.$route.params.type === 'resume') {
      isResume = true
      this.$store.commit('set', ['resumeViaButton', true])
    }

    // Status is either email, OTP or return
    return {
      status: curStatus,
      curEmail: curEmail,
      initialEmail: curEmail,
      isResume,
      readOnly
    }
  },
  computed: {
    setHeading() {
      if (this.status === 'login' && this.isResume) {
        return '<h1>Sign in to online enrolment</h1>'
      } else if (this.status === 'login') {
        return '<h1>Starting a new<br>enrolment application</h1>'
      } else if (this.status === 'OTP') {
        window.document.title = 'Verification | Online Enrolment'
        return '<h1>Please enter your<br />6-digit verification code</h1>'
      } else if (this.status === 'return') {
        return 'Welcome back ' + this.curEmail
      } else if (this.status === 'timeout') {
        return 'Your session has timed out'
      } else {
        return ''
      }
    }
  },
  created() {
    const { schoolCode } = this.$route.query

    if (schoolCode !== undefined) {
      this.$store
        .dispatch('getSchool', schoolCode)
        .then((resp) => {
          if (resp && resp.isError) {
            this.$router.history.push('/error')
          }
        })
        .catch(() => {
          this.$router.history.push('/error')
        })
    } else {
      // Not a valid resume, send to new
      if (
        this.$route.params.type === 'timeout' &&
        this.$store.state.auth.userId === ''
      ) {
        this.status = 'login'
        this.$router.history.push('/login/resume')
        this.readOnly = false
      }
    }
  },
  methods: {
    handleAuthInitiated(email) {
      this.curEmail = email
      this.status = 'OTP'
    },
    resumeApp() {
      this.status = 'login'
      this.isResume = true
      // reset isOoaApplication flag after resume from session timeout
      this.$store.commit('set', ['isOoaApplication', false])
    },
    resetEmail(email) {
      this.status = 'login'
      this.curEmail = ''
      this.readOnly = false
      this.initialEmail = email
    }
  }
}
</script>

<style lang="scss" scoped>
.LoginView {
  z-index: 1;
  position: relative;
  padding: 0;
  width: 100%;
  padding-bottom: 40px;
  min-height: 560px;
}

@include desktop {
  .LoginView {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }

  .content {
    margin: 0 auto;

    & ::v-deep .code-input,
    & ::v-deep .email-input {
      margin: 0 auto 2rem;
      padding: 2.4rem 2rem;
      min-width: 180px;
      border: 1px solid $color-grey-60;
      border-radius: 0.4rem;
    }

    & ::v-deep h1 {
      font-weight: bold;
      font-size: $text-h1;
      line-height: 48px;
    }

    & ::v-deep p {
      margin: 2rem 0;
      font-size: $text;
    }

    & ::v-deep button {
      margin: 1rem auto 1rem;

      &:focus,
      &:hover {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

@include tablet-wide {
  .LoginView {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }
}

@include tablet {
  .LoginView {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }

    .ApplicationWelcomeEmail {
      & ::v-deep .email-form-row {
        .email-form {
          .form-control.email-input {
            max-width: 100%;
          }

          button {
            top: 0;
            left: 50%;
            margin: 0;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

@include mobile {
  .LoginView {
    height: auto;

    .ApplicationWelcomeEmail {
      height: auto;

      .typed-email-headline {
        overflow-wrap: break-word;
        word-wrap: break-word;
      }

      &::v-deep .content.container {
        margin-bottom: 10px;

        button {
          width: 100%;
        }
      }
    }
  }
}

.fade-enter-active {
  transition: all 0.8s ease-in;
}

.fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
</style>
