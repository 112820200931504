import xhr from './xhr'

// Environment variables
const API_BASE = process.env.VUE_APP_API_BASE
const API_REFDATA = process.env.VUE_APP_API_REFDATA
const PARENTID_ENROL = process.env.VUE_APP_PARENTID_ENROL
const EHUB_API_BASE = process.env.VUE_APP_EHUB_API_BASE
const APP_VERSION = process.env.VUE_APP_VERSION

export default {
  getAuthCognitoSession(userId, recaptchaToken) {
    return xhr.post(`${PARENTID_ENROL}/passwordless/initiateAuth`, {
      userId: userId,
      recaptchaToken
    })
  },
  getAuthRefreshToken(userId, refreshToken) {
    return xhr.post(`${PARENTID_ENROL}/passwordless/refreshToken`, {
      userId,
      refreshToken
    })
  },
  postAuthOTP(userId, cognitoSession, otpCode) {
    return xhr.post(`${PARENTID_ENROL}/passwordless/authenticate`, {
      userId: userId,
      cognitoSession: cognitoSession,
      code: otpCode
    })
  },
  getFormData(auth) {
    return xhr.get(`${API_BASE}/applications/self`, {
      headers: {
        Pragma: 'no-cache',
        Authorization: `Bearer ${auth}`
      }
    })
  },
  createApplication(idToken, application) {
    return xhr.put(
      `${API_BASE}/applications/self`,
      { application, isNew: true },
      {
        headers: {
          Pragma: 'no-cache',
          Authorization: `Bearer ${idToken}`
        }
      }
    )
  },
  saveApplication(auth, payload) {
    payload.meta = {
      ...payload.meta,
      lastSavedAsVersion: APP_VERSION
    }
    return xhr.put(
      `${API_BASE}/applications/self`,
      { application: payload },
      {
        headers: {
          Pragma: 'no-cache',
          Authorization: `Bearer ${auth}`
        }
      }
    )
  },
  submitApplication(auth, payload, userConfig) {
    let data = { application: payload }

    if (userConfig) {
      data.userConfig = userConfig
    }

    return xhr.post(`${API_BASE}/applications/self`, data, {
      headers: {
        Pragma: 'no-cache',
        Authorization: `Bearer ${auth}`
      }
    })
  },

  cancelApplication(auth) {
    return xhr.delete(`${API_BASE}/applications/self`, {
      headers: {
        Pragma: 'no-cache',
        Authorization: `Bearer ${auth}`
      }
    })
  },
  // Predictive Addresses -physical Only - 1st step: Send address to API
  getPredictiveAddresses(addressVal) {
    return xhr.get(`${API_BASE}/nswpoint/v2/api/predictive1`, {
      params: {
        address: addressVal,
        addressType: 'physical',
        maxNumberOfResults: 10
      },
      headers: {
        'x-preflight': 'force'
      }
    })
  },
  // Predictive Addresses - PoBox and Physical addresses - 1st step: Send address to API
  getPredictiveAddressesWithPoBox(addressVal) {
    return xhr.get(`${API_BASE}/nswpoint/v2/api/predictive1`, {
      params: {
        address: addressVal,
        maxNumberOfResults: 10
      },
      headers: {
        'x-preflight': 'force'
      }
    })
  },
  // Predictive Addresses - 2nd step: Send chosen address to API, NSW Point V2
  getFullAddressInfo(oItem) {
    return xhr.post(
      `${API_BASE}/nswpoint/v2/api/predictive2`,
      { id: oItem.id },
      {
        headers: { 'x-preflight': 'force' }
      }
    )
  },
  // Catchment residential address API integration
  getLocalSchools(baseUrls, long, lat, catchmentLevel, selectedYear) {
    //NOTE: catchmentLevel will be limited to 'primary' or 'secondary' by app's logic
    let queryString =
      `?q=` +
      `SELECT s.school_code, s.school_name, s.school_email, s.street, s.town_suburb, s.postcode, s.longitude, s.latitude, phone, website, c.calendar_year
      FROM dec_schools_2020 s JOIN catchments_2020 c ON s.school_code = c.school_code `
    if (catchmentLevel === 'primary' || catchmentLevel === 'infants') {
      queryString += `WHERE  (c.catchment_level = 'primary' OR c.catchment_level = 'infants')`
    } else {
      queryString += `WHERE  c.catchment_level = '${catchmentLevel}'`
    }

    if (selectedYear == 0) {
      const currentYear = new Date().getFullYear()
      queryString += ` AND c.calendar_year IN (${selectedYear}, ${currentYear})`
    } else {
      queryString += ` AND c.calendar_year IN (${selectedYear})`
    }
    queryString += ` AND ST_CONTAINS(c.the_geom, ST_SetSRID(ST_Point(${long},${lat}), 4326));`

    const urls = baseUrls.map((url) => url + queryString)
    return xhr.getWithFallbacks(urls)
  },

  getCatchmentAndYearData(baseUrls, schoolCode) {
    const queryString =
      `?q=` +
      `SELECT calendar_year, catchment_level from catchments_2020 where school_code = ${schoolCode};`

    const urls = baseUrls.map((url) => url + queryString)
    return xhr.getWithFallbacks(urls)
  },

  getFutureCatchmentData(baseUrls, schoolCodeList, catchment_level) {
    const queryString =
      `?q=` +
      `SELECT school_code, calendar_year, catchment_level from catchments_2020 where school_code IN (${schoolCodeList.join(
        ','
      )}) and catchment_level = '${catchment_level}' and calendar_year = 0;`

    const urls = baseUrls.map((url) => url + queryString)
    return xhr.getWithFallbacks(urls)
  },

  // Predictive School List
  getPredictiveSchools() {
    return xhr.get(`${API_REFDATA}/all-schools.json`)
  },
  // Pilot Schools API
  getPilotSchools() {
    return xhr.get(`${API_REFDATA}/oes-pilot-schools.json`)
  },
  // Reference Data API
  getReferenceData() {
    return xhr.get(`${API_REFDATA}/pi-reference-data.json`)
  },
  getOESProperties() {
    return xhr.get(`${API_REFDATA}/oes-properties.json`)
  },
  getTermDates() {
    return xhr.get(`${API_REFDATA}/pi-school-term-dates.json`)
  },
  // Scholastic Year API
  getScholasticYears(schoolCode) {
    schoolCode = Number(schoolCode)
    return xhr.get(`${API_BASE}/scholasticYr?schoolCode=${schoolCode}`)
  },
  generateSignedURL({ authToken, file, category, action, path }) {
    const cleanedPath = path ? path.toString().replace(/^\/|\/$/g, '') : null

    return xhr.post(
      `${API_BASE}/application/self/uploads/generateSignedUrl`,
      {
        filename: cleanedPath ? `${cleanedPath}/${file.name}` : file.name,
        filetype: file.type,
        category,
        action
      },
      {
        headers: {
          Pragma: 'no-cache',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
  },
  uploadFile(url, file, progressCallback) {
    const config = {
      onUploadProgress: progressCallback,
      headers: {
        'Content-Type': file.type
      }
    }
    return xhr.put(url, file, config)
  },
  deleteFile(url) {
    return xhr.delete(url)
  },
  // OoA offers
  getOOAOffers(auth, schoolCode) {
    return xhr.get(
      `${API_BASE}/v1/ooa/applications/submitted/${schoolCode}/offered`,
      {
        headers: {
          Pragma: 'no-cache',
          Authorization: `Bearer ${auth}`
        }
      }
    )
  },
  // decline OOA offer
  declineOOAOffer(auth, schoolCode, applicationId) {
    return xhr.put(
      `${API_BASE}/v1/ooa/applications/submitted/self/${schoolCode}/${applicationId}`,
      { applicationStatus: 'Not Accepted' },
      {
        headers: {
          Pragma: 'no-cache',
          Authorization: `Bearer ${auth}`
        }
      }
    )
  },
  // decline Y67T offer
  declineY67TOffer(auth, schoolCode, eoiId) {
    return xhr.put(
      `${API_BASE}/v1/y67t/applications/submitted/self/${schoolCode}/${eoiId}`,
      { action: 'Not Accepted' },
      {
        headers: {
          Pragma: 'no-cache',
          Authorization: `Bearer ${auth}`
        }
      }
    )
  },
  // 6/7 offers
  getY67TOffers(auth, schoolCode) {
    return xhr.get(
      `${API_BASE}/v1/y67t/eoi/submitted/self/${schoolCode}/offered`,
      {
        headers: {
          Pragma: 'no-cache',
          Authorization: `Bearer ${auth}`
        }
      }
    )
  },
  // EHUB
  getErnRecordBySRN(auth, srn) {
    return xhr.get(`${EHUB_API_BASE}/v1/ehub/lookupStudent/${srn}`, {
      headers: {
        Pragma: 'no-cache',
        Authorization: `Bearer ${auth}`
      }
    })
  },

  getPrefillDataBySRN(auth, srn) {
    const encodedURI = encodeURI(
      `${EHUB_API_BASE}/v1/ehub/prefillParentStudent/${srn}`
    )
    return xhr.get(encodedURI, {
      headers: {
        Pragma: 'no-cache',
        Authorization: `Bearer ${auth}`
      }
    })
  },

  getPrefillDataByNames(auth, student) {
    const encodedURI = encodeURI(
      `${EHUB_API_BASE}/v1/ehub/prefillParentStudent?studentName=${student.firstName}_${student.familyName}`
    )
    return xhr.get(encodedURI, {
      headers: {
        Pragma: 'no-cache',
        Authorization: `Bearer ${auth}`
      }
    })
  },

  getPrefillDataByQuery(auth, query) {
    if (query) {
      const encodedURI = encodeURI(
        `${EHUB_API_BASE}/v1/ehub/prefillParentStudent${query}`
      )
      return xhr.get(encodedURI, {
        headers: {
          Pragma: 'no-cache',
          Authorization: `Bearer ${auth}`
        }
      })
    }
    return null
  },

  getPrefillDataForNewStudent(auth) {
    return xhr.get(`${EHUB_API_BASE}/v1/ehub/prefillParentStudent`, {
      headers: {
        Pragma: 'no-cache',
        Authorization: `Bearer ${auth}`
      }
    })
  },

  getGlobalWebContentRef() {
    return xhr.get(`${API_REFDATA}/global-web-content-ref.json`)
  },

  getWebCustomisation(schoolCode) {
    return xhr.get(
      `${API_REFDATA}/webCustomisation/${schoolCode}-web-customisation.json`
    )
  },

  getSchoolSettingsJson(schoolCode) {
    // this api will return an error until the school saves their settings
    // for the first time.
    // use a separate xhr instance to circumvent the default error handling
    return xhr
      .get(
        `${API_REFDATA}/webCustomisation/${schoolCode}-web-customisation.json`,
        {
          headers: { schoolCode }
        }
      )
      .then((response) => {
        return response.data
      })
      .catch(() => {
        // TODO: Probably want to handle the error in future if requireds.
        return null
      })
  }
}
