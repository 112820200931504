<!--
  Provides a spinner covering the UI. Useful for blocking
  the UI while important data is loading. Uses a Vue transition
  for fading in and out.
-->

<template>
  <div aria-live="assertive">
    <transition name="fade">
      <div v-if="isVisible" class="AppSpinner" role="alert" aria-busy="true">
        <font-awesome-icon class="icon" icon="circle-notch" />
        <div class="sr-only">Loading data. Please wait.</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AppSpinner',
  computed: {
    isVisible() {
      return this.$store.state.showSpinner
    }
  }
}
</script>

<style lang="scss" scoped>
.AppSpinner {
  z-index: z('overlay');
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(87, 101, 119, 0.28); // Shades the background slightly
  color: $color-white;
  font-size: 8rem;

  .icon {
    animation: spin 3s infinite linear;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

// Classes auto generated by Vue transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
