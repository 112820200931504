<template>
  <div class="WelcomeView">
    <ApplicationWelcome />
  </div>
</template>

<script>
import ApplicationWelcome from '@/components/application/ApplicationWelcome.vue'

export default {
  name: 'WelcomeView',
  components: {
    ApplicationWelcome
  },
  beforeCreate() {
    //RA: Ensure application is forcibly set to null, to force a reload when user resumes or
    // starts a new application. Data is kept consistent between the school & application in vue store.
    //DG: Ensure school is also set to null as the logic for setting catchmentLevel is dependent on the
    // URL flow and will cause issues if already set.
    this.$store.commit('set', ['school', null])
    this.$store.commit('set', ['application', null])
    // reset resumeViaEmail and resumeViaButton flag
    this.$store.commit('set', ['resumeViaEmail', false])
    this.$store.commit('set', ['resumeViaButton', false])
    // reset isOoaApplication flag
    this.$store.commit('set', ['isOoaApplication', false])
  }
}
</script>

<style lang="scss">
@include desktop {
  .WelcomeView {
    width: 100%;
    height: 100%;
    min-height: 610px;
    text-align: left;
  }
}
</style>
