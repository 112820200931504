<template>
  <div class="PilotView">
    <ApplicationBanner
      content="<h1>Please read and agree<br>to our Privacy Agreement</h1>"
    />
    <Page />
  </div>
</template>

<script>
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import Page from '@/components/pilot/Page.vue'

export default {
  name: 'PilotView',
  components: {
    ApplicationBanner,
    Page
  }
}
</script>

<style lang="scss" scoped>
.PilotView {
  height: 100%;
  .ApplicationBanner {
    height: 218px;
  }
}

@include desktop {
  .PilotView {
    z-index: 1;
    position: relative;
    width: 100%;
    min-height: 400px;

    .content {
      margin: 0 auto;
      padding: 30px;
    }
  }
}

@include tablet-wide {
  .PilotView {
    .content {
      & ::v-deep h1 {
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
}

@include tablet {
  .PilotView {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
      .headline {
        h1 {
          font-size: 28px;
        }
      }
    }
    .content {
      padding-top: 8rem;

      & ::v-deep h1 {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}

@include mobile {
  .PilotView {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }
    .content {
      padding-top: 8rem;

      & ::v-deep .row {
        .container {
          padding: 30px 12px;
        }
      }

      & ::v-deep h1 {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
      }

      & ::v-deep button {
        margin: 1rem auto 1rem;
        width: 100%;
      }
    }
  }
}
</style>
