<template>
  <div class="CancelView">
    <ApplicationBanner
      :content="'<h1>Your application has been cancelled</h1>'"
    />
    <b-container v-if="application">
      <b-row>
        <div class="Content">
          <p>
            The application for
            <strong class="breakWord">{{ studentName }}</strong> has now been
            cancelled.
          </p>
          <p>
            You can begin a new application for {{ schoolName }} below, or go to
            the school finder to locate a different school.
          </p>
          <RestartAppActions
            :school-code="schoolCode"
            :catchment-level="catchmentLevel"
          />
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import RestartAppActions from '@/components/app/RestartAppActions'

export default {
  name: 'CancelView',
  components: {
    ApplicationBanner,
    RestartAppActions
  },
  computed: {
    application() {
      return this.$store.state.application
    },
    school() {
      return this.$store.state.school
    },
    schoolName() {
      if (this.application) return this.application.schoolName
      else if (this.school) return this.school.schoolName
      return ''
    },
    studentName() {
      let firstName
      let familyName
      if (this.application) {
        firstName = this.application.student.firstName || ''
        familyName = this.application.student.familyName || ''
      }
      if (firstName || familyName) {
        return (firstName + ' ' + familyName).trim()
      }
      return 'this student'
    },
    schoolCode() {
      if (this.application) return this.application.schoolCode
      else if (this.school) return this.school.schoolCode
      return ''
    },
    catchmentLevel() {
      if (this.application) return this.application.catchmentLevel
      else if (this.school) return this.school.catchmentLevel
      return ''
    }
  },
  mounted() {
    if (!this.application) {
      this.$store.dispatch('set', [
        'error',
        new Error('No application in progress')
      ])

      this.$router.history.push('/error')
    }
  }
}
</script>

<style lang="scss" scoped>
.CancelView {
  height: 100%;
  .ApplicationBanner {
    margin-bottom: 10rem;
  }
  ::v-deep .container {
    max-width: 97rem;
  }
  .Content {
    margin: 2rem auto;
    padding: 2rem 2rem;
    p,
    span {
      font-size: 2rem;
    }
    span {
      margin: 1rem;
    }
  }
}

@include desktop {
  .CancelView {
    .ApplicationBanner {
      height: 20.4rem;
      max-height: 20.4rem;
      .container {
        max-width: 97rem;
      }
    }
    .Content {
      max-width: 100rem;
      margin: 0 auto;
    }
  }
}

@include tablet-wide {
  .CancelView {
    .ApplicationBanner {
      height: 20.4rem;
      max-height: 20.4rem;
      ::v-deep .container {
        max-width: 72rem;
      }
    }
    .error-row {
      max-width: 94rem;
    }
  }
  .Content {
    max-width: 72rem;
    span {
      display: block;
    }
  }
}

@include tablet {
  .CancelView {
    .ApplicationBanner {
      height: 12.2rem;
      max-height: 12.2rem;
      ::v-deep .container {
        max-width: 54rem;
      }
    }

    .Content {
      padding-top: 2rem;
      max-width: 54rem;
      span {
        display: block;
      }
      & ::v-deep h1 {
        font-size: 3.2rem;
      }
    }
  }
}

@include mobile {
  .CancelView {
    .Content {
      padding: 2rem;
      max-width: 42rem;
      span {
        display: block;
      }
      & ::v-deep h1 {
        font-size: 2.6rem;
      }
    }
  }
}
</style>
