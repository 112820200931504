// See README.md for more info about field configuration

import { FIELD_TYPE } from '@/constants'

import { YES_NO } from './droplistData/boolean'

import { SUSPENSIONS } from './droplistData/suspensions'

import {
  getFirstName,
  getLabelName,
  hasAttendedPrevSchoolVisible
} from './helper'

export default [
  {
    label() {
      return getLabelName(null, 'Risk assessment')
    },
    description(application) {
      const name = getFirstName(application)
      const prefix =
        'The NSW Department of Education has a responsibility to assess and manage any risk of harm to '

      if (
        application.student.firstName === '' &&
        application.student.prefFirstName === ''
      ) {
        return `${prefix} students and staff. This information will help us to manage each student's transition into their new school setting.`
      } else {
        return (
          getLabelName(prefix, name, ', other students and staff. ') +
          getLabelName(
            'This information will help us to manage ',
            name,
            "'s transition into the new school setting."
          )
        )
      }
    },
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'anyHistoryViolentBehaviour',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Does ',
        name,
        ' have any history of violent behaviour?',
        'the student'
      )
    },
    required: true,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'violentBehaviourDetails',
    label: 'Provide details',
    placeHolder: 'Enter details',
    required: true,
    visible(application) {
      return application.anyHistoryViolentBehaviour
    },
    type: FIELD_TYPE.TEXTAREA
  },
  {
    apiKey: 'hasSuspendedorExpelled',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Has ',
        name,
        ' ever been suspended or expelled from any previous school?',
        'the student'
      )
    },
    required: true,
    visible(application) {
      return application.scholasticYear !== 'K'
    },
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'meta.reasonForSuspension',
    label: 'Select the reason/s for suspension or expulsion',
    description:
      'There are many reasons for suspension or expulsion. This list describes serious behaviours that should be disclosed to the enrolling school.',
    required: false,
    type: FIELD_TYPE.CHECKBOX_GROUP,
    visible(application) {
      return (
        hasAttendedPrevSchoolVisible &&
        application.hasSuspendedorExpelled &&
        application.scholasticYear !== 'K'
      )
    },
    options: SUSPENSIONS
  },
  {
    apiKey: 'hasOtherIncident',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Has ',
        name,
        ' been involved in any incidents outside of school that involve serious behaviours?',
        'the student'
      )
    },
    description: 'Serious behaviours may include:',
    descriptionList: [
      'Actual violence to any person',
      'Possession of a weapon or any item used to cause harm or injury',
      'Threats of violence or intimidation of staff, students, or others at the school',
      'Possession or use of illegal drugs'
    ],
    required: false,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'otherIncidentDetails',
    label: 'Provide details',
    placeHolder: 'Enter details',
    required: true,
    type: FIELD_TYPE.TEXTAREA,
    colspan: 2,
    visible(application) {
      return application.hasOtherIncident
    }
  },
  {
    apiKey: 'riskOfAnyTypeToStudentsORStaff',
    label(application) {
      const name = getFirstName(application)
      return (
        getLabelName(
          'Is there anything additional in ',
          name,
          "'s history or circumstances (not already mentioned)",
          'the student'
        ) +
        getLabelName(
          ' which might pose a risk of any type to ',
          name,
          ', other students or school staff?',
          'the student'
        )
      )
    },
    required: true,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'riskOfAnyTypeDetails',
    label: 'Provide details',
    placeHolder: 'Enter details',
    required: true,
    type: FIELD_TYPE.TEXTAREA,
    colspan: 2,
    visible(application) {
      return application.riskOfAnyTypeToStudentsORStaff
    }
  },
  {
    apiKey: 'healthProfHaveKnowledge',
    label:
      'Does a health professional or other relevant body have knowledge of these issues?',
    type: FIELD_TYPE.RADIO,
    visible(application) {
      return application.riskOfAnyTypeToStudentsORStaff
    },
    options: YES_NO
  },

  {
    apiKey: 'healthProfContact',
    type: FIELD_TYPE.COLLECTION,
    name: 'Doctor',
    heading(contactItem, contactItemIndex, application) {
      const name = getFirstName(application)
      return getLabelName(null, name, "'s health professional's details ")
    },
    visible(application) {
      return (
        application.riskOfAnyTypeToStudentsORStaff &&
        application.healthProfHaveKnowledge
      )
    },
    showLine: true,
    alert(application) {
      return !application[this.apiKey].length
        ? 'Doctor details should be present'
        : ''
    },
    fields(doctorItem, doctorIndex) {
      var apiKeyPrefix = `${this.apiKey}[${doctorIndex}]`
      return [
        {
          apiKey: apiKeyPrefix + '.doctorName',
          label: 'Name of health professional',
          placeHolder: 'Enter name',
          type: FIELD_TYPE.NAME,
          maxLength: 132
        },
        {
          apiKey: apiKeyPrefix + '.doctorPhoneNumber',
          label: 'Contact number',
          placeHolder: 'Enter contact number',
          type: FIELD_TYPE.PHONE
        }
      ]
    }
  }
]
