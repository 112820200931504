<template>
  <div
    v-if="application && isVisible({ field, application })"
    class="ApplicationFieldHeading"
  >
    <h3 v-if="field.showLabel" class="ApplicationFieldHeading__title">
      <span v-if="field.required" class="required-mark" aria-hidden="true"
        >*</span
      >
      <span v-if="field.label">{{ getLabel(field, application) }}</span>
      <span
        v-else-if="field.vHtmlLabel"
        v-html="getVHtmlLabel(field, application)"
      ></span>
    </h3>
    <p
      class="description"
      aria-label="description"
      role="heading"
      v-if="getDescription(field, application)"
    >
      {{ getDescription(field, application) }}
    </p>

    <div
      :class="[
        { highlightedDescription: highlightedDescription },
        'description'
      ]"
      v-if="field.vHtmlDescription"
      v-html="getVHtmlDescription(field, application)"
    ></div>

    <ul class="smallMessage" v-if="field.descriptionList">
      <li v-for="item in field.descriptionList" :key="item">{{ item }}</li>
    </ul>
  </div>
</template>

<script>
import fieldsMixin from '@/components/mixins/fieldsMixin'
export default {
  name: 'ApplicationFieldHeading',
  props: {
    field: {
      type: Object
    }
  },
  mixins: [fieldsMixin],
  computed: {
    application() {
      return this.$store.state.application || null
    },
    highlightedDescription() {
      return this.field.highlightedDescription
    }
  },
  methods: {
    getVHtmlDescription(field, application) {
      if (typeof field.vHtmlDescription === 'function') {
        return field.vHtmlDescription(application, this.$store)
      } else if (field.vHtmlDescription) {
        return field.vHtmlDescription
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ApplicationFieldHeading {
  padding: $content-padding;
  &__title {
    font-size: $text-h5;
    font-weight: normal;
    color: $color-primary;
  }
  .highlightedDescription {
    background: $color-grey-10;
    padding: 1rem;
    border-radius: 0.2rem;
    color: $color-primary;
    margin-top: 1rem;
  }
  .required-mark {
    color: $color-red;
  }

  h2 {
    margin: 0;
    padding: 6rem 0 1em 0;
    font-weight: $text-bold;
    font-size: $text-h2;
  }

  p {
    font-size: $text-h6;
  }

  .description,
  .description ::v-deep p {
    font-size: 1.8rem;
  }

  .description ::v-deep .link-outline {
    &:focus {
      outline-style: auto;
    }
  }

  .smallMessage {
    display: block;
    padding-top: 0.5rem;
    font-size: 1.8rem;
  }
}
</style>
