import {
  CHOOSE_DATE,
  DATE_FORMAT,
  EHUB_PREFILL_SECTION_KEYS,
  FIELD_TYPE,
  NONE_OPTION,
  REFDATA_MAP
} from '@/constants'
import moment from 'moment'
import _unset from 'lodash/unset'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { dropDownSelect } from '@/applicationDefinition/droplistData/helper'

export const sanitize = (userText) => {
  // Neutralises any malicious user content by removing '<' chrs if
  // the string contains both '<' and '>' chrs. If there is a valid
  // use-case for user content to contain both these characters, then
  // this function should not be used in these cases.
  if (userText.indexOf('<') >= 0 && userText.indexOf('>') > 0) {
    return userText.replace(/</g, '')
  }
  return userText
}

export const concatNone = (data) => {
  let result = [NONE_OPTION]
  return result.concat(data)
}

export const getAddressString = (data, store) => {
  let countries = store.state.refData[REFDATA_MAP.COUNTRIES]
  if (!data.countryName && data.countryCode) {
    // convert code to name
    const countryName = countries.find(
      (item) => item.value === data.countryCode
    )
    // only assign if successful
    if (countryName) {
      data.countryName = countryName.text
    }
  }
  let result = data.addressLine1 + ' '
  if (data.addressLine2) {
    result = result + data.addressLine2 + ' '
  }
  result =
    result +
    data.suburbName +
    ', ' +
    data.stateCode +
    ', ' +
    data.postCode +
    ', ' +
    data.countryName
  result = String(result.replace(/ {2}/g, ' ')).trim()
  return result
}

export const getAddressStringFromLine1 = (data, store) => {
  // Convert code to name
  let countries = store.state.refData[REFDATA_MAP.COUNTRIES]
  const countryName = countries.find((item) => item.value === data.countryCode)

  // Only assign if successful
  if (data.addressLine1 && countryName && data.suburbName && data.stateCode) {
    return (
      data.addressLine1 +
      ', ' +
      (data.addressLine2 ? data.addressLine2 + ', ' : '') +
      data.suburbName +
      ', ' +
      data.stateCode +
      ', ' +
      data.postCode +
      ', ' +
      countryName.text
    )
  } else if (data.addressLine1) {
    return (
      data.addressLine1 +
      ', ' +
      (data.addressLine2 ? data.addressLine2 + ', ' : '') +
      countryName.text
    )
  } else {
    return ''
  }
}

export const getAddressLine1 = (data) => {
  // The first part of formattedAddressString contains the full street address,
  // e.g. "WATERFRONT TOURIST PARK UNIT 40 18-20 BEACH PARADE"
  return data.formattedAddress.split(',')[0]
}

export const clone = (obj) => {
  var clone = JSON.stringify(obj)
  return JSON.parse(clone)
}

export const purgeEmptyFromObject = (data, isSubmit) => {
  let cloneData = clone(data)
  // purge array first
  if (Array.isArray(cloneData)) {
    cloneData = cloneData.filter((item) => item !== null && item !== undefined)
  }

  if (typeof cloneData === 'object') {
    for (var key in cloneData) {
      if (Object.prototype.hasOwnProperty.call(cloneData, key)) {
        if (
          cloneData[key] === null ||
          cloneData[key] === undefined ||
          cloneData[key] === '' ||
          cloneData[key] === '--01'
        ) {
          delete cloneData[key]
          // recusion for nested objects
        } else if (typeof cloneData[key] === 'object') {
          let isEmpty = true
          cloneData[key] = purgeEmptyFromObject(cloneData[key], isSubmit)
          for (var index in cloneData[key]) {
            // dont count collectionItemUniqueId, severeAllergy as it is auto generated
            // dont count countryCode as it has default value
            if (Object.prototype.hasOwnProperty.call(cloneData[key], index)) {
              if (index !== 'severeAllergy' && index !== 'isEnrolmentOwner') {
                if (!isSubmit) {
                  isEmpty = false
                } else if (
                  index !== 'collectionItemUniqueId' &&
                  index !== 'countryCode'
                ) {
                  isEmpty = false
                }
              }
            }
          }
          if (isEmpty) {
            delete cloneData[key]
          }
        }
      }
    }
    // purge array clean up
    if (Array.isArray(cloneData)) {
      cloneData = cloneData.filter(
        (item) => item !== null && item !== undefined
      )
    }
    return cloneData
  }
}

export const purgeMetaFromObject = (data) => {
  if (typeof data === 'object') {
    // dont mutate param object
    let cloneData = clone(data)
    for (var key in cloneData) {
      if (Object.prototype.hasOwnProperty.call(cloneData, key)) {
        if (key === 'meta') {
          delete cloneData[key]
          // recusion for nested objects
        } else if (typeof cloneData[key] === 'object') {
          cloneData[key] = purgeMetaFromObject(cloneData[key])
        }
      }
    }
    return cloneData
  }
}
export const purgeHiddenFromFields = (fields, application, store) => {
  // dont mutate param object
  application = clone(application)

  if (typeof application === 'object') {
    fields.map((field) => {
      if (field.visible) {
        if (field.apiKey && typeof field.visible === 'function') {
          if (!field.visible(application)) {
            _unset(application, field.apiKey)
          } else if (field.type === FIELD_TYPE.COLLECTION) {
            const curFields = _get(application, field.apiKey)
            if (curFields) {
              curFields.map((curField, index) => {
                application = purgeHiddenFromFields(
                  field.fields(curField, index, store),
                  application
                )
              })
            }
          }
        }
      }
    })
  }
  return application
}

export const clearHiddenFieldsFromSection = (fields = [], application) => {
  // dont mutate param object
  application = clone(application)

  if (typeof application === 'object') {
    fields.map((field) => {
      if (field.visible) {
        if (field.apiKey && typeof field.visible === 'function') {
          if (!field.visible(application)) {
            if (field.newItem && field.type === FIELD_TYPE.COLLECTION) {
              //set the field as new Item without value if a field has newItem prop defined - collection with add functionality such as allergies
              _set(application, field.apiKey, [field.newItem])
            } else if (field.type === FIELD_TYPE.COLLECTION) {
              // collection without add functionality such siblings
              const resetField = clearObjectValues(
                _get(application, field.apiKey)
              )
              _set(application, field.apiKey, resetField)
            } else if (field.type === FIELD_TYPE.CHECKBOX_GROUP) {
              _set(application, field.apiKey, [])
            } else {
              // simple fields
              _set(application, field.apiKey, null)
            }
          } else if (
            field.visible(application) &&
            field.type === FIELD_TYPE.COLLECTION
          ) {
            //clear hidden fields for visible collections such as parentCarers living with students
            const curFields = _get(application, field.apiKey)
            if (curFields) {
              curFields.map((curField, index) => {
                application = clearHiddenFieldsFromSection(
                  field.fields(curField, index),
                  application
                )
              })
            }
            //
          }
        } else if (
          !field.apiKey &&
          field.type === FIELD_TYPE.GROUP &&
          !field.visible(application)
        ) {
          // group fields without apiKey in the field definition
          const groupFields = field.fields()
          groupFields.map((field) => {
            _set(application, field.apiKey, null)
          })
        }
      }
    })
  }
  return application
}
// https://stackoverflow.com/questions/684575/how-to-quickly-clear-a-javascript-object
export const clearObjectValues = (objToClear) => {
  Object.keys(objToClear).forEach((param) => {
    if (
      !!objToClear[param] &&
      objToClear[param].toString() === '[object Object]'
    ) {
      clearObjectValues(objToClear[param])
    } else {
      objToClear[param] = undefined
    }
  })
  return objToClear
}

export const getFirstName = (fields) => {
  return fields.student.prefFirstName
    ? fields.student.prefFirstName
    : fields.student.firstName
}

export const getSchoolName = (fields) => {
  return fields.schoolName
}

export const getStudentDisplayName = (student, defaultText) => {
  const preferredName = student.prefFirstName && student.prefFirstName.trim()
  const firstName = student.firstName && student.firstName.trim()
  const name = preferredName || firstName
  return name || defaultText || 'the student'
}

export const getLabelName = (prefix, field, suffix, alt) => {
  var result = alt || 'student'
  if (!prefix) {
    result = result.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
      return index == 0 ? letter.toUpperCase() : letter.toLowerCase()
    })
  }
  if (field) {
    result = field
  }
  if (!prefix) {
    prefix = ''
  } else {
    prefix += ''
  }
  // reset null suffix
  if (!suffix) {
    suffix = ''
  }

  return prefix + result + suffix
}

export const getHeading = (prefix, field) => {
  const filterUndefinedField = field
    .split(' ')
    .filter((subString) => subString !== 'undefined')
  return field.trim() === '' || filterUndefinedField.length === 0
    ? prefix
    : prefix + ': ' + field
}

export function getContactText(contact) {
  if (contact.contactType === 'EMAIL') {
    return 'Email address'
  } else if (contact.contactType === 'PHMOB') {
    return 'Mobile number'
  } else if (contact.contactType === 'PHWRK') {
    return 'Work number'
  } else if (contact.contactType === 'PHHOM') {
    return 'Home number'
  }
  return 'Contact number'
}
export function getParentContactText(contact) {
  if (contact.contactType === 'EMAIL') {
    return 'Email address'
  } else if (contact.contactType === 'PHMOB') {
    return 'Mobile'
  } else if (contact.contactType === 'PHWRK') {
    return 'Work'
  } else if (contact.contactType === 'PHHOM') {
    return 'Home'
  }
  return null
}

export function getContactNumberFields(
  collectionApiKey,
  contactItem,
  contactItemIndex,
  store
) {
  let apiKeyPrefix = `${collectionApiKey}[${contactItemIndex}].`
  let contactTypes = store.state.refData[REFDATA_MAP.CONTACT_TYPE]
  return [
    {
      apiKey: apiKeyPrefix + 'contactType',
      label: 'Phone number type',
      type: FIELD_TYPE.DROPDOWN,
      required: true,
      options: dropDownSelect(
        contactTypes.filter((item) => item.text !== 'Email')
      )
    },
    {
      apiKey: apiKeyPrefix + 'contactValue',
      label: 'Phone number',
      placeHolder: 'Enter contact number',
      required: true,
      type: getInputType(contactItem.contactType)
    },
    {
      apiKey: apiKeyPrefix + 'comments',
      label: 'Comments',
      placeHolder: 'Enter comments',
      maxLength: 50,
      required: false
    }
  ]

  function getInputType(type) {
    if (type === 'EMAIL') return FIELD_TYPE.EMAIL
    return FIELD_TYPE.PHONE
  }
}

export function getEmergencyNumberFields(
  collectionApiKey,
  contactItem,
  contactItemIndex,
  store
) {
  let apiKeyPrefix = `${collectionApiKey}[${contactItemIndex}].`
  let contactTypes = store.state.refData[REFDATA_MAP.CONTACT_TYPE]
  return [
    {
      apiKey: apiKeyPrefix + 'contactType',
      label: 'Phone number type',
      type: FIELD_TYPE.DROPDOWN,
      required: true,
      // eslint-disable-next-line
      options: dropDownSelect(
        contactTypes.filter((item) => item.text !== 'Email')
      )
    },
    {
      apiKey: apiKeyPrefix + 'contactValue',
      label: 'Phone number',
      placeHolder: 'Enter contact number',
      required: true,
      type: FIELD_TYPE.PHONE
    },
    {
      apiKey: apiKeyPrefix + 'comments',
      label: 'Comments',
      placeHolder: 'Enter comments',
      maxLength: 50,
      required: false
    }
  ]
}

export function getUniqueRequestId() {
  // Source:
  // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

// hide show logic for nested statements, accepts application/form data to process

// used for nested hide show logic
export const hasAttendedPresSchoolVisible = (application) => {
  if (application.scholasticYear === 'K') {
    return true
  }
  return false
}

export const hasAttendedPrevSchoolVisible = (application) => {
  if (
    application.intendedStartDate !== '' &&
    application.scholasticYear !== '' &&
    !hasAttendedPresSchoolVisible(application)
  ) {
    return true
  }
  return false
}

export const monthValueToMonthName = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}
// isNumberKey is used to restrict text input to numbers only for keyboard input
export const isNumberKey = (event) => {
  const key = event.key
  if (
    key !== 'Backspace' &&
    key !== 'Delete' &&
    key !== 'ArrowLeft' &&
    key !== 'ArrowRight' &&
    key !== 'Left' &&
    key !== 'Right' &&
    key !== 'Tab' &&
    !/[0-9]+/.test(key)
  ) {
    event.preventDefault()
  }
}

export function getSchoolNameFromId(prevSchoolId) {
  // Gets school name from prevSchoolId by splitting, e.g.
  // "591|Abercorn State School".split('|')[1]  -->  "Abercorn State School"
  try {
    return prevSchoolId.split('|')[1] || ''
  } catch (e) {
    return ''
  }
}

export function getStartYear(application) {
  // Gets start year from intendedStartDate (or enterStartDate if manual date is entered).
  // A year will only be returned if the whole date is valid.
  try {
    let startDate =
      application.intendedStartDate === CHOOSE_DATE
        ? application.enterStartDate
        : application.intendedStartDate
    return startDate.length === 10
      ? moment(startDate, DATE_FORMAT).year()
      : null
  } catch (e) {
    return null
  }
}

export function isValidDropdownValue(dropdownOptions, selectedValue) {
  return !!dropdownOptions.find((option) => option.value === selectedValue)
}

// helper function to generate ehub prefill flags
export function getEhubPrefillFlags(apiData) {
  if (!apiData || typeof apiData !== 'object') return {}
  return EHUB_PREFILL_SECTION_KEYS.reduce(function (flags, key) {
    flags[key] = !(
      (Array.isArray(apiData[key]) && !apiData[key].length) ||
      !apiData[key]
    )
    return flags
  }, {})
}

//helper function to map ErnParent to show in PI
// export function mapErnParent(ernRecord, parentId) {
//   let parentEmail
//   const emailDetails = ernRecord.parentCarer.contactDetails.filter(
//     c => c.contactType === 'EMAIL'
//   )
//   if (emailDetails) {
//     parentEmail = emailDetails[0].contactValue
//   }
//   const parents = [
//     {
//       parentId: parentId,
//       parentCarerRelation: ernRecord.parentCarer.relation,
//       parentCarerTitle: ernRecord.parentCarer.title,
//       parentCarerFamilyName: ernRecord.parentCarer.familyName,
//       parentCarerGivenName: ernRecord.parentCarer.firstName,
//       contactDetails: ernRecord.parentCarer.contactDetails.filter(
//         c => c.contactType !== 'EMAIL'
//       ),
//       isEnrolmentOwner: ernRecord.parentCarer.liveOnResidentialAddress,
//       parentCarerCountryBirth: ernRecord.parentCarer.countryBirth,
//       isParentCarerAboriOrTorres: ernRecord.parentCarer.isAboriOrTorres,
//       parentCarerOccupationName: ernRecord.parentCarer.occupationName,
//       parentCarerOccupationGroup: ernRecord.parentCarer.occupationGroup,
//       parentCarerSchoolEducationCode: ernRecord.parentCarer.schoolEducationCode,
//       parentCarerEducationQualificationCode:
//         ernRecord.parentCarer.educationQualificationCode,
//       isParentCarerSpeakLangOtherEnglish:
//         ernRecord.parentCarer.speakLanguageOtherThanEnglish,
//       parentCarerMainLanguage: ernRecord.parentCarer.mainLangOtherThanEnglish,
//       parentCarerSpeakLang: ernRecord.parentCarer.otherLanguage,
//       parentCarerEmail: parentEmail,
//       meta: {
//         isCompletingApplication: true
//       },
//       parentCarerGenderCode: ernRecord.parentCarer.gender
//     }
//   ]
//   return parents
// }

export function ehubPrefillApplication(
  applicationData,
  prefillData,
  emailFromToken
) {
  let application
  if (prefillData) {
    application = { ...applicationData, ...prefillData }

    if (prefillData.student) {
      application.student = {
        ...prefillData.student,
        ...applicationData.student
      }
      application.residencyStatus = prefillData.residencyStatus
      application.dateArriveAustralia = prefillData.dateArriveAustralia
      application.visaSubClass = prefillData.visaSubClass
    }
    if (Array.isArray(prefillData.siblings) && prefillData.siblings.length) {
      application.siblings = prefillData.siblings
        .sort((a, b) => {
          if (a.siblingsDOB > b.siblingsDOB) {
            return 1
          }
          if (a.siblingsDOB < b.siblingsDOB) {
            return -1
          }
          return 0
        })
        .slice(0, 1)
      application.hasAnySiblings = true
    }

    if (prefillData.parentCarers) {
      // add meta data -isCompletingApplication- required by FE code based on the flags from API
      prefillData.parentCarers.forEach((p) => {
        if (p.isErnSource) {
          p.meta = {
            isCompletingApplication: true
          }
          p.parentCarerEmail = emailFromToken
        } else if (
          !p.isErnSource &&
          p.parentCarerEmail &&
          String(p.parentCarerEmail).toLowerCase() ===
            String(emailFromToken).toLowerCase()
        ) {
          p.meta = {
            isCompletingApplication: true
          }
          p.parentCarerEmail = emailFromToken
        } else {
          p.meta = {
            isCompletingApplication: false
          }
        }
        // filter out the contact details
        if (Array.isArray(p.contactDetails) && p.contactDetails.length) {
          p.contactDetails = p.contactDetails.filter(
            (c) => c.contactType !== 'EMAIL'
          )
        }
        return p
      })
      application.parentCarers = prefillData.parentCarers
    }

    if (prefillData.doctorDetails) {
      application.doctorDetails = prefillData.doctorDetails
    }

    if (prefillData.correspondenceAddress) {
      application.correspondenceAddress = prefillData.correspondenceAddress
      application.meta.hasAdditionalPostalAddress = 'Yes'
    }

    if (prefillData.emergencyContact) {
      application.meta = {
        ...application.meta,
        additionalEmergencyContacts: ['yes']
      }
      application.emergencyContact = prefillData.emergencyContact
    }
  }
  return application
}

export function ehubPrefill(application, prefillAPIData, emailFromToken) {
  const prefillData = prefillAPIData?.data?.body
  application.meta.prefillFlags = getEhubPrefillFlags(prefillData)
  return ehubPrefillApplication(application, prefillData, emailFromToken)
}

export function createDraftApplicationId(userId) {
  // Get the current timestamp
  const timestamp = Date.now()
  // Generate a random number between 0 and 9999
  const random = Math.floor(Math.random() * 10000)
  // Combine the user ID, timestamp, and random number
  const draftApplicationId = `${userId}_${timestamp}_${random}`
  return draftApplicationId
}

export function removeSrnFromPrefillData(prefillAPIData) {
  let studentData = null
  if (prefillAPIData && prefillAPIData.data && prefillAPIData.data.body) {
    studentData = prefillAPIData.data.body.student
  }
  if (studentData) {
    delete studentData.srn
  }
}
