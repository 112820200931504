var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.application &&
    _vm.isAllowedFieldType(_vm.field) &&
    _vm.isVisible({ field: _vm.field, application: _vm.application })
  )?_c('div',{class:_vm.alertClass},[(_vm.getType(_vm.field, _vm.application) === _vm.fieldType.HEADING)?_c('div',[_c('ApplicationFieldHeading',{attrs:{"field":_vm.field,"section":_vm.section}})],1):[(_vm.getType(_vm.field, _vm.application) !== _vm.fieldType.DOCUMENTS)?_c('div',{class:[{ disabled: _vm.isDisabled(_vm.field, _vm.application) }, 'label']},[(_vm.getRequired(_vm.field, _vm.application))?_c('span',{staticClass:"required-mark",attrs:{"aria-hidden":"true"}},[_vm._v("*")]):_vm._e(),(_vm.field.label)?_c('span',[_vm._v(_vm._s(_vm.getLabel(_vm.field, _vm.application)))]):(_vm.field.vHtmlLabel)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getVHtmlLabel(_vm.field, _vm.application))}}):_vm._e()]):_vm._e(),(
        _vm.getType(_vm.field, _vm.application) === _vm.fieldType.DOCUMENTS &&
        _vm.finalValue.length > 0
      )?_c('div',_vm._l((_vm.finalValue),function(file){return _c('p',{key:file.name,staticClass:"document__value value"},[_c('v-icon',{staticClass:"document__icon",attrs:{"size":"30","color":"primary"}},[_vm._v("mdi-file-document-outline")]),_vm._v(" "+_vm._s(file.name)+" ")],1)}),0):(_vm.getType(_vm.field, _vm.application) === _vm.fieldType.CUSTOM)?_c('div',[_c('ApplicationFieldCustom',{attrs:{"field":_vm.field}})],1):(typeof _vm.finalValue === 'string')?_c('div',{class:[{ disabled: _vm.isDisabled(_vm.field, _vm.application) }, 'value']},[_c('span',[(_vm.isDisabled(_vm.field, _vm.application))?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'lock']}}):_vm._e()],1),_vm._v(" "+_vm._s(_vm.finalValue)+" ")]):(
        _vm.finalValue !== null &&
        typeof _vm.finalValue === 'object' &&
        Object.keys(_vm.finalValue).length
      )?_c('div',{staticClass:"value"},[_c('ul',_vm._l((_vm.finalValue),function(item){return _c('li',{key:item.value},[_vm._v(_vm._s(item))])}),0)]):_c('div',{staticClass:"value value-empty"},[_vm._v("(empty)")]),(_vm.alert && _vm.alert.type)?_c('div',{staticClass:"alertRow"},[_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}}),_c('span',{staticClass:"alertMessage",domProps:{"innerHTML":_vm._s(_vm.alert.message)}})],1):_vm._e()]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }