// See README.md for more info about field configuration

import moment from 'moment'
import _get from 'lodash/get'
import {
  FIELD_TYPE,
  REFDATA_MAP,
  DATE_FORMAT,
  CHOOSE_DATE,
  ALLOWED_RESIDENCIES,
  PREFILL_NOTIFICATION,
  TMP_NOTIFICATION,
  ENROLMENT_TYPES
} from '@/constants'

import { YES_NO } from '@/applicationDefinition/droplistData/boolean'

import { GENDERCODE } from '@/applicationDefinition/droplistData/genderCode'
import { LOTE } from '@/applicationDefinition/droplistData/lote'

import { getTerms } from '@/applicationDefinition/droplistData/termDates'
import { dropDownSelect } from '@/applicationDefinition/droplistData/helper'
import {
  getFirstName,
  getLabelName,
  hasAttendedPresSchoolVisible,
  getStartYear,
  isValidDropdownValue,
  getSchoolName
} from './helper'

export default [
  {
    label: 'Student details',
    description:
      'Please provide accurate details of the student applying to enrol. This information is used to help the school best cater for the educational needs of the student.',
    type: FIELD_TYPE.HEADING
  },

  {
    apiKey: 'residentialAddress',
    vHtmlLabel(application) {
      return application.meta.hasAdditionalPostalAddress === 'Yes'
        ? 'Student residential address'
        : 'Student residential address<p class="labelDescription">This is the address we will use to correspond with you about the student.</p>'
    },
    type: FIELD_TYPE.ADDRESS,
    required: true,
    disabled: true
  },
  {
    apiKey: 'meta.hasAdditionalPostalAddress',
    type: FIELD_TYPE.CHECKBOX,
    vHtmlLabel: 'Use a different <strong>postal address</strong>',
    trueValue: 'Yes',
    falseValue: 'No'
  },
  {
    apiKey: 'correspondenceAddress',
    vHtmlLabel:
      'Postal address<p class="labelDescription">This is the address we will use to correspond with you about the student.</p>',
    type: FIELD_TYPE.ADDRESS,
    withPoBox: true,
    visible(application) {
      return application.meta.hasAdditionalPostalAddress === 'Yes'
        ? true
        : false
    },
    required: true,
    className: 'boxWhite'
  },
  {
    type: FIELD_TYPE.NOTIFICATION,
    notificationProps: {
      inPage: true,
      type: 'error',
      elevation: 0
    },
    text() {
      return TMP_NOTIFICATION
    },
    visible(applicaition) {
      return applicaition.residencyStatus === 'TMP'
    }
  },
  {
    type: FIELD_TYPE.NOTIFICATION,
    notificationProps: {
      inPage: true,
      type: 'info',
      elevation: 0,
      alertClass: 'blue-alert'
    },
    text() {
      return PREFILL_NOTIFICATION
    },
    visible(application) {
      return (
        (application.meta &&
          application.meta.prefillFlags &&
          (application.meta.prefillFlags.student ||
            application.meta.prefillFlags.correspondenceAddress)) ||
        (application.enrolmentType === ENROLMENT_TYPES.CORE_SCT &&
          application.prevAttendSchool &&
          application.prevSchoolId)
      )
    }
  },
  {
    apiKey: 'student.firstName',
    label: "Student's first name",
    description: 'As it is shown on the birth certificate',
    placeHolder: 'Enter first name',
    required: true,
    type: FIELD_TYPE.NAME,
    maxLength: 100,
    disabled(application) {
      return (
        application.ooaApplicationID ||
        application.eoiID ||
        !!(
          application.meta.isEhubLinkedStudent && application.student.firstName
        ) ||
        (application?.shsOfferId &&
          !application?.student?.isErnSource &&
          !application?.student?.isShsSource)
      )
    }
  },
  {
    apiKey: 'student.otherName',
    label(application) {
      const name = application.student.firstName
      return getLabelName(null, name, "'s middle name")
    },
    placeHolder: 'Enter middle name',
    required: false,
    type: FIELD_TYPE.NAME,
    maxLength: 100
  },
  {
    apiKey: 'student.familyName',
    label(application) {
      const name = application.student.firstName
      return getLabelName(null, name, "'s family name")
    },
    description: 'As it is shown on the birth certificate',
    placeHolder: 'Enter family name',
    required: true,
    type: FIELD_TYPE.NAME,
    maxLength: 100,
    disabled(application) {
      return (
        application.ooaApplicationID ||
        application.eoiID ||
        application?.student?.isShsSource
      )
    }
  },
  {
    apiKey: 'student.prefFirstName',
    label(application) {
      const name = application.student.firstName
      return getLabelName(null, name, "'s preferred name")
    },
    placeHolder: 'Enter preferred name',
    required: false,
    description:
      'This is the name that is usually used to address the child. It can be different from the birth certificate.',
    type: FIELD_TYPE.NAME,
    maxLength: 100
  },
  {
    apiKey: 'student.genderCode',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(null, name, "'s gender")
    },
    required: true,
    type: FIELD_TYPE.RADIO,
    options: GENDERCODE,
    disabled(application) {
      return application.ooaApplicationID || application.eoiID
    }
  },
  {
    apiKey: 'student.dateOfBirth',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(null, name, "'s date of birth")
    },
    required: true,
    type: FIELD_TYPE.DATE,
    pastYears: 20,
    futureYears: 0,
    validation(value, application) {
      if (moment(value) > moment()) {
        return 'Cannot be a future date'
      }

      const isHighSchoolStudent = application.catchmentLevel === 'secondary'
      const minAge = isHighSchoolStudent ? 9 : 5
      const maxAgeHighSchooler = 18

      const currentYr = moment().year()

      let target = `${application.meta.calendarYear}-01-01`
      if (+application.meta.calendarYear === currentYr) {
        target = moment().format('YYYY-MM-DD')
      }

      const baseDateForAge = isHighSchoolStudent
        ? `${target}`
        : `${application.meta.calendarYear}-07-31`
      const diff = moment(baseDateForAge).diff(value, 'year', true)

      if (isHighSchoolStudent && diff >= maxAgeHighSchooler) {
        return 'Children enrolling in high school must be no younger than 9 and no older than 18 years of age. Please choose a date of birth within this range.'
      }

      if (diff < minAge) {
        return isHighSchoolStudent
          ? 'Children enrolling in high school must be at least 9 years of age.'
          : `This student is too young to begin school in ${application.meta.calendarYear}.`
      }
    },
    disabled(application) {
      return (
        application.ooaApplicationID ||
        application.eoiID ||
        application.shsOfferId ||
        !!(
          application.meta.isEhubLinkedStudent &&
          application.student.dateOfBirth
        )
      )
    }
  },
  {
    apiKey: 'intendedStartDate',
    label: 'Intended start date',
    required: true,
    type: FIELD_TYPE.DROPDOWN,
    options(application, store) {
      return getTerms(
        store.state.termDates,
        application.meta.calendarYear,
        store.state.school.calendarLateInd
      )
    },
    validation(value, application, store) {
      const validOptions = getTerms(
        store.state.termDates,
        application.meta.calendarYear,
        store.state.school.calendarLateInd
      )
      return (
        !isValidDropdownValue(validOptions, value) &&
        'Please select a valid intended start date'
      )
    },
    onFieldSetAfterAlerts: true,
    onFieldSet(value, application, store) {
      const calendarYear = application.meta.calendarYear
      //If we're choosing a date, we selected a date on the landing page and we haven't triggered this before
      if (
        value === CHOOSE_DATE &&
        calendarYear &&
        !application.enterStartDate
      ) {
        store.dispatch('set', [
          `application.enterStartDate`,
          `${calendarYear}--`
        ])
      }
    }
  },
  {
    apiKey: 'enterStartDate',
    label: 'Enter start date',
    required: true,
    type: FIELD_TYPE.DATE,
    pastYears: 0,
    futureYears: 1,
    disabledFields(application) {
      return application.meta.calendarYear ? ['year'] : null
    },
    startYear(application) {
      return application.meta.calendarYear
    },
    endYear(application) {
      return application.meta.calendarYear
    },
    ascendingYears: true,
    visible(application) {
      if (application.intendedStartDate !== CHOOSE_DATE) {
        return false
      }
      return true
    },
    validation(value) {
      if (moment(value) < moment().startOf('day')) {
        return 'Cannot be a past date'
      }
    }
  },
  {
    apiKey: 'scholasticYear',
    label(application) {
      const name = getFirstName(application)
      return getLabelName('School year ', name, ' is to be enrolled in')
    },
    required: true,
    type: FIELD_TYPE.DROPDOWN,
    options(application) {
      return dropDownSelect(
        this.getScholasticYears(getStartYear(application), application) || []
      )
    },
    visible(application) {
      return getStartYear(application) ? true : false
    },
    disabled(application) {
      return (
        application.shsOfferId ||
        application.enrolmentType === ENROLMENT_TYPES.CORE_SCT
      )
    }
  },

  {
    apiKey: 'hasAttendedPreSchool',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'In the year before school, has ',
        name,
        ' been in non-parental care on a regular basis and/or attended any other educational programs?'
      )
    },
    required: true,
    description:
      'An educational program is delivered outside of home and includes structured early learning delivered by a qualified teacher in a preschool or long day care centre.',
    type: FIELD_TYPE.RADIO,
    visible: hasAttendedPresSchoolVisible,
    options: YES_NO
  },

  {
    apiKey: 'preschools',
    type: FIELD_TYPE.COLLECTION,
    heading(preschool, index) {
      return `Educational program ${index}`
    },
    description(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Please add the educational programs ',
        name,
        ' has attended:'
      )
    },
    visible(application) {
      if (
        application.hasAttendedPreSchool &&
        hasAttendedPresSchoolVisible(application)
      ) {
        return true
      }
      return false
    },
    headingAdd: 'Add another educational program',
    newItem: {
      preschoolType: '',
      preschoolHoursType: '',
      schoolName: '',
      postcode: ''
    },
    fields(preschool, index) {
      var apiKeyPrefix = `${this.apiKey}[${index}]`

      var isDisplayNameAndPostcode = function (application) {
        var preschoolType = _get(application, `${apiKeyPrefix}.preschoolType`)
        return (
          hasAttendedPresSchoolVisible &&
          (preschoolType === 'PRS' ||
            preschoolType === 'LDP' ||
            preschoolType === 'LDW')
        )
      }

      return [
        {
          apiKey: `${apiKeyPrefix}.preschoolType`,
          label: 'Educational program type',
          required: true,
          type: FIELD_TYPE.DROPDOWN,
          options() {
            return dropDownSelect(
              this.getReferenceDataSet(REFDATA_MAP.PRESCHOOL_TYPE)
            )
          }
        },
        {
          apiKey: `${apiKeyPrefix}.schoolName`,
          label: 'Name',
          required: false,
          visible: isDisplayNameAndPostcode,
          type: FIELD_TYPE.NAME,
          maxLength: 132
        },
        {
          apiKey: `${apiKeyPrefix}.postcode`,
          label: 'Postcode',
          required: true,
          type: FIELD_TYPE.POSTCODE,
          visible: isDisplayNameAndPostcode
        },
        {
          apiKey: `${apiKeyPrefix}.preschoolHoursType`,
          label: 'Hours attended',
          required(application) {
            const preschoolType = _get(
              application,
              `${apiKeyPrefix}.preschoolType`
            )

            if (
              preschoolType === 'PRS' ||
              preschoolType === 'LDP' ||
              preschoolType === 'LDW'
            ) {
              return true
            }
            return false
          },
          type: FIELD_TYPE.RADIO,
          options() {
            return this.getReferenceDataSet(REFDATA_MAP.PRESCHOOL_HOURS_TYPE)
          }
        }
      ]
    }
  },
  {
    apiKey: 'prevAttendSchool',
    label(application) {
      const name = getFirstName(application)
      return getLabelName('Has ', name, ' attended another school?')
    },
    required: true,
    description: 'e.g. NSW, interstate or overseas',
    type: FIELD_TYPE.RADIO,
    options: YES_NO,
    disabled(application) {
      return (
        application.eoiID ||
        application.enrolmentType === ENROLMENT_TYPES.CORE_SCT
      )
    }
  },
  {
    type: FIELD_TYPE.GROUP,
    visible(application) {
      return application.prevAttendSchool
    },
    fields() {
      return [
        {
          apiKey: 'prevSchoolType',
          label(application) {
            const name = getFirstName(application)
            return getLabelName(
              'Where did ',
              name,
              ' most recently attend school?'
            )
          },
          required: true,
          type: FIELD_TYPE.DROPDOWN,
          options() {
            // the school types is categorised by Australia Stats plus Overseas here
            let states = this.getReferenceDataSet(REFDATA_MAP.STATES).slice(0)

            states.push({
              text: 'Overseas',
              value: 'OVS'
            })

            return dropDownSelect(states)
          },
          onChange(value, store) {
            // Ensure any australian school id or overseas school name is cleared
            // when prevSchoolType changes.
            store.dispatch('set', ['application.otherSchoolAttendedName', ''])
            store.dispatch('set', ['application.prevSchoolId', ''])
          },
          visible(application) {
            return application.prevAttendSchool
          },
          disabled(application) {
            return (
              application.eoiID ||
              application.enrolmentType === ENROLMENT_TYPES.CORE_SCT
            )
          }
        },

        {
          apiKey: 'prevSchoolId',
          label: 'School name',
          visible(application) {
            return (
              application.prevAttendSchool &&
              application.prevSchoolType !== 'OVS'
            )
          },
          required: true,
          type: FIELD_TYPE.LOOKUP,
          disabled(application) {
            return (
              application.eoiID ||
              application.enrolmentType === ENROLMENT_TYPES.CORE_SCT
            )
          }
        },
        {
          apiKey: 'otherSchoolAttendedName', // Currently transformed to prevSchoolName when submitting to SI
          label: 'School name',
          visible(application) {
            return (
              application.prevAttendSchool &&
              application.prevSchoolType === 'OVS'
            )
          },
          required: true,
          type: FIELD_TYPE.NAME,
          maxLength: 132
        },

        {
          apiKey: 'otherSchoolAttendedLocation',
          label: 'School location',
          visible(application) {
            return (
              application.prevAttendSchool &&
              application.prevSchoolType === 'OVS'
            )
          },
          required: true,
          maxLength: 128
        },
        {
          apiKey: 'prevSchoolStartDate',
          className: 'fieldInline',
          label: 'Attended from',
          visible(application) {
            return application.prevSchoolType
          },
          validation(value) {
            if (moment(value, DATE_FORMAT) > moment()) {
              return 'Cannot be future date'
            }
          },
          selectMonth: true,
          required: true,
          type: FIELD_TYPE.DATE,
          pastYears: 16,
          futureYears: 0
        },
        {
          apiKey: 'prevSchoolLastDate',
          className: 'fieldInline',
          label: 'Attended until',
          visible(application) {
            return application.prevSchoolType
          },
          validation(value, application) {
            if (
              moment(value) <
              moment(application.prevSchoolStartDate, DATE_FORMAT)
            ) {
              return `Cannot be earlier than 'Attended from' date`
            }
          },
          selectMonth: true,
          required: true,
          type: FIELD_TYPE.DATE,
          pastYears: 16,
          futureYears: 0
        }
      ]
    }
  },
  {
    apiKey: 'student.countryBorn',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(null, name, "'s country of birth")
    },
    required: true,
    type: FIELD_TYPE.DROPDOWN,
    options() {
      return dropDownSelect(this.getReferenceDataSet(REFDATA_MAP.COUNTRIES))
    }
  },
  {
    apiKey: 'dateArriveAustralia',
    label(application) {
      const name = getFirstName(application)
      return getLabelName('What date did ', name, ' arrive in Australia?')
    },
    required: true,
    visible(application) {
      if (
        application.student &&
        application.student.countryBorn !== '' &&
        application.student.countryBorn !== 'AUS'
      ) {
        return true
      }
      return false
    },
    type: FIELD_TYPE.DATE,
    validation(value) {
      if (moment(value) > moment()) {
        return 'Cannot be a future date'
      }
    },
    pastYears: 20,
    futureYears: 0
  },
  {
    apiKey: 'meta.hasReturnAustralia',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Has ',
        name,
        ' ever lived in a non-English speaking country for two or more years?'
      )
    },
    description:
      'Australian citizens returning from two years or more overseas may be eligible for intensive English support.',
    visible(application) {
      if (application.student && application.student.countryBorn === 'AUS') {
        return true
      }
      return false
    },
    required: true,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'dateReturnAustralia',
    label(application) {
      const name = getFirstName(application)
      return getLabelName('What date did ', name, ' return to Australia?')
    },
    selectMonth: true,
    required: true,
    visible(application) {
      if (
        application.student.countryBorn === 'AUS' &&
        application.meta.hasReturnAustralia
      ) {
        return true
      }
      return false
    },
    type: FIELD_TYPE.DATE,
    validation(value) {
      if (moment(value) > moment()) {
        return 'Cannot be a future date'
      }
    },
    pastYears: 20,
    futureYears: 0
  },
  {
    apiKey: 'residencyStatus',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(null, name, "'s residency status")
    },
    description(fields) {
      const name = getFirstName(fields)
      return getLabelName(
        'To be an Australian citizen, at least one parent needed to be an Australian citizen or permanent resident when ',
        name,
        ' was born.'
      )
    },
    required: true,
    type: FIELD_TYPE.DROPDOWN,
    options() {
      return dropDownSelect(
        this.getReferenceDataSet(REFDATA_MAP.RESIDENCY_STATUS).map((item) => {
          if (item.value === 'TMP') {
            item.text = 'Temporary Resident'
          }
          return item
        })
      )
    },
    validation(value) {
      if (!ALLOWED_RESIDENCIES.includes(value)) {
        return `This online application can only be used for Australian or NZ citizens or Permanent residents. ${
          value === 'TMP'
            ? 'This online service is no longer available for temporary residents. '
            : ''
        }Please visit the website for <a class="link-outline" href="https://www.deinternational.nsw.edu.au/" target="_blank"> temporary residents and international students</a>${
          value === 'TMP' ? '' : ' or contact the school for more information'
        }.`
      }
    }
  },
  {
    apiKey: 'hasATE',
    label(application) {
      const name = getFirstName(application)
      const schoolName = getSchoolName(application)
      return getLabelName(
        'Has ',
        name,
        ` received an Authority to Enrol for ${schoolName}?`
      )
    },
    required: true,
    visible() {
      return false
    },
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    type: FIELD_TYPE.NOTIFICATION,
    visible() {
      return false
    },
    notificationProps: {
      inPage: true,
      type: 'error',
      elevation: 4,
      alertClass: 'alerterror'
    },
    text(application) {
      const name = getFirstName(application)
      const schoolName = getSchoolName(application)
      return `
      <p><strong>${
        name || 'Student'
      } must have an Authority to Enrol for ${schoolName} in order to proceed.</strong></p>
      <p>
        Without authority to enrol, you will not be able to proceed with this enrolment application.<br>
        For further information, contact the 
        <a target="_blank" href="https://www.deinternational.nsw.edu.au/study-options/study-programs/temporary-residents">
          Temporary Residents Program
        </a>
      </p>`
    }
  },

  {
    type: FIELD_TYPE.GROUP,
    visible() {
      return false
    },
    fields() {
      return [
        {
          vHtmlLabel() {
            return '<strong>Travel documentation</strong>'
          },
          showLabel: true,
          type: FIELD_TYPE.HEADING
        },
        {
          apiKey: 'passportNumber',
          label(application) {
            const name = getFirstName(application)
            return getLabelName(
              null,
              name,
              "'s passport or travel documentation no."
            )
          },
          required(application) {
            return application.student?.countryBorn !== 'AUS'
          },
          maxLength: 64
        },
        {
          apiKey: 'tempResPassportCountryOfIssue',
          label: 'Country of issue of passport',
          type: FIELD_TYPE.DROPDOWN,
          options() {
            return dropDownSelect(
              this.getReferenceDataSet(REFDATA_MAP.COUNTRIES)
            )
          },
          required(application) {
            return application.student?.countryBorn !== 'AUS'
          }
        },
        {
          apiKey: 'tempResVisaClass',
          label(application) {
            const name = getFirstName(application)
            return getLabelName(null, name, "'s current visa class")
          },
          required: true,
          type: FIELD_TYPE.DROPDOWN,
          options() {
            return dropDownSelect(
              this.getReferenceDataSet(REFDATA_MAP.TEMP_VISA_CLASS)
            )
          }
        },
        {
          apiKey: 'tempResVisaSubClass',
          label(application) {
            const name = getFirstName(application)
            return getLabelName(null, name, "'s current visa subclass")
          },
          required: true,
          type: FIELD_TYPE.DROPDOWN,

          disabled(application) {
            return !application.tempResVisaClass
          },
          options(application, store) {
            const tempVisaSubClassMap = this.getReferenceDataSet(
              REFDATA_MAP.TEMP_VISA_SUB_CLASS_MAP
            )
              .filter((vsc) => vsc.value === application.tempResVisaClass)
              .map((vsc) => vsc.text)

            const tempVisaSubClasses = store.state.refData[
              REFDATA_MAP.TEMP_VISA_SUBCLASS
            ].filter((vsc) => tempVisaSubClassMap.includes(vsc.value))

            return dropDownSelect(tempVisaSubClasses)
          }
        },
        {
          apiKey: 'tempResVisaExpDate',
          label: 'Visa expiry date',
          required: true,
          type: FIELD_TYPE.DATE,
          validation(value) {
            if (moment(value) < moment()) {
              return 'Cannot be a past date'
            }
          },
          pastYears: 0,
          futureYears: 10
        }
      ]
    }
  },
  {
    apiKey: 'visaSubClass',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(null, name, "'s current visa subclass")
    },
    required: true,
    visible(application) {
      if (application.residencyStatus === 'PER') {
        return true
      }
      return false
    },
    type: FIELD_TYPE.DROPDOWN,
    options() {
      return dropDownSelect(
        this.getReferenceDataSet(REFDATA_MAP.VISA_SUB_CLASS)
      )
    }
  },
  {
    apiKey: 'student.isAboriTorStraitIslander',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Is ',
        name,
        ' of Aboriginal or Torres Strait Islander origin?'
      )
    },
    required: true,
    type: FIELD_TYPE.RADIO,
    options() {
      return this.getReferenceDataSet(REFDATA_MAP.ABORIGINALITY)
    }
  },
  {
    apiKey: 'student.homeLangOtherThanEnglish',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Does ',
        name,
        ' speak a language other than English at home?',
        'the student'
      )
    },
    type: FIELD_TYPE.RADIO,
    options: LOTE,
    required: true
  },
  {
    apiKey: 'student.mainLanguageOtherThanEnglish',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'What language other than English does ',
        name,
        ' mainly speak at home?',
        'the student'
      )
    },
    type: FIELD_TYPE.DROPDOWN,
    options() {
      return dropDownSelect(
        this.getReferenceDataSet(REFDATA_MAP.LANGUAGES).filter(
          (item) => item.value !== 'ENG'
        )
      )
    },
    visible(application) {
      return application.student.homeLangOtherThanEnglish
    },
    required: true
  },
  {
    apiKey: 'student.otherLanguage',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'What other language does ',
        name,
        ' speak at home?',
        'the student'
      )
    },
    type: FIELD_TYPE.DROPDOWN,
    options() {
      return dropDownSelect(this.getReferenceDataSet(REFDATA_MAP.LANGUAGES))
    },
    visible(application) {
      return application.student.homeLangOtherThanEnglish
    }
  },
  {
    apiKey: 'student.mobileNumber',
    placeHolder: 'Enter mobile number',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(null, name, "'s mobile number")
    },
    visible(application) {
      if (application.scholasticYear > 6) {
        return true
      }
      return false
    },
    description: 'Only provide if they have their own mobile phone.',
    required: false,
    type: FIELD_TYPE.PHONE
  }
]
