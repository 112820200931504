<template>
  <div
    class="OOASchoolReminderCard"
    :class="{ 'OOASchoolReminderCard--no-local': !hasLocalSchools }"
  >
    <div v-if="!hasLocalSchools" class="addressHeader">
      <v-icon
        class="material-icons-outlined"
        :class="{ 'ml-6 pl-9': $vuetify.breakpoint.mdAndUp }"
      >
        place
      </v-icon>
      <span
        class="ml-4 address"
        :class="{ 'pl-12': $vuetify.breakpoint.mdAndUp }"
        >{{ address }}</span
      >
    </div>

    <v-card class="mb-6">
      <ContentBox
        v-if="school"
        class="pt-8"
        :img-file-name="icon"
        image-class="largeImage"
      >
        <h2 class="pb-2" slot="title">
          <template v-if="!hasLocalSchools">
            Your address is not in the catchment area of any NSW public school
          </template>
          <template v-else>
            <span class="font-weight-medium">Out-of-area enrolment for</span>
            {{ school.schoolName }}
          </template>
        </h2>
        <div slot="copyContent">
          <template v-if="!hasLocalSchools">
            <ul>
              <li>
                If you entered a non-NSW address: you will need a NSW address to
                apply for enrolment.
              </li>
              <li>
                If you did enter a NSW address: please contact us to discuss.
              </li>
            </ul>
            <SchoolInfoCard :school="school" link-classes="ooaSchool" />
          </template>
          <template v-else-if="school.ooa">
            <p>
              To find out more, visit our page on
              <a :href="ooaRoute">out-of-area enrolment</a>
            </p>
          </template>
          <template v-else>
            <p>
              We may accept out-of-area applications if places are available.
              Such applications are subject to the
              <a
                href="https://education.nsw.gov.au/policy-library/policies/pd-2002-0006"
                target="_blank"
                >department’s policy.</a
              >
              Please contact us directly for more information.
            </p>
            <SchoolInfoCard :school="school" link-classes="ooaSchool" />
          </template>
        </div>
      </ContentBox>
    </v-card>
  </div>
</template>

<script>
import ContentBox from './ContentBox.vue'
import UTILS from '@/store/utils'
import SchoolInfoCard from './SchoolInfoCard.vue'

export default {
  name: 'OOASchoolReminderCard',
  components: { ContentBox, SchoolInfoCard },
  computed: {
    address() {
      const application = this.$store.state.application
      return (
        application &&
        UTILS.addressToTitleCaseString(application.residentialAddress)
      )
    },
    school() {
      const school = this.$store.state.school
      return { ...school, phone: `${school.phone}` }
    },
    ooaRoute() {
      const { meta, scholasticYear } = this.$store.state.application
      return encodeURI(
        `${process.env.VUE_APP_OOA_BASE_URL}/` +
          `?schoolCode=${this.school.schoolCode}` +
          `&catchmentLevel=${this.school.catchmentLevel}` +
          `&enrolmentYear=${meta.calendarYear}` +
          `&scholasticYear=${scholasticYear}` +
          `&address=${this.address}`
      )
    },
    hasLocalSchools() {
      return this.$store.state.localSchools.length > 0
    },
    icon() {
      return this.hasLocalSchools
        ? 'icon-out-of-area.svg'
        : 'icon-no-local-school.svg'
    }
  }
}
</script>

<style scoped lang="scss">
.OOASchoolReminderCard__proceed-button {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
}
.addressHeader {
  padding: 8px;
  min-height: 72px;
  height: auto;
  background: $color-third;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  color: $color-text-body;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12); //vuetify copy
}

@include desktop {
  .addressHeader {
    padding: 0;
  }
}

.OOASchoolReminderCard--no-local {
  .v-card {
    border-top-left-radius: 0 !important; //!important to override vuetify styles
    border-top-right-radius: 0 !important;
  }
}
</style>
