<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        :color="color"
        class="ml-3"
        :class="iconClass"
        fab
        small
        dark
        v-on="on"
        v-bind="attrs"
        @click="iconClick"
      >
        <v-icon>{{ icon }}</v-icon>
        <span class="sr-only">{{ iconLabel }}</span>
      </v-btn>
    </template>
    <v-list dense class="IconDropdown">
      <v-subheader v-if="subHeader" class="font-weight-bold ml-2 black--text">{{
        subHeader
      }}</v-subheader>
      <v-list-item
        v-for="(item, index) in menuItems"
        :class="item.class"
        :key="index"
        v-on="{ click: itemClick(item) }"
      >
        <v-icon size="20" color="primary" class="mr-2">{{ item.icon }}</v-icon>
        <span class="text">{{ item.text }}</span>
      </v-list-item>
      <slot name="footer"></slot>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: 'IconDropdown',
  data() {
    return {}
  },
  props: {
    subHeader: {
      type: String
    },
    menuItems: {
      type: Array
    },
    icon: {
      // vuetify material UI icon
      type: String,
      default: 'mdi-cog-outline'
    },
    color: {
      //accept the colors defined in Vuetify theme
      type: String,
      default: 'blue lighten-4'
    },
    iconLabel: {
      //Set a icon label for sreen reader
      type: String,
      default: 'label'
    },
    iconClick: {
      type: Function
    },
    iconClass: {
      type: String
    }
  },
  methods: {
    itemClick(item) {
      return (event) => {
        if (typeof item.onClick === 'function') {
          item.onClick(event)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn:focus {
  background-color: #fff !important;
}

.text {
  color: $ads-grey-01 !important;
}
.IconDropdown ::v-deep {
  .v-subheader {
    font-size: 1.6rem;
  }

  .v-list-item {
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .v-list-item--active {
    background: transparent;
  }
}
</style>
