<template>
  <div
    v-if="isVisible({ field, application })"
    :id="field && field.elemId"
    class="ApplicationReviewCollection"
  >
    <div
      class="collectionItemFields"
      v-for="(collectionItem, index) in filteredCollection"
      :key="field.apiKey + index"
    >
      <ApplicationFieldCollectionHeading
        :field="field"
        :application="application"
        :collection-item="collectionItem"
        :collection-item-index="index"
        :collection-size="getCollection().length"
      />

      <div
        v-for="collectionItemField in field.fields(
          collectionItem,
          index,
          $store
        )"
        :key="collectionItemField.apiKey"
      >
        <ApplicationReviewCollection
          v-if="collectionItemField.type == 'COLLECTION'"
          :section="section"
          :field="collectionItemField"
          :alerts="alerts"
        />
        <ApplicationFieldReadOnly
          v-else
          :field="collectionItemField"
          :section="section"
          :alert="alertByApiKey(collectionItemField)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'

import ApplicationFieldCollectionHeading from './ApplicationFieldCollectionHeading.vue'
import ApplicationFieldReadOnly from './ApplicationFieldReadOnly.vue'
import ApplicationReviewCollection from './ApplicationReviewCollection.vue'

import ApplicationMixin from '@/components/mixins/application'
import fieldsMixin from '@/components/mixins/fieldsMixin'

export default {
  name: 'ApplicationReviewCollection',
  components: {
    ApplicationFieldCollectionHeading,
    ApplicationReviewCollection,
    ApplicationFieldReadOnly
  },
  mixins: [ApplicationMixin, fieldsMixin],
  props: {
    field: {
      type: Object,
      required: false
    },
    section: {
      type: Object,
      required: false
    },
    alerts: {
      type: Array,
      required: false
    }
  },
  computed: {
    isReviewing() {
      return (
        this.application && this.application.meta.sectionExpandedId === 'review'
      )
    },
    filteredCollection() {
      return this.getCollection().filter(
        (item) => !this.isItemFilteredOut(item)
      )
    }
  },
  methods: {
    alertByApiKey(field) {
      return this.alerts.find((item) => item.field.apiKey === field.apiKey)
    },
    getCollection() {
      try {
        return _get(this, `$store.state.application.${this.field.apiKey}`) || []
      } catch (ex) {
        return []
      }
    },
    isItemFilteredOut(collectionItem) {
      if (this.field.filter) {
        return (
          collectionItem[this.field.filter.apiKey] !== this.field.filter.value
        )
      }
    }
  }
}
</script>

<style lang="scss">
.ApplicationReviewCollection {
  .ApplicationFieldCollectionHeading {
    padding: 0 0 2rem;

    h3 {
      margin-bottom: 0;
    }
  }

  h3:first-child {
    margin-top: $content-padding * 1.25;
  }

  .collectionItemFields {
    margin: 0;
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.ApplicationReviewCollection {
  h2 {
    padding: 0 $content-padding;
    margin: 0 0 3.5rem;
  }

  .collectionItemFields {
    margin-top: $content-padding * 1.25;
    margin-bottom: $content-padding * 1.25;
    padding: $content-padding * 2;
    background: $color-grey-2;

    .collectionItemFields {
      padding: 0;
      margin: 0;
    }

    .collectionFooter {
      button.primary {
        padding: 0;
        color: $color-primary;
        background: transparent !important;
      }
    }
  }

  .columnLayout {
    display: inline-block;
    width: 33.33%; // Currently giving a three column layout - TODO: Make responsive

    & > * {
      width: 100%;
    }
  }

  .collectionFooter {
    margin: 0.5rem 0 4rem 0;

    .ApplicationFieldAlert {
      padding: 0;
      margin: 0.5rem 0;
    }

    &.full {
      padding: 0 1rem;

      .add {
        margin: 0;
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }
}
</style>
