<template>
  <div
    class="black--text notification"
    :class="notificationTheme.notificationClass"
  >
    <v-icon class="notification__icon" :color="notificationTheme.iconColour">{{
      notificationTheme.icon
    }}</v-icon>
    <div class="notification__content" tabindex="0">
      <div class="notification__header">
        <span
          v-if="notification && notification.vHtmlTitle"
          v-html="notification.vHtmlTitle"
        ></span>
        <span v-else-if="notification && notification.title">
          {{ notification && notification.title }}
        </span>
      </div>
      <div class="notification__body">
        <p
          v-if="notification && notification.vHtmlBody"
          v-html="notification.vHtmlBody"
        ></p>
        <p v-else-if="notification && notification.body">
          {{ notification && notification.body }}
        </p>
      </div>
    </div>
    <v-btn
      icon
      aria-label="close notification"
      class="notification__close-btn"
      @click="$emit('dismiss')"
    >
      <v-icon color="grey lighten-2">mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'GenericNotification',
  props: {
    notification: {
      type: Object
    }
  },
  data() {
    return {
      themeConfig: {
        success: {
          notificationClass: 'notification--success',
          icon: 'mdi-check-circle',
          iconColour: 'success'
        },
        warning: {
          notificationClass: 'notification--warning',
          icon: 'mdi-alert',
          iconColour: 'orange darken-1'
        },
        error: {
          notificationClass: 'notification--error',
          icon: 'mdi-alert-circle',
          iconColour: 'red darken-1'
        },
        info: {
          notificationClass: 'notification--info',
          icon: 'mdi-information',
          iconColour: 'info'
        },
        default: {
          notificationClass: '',
          icon: 'mdi-information',
          iconColour: 'info'
        }
      }
    }
  },
  computed: {
    notificationTheme() {
      return (
        (this.notification && this.themeConfig[this.notification.type]) ||
        this.themeConfig.default
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.notification {
  background-color: white;
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0 0.5rem 1.5rem;
  padding-bottom: 1rem;
  border-left: 8px solid;
  border-radius: 4px;
  border-color: transparent;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  max-width: 600px;
  word-break: break-word;

  &--error {
    border-color: #b81237;
  }

  &--success {
    border-color: #00a908;
  }

  &--info {
    border-color: #2e5299;
  }

  &--warning {
    border-color: #dc5800;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header,
  &__body {
    display: flex;
    font-size: 1.6rem;
  }

  &__body {
    margin-right: 2rem;
  }

  &__header {
    align-items: center;
    font-weight: bold;
    min-height: 36px;
  }

  &__close-btn {
    border: none;
    margin-left: auto;
  }

  &__icon {
    margin-top: 0.7rem;
    margin-right: 1.6rem;
  }
}
::v-deep .v-snack {
  &__content {
    align-items: flex-start;
    padding-bottom: 1rem;
    border-left: 8px solid;
    border-radius: 4px;
  }
}
</style>
