import { auth } from './fixture'

export default {
  auth: { ...auth },
  error: null, // The last error manually encountered
  errorCode: null, // Check current state of error code
  school: null, // Data about the school being applied to
  application: null, // Complete dataset for the currently displayed application
  currentApplication: null, // capture the vital information on the welcome page to be used in the pending application process
  sections: [], // Array of application form sections (defined in applicationDefinition > applicationSections.js)
  refData: null, // Externally controlled source of data for <select> options (among other fields)
  scholasticYears: null, // Externally controlled source of scholastic years (K-12) for current school
  alerts: [], // Array of alerts for the currently displayed application
  counters: [], // Array of counters used for completion status
  messageBox: {}, // If a message box is to be displayed, its configuration must be inserted into this object
  modal: {}, // Determines which modal dialog (if any) to display
  saveStatus: null, // Show the database save status of the application data (Saving, Saved or null)
  showSpinner: false,
  showOverlay: false,
  showMenu: false,
  inCatchment: null,
  isNewApplication: false, // True if choosing the new application flow, not the resume flow.
  oesProperties: {}, // Externally controlled data to allow for updates to the portal without a full deploy. Currently only holds values for footer links and SchoolFinder endpoints.
  focusID: null, //Used to set focus to an focusable element with the ID
  inTrainingMode: false, //Whether or not we are in training mode
  oesSchools: [], //List of schools that are part of OES application process
  localSchools: [], //List of schools that are within the catchment area of the provided address
  termDates: null, //List of all valid upcoming school term start dates
  ooaStudentApplications: null, // List of OOA offered applications
  y67tApplications: null, // List of year 6/7 transition offered applications
  studentName: null, // Student's full name from in progress application for confirm pending offer popop
  schoolName: null, // School name from in progress application for confirm pending offer popop
  notificationQueue: [], //A queue to place notification objects. The queue will be consumed by NotificationQueue.
  uploadStatuses: {}, //Current status of a file upload component. Used to block navigation while removing/uploading a file.
  disableLeftNavItems: [], // Disable nav items array
  resumeViaEmail: false, // set to true when user resume via email (need to skip pending offer page when user resume via email)
  resumeViaButton: false, //set to true when user resume via button in landing page
  ooaApplicationID: null, // set to proceed OOA application,
  isOoaApplication: false, // set to identify OOA application,
  hasPendingApplication: false, // set to redirect OoaDeclineView when no pending offers found
  declinedOoaOffers: [], // list of declined ooa offers
  submittedApplicationId: null,
  y67tApplicationID: null, // set to proceed y67t offer
  isY67TApplication: false, // set to identify Y67T application,
  declinedY67TOffers: [], // list of declined y67t offers
  ernEnrolmentOwnerName: null, // set to compare the enrolment owner name in ERN and draft application
  ernEnrolmentOwnerNameNotMatched: null, // set to true when the enrolment owner name in ern is not matching with the ones in draft application
  isSchoolOfferSreSee: false,
  validSREOptions: [],
  validSEEOptions: [],
  validAMAOptions: [],
  isRemovedSelecedOption: false,
  schoolCustomisation: null
}
