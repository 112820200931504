export const auth = {
  cognitoSession: '',
  idTokenTime: 0,
  isUserActive: false,
  isUserActiveTime: 0,
  userId: '',
  idToken: '',
  refreshToken: '',
  authProvider: ''
}
