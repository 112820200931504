import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faSearch,
  faExclamationTriangle,
  faExclamationCircle,
  faExclamation,
  faEllipsisH,
  faSync,
  faSyncAlt,
  faCircleNotch,
  faTimes,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faPlus,
  faMinus,
  faPen,
  faQuestionCircle,
  faCheckCircle,
  faCheck,
  faBars,
  faRedoAlt,
  faPlusCircle,
  faMapMarkerAlt,
  faLock,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons'

import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons'

export default function addGlyphs() {
  library.add(
    faSearch,
    faExclamationTriangle,
    faExclamationCircle,
    faExclamation,
    faEllipsisH,
    faSync,
    faSyncAlt,
    faCircleNotch,
    faTimes,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faAngleDown,
    faPlus,
    faMinus,
    faPen,
    faQuestionCircle,
    faCheckCircle,
    faCheck,
    farCheckCircle,
    faBars,
    faRedoAlt,
    faPlusCircle,
    faMapMarkerAlt,
    faLock,
    faArrowLeft
  )

  Vue.component('FontAwesomeIcon', FontAwesomeIcon)
}
