<!--
this checkbox group has 3 config text,value,group
-->

<template>
  <fieldset class="fieldContent">
    <label :class="'AppCheckbox' + (disabled ? ' disabled' : '')">
      <input
        :aria-required="!!required"
        type="checkbox"
        :id="id"
        :disabled="disabled"
        @change="onChange"
        @focus="onFocus"
        @blur="onBlur"
        :checked="ticked"
      />
      <span v-if="label">{{ label }}</span>
      <span v-else-if="vHtmlLabel" v-html="vHtmlLabel"></span>
    </label>
  </fieldset>
</template>

<script>
import _isEqual from 'lodash/isEqual'
export default {
  name: 'AppCheckbox',
  props: {
    id: String,
    label: [String, Boolean],
    vHtmlLabel: [String, Boolean],
    disabled: Boolean,
    value: [String, Number, Array, Boolean],
    trueValue: [String, Number, Array, Boolean],
    falseValue: [String, Number, Array, Boolean],
    required: Boolean
  },
  data() {
    return {
      ticked: false
    }
  },
  mounted() {
    if (_isEqual(this.value, this.trueValue)) {
      this.ticked = true
    } else {
      this.ticked = false
    }
  },
  methods: {
    onChange(e) {
      if (e.target.checked) {
        this.$emit('change', this.trueValue)
      } else {
        this.$emit('change', this.falseValue)
      }
    },
    onFocus() {
      this.$emit('focus', this)
    },
    onBlur() {
      this.$emit('blur', this)
    }
  }
}
</script>

<style scoped lang="scss">
$box-size: 2rem;
$offset-left: 1rem;

.fieldLabel {
  color: $color-text-body;
  font-size: $text-h5;
  font-weight: normal;
  margin: 0 0 0.6rem 0;

  > span {
    color: $color-red;
  }

  .required-mark {
    float: left;
  }
}

.AppCheckbox {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 0.15em 0 0 0;
  margin: 0.25em 1em 0.25em 0;
  min-height: $box-size;
  user-select: none;
  font-size: 1.6rem;

  &.disabled {
    cursor: default;
    opacity: 0.75;
  }

  &.extend {
    span {
      &:before {
        // Box image
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23333333' d='M0,0h32v32H0V0z'/%3E%3Cpath fill='%23ffffff' d='M2,2h28v28H2V2z'/%3E%3C/svg%3E");
      }
    }
  }

  &.single {
    margin: 0;
    padding: 1rem 1rem 1rem 0.8rem;
    background-color: $color-field-background;

    span {
      position: relative;
    }
  }

  span {
    padding-left: 3rem + $offset-left;
    margin: 0;
    display: inline-block;

    &:before {
      // Box image
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23333333' d='M0,0h32v32H0V0z'/%3E%3Cpath fill='%23ffffff' d='M2,2h28v28H2V2z'/%3E%3C/svg%3E");
    }

    &:before,
    &:after {
      width: $box-size;
      height: $box-size;
      content: ' ';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      left: $offset-left;
      top: 0.25rem;
    }
  }

  input {
    position: absolute;
    top: 0.25rem;
    left: $offset-left;
    height: $box-size;
    width: $box-size;
    border: 0;
    padding: 0;
  }

  input:checked + span:after {
    // Tick image
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23313131' d='M25.6,11.4c0.2-0.2,0.2-0.5,0-0.7l-2.3-2.3c-0.2-0.2-0.5-0.2-0.7,0L14,17l-3.6-3.6c-0.2-0.2-0.5-0.2-0.7,0l-2.3,2.3 c-0.2,0.2-0.2,0.5,0,0.7l6.3,6.3c0.2,0.2,0.5,0.2,0.7,0L25.6,11.4L25.6,11.4z'/%3E%3C/svg%3E");
  }
}
</style>
