<template>
  <v-card class="SchoolInfoCard text-left">
    <div class="d-flex pb-3 pt-2 SchoolInfoCard__title">
      <v-icon class="pr-4">mdi-school-outline</v-icon>{{ school.schoolName }}
    </div>
    <div class="d-flex pb-3">
      <v-icon class="pr-4">mdi-map-marker-outline</v-icon>
      <a
        class="schoolAddress"
        :class="linkClasses"
        :href="getSchoolAddressURL(school)"
        target="_blank"
        rel="noopener noreferrer"
        >{{ school.address }}</a
      >
    </div>
    <div class="d-flex pb-3">
      <v-icon class="pr-4">mdi-phone-outline</v-icon>
      <div>{{ school.phone }}</div>
    </div>
    <div class="d-flex">
      <v-icon class="pr-4">mdi-web</v-icon>
      <div>
        <a
          class="schoolWebsite"
          :class="linkClasses"
          :href="getSchoolWebsiteURL(school)"
          target="_blank"
          rel="noopener noreferrer"
          >{{ school.website }}</a
        >
      </div>
    </div>
    <b-button
      type="button"
      class="SchoolInfoCard__apply-button mt-5"
      variant="primary"
      v-if="school.oes && includeApplyButton"
      :href="getOESRoute(school)"
      target="_blank"
      rel="noopener noreferrer"
      >Apply</b-button
    >
  </v-card>
</template>

<script>
export default {
  name: 'SchoolInfoCard',
  props: {
    school: {
      type: Object,
      default: null
    },
    includeApplyButton: {
      type: Boolean,
      default: false
    },
    linkClasses: {
      type: String,
      default: ''
    }
  },
  methods: {
    getSchoolAddressURL(school) {
      return school.long && school.lat
        ? `https://www.google.com/maps?q=${school.lat},${school.long}`
        : `https://www.google.com/maps?q=${school.address}`
    },
    getSchoolWebsiteURL(school) {
      return school.website.startsWith('http')
        ? school.website
        : `https://${school.website}`
    },
    getOESRoute(school) {
      return `/?schoolCode=${school.schoolCode}${
        school.catchmentLevel ? `&catchmentLevel=${school.catchmentLevel}` : ''
      }`
    }
  }
}
</script>

<style scoped lang="scss">
.SchoolInfoCard {
  font-weight: 500;
  font-size: 1.6rem;
  padding: 16px 24px 32px;

  &__title {
    font-weight: bold;
  }

  &__apply-button {
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
  }
}

@include desktop {
  .addressHeader {
    padding: 0;
  }
}
</style>
