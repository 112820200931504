<template>
  <div :class="getClass">
    <font-awesome-icon
      focusable="false"
      class="icon"
      icon="exclamation-triangle"
    />
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'AppMessage',
  props: {
    message: String,
    type: String
  },
  computed: {
    getClass() {
      return 'AppMessage ' + this.type
    }
  }
}
</script>

<style scoped lang="scss">
.AppMessage {
  display: flex;
  align-items: center;
  margin: $content-padding;
  padding: $content-padding;
  border-radius: $field-border-radius;
  font-size: 1.6em;

  &.warning {
    border: 1px solid $color-warning-border;
    background-color: $color-warning;
  }

  &.error {
    border: 1px solid $color-red;
    background-color: $color-red-lighter;
  }

  .icon {
    margin-right: 0.6em;
  }
}
</style>
