<template>
  <v-snackbar
    color="white"
    class="black--text BrowserSupportNotification"
    :value="isVisible"
    :timeout="-1"
    top
    right
    multi-line
  >
    <v-icon large class="orange--text text--darken-1 warning-icon"
      >mdi-alert</v-icon
    >
    <div class="notification__content">
      <div class="notification__header">
        <h1>This browser is not supported</h1>
        <v-btn icon class="notification__close-btn" @click="dismiss">
          <v-icon color="grey lighten-3">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="notification__body">
        <p>
          Online Enrolment may have issues when running in this browser. For the
          best experience, please use one of the supported browsers below
        </p>
      </div>
      <div class="notification__actions">
        <a
          :href="chromeURL"
          target="_blank"
          rel="noopener noreferrer"
          class="orange--text text--darken-1"
        >
          DOWNLOAD CHROME
        </a>
        <a
          :href="edgeURL"
          target="_blank"
          rel="noopener noreferrer"
          class="orange--text text--darken-1"
        >
          DOWNLOAD MICROSOFT EDGE
        </a>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: 'BrowserSupportNotification',
  data() {
    return {
      unsupportedBrowser: this.isIe(),
      hasDismissed: JSON.parse(
        window.sessionStorage.getItem('browserSupportNotification.dismissed')
      ),
      chromeURL: 'https://www.google.com/chrome/',
      edgeURL: 'https://www.microsoft.com/en-us/edge'
    }
  },
  computed: {
    isVisible() {
      return this.unsupportedBrowser && !this.hasDismissed
    }
  },
  methods: {
    dismiss() {
      this.hasDismissed = true
      window.sessionStorage.setItem(
        'browserSupportNotification.dismissed',
        'true'
      )
    },
    isIe() {
      const ua = window.navigator.userAgent
      // MSIE 10 or below || MSIE11.x
      return (
        ua.indexOf('MSIE') !== -1 ||
        !!navigator.userAgent.match(/Trident.*rv:11\./)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.BrowserSupportNotification {
  &.v-snack {
    z-index: z('snackbar');
  }

  ::v-deep .v-snack__content .notification__header .notification__close-btn {
    margin-left: auto;
  }
  ::v-deep .v-snack__content .notification__actions button {
    margin-left: 0;
  }
}

.notification {
  &__content {
    color: $color-primary;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header,
  &__body {
    display: flex;
    font-size: 1.6rem;
  }

  &__header {
    align-items: center;
  }

  &__close-btn {
    border: none;
    margin-left: auto;
  }

  &__actions {
    font-size: 1.6rem;
    display: flex;

    a {
      text-decoration: none;
    }

    > :last-child {
      margin-left: 3rem;
    }
  }
}

.warning-icon {
  margin-top: 0.7rem;
  margin-right: 1.6rem;
}

::v-deep .v-snack__content {
  display: flex;
  align-items: flex-start;
  padding-bottom: 1rem;
}

h1 {
  font-size: 1.6rem;
  margin-bottom: 0;
}

@media (max-width: 580px) {
  .notification__actions {
    flex-direction: column;
    > :last-child {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}
</style>
