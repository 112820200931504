import { CATEGORY_UPLOAD_STATUSES } from '../constants'

function isAteBlocked(state) {
  const {
    residencyStatus,
    hasATE,
    prevAttendSchool,
    prevSchoolId,
    prevSchoolType
  } = state.application
  if (residencyStatus === 'TMP') {
    return true
  } else if (prevAttendSchool) {
    if (!(prevSchoolType === 'NSW' && prevSchoolId) && hasATE === false) {
      return true
    }
  } else {
    if (residencyStatus === 'TMP' && hasATE === false) {
      return true
    }
  }
  return false
}

export default {
  blockNavigation: (state) => {
    if (!state.application) return false

    //For now only blocking for uploading/removing but may extend in the future
    const anyUploading = Object.values(state.uploadStatuses).some(
      (reason) => reason === CATEGORY_UPLOAD_STATUSES.UPLOADING
    )
    const anyRemoving = Object.values(state.uploadStatuses).some(
      (reason) => reason === CATEGORY_UPLOAD_STATUSES.REMOVING
    )
    const isTmpResAndNoAte = isAteBlocked(state)

    if (anyUploading) {
      return { reason: 'uploading' }
    } else if (anyRemoving) {
      return { reason: 'removing' }
    } else if (isTmpResAndNoAte) {
      return { reason: 'tmpResAndNoAte' }
    }
    return false
  },
  disableLeftNavItems: (state) => {
    if (isAteBlocked(state)) {
      return state.sections
        .map((section) => section.id)
        .filter((id) => id !== 'student')
    }
    return []
  },
  sectionExpandedId: (state) =>
    state.application.meta && state.application.meta.sectionExpandedId,
  hasAttemptToSubmit: (state) =>
    state.application.meta && state.application.meta.hasAttemptToSubmit,
  scholasticYear: (state) =>
    state.application && state.application.scholasticYear,
  isSchoolOfferSreSee: (state) => state.isSchoolOfferSreSee,
  sections: (state) => state.sections,
  intendedStartDate: (state) =>
    state.application && state.application.intendedStartDate,
  isEhubLinkedStudent: (state) =>
    state.application && state.application.isEhubLinkedStudent
}
