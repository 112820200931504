<!--
this checkbox group has 3 config text,value,group
-->

<template>
  <fieldset class="fieldContent">
    <legend class="fieldLabel">
      <span class="required-mark" aria-hidden="true" v-if="field.required"
        >*</span
      >
      <span v-if="field.label">{{ getLabel(field, application) }}</span>
      <span
        v-else-if="field.vHtmlLabel"
        v-html="getVHtmlLabel(field, application)"
      ></span>
      <span class="sr-only" v-if="!!field.required">Required</span>
    </legend>
    <label
      :class="'AppCheckbox' + (disabled ? ' disabled' : '')"
      v-for="(item, index) in options"
      :key="`checkbox-${index}`"
    >
      <input
        type="checkbox"
        :id="id"
        :disabled="disabled"
        :value="item.value === undefined ? item.text : item.value"
        v-model="checkboxValue"
        @change="onChange"
        @focus="onFocus"
        @blur="onBlur"
        :aria-describedby="ariaDescribedBy"
      />
      <span>{{ item.text }}</span>
    </label>
  </fieldset>
</template>

<script>
import fieldsMixin from '@/components/mixins/fieldsMixin'
export default {
  name: 'AppCheckbox',
  props: {
    id: String,
    label: String,
    name: String,
    checked: Boolean,
    hidden: Boolean,
    disabled: Boolean,
    value: Array,
    field: Object,
    options: Array,
    ariaDescribedBy: String
  },
  data() {
    return {
      checkboxValue: this.value
    }
  },
  mixins: [fieldsMixin],
  methods: {
    onChange(e) {
      //Multiple Checkboxes
      // Uncheck other fields if a 'group' is specified on any field
      // Only happens when a checkbox is checked
      if (e.target.checked) {
        const curOption = this.options.find(
          (option) => option.value === e.target.value
        )

        this.checkboxValue = this.checkboxValue.filter((item) => {
          const curItem = this.options.find((option) => option.value === item)
          return curOption.group === curItem.group
        })
      }
      this.$emit('change', this.checkboxValue)
    },
    onFocus() {
      this.$emit('focus', this)
    },
    onBlur() {
      this.$emit('blur', this)
    }
  },
  computed: {
    application() {
      return this.$store.state.application || {}
    }
  }
}
</script>

<style scoped lang="scss">
$box-size: 2rem;
$offset-left: 1rem;

.fieldLabel {
  color: $color-text-body;
  font-size: $text-h5;
  font-weight: normal;
  margin: 0 0 0.6rem 0;

  > span.required-mark {
    color: $color-red;
  }

  .required-mark {
    float: left;
  }
}

.AppCheckbox {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 0.15em 0 0 0;
  margin: 0.25em 1em 0.25em 0;
  min-height: $box-size;
  user-select: none;
  font-size: 1.6rem;

  &.disabled {
    cursor: default;
    opacity: 0.75;
  }

  &.extend {
    span {
      &:before {
        // Box image
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23333333' d='M0,0h32v32H0V0z'/%3E%3Cpath fill='%23ffffff' d='M2,2h28v28H2V2z'/%3E%3C/svg%3E");
      }
    }
  }

  &.single {
    margin: 0;
    padding: 1rem 1rem 1rem 0.8rem;
    background-color: $color-field-background;

    span {
      position: relative;
    }
  }

  span {
    padding-left: 3rem + $offset-left;
    margin: 0;
    display: inline-block;

    &:before {
      // Box image
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23333333' d='M0,0h32v32H0V0z'/%3E%3Cpath fill='%23ffffff' d='M2,2h28v28H2V2z'/%3E%3C/svg%3E");
    }

    &:before,
    &:after {
      width: $box-size;
      height: $box-size;
      content: ' ';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      left: $offset-left;
      top: 0.25rem;
    }
  }

  input {
    position: absolute;
    top: 0.25rem;
    left: $offset-left;
    height: $box-size;
    width: $box-size;
    border: 0;
    padding: 0;
  }

  input:checked + span:after {
    // Tick image
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23313131' d='M25.6,11.4c0.2-0.2,0.2-0.5,0-0.7l-2.3-2.3c-0.2-0.2-0.5-0.2-0.7,0L14,17l-3.6-3.6c-0.2-0.2-0.5-0.2-0.7,0l-2.3,2.3 c-0.2,0.2-0.2,0.5,0,0.7l6.3,6.3c0.2,0.2,0.5,0.2,0.7,0L25.6,11.4L25.6,11.4z'/%3E%3C/svg%3E");
  }
}
</style>
