<!-- Display pending out of area and y67t offered applications-->
<template>
  <div class="PendingOffers">
    <ApplicationBanner
      data-testid="application-banner"
      content="<h1>Choose an enrolment<br /> application to continue</h1>"
    />
    <div class="pending-offers">
      <b-container class="content">
        <div v-if="declinedOoaOffers.length">
          <Notification
            v-for="offer in declinedOoaOffers"
            :key="offer.application.ooaApplicationID"
            class="notification-alert"
            :notification="notification(offer.application.ooaApplicationID)"
            data-testid="notification"
            @dismiss="dismiss(offer.application.ooaApplicationID)"
          />
        </div>
        <div v-if="declinedY67TOffers.length">
          <Notification
            v-for="offer in declinedY67TOffers"
            :key="offer.application.eoiID"
            class="notification-alert"
            :notification="y67tNotification(offer.application.eoiID)"
            @dismiss="y67tDismiss(offer.application.eoiID)"
          />
        </div>
        <div v-if="cancelledApplication">
          <Notification
            class="notification-alert"
            :notification="cancelledApplication"
            data-testid="cancel-notification"
            @dismiss="cancelledApplication = null"
          />
        </div>
        <div
          v-if="ooaStudentApplications.length"
          class="white-card ooa-pending-offers"
          data-testid="pending-offers-section"
        >
          <b-row>
            <b-col cols="3" md="2" class="text-center col">
              <v-avatar size="60" color="primary lighten-4">
                <v-icon
                  class="material-icons-outlined"
                  color="primary"
                  size="40"
                  alt="Pending out of area offered applications"
                  aria-label="Pending out of area offered applications"
                >
                  new_releases
                </v-icon>
              </v-avatar>
            </b-col>
            <b-col cols="9" md="10">
              <h2>Out-of-area offer(s)</h2>
              <p>
                To proceed with an offer, select <strong>Proceed</strong> and
                submit a full enrolment application within
                <strong>14 days.</strong><br />
                The school will be notified of your acceptance. If you wish to
                decline, select <strong>Decline</strong> to send the school a
                notification.
              </p>
            </b-col>
          </b-row>
          <div
            v-for="application in ooaStudentApplications"
            :key="application.application.userId"
            class="mt-4"
          >
            <b-row
              :class="ooaStudentApplications.length === 1 ? 'pb-6' : 'pb-0'"
            >
              <b-col cols="3" md="2" class="text-center">
                <v-icon class="material-icons-outlined person-icon"
                  >person_outline</v-icon
                >
              </b-col>
              <b-col cols="9" md="10" class="application-details">
                <b-row>
                  <b-col cols="12" md="7">
                    <div class="item">
                      <h3>
                        <strong>{{
                          studentName(application.application.student)
                        }}</strong>
                      </h3>
                      <p>{{ application.application.schoolName }}</p>
                    </div>
                  </b-col>
                  <b-col cols="12" md="5">
                    <div class="item">
                      <b-button
                        variant="outline-primary"
                        :aria-label="
                          applicationAriaLabel('Decline', application, 'OOA')
                        "
                        @click="
                          declineOoaApplication(
                            application.application.ooaApplicationID
                          )
                        "
                        >Decline</b-button
                      >
                    </div>
                    <div class="item">
                      <b-button
                        variant="primary"
                        :aria-label="
                          applicationAriaLabel('Proceed', application, 'OOA')
                        "
                        @click="
                          proceedOoaApplication(
                            application.application.ooaApplicationID
                          )
                        "
                        data-testid="proceed-ooa-offer"
                        >Proceed</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <v-divider v-if="ooaStudentApplications.length > 1"></v-divider>
          </div>
        </div>

        <div
          v-if="y67tApplications.length"
          class="white-card ooa-pending-offers"
          data-testid="pending-y67t-offers-section"
        >
          <b-row>
            <b-col cols="3" md="2" class="text-center col">
              <v-avatar size="60" color="primary lighten-4">
                <v-icon
                  class="material-icons-outlined"
                  color="primary"
                  size="40"
                  alt="Pending out of area offered applications"
                  aria-label="Pending out of area offered applications"
                >
                  new_releases
                </v-icon>
              </v-avatar>
            </b-col>
            <b-col cols="9" md="10">
              <h2>Year 7 placement offers</h2>
              <p>
                Pending year 7 placement offers are displayed below. To accept
                an offer, click <strong>Proceed</strong> and submit the full
                enrolment application within <strong>14 days</strong> as advised
                to you in the offer email. To decline an offer, click
                <strong>Decline</strong> and submit.
              </p>
            </b-col>
          </b-row>
          <div
            v-for="application in y67tApplications"
            :key="application.application.userId"
            class="mt-4"
          >
            <b-row :class="y67tApplications.length === 1 ? 'pb-6' : 'pb-0'">
              <b-col cols="3" md="2" class="text-center">
                <v-icon class="material-icons-outlined person-icon"
                  >person_outline</v-icon
                >
              </b-col>
              <b-col cols="9" md="10" class="application-details">
                <b-row>
                  <b-col cols="12" md="7">
                    <div class="item">
                      <h3>
                        <strong>{{
                          studentName(application.application.student)
                        }}</strong>
                      </h3>
                      <p>{{ application.application.schoolName }}</p>
                    </div>
                  </b-col>
                  <b-col cols="12" md="5">
                    <div class="item">
                      <div class="item">
                        <b-button
                          variant="outline-primary"
                          :aria-label="
                            applicationAriaLabel('Decline', application, 'Y67T')
                          "
                          @click="
                            declineY67TApplication(
                              application.application.eoiID
                            )
                          "
                          >Decline</b-button
                        >
                      </div>
                    </div>
                    <div class="item">
                      <b-button
                        variant="primary"
                        :aria-label="
                          applicationAriaLabel('Proceed', application, 'Y67T')
                        "
                        @click="
                          proceedY67TApplication(application.application.eoiID)
                        "
                        data-testid="proceed-y67t-offer"
                        >Proceed</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <v-divider v-if="y67tApplications.length > 1"></v-divider>
          </div>
        </div>
        <div
          v-if="hasInProgressApplication && inProgressApplication"
          class="white-card inProgressApplication"
          data-testid="inProgressApplication-section"
        >
          <b-row>
            <b-col cols="3" md="2" class="text-center">
              <v-avatar size="60" color="primary lighten-4">
                <v-icon
                  color="primary"
                  size="40"
                  alt="In progress applications"
                  aria-label="In progress applications"
                >
                  restore
                </v-icon>
              </v-avatar>
            </b-col>
            <b-col cols="9" md="10">
              <h2>Application in progress</h2>
              <p>
                Please submit applications <strong>within 30 days,</strong> so
                the school can process your enrolment application.
              </p>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="3" md="2" class="text-center">
              <v-icon class="material-icons-outlined person-icon"
                >person_outline</v-icon
              >
            </b-col>
            <b-col cols="9" md="10" class="application-details">
              <b-row>
                <b-col cols="12" md="7">
                  <div class="item">
                    <h3>
                      <strong>
                        <span>{{
                          studentName(inProgressApplication.student)
                        }}</span>
                      </strong>
                    </h3>
                    <p>Local area enrolment</p>
                    <p>{{ inProgressApplication.schoolName }}</p>
                  </div>
                </b-col>
                <b-col class="d-flex align-center flex-wrap" cols="12" md="5">
                  <div class="item">
                    <b-button
                      variant="outline-primary"
                      :aria-label="'Cancel ' + resumeLabel"
                      @click="cancelApplication"
                      data-testid="cancel-application"
                      >Cancel</b-button
                    >
                  </div>
                  <div class="item proceedbtn">
                    <b-button
                      variant="primary"
                      :aria-label="'Resume ' + resumeLabel"
                      @click="resumeApplication"
                      data-testid="resume-application"
                      >Resume</b-button
                    >
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import endPoint from '@/store/endPoint'
import Notification from '@/components/app/Notification.vue'
import { API_ERROR_SUBTYPES } from '@/constants'

export default {
  name: 'PendingOffers',
  components: {
    ApplicationBanner,
    Notification
  },
  props: {
    // show cancelled application alert for student
    cancelledApplicationStudentName: {
      type: String
    }
  },
  data() {
    return {
      hasInProgressApplication: false,
      inProgressApplication: null,
      cancelledApplication: this.getCancelledAppNotificationData(
        this.cancelledApplicationStudentName
      )
    }
  },
  computed: {
    ooaOffers() {
      return this.$store.state.ooaStudentApplications || []
    },
    isInProgressApplicationOoa() {
      return (
        this.inProgressApplication &&
        this.inProgressApplication.ooaApplicationID
      )
    },
    ooaStudentApplications() {
      if (this.isInProgressApplicationOoa) {
        return this.ooaOffers.filter(
          (ooaOffer) =>
            ooaOffer.application.ooaApplicationID !==
            this.inProgressApplication.ooaApplicationID
        )
      }
      return this.ooaOffers
    },
    resumeLabel() {
      return `in progress application for ${this.studentName(
        this.inProgressApplication.student
      )} for ${this.inProgressApplication.schoolName}`
    },
    declinedOoaOffers() {
      return this.$store.state.declinedOoaOffers
    },
    declinedY67TOffers() {
      return this.$store.state.declinedY67TOffers
    },
    y67tOffers() {
      return this.$store.state.y67tApplications || []
    },
    isInProgressApplicationY67T() {
      return this.inProgressApplication && this.inProgressApplication.eoiID
    },
    y67tApplications() {
      if (this.isInProgressApplicationY67T) {
        return this.y67tOffers.filter(
          (y67tOffer) =>
            y67tOffer.application.eoiID !== this.inProgressApplication.eoiID
        )
      }
      return this.y67tOffers
    }
  },
  created() {
    if (this.$store.state.auth.userId === '') {
      this.$router.history.push('/login/resume')
    } else {
      this.$store.commit('set', ['hasPendingApplication', false])
      endPoint
        .getFormData(this.$store.state.auth.idToken)
        .then((resp) => {
          if (resp.data.message === 'Lookup successful') {
            this.hasInProgressApplication = true
            this.$store.commit('set', ['hasPendingApplication', true])
            this.inProgressApplication = resp.data.body.application
          }
        })
        .catch((error) => {
          this.$store.dispatch('hideSpinner')
          this.$store.dispatch('setAPIError', {
            error,
            fallbackCode: API_ERROR_SUBTYPES.getApplication
          })
          this.$router.history.push('/error')
        })
    }
  },
  methods: {
    getCancelledAppNotificationData(studentName) {
      if (!studentName) return null

      return {
        type: 'info',
        title: 'Application in progress has been cancelled',
        body: `The in progress application for ${studentName} has been cancelled`
      }
    },
    applicationAriaLabel(action, application, applicationType) {
      const name =
        application.application.student.firstName +
        ' ' +
        application.application.student.familyName
      const schoolName = application.application.schoolName
      const type = applicationType === 'OOA' ? 'out-of-area' : 'year 6 to 7'
      return `${action} pending ${type} application offer for ${name} for ${schoolName}`
    },
    resumeApplication() {
      this.$store.commit('set', ['isOoaApplication', false])
      this.$store.commit('set', ['declinedOoaOffers', []])
      this.$router.history.push('/application')
    },
    cancelApplication() {
      this.$store.dispatch('openModal', {
        componentName: 'ModalCancel',
        logout:
          this.ooaOffers.length ||
          this.isInProgressApplicationOoa ||
          this.y67tOffers.length ||
          this.isInProgressApplicationY67T
            ? false
            : true,
        isOoa: this.isInProgressApplicationOoa,
        isY67T: this.isInProgressApplicationY67T,
        onCancelCallback: this.handleApplicationCancelled,
        application: this.inProgressApplication,
        size: 'small'
      })
    },
    handleApplicationCancelled() {
      this.cancelledApplication = this.getCancelledAppNotificationData(
        this.studentName(this.inProgressApplication.student)
      )
      this.hasInProgressApplication = false
      this.inProgressApplication = null
      this.$store.commit('set', ['studentName', ''])
      this.$store.commit('set', ['schoolName', ''])
      this.$store.commit('set', ['hasPendingApplication', false])

      this.$store.dispatch('getOoaOffers')
      this.$store.dispatch('getY67TOffers')
    },
    proceedOoaApplication(ooaApplicationID) {
      this.$store.commit('set', ['ooaApplicationID', ooaApplicationID])
      let application = this.$store.state.application
        ? this.$store.state.application
        : {}
      const ooaStudentApplications = this.ooaStudentApplications
      let ooaApplication = ooaStudentApplications.filter(
        (application) =>
          application.application.ooaApplicationID === ooaApplicationID
      )
      ooaApplication = ooaApplication[0].application
      application.schoolName = ooaApplication.schoolName
      application.schoolCode = ooaApplication.schoolCode
      this.$store.commit('set', ['application', application])
      this.$store.commit('set', ['declinedOoaOffers', []])
      if (this.hasInProgressApplication) {
        this.notifyInProgressApplication('ModalProceedOoAOffer')
      } else {
        this.$store.commit('set', ['isOoaApplication', true])
        this.$router.history.push('/pilot')
      }
    },
    declineOoaApplication(ooaApplicationID) {
      this.$store.commit('set', ['ooaApplicationID', ooaApplicationID])
      this.$store.dispatch('openModal', {
        componentName: 'ModalDeclineOoAOffer'
      })
    },
    declineY67TApplication(eoiID) {
      this.$store.commit('set', ['y67tApplicationID', eoiID])
      this.$store.dispatch('openModal', {
        componentName: 'ModalDeclineY67TOffer'
      })
    },
    selectedApplication(applicationID) {
      const { application } = this.declinedOoaOffers.find(
        ({ application }) => application.ooaApplicationID === applicationID
      )
      return application
    },
    selectedY67TApplication(eoiID) {
      const { application } = this.declinedY67TOffers.find(
        ({ application }) => application.eoiID === eoiID
      )
      return application
    },
    dismiss(applicationID) {
      const applications = this.declinedOoaOffers.filter(
        (application) =>
          application.application.ooaApplicationID !== applicationID
      )
      this.$store.commit('set', ['declinedOoaOffers', applications])
    },
    notification(applicationID) {
      if (this.declinedOoaOffers.length > 0) {
        const application = this.selectedApplication(applicationID)
        const firstName = application.student.firstName
        const otherName = application.student.otherName
          ? application.student.otherName
          : ''
        const familyName = application.student.familyName
        const name = (firstName + ' ' + otherName + ' ' + familyName).trim()
        const notificationText = {
          title: 'Out-of-area offer declined',
          type: 'info',
          body: `The out of area offer for ${name} has been declined. The school has been notified and a confirmation email has been sent to 
            ${this.$store.state.auth.userId}`
        }
        return notificationText
      }
      return {}
    },
    y67tDismiss(eoiID) {
      const applications = this.declinedY67TOffers.filter(
        (application) => application.application.eoiID !== eoiID
      )
      this.$store.commit('set', ['declinedY67TOffers', applications])
    },
    y67tNotification(eoiID) {
      if (this.declinedY67TOffers.length > 0) {
        const application = this.selectedY67TApplication(eoiID)
        const firstName = application.student.firstName
        const familyName = application.student.familyName
        const name = (firstName + ' ' + familyName).trim()
        const schoolName = application.schoolName.trim()
        const notificationText = {
          title: 'Year 7 placement offer declined',
          type: 'error',
          vHtmlBody: `The year 7 placement offer for ${name} at ${schoolName} has been declined. The school has been notified and a confirmation email has been sent to 
            <b class="underline-text">${this.$store.state.auth.userId}</b>`
        }
        return notificationText
      }
      return {}
    },
    proceedY67TApplication(eoiID) {
      this.$store.commit('set', ['y67tApplicationID', eoiID])
      let application = this.$store.state.application
        ? this.$store.state.application
        : {}
      let y67tApplication = this.y67tApplications.find(
        (application) => application.application.eoiID === eoiID
      )
      y67tApplication = y67tApplication.application
      application.schoolName = y67tApplication.schoolName
      application.schoolCode = y67tApplication.schoolCode
      this.$store.commit('set', ['application', application])
      if (this.hasInProgressApplication) {
        this.notifyInProgressApplication('ModalProceedY67TOffer')
      } else {
        this.$store.commit('set', ['isY67TApplication', true])
        this.$router.history.push('/pilot')
      }
    },
    notifyInProgressApplication(modalName) {
      this.$store.commit('set', [
        'studentName',
        this.studentName(this.inProgressApplication.student)
      ])
      this.$store.commit('set', [
        'schoolName',
        this.inProgressApplication.schoolName
      ])
      this.$store.dispatch('openModal', {
        componentName: modalName
      })
    },
    studentName(studentDetails) {
      if (
        studentDetails &&
        (studentDetails.firstName ||
          studentDetails.otherName ||
          studentDetails.familyName)
      ) {
        const studentFirstName = studentDetails.firstName
          ? studentDetails.firstName.trim()
          : ''
        const studentOtherName = studentDetails.otherName
          ? studentDetails.otherName.trim()
          : ''
        const studentFamilyName = studentDetails.familyName
          ? studentDetails.familyName.trim()
          : ''
        return (
          studentFirstName +
          ' ' +
          studentOtherName +
          ' ' +
          studentFamilyName
        ).trim()
      }
      return 'No name provided'
    }
  }
}
</script>

<style lang="scss" scoped>
.pending-offers {
  position: relative;
  top: $header-height;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  .content {
    margin: 0 auto;
    &.container {
      padding: 40px 15px 15px;
    }
  }
}
.person-icon {
  font-size: 40px;
  padding: 5px 0 0;
}
.white-card.ooa-pending-offers {
  padding: 30px 0 0;
  margin-bottom: 40px;
}
.inProgressApplication {
  margin-bottom: 40px;
  padding: 30px 0;
}
.application-details .item {
  display: inline-block;
  margin-right: 15px;
}
.application-details .item.spacer {
  min-width: 40.1%;
}
.notification-alert {
  max-width: 100%;
  margin-bottom: 40px;
}
::v-deep .underline-text {
  text-decoration: underline;
}
@include desktop {
  .PendingOffers {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }
}
@include tablet-wide {
  .PendingOffers {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }
}
@include tablet {
  .PendingOffers {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }
  }
}
@include mobile {
  .application-details .item {
    display: block;
    width: 100%;
  }
  .application-details .item.proceedbtn {
    text-align: left;
  }
  .white-card,
  .white-card.ooa-pending-offers {
    padding: 30px 10px;
  }
}
</style>
