import _get from 'lodash/get'
import _set from 'lodash/set'

export default {
  // "key" can be a nested structure (e.g. 'student.familyName')
  // which is why an eval is required. ESLint also can't understand
  // the eval, hence the lint disabling comments.

  /* eslint-disable */
  set(state, [key, value]) {
    try {
      // Warn if attempting to set a field which doesn't yet exist in our
      // state. Our state will still update, but the UI will not reflect
      // the change.
      var currentStateVal = _get(state, key)
      if (currentStateVal === undefined) {
        console.warn(
          `STATE Warning - ${key} MUST INITIALLY EXIST IN STATE TO BE REACTIVE`
        )
      }
      // Sets our state value
      _set(state, key, value)
    } catch (ex) {
      console.error(`FAILED TO SET STATE: ${key} = "${value}"`)
    }
  },
  /* eslint-enable */
  setSchoolCustomisation(state, { schoolCode, customisation }) {
    state.schoolCustomisations[schoolCode] = customisation
  }
}
