<template>
  <div class="ApplicationReview">
    <header class="reviewHeading">
      <p>
        Please check through the details of this application carefully. If you
        need to amend anything, please do so before you submit.
      </p>

      <div v-if="alerts.length > 0 && !hasAttemptToSubmit" class="message">
        <font-awesome-icon
          focusable="false"
          class="icon"
          icon="exclamation-circle"
        />Your application is incomplete. Please review the highlighted
        sections.
      </div>
      <div v-else-if="alerts.length > 0 && hasAttemptToSubmit" class="message">
        <font-awesome-icon
          focusable="false"
          class="icon"
          icon="exclamation-circle"
        />There was a problem submitting your application. Please review the
        highlighted sections.
      </div>
    </header>
    <AppMessage
      v-if="isSectionDisabled('emergency')"
      message="Please note that section 7 'Emergency contacts' has not been enabled. In order to view the contents of this section, you need to complete and save section 6 'Parents/carers'."
      type="warning"
    />

    <div class="d-flex justify-end pr-2 pb-4">
      <button
        type="button"
        @click="toggleSections"
        class="sections__toggle"
        ref="preFocus"
      >
        {{ collapsed.length === 0 ? 'Expand all' : 'Collapse all' }}
        <v-icon
          >mdi-{{
            collapsed.length === 0 ? `chevron-down` : `chevron-up`
          }}</v-icon
        >
      </button>
    </div>
    <v-expansion-panels accordion multiple v-model="collapsed" class="sections">
      <v-expansion-panel
        v-for="section in filterSection"
        :key="section.id"
        :class="{ hasAlert: sectionWithAlerts(section) }"
      >
        <template v-if="section.id === 'documents' && !hasDocumentUploaded">
          <v-expansion-panel-header>
            <v-icon size="24" class="alertIcon">mdi-alert-outline</v-icon>
            <h2 style="color: #dc5800">Supporting documents</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <p class="alertText" tabindex="0">
              You haven’t uploaded any supporting documents. If you have
              documents to upload, return to the
              <button
                class="linkButton"
                @click.stop="backToSupportingDocumentsSection"
              >
                Supporting Documents
              </button>
              step to add them now.
            </p>
          </v-expansion-panel-content>
        </template>

        <template v-else>
          <v-expansion-panel-header>
            <h2
              :data-testid="`panel-header-${section.id}`"
              :class="sectionWithAlerts(section) ? 'section__title--error' : ''"
            >
              {{ section.label }}
              <v-icon v-if="sectionWithAlerts(section)" color="red"
                >mdi-alert-circle</v-icon
              >
            </h2>
            <span class="sr-only" v-if="sectionWithAlerts(section)"
              >Incomplete</span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ApplicationReviewSection
              :section="section"
              :fields="section.fields"
              :alerts="alerts"
            />
          </v-expansion-panel-content>
        </template>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="reviewDeclaration__container">
      <div class="reviewDeclaration white-card" tabindex="0">
        <h2 class="reviewDeclaration__heading">Declaration</h2>
        <section class="reviewDeclaration__terms">
          <p>
            I have read and understand the information in this application
            including about the collection of personal information, publishing
            student information and student access to the department's network
            and learning applications.
          </p>
          <p>
            Where I have given personal information about people other than
            myself or my
            <span class="nowrap">child(ren)</span> I have done so with their
            authorisation. I am aware that if information I have given is false
            or misleading, any decision made as a result of this application may
            be changed.
          </p>
          <p>
            I have provided information about the learning and support needs,
            including health condition(s) and/or special need(s) and/or history
            relevant to a risk assessment, related to the student named in
            &lsquo;Student details&rsquo; section of this application form.
          </p>
          <p>
            I consent to the school/Department of Education seeking information
            from previous schools, other NSW government departments, public
            hospitals, health professionals or other organisations that may also
            hold information related to this assessment for the student named in
            &lsquo;Student details&rsquo; section. I consent to the health
            professional(s) treating any medical or health condition identified
            in this application, to provide the school/Department with
            information about any condition that has been identified in this
            application. This may include any other aspects of the
            student&rsquo;s health that may impact on the condition or on the
            health and safety of this student or other students at school or on
            staff at the school.
          </p>
        </section>
        <section class="reviewDeclaration__declaration">
          <p class="reviewDeclaration__declaration-heading">
            Please complete the declaration below:
          </p>

          <AppCheckbox
            :value="declarationAccepted"
            :true-value="true"
            :false-value="false"
            @change="toggleDeclarer"
            :disabled="!curDeclarer"
            :v-html-label="declarationText"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationReviewSection from '@/components/application/ApplicationReviewSection.vue'
import AppMessage from '@/components/app/AppMessage.vue'
import AppCheckbox from '@/components/app/AppCheckbox'
import fieldsMixin from '@/components/mixins/fieldsMixin'

export default {
  name: 'ApplicationReview',
  components: {
    ApplicationReviewSection,
    AppMessage,
    AppCheckbox
  },
  mixins: [fieldsMixin],
  props: {
    sections: {
      type: Array,
      required: false
    },
    alerts: {
      type: Array,
      required: false
    },
    declarer: {
      type: Object,
      required: false
    },
    fields: {
      type: Array,
      required: false
    },
    hasAttemptToSubmit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      curDeclarer: this.declarer,
      collapsed: []
    }
  },
  computed: {
    declarationAccepted() {
      return (
        this.curDeclarer &&
        this.$store.state.application.parentCarerName_1 ===
          `${this.curDeclarer.collectionItemUniqueId}`
      )
    },
    declarerFullName() {
      return (
        this.curDeclarer &&
        `${this.curDeclarer.parentCarerGivenName} ${this.curDeclarer.parentCarerFamilyName}`.trim()
      )
    },
    declarationText() {
      const declarerText =
        this.curDeclarer &&
        `, <strong> ${this.declarerFullName} </strong> of <strong>${this.curDeclarer.parentCarerEmail}</strong>`

      return `<span class="reviewDeclaration__declaration-text">
            I${this.curDeclarer ? declarerText : ''} declare that the
            information provided in this application is, to the best of my
            knowledge and belief, accurate and complete.

          </span>`
    },
    filterSection() {
      return this.sections.filter((section) => section.id !== 'review')
    },
    hasDocumentUploaded() {
      const supportingDocumentFields = this.sections.filter(
        (section) => section.id === 'documents'
      )
      if (supportingDocumentFields.length === 0) {
        return false
      }
      const supportingDocumentPanels =
        supportingDocumentFields[0].fields.filter(
          (field) => field.type === 'PANELS'
        )
      if (supportingDocumentPanels.length === 0) {
        return false
      }
      //  check if at least one document has been uploaded and the checkbox is ticked
      return supportingDocumentPanels[0].panels.some(
        (panel) =>
          this.getValue(panel.fields[0]).length > 0 &&
          this.getValue(panel) === true
      )
    }
  },
  methods: {
    isSectionDisabled(id) {
      this.$emit('isSectionDisabled', { id })
    },
    toggleDeclarer(value) {
      if (value) {
        this.$emit(
          'setFinalDeclarer',
          `${this.curDeclarer.collectionItemUniqueId}`
        )
      } else {
        this.$emit('clearFinalDeclarer')
      }
    },
    toggleSections() {
      if (this.collapsed.length === 0) {
        this.collapsed = [...Array(this.sections.length).keys()].map(
          (k, i) => i
        )
      } else {
        this.collapsed = []
      }
    },
    sectionWithAlerts(section) {
      return (
        this.alerts.filter((item) => item.section.id === section.id).length > 0
      )
    },
    backToSupportingDocumentsSection() {
      const filterSupportingDocuments = this.sections.filter(
        (section) => section.id === 'documents'
      )
      if (filterSupportingDocuments.length === 0) {
        return
      }
      const section = filterSupportingDocuments[0]
      this.$store.dispatch('set', [
        'application.meta.sectionExpandedId',
        section.id
      ])
      this.$scrollTo('#app', 1)
      this.$store.commit('set', ['auth.isUserActive', true])
      this.$store.commit('set', ['auth.isUserActiveTime', new Date().getTime()])
    }
  },
  mounted() {
    try {
      this.$refs.preFocus.focus()
    } catch (error) {
      // console.warn('preFocus failed:', error)
    }

    if (!this.hasDocumentUploaded) {
      let documentSectionIndex = 0
      this.sections.forEach((section, index) => {
        if (section.id === 'documents') {
          documentSectionIndex = index
        }
      })
      this.collapsed = [documentSectionIndex]
    }
  }
}
</script>
<style scoped lang="scss">
.ApplicationReview {
  padding: $content-padding;

  word-wrap: break-word;
  overflow-wrap: break-word;

  .message {
    display: flex;
    margin: $content-padding 0;
    padding: $content-padding;
    border: 1px solid $color-red;
    border-radius: $field-border-radius;
    background-color: $color-red-lighter;
    font-size: 1.6em;

    .icon {
      margin-right: 0.6em;
      margin-top: 0.2em;
      color: $color-red;
      font-size: 2.5rem;
    }
  }

  .reviewHeading {
    padding: $content-padding;

    h2 {
      margin: 0;
      padding-top: $content-padding * 6;
      padding-bottom: 1em;
      font-weight: $text-bold;
      font-size: $text-h2;
    }

    p {
      font-size: $text-h5;
    }

    .small {
      font-size: $text-small;
    }
  }

  .sections {
    padding: 0 1rem;

    &__toggle {
      @include button-reset;
      font-size: 14px;
    }
  }

  .section {
    padding: $content-padding;

    &__title {
      &--error {
        color: $color-red;
      }
    }
  }

  .fields {
    padding: 0 0 $content-padding;
  }

  .reviewDeclaration {
    padding: 2.5rem 4rem 5rem 4rem;

    &__container {
      padding: 1rem;
      margin-top: 2rem;
    }

    &__declaration-heading {
      margin-top: 3rem;
      color: $color-primary;
      font-size: 1.6rem;
      font-weight: bold;
    }

    &__heading {
      font-weight: $text-bold;
      margin-bottom: 2rem;
    }

    &__terms p {
      font-size: 1.2rem;
      line-height: 2rem;
      color: $color-grey-dark-70;
    }

    & ::v-deep {
      .reviewDeclaration__declaration-text {
        font-size: 1.6rem;
        line-height: 2.7rem;
        color: $color-primary;
      }

      .AppCheckbox {
        margin-top: 1rem;
      }

      .AppCheckbox span::before,
      .AppCheckbox span::after,
      input {
        left: 0;
        top: 9px;
      }
    }
  }

  .v-expansion-panel-header {
    background: $color-grey-2;

    &.v-expansion-panel-header--active {
      background: $color-white;
    }
  }

  .alertText {
    margin-left: 2rem;
  }

  .linkButton {
    border: none;
    padding: 0;
    font-weight: 700;
    text-decoration: underline;
  }

  .alertIcon {
    color: $color-orange-2 !important;
    max-width: 24px;
    margin-right: 1rem;
  }
}
</style>
