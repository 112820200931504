<template>
  <ApplicationLoginCard>
    <template #icon>
      <img src="@/assets/icon-nsw-stacked.svg" alt="Service NSW" />
    </template>
    <template #body>
      <div class="snsw_content">
        <span v-if="isResume">
          <h2>Sign in using your Service NSW account</h2>
          <p>
            If you used your
            <strong>Service NSW account</strong>
            to begin your application, login to resume your progress
          </p>
          <p>
            By using this, you will be logged into your Service NSW account.
            When you are done, please remember to go to your service NSW account
            and log out if you are on a public computer.
          </p>
        </span>
        <span v-else>
          <h2>Use your Service NSW account to begin</h2>
          <p>
            Logging in with your
            <strong>Service NSW account</strong>
            will allow you to start the application, save it and come back later
          </p>
          <p>
            By using this, you will be logged into your Service NSW account.
            When you are done, please remember to go to your service NSW account
            and log out if you are on a public computer.
          </p>
        </span>
        <b-button variant="primary" @click="startAuth">Continue</b-button>
      </div>
    </template>
  </ApplicationLoginCard>
</template>

<script>
import ApplicationLoginCard from './ApplicationLoginCard.vue'
import ehubUtil from '@/utils/ehub'

export default {
  name: 'ApplicationSNSWLoginCard',
  components: {
    ApplicationLoginCard
  },
  props: {
    isResume: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    startAuth() {
      this.$auth.SNSW.startAuth()
      //Google Analytics
      const gtmApplication = ehubUtil.getGtmApplication()
      this.$gtm.trackEvent({
        event: 'interaction',
        category: 'Authentication',
        action: 'Attempt',
        label: 'ServiceNSW',
        application: gtmApplication
      })
    }
  }
}
</script>

<style scoped lang="scss">
button {
  position: relative;
  top: -3px;

  &.disabled {
    background-color: #666;
    border-color: #666;
    cursor: not-allowed;
  }
}

.snsw_content {
  display: flex;
  align-items: center;
}

@include tablet {
  .snsw_content {
    flex-direction: column;
  }

  button {
    align-self: flex-end;
  }

  img {
    content: url('~@/assets/icon-snsw-inline.svg');
  }
}
</style>
