<template>
  <div
    v-if="
      field &&
      application &&
      isVisible({ field, application, isRemovedSelecedOption })
    "
    :class="[isErrorClass(field, application), field.className]"
  >
    <label v-if="canFieldUseLabel(field, application)">
      <span class="fieldLabel">
        <span
          class="required-mark"
          aria-hidden="true"
          v-if="getRequired(field, application)"
          >*</span
        >
        <span v-if="field.label">{{ getLabel(field, application) }}</span>
        <span
          v-else-if="field.vHtmlLabel"
          v-html="getVHtmlLabel(field, application)"
        ></span>
      </span>

      <div
        v-if="isSimpleInput(field, application)"
        class="fieldContent fieldText"
      >
        <input
          :aria-required="!!getRequired(field, application)"
          :ref="fieldId"
          :type="getType(field, application) === 'EMAIL' ? 'email' : 'text'"
          :value="getValue(field)"
          :maxlength="getMaxLengthAttribute(field, application)"
          :placeholder="field.placeHolder"
          :readonly="isDisabled(field, application)"
          :aria-disabled="isDisabled(field, application)"
          @blur="setValue(section, field, $event.target.value)"
          :aria-describedby="`${fieldId} ${field.apiKey}`"
        />
      </div>
      <div
        v-if="getType(field, application) === 'TEXTAREA'"
        class="fieldContent"
      >
        <textarea
          :aria-required="!!getRequired(field, application)"
          :rows="getRows(field, application)"
          :ref="fieldId"
          :value="getValue(field)"
          :maxlength="getMaxLengthAttribute(field, application)"
          :placeholder="field.placeHolder"
          @blur="setValue(section, field, $event.target.value)"
          :readonly="isDisabled(field, application)"
          :aria-disabled="isDisabled(field, application)"
          :aria-describedby="`${fieldId} ${field.apiKey}`"
        ></textarea>
      </div>

      <div
        v-if="getType(field, application) === 'DROPDOWN'"
        class="fieldContent fieldSelect"
      >
        <select
          :aria-required="!!getRequired(field, application)"
          :ref="fieldId"
          :value="getValue(field)"
          :class="[{ isPlaceholder: getValue(field) === '' }]"
          @change="onChange(section, field, $event.target.value)"
          :disabled="isDisabled(field, application)"
          :aria-describedby="field.apiKey"
        >
          <option
            :disabled="isDisabled(option, application)"
            v-for="(option, index) in getOptions(field, application, store)"
            :key="typeof option === 'object' ? option.value + index : option"
            :value="typeof option === 'object' ? option.value : option"
          >
            {{ typeof option === 'object' ? option.text : option }}
          </option>
        </select>
      </div>
    </label>

    <fieldset
      v-if="getType(field, application) === 'RADIO'"
      class="fieldContent"
    >
      <legend class="fieldLabel">
        <span
          class="required-mark"
          aria-hidden="true"
          v-if="getRequired(field, application)"
          >*</span
        >
        {{ getLabel(field, application) }}
        <span class="sr-only" v-if="getRequired(field, application)"
          >Required</span
        >
      </legend>

      <AppRadioButton
        v-for="option in getOptions(field, application)"
        :key="option.value"
        :disabled="
          isDisabled(field, application) || isDisabled(option, application)
        "
        :ref="fieldId"
        :label="option.text"
        :checked="getValue(field) === option.value"
        @change="setRadioButton(section, field, option.value, ...arguments)"
        :aria-described-by="fieldId"
      />
    </fieldset>
    <v-card
      v-if="getType(field, application) === 'RADIOGROUP'"
      class="fieldContentRadioGroup"
    >
      <AppRadioButton
        v-for="option in getOptions(field, application)"
        :key="option.value"
        :disabled="
          isDisabled(field, application) || isDisabled(option, application)
        "
        :ref="fieldId"
        :text="option.text"
        :checked="getValue(field) === option.value"
        @change="setRadioButton(section, field, option.value, ...arguments)"
        :aria-described-by="fieldId"
        :heading="option.heading"
        :notification="option.notification"
        :notification-props="option.notificationProps"
        :notification-text="option.notificationText"
        is-radio-group
      />

      <div
        class="smallMessage"
        v-if="field.optionSubtext"
        v-html="getOptionSubtext(field, application)"
      />
    </v-card>

    <AppCheckboxGroup
      v-if="getType(field, application) === 'CHECKBOX_GROUP'"
      :ref="fieldId"
      :field="field"
      :label="getLabel(field, application)"
      :value="getValue(field)"
      @change="setValue(section, field, ...arguments)"
      :options="getOptions(field, application)"
      :aria-described-by="fieldId"
    />

    <AppCheckbox
      v-if="getType(field, application) === 'CHECKBOX'"
      :disabled="isDisabled(field, application)"
      :ref="fieldId"
      :label="getLabel(field, application)"
      :v-html-label="getVHtmlLabel(field, application)"
      :value="getValue(field)"
      @change="setValue(section, field, ...arguments)"
      :true-value="field.trueValue"
      :false-value="field.falseValue"
      :required="getRequired(field, application)"
    />

    <fieldset v-if="getType(field, application) === 'DATE'">
      <legend class="fieldLabel">
        <span
          class="required-mark"
          aria-hidden="true"
          v-if="getRequired(field, application)"
          >*</span
        >
        <span v-if="field.label">{{ getLabel(field, application) }}</span>
        <span
          v-else-if="field.vHtmlLabel"
          v-html="getVHtmlLabel(field, application)"
        ></span>
      </legend>

      <AppDatePicker
        :ref="fieldId"
        :disabled="isDisabled(field, application)"
        :disabled-fields="getDisabledFields(field, application)"
        :label="getLabel(field, application)"
        :value="getValue(field)"
        :select-month="field.selectMonth"
        :future-years="field.futureYears"
        :past-years="field.pastYears"
        :start-year-override="startYear(field, application)"
        :end-year-override="endYear(field, application)"
        :ascending-years="field.ascendingYears"
        @change="setDate(section, field, ...arguments)"
        :required="getRequired(field, application)"
      />
    </fieldset>

    <div v-if="getType(field, application) === 'ADDRESS'">
      <AppAddressFinder
        :value="getValue(field)"
        :disabled="isDisabled(field, application)"
        :required="getRequired(field, application)"
        :field="field"
        :section="section"
        @change="setValue(section, field, ...arguments)"
        :v-html-label="getVHtmlLabel(field, application)"
        :with-po-box="field.withPoBox"
      />
    </div>

    <div v-if="getType(field, application) === 'DOCUMENTS'">
      <FileUpload
        :value="getValue(field)"
        :disabled="isDisabled(field, application)"
        :required="getRequired(field, application)"
        :field="field"
        :section="section"
        @change="setValue(section, field, ...arguments)"
      />
    </div>

    <div v-if="getType(field, application) === 'LOOKUP'">
      <AppSchoolLookup
        :label="getLabel(field, application)"
        :v-html-label="getVHtmlLabel(field, application)"
        :value="getValue(field)"
        :disabled="isDisabled(field, application)"
        :required="getRequired(field, application)"
        :field="field"
        :section="section"
        @change="setValue(section, field, ...arguments)"
      />
    </div>

    <div v-if="getType(field, application) === 'LINKTO'">
      <button
        class="button-link-to primary"
        type="button"
        v-scroll-to="field.value"
      >
        {{ field.label }}
      </button>
    </div>

    <div v-if="getType(field, application) === 'BUTTON'">
      <button
        class="button-link-to primary"
        type="button"
        @click="onButtonClick"
      >
        {{ field.label }}
      </button>
    </div>

    <div v-if="getType(field, application) === 'CUSTOM'">
      <ApplicationFieldCustom :field="field" />
    </div>

    <div
      v-if="getType(field, application) === 'NOTIFICATION'"
      class="notification"
      tabindex="0"
      role="alert"
      aria-labelledby="notification-label"
      aria-describedby="notification-description"
    >
      <Alert
        v-bind="field.notificationProps"
        :html-text="getNotificationText(field, application)"
      />
    </div>

    <div v-if="getAlert().type" class="alertRow" :id="fieldId">
      <font-awesome-icon icon="exclamation-circle" />
      <span class="alertMessage" v-html="getAlert().message"></span>
    </div>

    <div v-if="field.additionalInfo">
      <button
        class="additionalInfoButton"
        type="button"
        @click="openAdditionalInfo(field)"
      >
        {{ field.additionalInfo.linkText }}
      </button>
    </div>

    <div v-if="canFieldUseDescription(field, application)" :id="field.apiKey">
      <div class="smallMessage" v-if="field.description">
        {{ getDescription(field, application) }}
      </div>
      <div
        class="smallMessage"
        v-if="field.vHtmlDescription"
        v-html="getVHtmlDescription(field, application)"
      ></div>
      <ul class="smallMessage" v-if="field.descriptionList">
        <li
          v-for="item in field.descriptionList"
          :key="item"
          v-html="item"
        ></li>
      </ul>
      <ul class="smallMessage" v-if="field.vHtmlDescriptionList">
        <li
          v-for="item in getVHtmlDescriptionList(field, application)"
          :key="item"
          v-html="item"
        ></li>
      </ul>
    </div>

    <div v-if="field.infoPanel">
      <ApplicationInfoPanel>
        <template #icon>
          <img
            :src="require(`../../../src/assets/${field.infoPanel.icon}`)"
            alt="information icon"
            style="width: 80px"
          />
        </template>
        <template #body>
          <h2>{{ field.infoPanel.title(application) }}</h2>
          <p>
            {{ field.infoPanel.textBeforeButton }}
            <button
              class="no-styling open-additional-info"
              @click="openAdditionalInfoFromPanel(field)"
            >
              here
            </button>
            {{ field.infoPanel.textAfterButton }}
          </p>
        </template>
      </ApplicationInfoPanel>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'

import AppAddressFinder from '@/components/app/AppAddressFinder.vue'
import AppCheckbox from '@/components/app/AppCheckbox.vue'
import AppCheckboxGroup from '@/components/app/AppCheckboxGroup.vue'
import AppDatePicker from '@/components/app/AppDatePicker.vue'
import AppRadioButton from '@/components/app/AppRadioButton.vue'
import AppSchoolLookup from '@/components/app/AppSchoolLookup.vue'
import ApplicationFieldCustom from '@/components/application/ApplicationFieldCustom'
import ApplicationInfoPanel from '@/components/application/ApplicationInfoPanel'
import Alert from '@/components/app/AppAlert'

import FileUpload from '@/components/app/FileUpload'
import fieldsMixin from '@/components/mixins/fieldsMixin'
import { FIELD_TYPE } from '@/constants'

export default {
  name: 'ApplicationField',
  components: {
    AppAddressFinder,
    AppCheckbox,
    AppCheckboxGroup,
    AppDatePicker,
    AppRadioButton,
    AppSchoolLookup,
    ApplicationFieldCustom,
    FileUpload,
    ApplicationInfoPanel,
    Alert
  },
  props: {
    field: Object,
    section: Object
  },
  data() {
    return {
      checkedValue: []
    }
  },
  computed: {
    fieldId() {
      return this.section.id + '>' + this.field.apiKey
    },
    application() {
      return this.$store.state.application || {}
    },
    isRemovedSelecedOption() {
      return this.$store.state.isRemovedSelecedOption
    },
    store() {
      return this.$store
    }
  },
  mixins: [fieldsMixin],
  methods: {
    isErrorClass(field, application) {
      // Three possible responses: error field, error child fields, no error
      if (this.getAlert().type) {
        // Address only display child field errors
        return (
          'ApplicationField ' +
          (this.getType(field, application) === 'ADDRESS'
            ? 'hasAlertChild'
            : 'hasAlert')
        )
      } else {
        return 'ApplicationField'
      }
    },
    canFieldUseDescription(field, application) {
      const type = this.getType(field, application)
      return (
        type !== FIELD_TYPE.DOCUMENTS &&
        (field.description ||
          field.descriptionList ||
          field.vHtmlDescription ||
          field.vHtmlDescriptionList)
      )
    },
    canFieldUseLabel(field, application) {
      const type = this.getType(field, application)
      // Only fields with a single input should be wrapped in a label tag
      return (
        type !== 'RADIO' &&
        type !== 'CHECKBOX_GROUP' &&
        type !== 'CHECKBOX' &&
        type !== 'LINKTO' &&
        type !== 'BUTTON' &&
        type !== 'DATE' &&
        type !== 'ADDRESS' &&
        type !== 'LOOKUP' &&
        type !== 'DOCUMENTS' &&
        type !== 'NOTIFICATION' &&
        type !== 'RADIOGROUP'
      )
    },
    isSimpleInput(field, application) {
      const type = this.getType(field, application)
      return (
        type !== 'TEXTAREA' &&
        type !== 'RADIO' &&
        type !== 'CHECKBOX_GROUP' &&
        type !== 'CHECKBOX' &&
        type !== 'DATE' &&
        type !== 'LINKTO' &&
        type !== 'BUTTON' &&
        type !== 'DROPDOWN' &&
        type !== 'ADDRESS' &&
        type !== 'LOOKUP' &&
        type !== 'CUSTOM' &&
        type !== 'DOCUMENTS' &&
        type !== 'NOTIFICATION' &&
        type !== 'RADIOGROUP'
      )
    },
    getRows(field, application) {
      const type = this.getType(field, application)
      if (type === 'TEXTAREA' && !field.maxRows) {
        return 5
      } else {
        return field.maxRows
      }
    },
    getMaxLengthAttribute(field, application) {
      const type = this.getType(field, application)
      // TEXTAREA is deliberately ignored here, as we want to explicity show
      // the error message to users. By adding maxlength="2048" to our inputs
      // we prevent the error message from ever being displayed. The actual
      // length checking is done in `store/buildAlerts.js`
      if (field.maxLength) {
        return field.maxLength
      } else if (type === 'POSTCODE') {
        return '4'
      } else if (type === 'MONTH_YEAR') {
        return '7'
      } else if (type === 'PHONE') {
        return '20'
      }
    },

    getVHtmlDescriptionList(field, application) {
      if (typeof field.vHtmlDescriptionList === 'function') {
        return field.vHtmlDescriptionList(application, this.$store)
      } else if (field.vHtmlDescriptionList) {
        return field.vHtmlDescriptionList
      }
    },

    getType(field, application) {
      if (typeof field.type === 'function') {
        return field.type(application)
      } else if (field.type) {
        return field.type
      }
    },

    onFieldSet(field, val) {
      if (typeof field.onFieldSet === 'function') {
        try {
          field.onFieldSet(val, this.application, this.$store)
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.log(`${field.apiKey} - ERROR IN onFieldSet() CONFIGURATION`)
        }
      }
    },
    formatPhoneNumber(fieldApiKey, val) {
      const phoneNumberApiKeyArray = []
      const doctorPhoneNumberApiKeyArray = []
      for (let i = 0; i < 10; i++) {
        for (let j = 0; j < 10; j++) {
          phoneNumberApiKeyArray.push(
            `parentCarers[${i}].contactDetails[${j}].contactValue`
          )
          phoneNumberApiKeyArray.push(
            `emergencyContact[${i}].contactDetails[${j}].contactValue`
          )
        }
      }

      for (let i = 0; i < 10; i++) {
        doctorPhoneNumberApiKeyArray.push(
          `doctorDetails[${i}].doctorPhoneNumber`
        )
      }

      if (
        fieldApiKey === 'student.mobileNumber' ||
        fieldApiKey === 'healthProfContact[0].doctorPhoneNumber' ||
        phoneNumberApiKeyArray.includes(fieldApiKey) ||
        doctorPhoneNumberApiKeyArray.includes(fieldApiKey)
      ) {
        // landline number, eg: 13 00 00
        if (val.replace(/\s/g, '').length === 6) {
          val = val.replace(/\W/gi, '').replace(/(.{2})(.{2})/g, '$1 $2 ')
        }

        // landline number, eg: 1234 4321
        if (val.replace(/\s/g, '').length === 8) {
          val = val
            .replace(/\W/gi, '')
            .replace(/(.{4})/g, '$1 ')
            .trim()
        }

        if (val.replace(/\s/g, '').length === 10) {
          // for mobile and Australia-wide landline number. eg: 0400 000 000 || 1300 975 707
          if (
            val.replace(/\W/gi, '').substring(0, 2) === '04' ||
            val.replace(/\W/gi, '').substring(0, 2) === '05' ||
            val.replace(/\W/gi, '').substring(0, 2) === '13' ||
            val.replace(/\W/gi, '').substring(0, 2) === '18'
          ) {
            val = val.replace(/\W/gi, '').replace(/(.{4})(.{3})/g, '$1 $2 ')
          }
          // for landline area code, eg: 02 1234 4321
          if (
            val.replace(/\W/gi, '').substring(0, 2) === '02' ||
            val.replace(/\W/gi, '').substring(0, 2) === '03' ||
            val.replace(/\W/gi, '').substring(0, 2) === '07' ||
            val.replace(/\W/gi, '').substring(0, 2) === '08'
          ) {
            val = val.replace(/\W/gi, '').replace(/(.{2})(.{4})/g, '$1 $2 ')
          }
        }
      }
      return val
    },
    removeNonAsciiCharacters(val) {
      // eslint-disable-next-line no-control-regex
      return val ? val.replace(/[^\x00-\x7F]/g, '') : val
    },
    setValue(section, field, val, force) {
      const oVal = _get(this, `$store.state.application.${field.apiKey}`)
      const commentsApiKeyArray = []

      if (oVal !== val || force) {
        //converting ’ back to ' to disable iphone's smart punctuation for name fields - FUS-85
        if (field.type === FIELD_TYPE.NAME) {
          val = val.replace(/’/g, "'")
        }
        // medicare number format adjust
        if (field.apiKey === 'student.medicare[0].number') {
          val = val.replace(/\W/gi, '').replace(/(.{4})(.{5})/g, '$1 $2 ')
        }
        if (field.type === FIELD_TYPE.PHONE) {
          val = this.removeNonAsciiCharacters(val)
        }

        // see https://jira.education.nsw.gov.au/browse/FUS-773 for restricting max length chars on the real Android mobile.
        // loop all the parent carers comments field
        for (let i = 0; i < 10; i++) {
          for (let j = 0; j < 10; j++) {
            commentsApiKeyArray.push(
              `parentCarers[${i}].contactDetails[${j}].comments`
            )
            commentsApiKeyArray.push(
              `emergencyContact[${i}].contactDetails[${j}].comments`
            )
          }
        }
        if (
          field.maxLength &&
          commentsApiKeyArray.includes(field.apiKey) &&
          val.length > field.maxLength
        ) {
          val = val.slice(0, field.maxLength)
        }

        // reformat phone number, see https://jira.education.nsw.gov.au/browse/FUS-628
        if (field.type === FIELD_TYPE.PHONE && /^[0-9]+$/.test(val)) {
          val = this.formatPhoneNumber(field.apiKey, val)
        }

        this.$store.dispatch('set', [`application.${field.apiKey}`, val])

        if (field.onFieldSet && !field.onFieldSetAfterAlerts) {
          this.onFieldSet(field, val)
        }
        this.$store.dispatch('addAlert', { section, field })

        this.$store.dispatch('buildCounters')
        this.$store.dispatch('buildAlerts')

        if (field.onFieldSet && field.onFieldSetAfterAlerts) {
          this.onFieldSet(field, val)
        }
        // When the user update scholasticYear, we should reset the selectedSreOrSeeOrAmaOption value
        if (field.apiKey === 'scholasticYear') {
          this.$store.dispatch('set', [
            `application.selectedSreOrSeeOrAmaOption`,
            ''
          ])
        }
        if (field.apiKey === 'selectedSreOrSeeOrAmaOption' && val) {
          this.$store.dispatch('set', [`isRemovedSelecedOption`, false])
        }
        this.$store
          .dispatch('saveApplicationSection', { section, type: '' })
          .catch(() => {
            this.$store.dispatch('set', ['saveStatus', null])
            this.$store.dispatch('set', [
              'error',
              new Error('Error when auto-saving: Could not reach server')
            ])

            this.$router.history.push('/error')
          })
      }
    },
    onChange(section, field, val) {
      if (typeof field.onChange === 'function') {
        try {
          field.onChange(val, this.$store)
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.log(`${field.apiKey} - ERROR IN onChange() CONFIGURATION`)
        }
      }
      this.setValue(section, field, val)
    },
    setDate(section, field, val) {
      this.setValue(section, field, val)
    },
    setRadioButton(section, field, val, checked) {
      this.setValue(section, field, checked ? val : '')
    },
    getAlert() {
      var alerts = [...this.$store.state.alerts]
      var alert = alerts.find(
        (a) =>
          a.section.id === this.section.id &&
          a.field.apiKey === this.field.apiKey
      )
      return alert || {}
    },
    setFocus() {
      var input = this.$refs[this.fieldId]
      if (!input.tagName) {
        // TODO: Refactor to make less brittle
        if (input.length) {
          // Find selected radio button...
          var selectedRadioButton = input.find((el) => el.checked)
          input = selectedRadioButton.$el.control || input[0].$el.control
        } else {
          // Find first select tag in date picker
          input = input.$el.firstChild.firstChild
        }
      }
      input.focus()
    },
    openAdditionalInfo(field) {
      this.$store.dispatch('openModal', field.additionalInfo)
    },
    openAdditionalInfoFromPanel(field) {
      this.$store.dispatch('openModal', field.infoPanel)
    },
    onButtonClick(event) {
      this.field.onClick.call(this, event)
    },
    getNotificationText(field, application) {
      return field.text(application)
    }
  }
}
</script>

<style lang="scss" scoped>
.ApplicationField {
  display: inline-block;
  margin-bottom: 3.5rem;
  padding: 0 $content-padding;
  width: 100%;
  vertical-align: top;

  label {
    display: block;
  }

  input,
  textarea {
    &:read-only {
      background: $color-grey-10;
      color: $color-primary;
    }
  }

  input,
  textarea,
  select {
    width: 100%;

    &.isPlaceholder {
      color: $color-text-light;
    }

    &:disabled {
      background: $color-grey-10;
      color: $color-primary;
    }
  }

  .fieldContentRadioGroup {
    padding: 1.25rem 2rem 3rem 3rem;
    @include mobile {
      padding: 1.25rem 1.5rem 3rem;
    }
  }
  .fieldContent {
    padding-top: 0.25rem;
    font-size: $text-small;
  }

  ::v-deep .fieldText,
  ::v-deep .fieldSelect {
    width: 100%;

    @include desktop {
      width: 50%;
    }
  }

  button.button-link-to {
    margin: 0;
    padding: 0;
    min-width: 7rem;
    border: 0;
    background: transparent !important;
    color: $color-primary;
    font-size: 1.6rem;
  }

  ::v-deep .fieldLabel {
    display: block;
    margin: 0 0 0.6rem 0;
    color: $color-text-body;
    font-weight: normal;
    font-size: $text-h5;

    > span.required-mark {
      color: $color-red;
    }

    .required-mark {
      float: left;
    }
  }

  &.fieldInline {
    display: inline-block;
    width: auto;
  }
  &.hasAlertChild {
    padding-left: 0 !important;
  }

  &.hasAlert,
  &.hasAlertChild ::v-deep .error-style {
    padding-left: 0.5rem;
    border-left: 0.5em solid $color-red;
  }

  .alertRow {
    display: flex;
    padding-top: 0.5rem;

    svg {
      position: relative;
      top: -0.5rem;
      height: 3em;
      color: $color-red;
    }
  }

  .alertMessage ::v-deep {
    display: inline-block;
    padding-left: 0.25rem;
    color: $color-red;
    font-size: $text-small;
    .link {
      color: $color-red;
    }
  }

  .icon {
    margin-top: 0.2em;
    margin-right: 0.6em;
    color: $color-red;
    font-size: 2.5rem;
  }

  .smallMessage,
  ::v-deep .smallMessage {
    display: block;
    padding-top: 0.8rem;
    font-size: $text-small;

    ::v-deep > p {
      font-size: $text-small;
    }

    ::v-deep > p:last-child {
      margin-bottom: 0;
    }
  }

  .smallMessage ::v-deep .link-outline {
    &:focus {
      outline-style: auto;
    }
  }

  .additionalInfoButton {
    margin: 0;
    padding: 0.8rem 0 0;
    border: none;
    background: transparent;
    color: $color-primary;
    font-weight: 700;
    font-size: 1.4rem;
    text-decoration: underline;
  }

  ::v-deep .review-only-label {
    @include sr-only;
  }

  .radioGroupLabel {
    font-size: 18px;
    font-weight: bold;
    color: #002040;
  }
}

.boxWhite {
  background: $color-white;
  padding: 1rem;
}

::v-deep .boxGrey {
  background: $color-grey-10;
  padding: 1rem;
  border-radius: 0.2rem;
  color: $color-primary;
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.textInfo {
  font-size: 14px;
}

::v-deep .sreSeeLink {
  &:focus {
    border: 2px solid $color-primary;
    text-decoration: none;
  }
}

.notification {
  margin-bottom: -2.5rem;
}
</style>
