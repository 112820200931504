export function extractContentBetweenParentheses(inputString) {
  if (!inputString) {
    return ''
  }
  const firstParenthesis = inputString.indexOf('(')
  const lastParenthesis = inputString.lastIndexOf(')')
  // Check if parentheses exist in the string
  if (firstParenthesis === -1) {
    return ''
  } else if (lastParenthesis === -1) {
    return inputString.substring(firstParenthesis + 1)
  }
  return inputString.substring(firstParenthesis + 1, lastParenthesis)
}
