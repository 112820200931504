import { render, staticRenderFns } from "./ApplicationReviewPanels.vue?vue&type=template&id=0b61ed2d&scoped=true&"
import script from "./ApplicationReviewPanels.vue?vue&type=script&lang=js&"
export * from "./ApplicationReviewPanels.vue?vue&type=script&lang=js&"
import style0 from "./ApplicationReviewPanels.vue?vue&type=style&index=0&id=0b61ed2d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b61ed2d",
  null
  
)

export default component.exports