/* eslint-disable no-console */

import axios from 'axios'
import axiosRetry, { isRetryableError, isNetworkError } from 'axios-retry'

const xhr = axios.create()

export function exponentialDelay(baseDelay = 100) {
  return (retryNumber = 0) => {
    const delay = Math.pow(2, retryNumber) * baseDelay
    const randomSum = delay * 0.2 * Math.random() // 0-20% of the delay
    return delay + randomSum
  }
}

function oesRetryableErrors(err) {
  let retryableErrors = [400, 401, 403, 404, 500, 502, 504]
  return retryableErrors.includes(err.response.status)
}

//retries iff network error or 5xx status
axiosRetry(xhr, {
  retries: 2,
  retryDelay: exponentialDelay(300),
  retryCondition: (e) =>
    isNetworkError(e) || isRetryableError(e) || oesRetryableErrors(e)
})

// Global XHR error handler
xhr.interceptors.response.use(null, globalXHRError)

function globalXHRError(err) {
  if (err.response) {
    /**
     * The request was made and the server responded with a status code that
     * falls out of the range of 2xx
     */
    console.log(err.response.data)
    console.log(err.response.status)
    console.log(err.response.headers)
  } else if (err.request) {
    /**
     * The request was made but no response was received. `error.request` is an
     * instance of XMLHttpRequest in the browser and an instance of
     * http.ClientRequest in node.js
     */
    console.log(err.request)
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error:', err.message)
  }

  console.log(err.config)

  return Promise.reject(err)
}

/*like xhr.get() but takes in an array of URLs instead of a single URL.
  Calls xhr.get() on each URL in order until a valid response is returned
  or in the case of no valid response it returns the final error caught.
 */
xhr.getWithFallbacks = async function (urls, config) {
  let urlAttempt = 0
  let response
  let error = new Error('No URLs provided')

  while (urlAttempt < urls.length) {
    try {
      response = await xhr.get(urls[urlAttempt], config)
      break
    } catch (e) {
      error = e
      urlAttempt++
    }
  }
  if (response) {
    return response
  }
  return Promise.reject(error)
}

export default xhr
