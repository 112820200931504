import { REFDATA_MAP } from '@/constants'
import _ from 'lodash'

function refData(data) {
  let normalised = {}

  //divider for dropdown list
  let divider = {
    value: null,
    text: '---------------------------------',
    disabled: true
  }

  if (!Array.isArray(data)) return normalised

  // Remap from retrieved API key/vals to PI key/vals
  data.forEach((parent) => {
    normalised[parent.name] = parent.data.map((child) => ({
      text: child.value,
      value: child.code
    }))
  })

  //language dropdown list - combine the languages with the popular languages, placeholder and divider
  let languagesPopular = normalised[REFDATA_MAP.LANGUAGES_POPULAR]
  let allLanguages = normalised[REFDATA_MAP.LANGUAGES]
  normalised[REFDATA_MAP.LANGUAGES] = languagesPopular
    .concat([divider], allLanguages)
    .filter((l) => l.value !== 'XXX' && l.value !== 'YYY')

  //Alergies dropdown list - remove the unwanted prefix from API
  normalised[REFDATA_MAP.MEDICAL_CONDITION_ALLERGIES] = normalised[
    REFDATA_MAP.MEDICAL_CONDITION_ALLERGIES
  ].map((a) => {
    let capitalizedText = _.upperFirst(a.text.replace('Allergy- ', ''))
    return { ...a, text: capitalizedText }
  })

  //Country List - add Australia on top with divider
  normalised[REFDATA_MAP.COUNTRIES] = [
    {
      value: 'AUS',
      text: 'Australia'
    },
    divider
  ].concat(normalised[REFDATA_MAP.COUNTRIES])

  //Medical condtions - sort by alphabetical order
  let sortedMedicalCondtions = _.sortBy(
    normalised[REFDATA_MAP.MEDICAL_CONDITION_NAME],
    'text'
  )
  normalised[REFDATA_MAP.MEDICAL_CONDITION_NAME] = sortedMedicalCondtions

  // Visa class, Visa subclasses - Prefix descriptions with codes
  normalised[REFDATA_MAP.TEMP_VISA_CLASS].map(
    (subclass) => (subclass.text = `${subclass.value} - ${subclass.text}`)
  )
  normalised[REFDATA_MAP.VISA_SUB_CLASS].map(
    (subclass) => (subclass.text = `${subclass.value} - ${subclass.text}`)
  )
  return normalised
}

function scholasticYears(data) {
  let normalised = {}

  if (!Array.isArray(data)) return normalised

  // Remap from retrieved API key/vals to PI key/vals and structure
  data.forEach((parent) => {
    normalised[parent.calenderYear] = parent.scholasticYears.map((child) => ({
      text: child.schoolYearName,
      value: child.schoolYearCode
    }))
  })

  return normalised
}

export default {
  refData,
  scholasticYears
}
