// See README.md for more info about field configuration

import { FIELD_TYPE } from '@/constants'

import { getFirstName, getLabelName, sanitize } from './helper'

const PERMISSION = [
  { value: true, text: 'Yes, I give permission' },
  { value: false, text: 'No, I do not give permission' }
]

export default [
  {
    label: 'Online services & publishing',
    vHtmlDescription(application) {
      const name = sanitize(getFirstName(application))
      return (
        '<p>' +
        getLabelName(
          'This section explains how we provide ',
          name,
          ' with protected access to the department&rsquo;s network and learning applications.</p><p>Here we ask for your permission for the school to publish information about',
          'the student'
        ) +
        getLabelName(' ', name, '.', 'the student') +
        getLabelName(
          ' If you change your mind in the future, you can always contact the school to find out how ',
          name,
          '&rsquo;s needs can be met.',
          'the student'
        ) +
        '</p>'
      )
    },
    type: FIELD_TYPE.HEADING
  },
  {
    vHtmlDescription() {
      return '<h2>Permission to publish</h2>'
    },
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'permissionToPublish',
    label(application) {
      const name = sanitize(getFirstName(application))
      return getLabelName(
        'I have read the information about publishing student information (below) and I give permission for the school/Department to publish information about ',
        name,
        ' in publicly accessible communications.',
        'the student'
      )
    },
    vHtmlDescription(application) {
      const name = sanitize(getFirstName(application))
      return (
        '<p>' +
        getLabelName(
          'The school/Department may publish information about ',
          name,
          ' for the purposes of sharing his/her experiences with other students, informing the school and broader community about school and student activities and recording student participation in noteworthy projects or community service.',
          'the student'
        ) +
        '</p><p>' +
        getLabelName(
          'This information may include ',
          name,
          '&rsquo;s name, age, class and information collected at school such as photographs, ',
          'the student'
        ) +
        getLabelName(
          'sound and visual recordings of ',
          name,
          ',',
          'the student'
        ) +
        getLabelName(
          ' ',
          name,
          '&rsquo;s work and expressions of opinion such as interactive media. ',
          'the student'
        ) +
        getLabelName(
          'The communications in which ',
          name,
          '&rsquo;s information may be published include but are not limited to:',
          'the student'
        ) +
        '</p>'
      )
    },
    descriptionList: [
      'Public websites of the Department including the school website, the Department&rsquo;s intranet (staff only), blogs and wikis.',
      'Departmental publications including the school newsletter, annual school magazine and school report, promotional material published in print and electronically including on the Department websites.',
      'Official departmental and school social media accounts on networks such as the school&rsquo;s YouTube, Facebook and Twitter pages.'
    ],
    required: true,
    type: FIELD_TYPE.RADIO,
    options: PERMISSION
  },
  {
    vHtmlDescription(application) {
      const name = sanitize(getFirstName(application))
      return (
        '<h2 class="pb-2">Online applications & services</h2><p>The department provides students with filtered access to the internet. Students also have access to a secure learning portal. After logging into their portal, students have access to a personalised email account and online applications. These resources enable students to collaborate with peers, publish online, and securely store their data for access within, or outside of, school.</p><p>' +
        getLabelName(
          'When accessing some online services, data, including ',
          name,
          '&rsquo;s name and files they have saved, are stored with the online application service provider in a location outside of the department’s network. The department has worked closely with online application providers to assess privacy impacts and data security controls.',
          'the student'
        ) +
        '</p><p>Individual schools may choose to use additional Applications to deliver student education or manage enrolment and behaviour and in which case they will contact you to advise you of those Applications and, where required, to obtain your permission.</p>'
      )
    },
    type: FIELD_TYPE.HEADING
  }
]
