// See README.md for more info about field configuration

import {
  FIELD_TYPE,
  SUPPLEMENTARY_DETAILS_NOTIFICATION,
  SUPPLEMENTARY_DETAILS_ALERT
} from '@/constants'
import { getFirstName, getSchoolName, getLabelName } from './helper'

export default [
  {
    label: 'Supplementary details',
    vHtmlDescription() {
      return '<p aria-label="description" role="heading" tabindex="0" style="color: #002040; font-weight: 800; font-size: 2.0rem;">Special Religious Education and Special Education in Ethics participation</p>'
    },
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'SreSeeAmaAlert',
    type: FIELD_TYPE.NOTIFICATION,
    notificationProps: {
      inPage: true,
      type: 'error'
    },
    text() {
      return SUPPLEMENTARY_DETAILS_ALERT
    },
    visible({ isRemovedSelecedOption }) {
      return isRemovedSelecedOption
    }
  },
  {
    type: FIELD_TYPE.NOTIFICATION,
    notificationProps: {
      inPage: true,
      type: 'info',
      elevation: 0,
      alertClass: 'blue-alert'
    },
    text() {
      return SUPPLEMENTARY_DETAILS_NOTIFICATION
    }
  },
  {
    label: 'Supplementary details',
    vHtmlDescription(application) {
      const name = getFirstName(application)
      const schoolName = getSchoolName(application)
      return (
        '<p aria-label="description" role="heading" tabindex="1" style="color: #002040;">A feature of the public education system in NSW is the opportunity to provide time in class for education in ethics, faith and morality from a religious or non-religious perspective of your choice.</p>' +
        '<p aria-label="description" role="heading" tabindex="2" style="color: #002040;">' +
        getLabelName(
          'If you do not wish for ',
          name,
          ' to participate in Special Religious Education, please select Special Education in Ethics or Alternative Meaningful Activities. '
        ) +
        'You may at any time notify the school in writing if you wish to change your decision. ' +
        getLabelName(
          null,
          name,
          ' will continue the same arrangement as the previous year, unless you request a change in writing.'
        ) +
        '</p>' +
        '<br><p aria-label="description" role="heading" tabindex="3" style="color: #002040; font-weight: 800; font-size: 1.8rem;">Please choose one of the following options currently available at ' +
        schoolName +
        '.</p>'
      )
    },
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'selectedSreOrSeeOrAmaOption',
    label: 'SRE/SEE Preference',
    optionSubtext(application, store) {
      const schoolName = getSchoolName(application)
      const { local } = store.state.schoolCustomisation.data
      const { schoolSreSeeUrl } = local
      return `
        <p class="mt-2 "><span class="smallMessage">
          If your preferred option is not available please contact the approved provider.
          </span></p>
        <p><span class="smallMessage">
          For more information, including the list of approved providers, please visit the 
          <a rel="noopener noreferrer" class="sreSeeLink" href="https://education.nsw.gov.au/teaching-and-learning/curriculum/religion-and-ethics" target="_blank">
            Religion and ethics website</a>${
              schoolSreSeeUrl
                ? ' or the <a target="_blank" class="sreSeeLink" href="' +
                  schoolSreSeeUrl +
                  '">' +
                  schoolName +
                  ' website</a>'
                : ''
            }.
            </span></p>
      </div>
        
      `
    },
    required: false,
    type: FIELD_TYPE.RADIO_GROUP,
    options() {
      const sreSeeOptions = this.getSREOptions()
        .concat(this.getSEEOptions())
        .concat(this.getAMAOptions())
        .filter((option) => !!option)
      return sreSeeOptions
    }
  },
  {
    label: 'Supplementary details',
    vHtmlDescription() {
      return '<p aria-label="description" style="color: #002040; font-size: 1.4rem;" role="heading" tabindex="7">If you selected Special Religious Education or Special Education in Ethics, you confirm that, in doing so, you consent to the Department providing your child’s name to the nominated approved provider. The Department is required to obtain your consent under section 26 of the Privacy and Personal Information Protection Act 1998 (NSW) so that it does not have to comply with section 19, which would otherwise apply to prevent the Department from providing your child’s name.</p>'
    },
    type: FIELD_TYPE.HEADING
  }
]
