<template>
  <ApplicationReview
    :declarer="declarer"
    :sections="sections"
    :fields="fields"
    :alerts="alerts"
    :has-attempt-to-submit="hasAttemptToSubmit"
    @isSectionDisabled="isSectionDisabled"
    @setFinalDeclarer="setFinalDeclarer"
    @clearFinalDeclarer="clearFinalDeclarer"
  />
</template>

<script>
import ApplicationReview from '@/components/application/ApplicationReview.vue'

export default {
  name: 'ContainerApplicationReview',
  components: {
    ApplicationReview
  },
  computed: {
    declarer() {
      const parentCarers = this.$store.state.application.parentCarers

      // The declarer will be whoever is defined as completing the application. We also only want
      // to use them if they have their family and given name defined
      return parentCarers.find(
        (pc) =>
          pc.meta.isCompletingApplication &&
          pc.parentCarerGivenName &&
          pc.parentCarerFamilyName
      )
    },
    sections() {
      return this.$store.state.sections
    },
    fields() {
      return this.$store.state.fields
    },
    alerts() {
      return this.$store.state.alerts || []
    },
    hasAttemptToSubmit() {
      return this.$store.getters.hasAttemptToSubmit
    }
  },
  methods: {
    isSectionDisabled(id) {
      if (id === 'emergency') {
        let numParents = this.$store.state.counters.filter((val) => {
          return val.section.id === 'parents'
        }).length

        if (numParents > 0) return true
      }

      return false
    },
    setFinalDeclarer(declarer) {
      const finalDeclarer = declarer || ''

      let today = new Date()
      let dd = today.getDate()

      let mm = today.getMonth() + 1
      const yyyy = today.getFullYear()
      if (dd < 10) {
        dd = `0${dd}`
      }

      if (mm < 10) {
        mm = `0${mm}`
      }
      // `parentCarerName_1` is reserved for parentCarer declaring the form as accurate
      this.$store.dispatch('set', [
        'application.parentCarerName_1',
        finalDeclarer
      ])
      this.$store.dispatch('set', ['application.hasStudentDeclaration', true])
      this.$store.dispatch('set', [
        'application.studentDeclarationDate',
        `${yyyy}-${mm}-${dd}`
      ])
    },
    clearFinalDeclarer() {
      this.$store.dispatch('set', ['application.parentCarerName_1', ''])
      this.$store.dispatch('set', ['application.hasStudentDeclaration', false])
      this.$store.dispatch('set', ['application.studentDeclarationDate', ''])
    }
  },
  mounted() {
    this.$store.commit('set', ['auth.isUserActive', true])
    this.$store.commit('set', ['auth.isUserActiveTime', new Date().getTime()])

    this.$gtm.trackView('ReviewView', `/review`)

    //if no declarer or the declarer does not match what's set in the store (e.g. because they were removed)
    //then clear declarer variables in store
    if (
      !this.declarer ||
      (this.declarer &&
        this.$store.state.application.parentCarerName_1 !==
          `${this.declarer.collectionItemUniqueId}`)
    ) {
      this.clearFinalDeclarer()
    }
  }
}
</script>
