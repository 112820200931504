<template>
  <div class="APIError">
    <ErrorContent
      :error-code="errorCode"
      :error-text="errorText"
      :error-id="errorId"
      :include-stack-trace="includeStackTrace"
      :stack-trace="stackTrace"
      :previous-route="previousRoute"
    >
      <template #footer>
        <div v-if="!fromEhub">
          <p>
            Please
            <button class="btn-link" @click="handleTryAgain">try again</button>
            later or
            <a
              href="https://enrolmentassist.freshdesk.com/support/tickets/new"
              rel="external noopener"
              target="_blank"
              >contact our support team</a
            >
            for further assistance.
          </p>
        </div>
        <div v-else>
          <ErrorCard
            v-if="isFromResumeRoute"
            :error-code="errorCode"
            :heading-text="errorText"
            :error-id="errorId"
            :previous-route="previousRoute"
          >
          </ErrorCard>
          <p v-else>
            Please
            <a class="error__link" href="/dashboard">return to the dashboard</a>
            to continue.
          </p>
        </div>
      </template>
    </ErrorContent>
  </div>
</template>

<script>
import ErrorContent from './ErrorContent.vue'
import ErrorCard from './ErrorCard.vue'
import { API_ERROR_SUBTYPES } from '@/constants'
import ehubUtil from '@/utils/ehub'

export default {
  name: 'APIError',
  props: {
    error: {
      type: Object,
      default: () => ({})
    },
    includeStackTrace: {
      type: Boolean,
      default: false
    },
    previousRoute: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      API_ERROR_SUBTYPES
    }
  },
  components: {
    ErrorContent,
    ErrorCard
  },
  computed: {
    errorObject() {
      return this.error.errorObject || {}
    },
    stackTrace() {
      return this.errorObject.stack || null
    },
    response() {
      return this.error.response || {}
    },
    errorList() {
      return (this.response.data && this.response.data.errors) || []
    },
    codeText() {
      return (
        (this.errorList[0] && this.errorList[0].error_code) ||
        this.error.fallbackCode
      )
    },
    codeStatus() {
      return (
        (this.errorList[0] && this.errorList[0].status) ||
        (this.isNetworkError(this.errorObject) && 'NET') ||
        this.response.status
      )
    },
    errorCode() {
      return (
        (this.codeText &&
          this.codeStatus &&
          `${this.codeText}-${this.codeStatus}`) ||
        null
      )
    },
    errorId() {
      return (this.errorList[0] && this.errorList[0].id) || null
    },
    errorText() {
      switch (this.codeText) {
        case this.API_ERROR_SUBTYPES.initiateAuth:
          return 'Sorry, we are unable to authenticate you right now.'
        case this.API_ERROR_SUBTYPES.submit:
          return 'Sorry, we are unable to submit your application right now.'
        case this.API_ERROR_SUBTYPES.getApplication:
          return 'Sorry, we are unable to retrieve your application at this time.'
        case this.API_ERROR_SUBTYPES.cancelApplication:
          return 'Sorry, we are unable to cancel your application right now.'
        case this.API_ERROR_SUBTYPES.proceedApplication:
          return 'Sorry, we are unable to proceed with your application right now.'
        case this.API_ERROR_SUBTYPES.oesPilotSchoolJson:
        case this.API_ERROR_SUBTYPES.oesPropertiesJson:
          return 'Sorry, this application is currently unavailable.'
        default:
          return 'An error has occurred and we’re trying to fix the problem.'
      }
    },
    fromEhub() {
      return ehubUtil.isFromEhub()
    },
    isFromResumeRoute() {
      if (this.previousRoute && 'name' in this.previousRoute) {
        return this.previousRoute.name === 'EHubResume'
      } else {
        return false
      }
    }
  },
  methods: {
    handleTryAgain() {
      switch (this.codeText) {
        case this.API_ERROR_SUBTYPES.getApplication:
          // To handle the oauth login case where the prevousRoute will be set as '/login/auth' after users login
          this.$router.push('/login/resume')
          return
        case this.API_ERROR_SUBTYPES.oesPropertiesJson:
          this.$store
            .dispatch('getOESProperties')
            .catch(() => this.$router.history.push('/error'))
          this.navigateToPrevPage()
          return
        case this.API_ERROR_SUBTYPES.submit:
        case this.API_ERROR_SUBTYPES.initiateAuth:
        default:
          this.navigateToPrevPage()
      }
    },
    navigateToPrevPage() {
      return this.previousRoute.name
        ? this.$router.push(this.previousRoute.fullPath)
        : this.$router.go(-1)
    },
    isNetworkError(e) {
      return e.message === 'Network Error'
    }
  }
}
</script>

<style lang="scss" scoped>
.typed-email-headline {
  color: $color-red;
  line-height: normal;
  font-weight: bold;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
</style>
