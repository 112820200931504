import loginMixin from '@/components/mixins/login'
import validateParams from './utils/validateParams'
import AuthPersist from './utils/AuthPersist'
import authCrypto from './utils/authCrypto'
import { createAuthRouteInterceptor, generateAuthUrl } from './utils/authRoute'

function createStartAuthCodeFlow({ urls, params }, authPersist, authProvider) {
  return async () => {
    const codeVerifier = authCrypto.randomString(128)
    const codeChallenge = await authCrypto.generateChallenge(codeVerifier) //SHA256 -> Base64URL
    const codeChallengeMethod = 'S256'
    const authURL = generateAuthUrl({
      urlBase: urls.authorise,
      params: {
        ...params,
        code_challenge: codeChallenge,
        code_challenge_method: codeChallengeMethod
      }
    })
    authPersist.setAuthValues(codeVerifier, authProvider)
    window.location = authURL
  }
}

function createStartImplicitFlow({ urls, params }, authPersist, authProvider) {
  return async () => {
    const nonce = authCrypto.randomString(16)
    const nonceHash = await authCrypto.generateHashHex(nonce)
    const authURL = generateAuthUrl({
      urlBase: urls.authorise,
      params: { ...params, nonce: nonceHash }
    })
    authPersist.setAuthValues(nonce, authProvider)
    window.location = authURL
  }
}

function createStartAuth(authProviderName, authProviderInfo, authPersist) {
  return authProviderInfo.params.response_type === 'code'
    ? createStartAuthCodeFlow(authProviderInfo, authPersist, authProviderName)
    : createStartImplicitFlow(authProviderInfo, authPersist, authProviderName)
}

/* PARAMS:
 *        router (required): Vue Router. Needed to add auth router interceptor.
 *
 *        authProviderConfig (required): Object. Contains the config for each auth provider. Each entry of the form:
 *
 *                                       [serviceProviderName]: { urls: {}, params: {} }
 *
 *                                       [serviceProviderName] should be the name of the auth provider and is used to call
 *                                       startAuth (e.g. SNSW).
 *
 *                                       'urls' is an object which must have an 'authorise' key that points to the authorise
 *                                       endpoint of the auth provider. If using auth code flow you must also provide a
 *                                       'token' key that points to the token endpoint of the auth provider.
 *
 *                                       'params' is an object that contains the require query parameters to be sent in
 *                                       the initial OAuth authorise request. Must contain a client_id. Will default to
 *                                       include OIDC scopes and setup for implicit flow.
 *
 *        appStateToPersist (optional): Function that returns an Object. Used to persist app state so that it is available after the
 *                           redirect from the initial OAuth authorise request. The contents of the object are stored in
 *                           sessionStorage and made available to the 'onAuthorisedRedirect' function.
 *
 *        storagePrefix (optional): String. Used to prefix variables persisted in sessionStorage in order to stop conflicts.
 *                                  Defaults to 'auth'.
 *
 *        onAuthorisedRedirect (optional): Function. This is called after successfully obtaining the relevant tokens from
 *                                         an auth provider. First argument is a token object that will contain all tokens
 *                                         received from auth provider:
 *
 *                                         { access: '...', refresh: '...', id: '...'}
 *
 *                                         Second argument is all persistedState including those provided in appStateToPersist.
 *
 *        onRedirectError (optional): Function. This is called with an error object if an error occurs after the redirect back
 *                                    from the OAuth provider.
 * USAGE:
 *       Add to Vue by calling Vue.use(OAuth, params). To begin the OAuth process for a particular [authProvider] given in
 *       the 'authProviderConfig' of the params, make the following call: this.$auth.[authProvider].startAuth()
 *       e.g. this.$auth.SNSW.startAuth(). The relevant flow will be used and onAuthorisedRedirect(tokens, persistedState)
 *       will be called once completed.
 * */

export default {
  install(Vue, params) {
    const {
      router,
      authProviderConfig,
      storagePrefix,
      onAuthorisedRedirect,
      onRedirectError,
      appStateToPersist
    } = params

    validateParams(params)

    const authPersist = new AuthPersist(appStateToPersist, storagePrefix)

    //Initialise auth for each auth provider given in params
    Vue.prototype.$auth = {}
    Object.entries(authProviderConfig).map(([name, info]) => {
      info.params = {
        response_type: 'token', //Default to implicit
        scope: 'openid profile email', //Default to include OIDC scopes
        ...info.params,
        redirect_uri: `${location.origin}/` //We override the redirect URI to work with hash routing and our route interceptor
      }

      Vue.prototype.$auth[name] = {
        ...info,
        startAuth: createStartAuth(name, info, authPersist)
      }
    })

    //Add router watcher for
    router.beforeEach(
      createAuthRouteInterceptor(
        authPersist,
        onAuthorisedRedirect,
        onRedirectError,
        authProviderConfig
      )
    )

    //Add mixin for use in OTP and OAuth
    Vue.mixin(loginMixin)
  }
}
