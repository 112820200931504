import _merge from 'lodash/merge'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { v4 as uuidv4 } from 'uuid'

import { FIELD_TYPE } from '@/constants'

import {
  purgeEmptyFromObject,
  clearHiddenFieldsFromSection,
  clone,
  createDraftApplicationId
} from '@/applicationDefinition/helper'
import applicationSections from '@/applicationDefinition/applicationSections'
import endPoint from './endPoint'
import {
  ERROR_CODES,
  DATE_FORMAT,
  CHOOSE_DATE,
  API_ERROR_SUBTYPES
} from '../constants'
import { addAlert, buildAlerts, buildCounters } from './buildAlerts'
import xhr from './xhr'
import normalise from '@/mock/helper/normalise'
import mockApplication from '@/mock/application'
import moment from 'moment'
import { sessionStorageUtils, localStorageUtils } from '@/utils/browserStorage'

const API_BASE = process.env.VUE_APP_API_BASE

function xhrError(dispatch, message) {
  dispatch('hideSpinner')
  alert(message || 'Server did not respond')
}

export default {
  // "set" is a generic action for setting a single
  // state value.
  set({ commit }, keyValueArray) {
    commit('set', keyValueArray)
  },
  // "setError" Matching the content of the error message to the appropriate errorCode
  setError({ commit }, error) {
    commit('set', ['error', error])
  },
  setAPIError({ commit }, { error, fallbackCode }) {
    commit('set', [
      'error',
      {
        errorObject: error,
        response: error.response,
        fallbackCode,
        type: ERROR_CODES.API
      }
    ])
  },
  resetError({ commit }) {
    commit('set', ['error', null])
  },
  showSpinner({ commit }) {
    commit('set', ['showSpinner', true])
  },
  hideSpinner({ commit }) {
    commit('set', ['showSpinner', false])
  },

  openMenu({ commit }) {
    commit('set', ['showOverlay', true])
    commit('set', ['showMenu', true])
  },
  closeMenu({ commit }) {
    commit('set', ['showMenu', false])
    commit('set', ['showOverlay', false])
  },

  setSaveStatus({ commit }, status) {
    commit('set', ['saveStatus', status])
  },

  showMessageBox({ commit }, options) {
    commit('set', ['messageBox', options])
  },
  hideMessageBox({ commit }) {
    commit('set', ['messageBox', {}])
  },

  alertBox(store, options) {
    alert(options.message)
  },

  openModal({ commit }, options) {
    commit('set', ['modal', options])
  },
  closeModal({ commit }) {
    commit('set', ['modal', {}])
  },
  setFocus({ commit }, focusID) {
    commit('set', ['focusID', ''])
    commit('set', ['focusID', focusID])
  },

  setInitialVitalData({ commit }, data) {
    commit('set', [
      'application',
      {
        residentialAddress: data.address,
        scholasticYear: data.selectedScholasticYear,
        schoolCode: data.school.schoolCode,
        catchmentLevel: data.school.catchmentLevel,
        schoolName: data.school.schoolName,
        meta: { calendarYear: data.calendarYear }
      }
    ])

    commit('set', [
      'currentApplication',
      {
        residentialAddress: data.address,
        scholasticYear: data.selectedScholasticYear,
        schoolCode: data.school.schoolCode,
        catchmentLevel: data.school.catchmentLevel,
        schoolName: data.school.schoolName,
        meta: { calendarYear: data.calendarYear }
      }
    ])
  },

  setScholasticYear({ commit }, selectedScholasticYear) {
    commit('set', ['application.scholasticYear', selectedScholasticYear])

    commit('set', ['currentApplication.scholasticYear', selectedScholasticYear])
  },

  setUser({ commit }, data) {
    commit('set', ['user', data])
  },
  setWelcome({ commit }) {
    commit('set', [
      'welcomePage',
      {
        test: 'test'
      }
    ]),
      commit('set', ['showSpinner', true])
  },

  /**
   * Cross verifing with SRE/SEE partipation cretiria's
   *   1) as school enables with sre_see flag
   *   2) school webcustomisation file loaded & has the data of schoolData object
   *   3) Is SRE or SEE options available or not
   *   4) Checking Parent selected scholasticYear is associated with Participation option
   *
   */
  async getSections({ commit, state, getters }) {
    let isSchoolOfferSreSee = state.isSchoolOfferSreSee
      ? state.isSchoolOfferSreSee
      : false
    const filteredAppSections = applicationSections.filter(
      (s) =>
        s.id !== 'sibling' &&
        (isSchoolOfferSreSee || s.id !== 'supplementaryDetails')
    )
    const filteredSreAppSections = applicationSections.filter(
      (s) => isSchoolOfferSreSee || s.id !== 'supplementaryDetails'
    )
    if (getters.isEhubLinkedStudent) {
      await commit('set', ['sections', filteredAppSections])
    } else {
      await commit('set', ['sections', filteredSreAppSections])
    }
  },

  getApplication({ commit, state, dispatch }) {
    commit('set', ['application', null])

    const addBlankTemplateRecords = (fields, mock, data) => {
      // Recursively updates our empty application template to ensure each
      // record collection has a blank record for every record in the real data.
      // This allows the real application data to then be merged with our blank
      // template.

      fields.map((field) => {
        if (field.type === FIELD_TYPE.COLLECTION) {
          // try to resolve name
          try {
            let fieldMock = _get(mock, field.apiKey)
            const fieldData = _get(data, field.apiKey)

            // only for larger then 1
            if (fieldData && fieldData.length > 1) {
              // make mock same size as data
              for (let i = 0; fieldMock.length < fieldData.length; i++) {
                // dont mutate field
                fieldMock.push(JSON.parse(JSON.stringify(field.newItem)))
              }

              // recursion for complex object
              if (field.fields) {
                fieldData.map((item, index) => {
                  addBlankTemplateRecords(field.fields(data, index), mock, data)
                })
              }
            }
          } catch (ex) {
            // there are some fields empty
          }
        }
      })
    }

    const parentCarerAltToParentCarer = (application) => {
      if (application.meta.isAltParent && application.parentCarersAlt) {
        application.parentCarers = application.parentCarers.concat(
          application.parentCarersAlt
        )
        delete application.parentCarersAlt
      }
    }

    const setParentCarerResidentialAddress = (application) => {
      application.parentCarers.forEach((parentCarer) => {
        if (parentCarer.isEnrolmentOwner) {
          parentCarer.residentialAddress = application.residentialAddress
        }
      })
    }

    const changeEnrolmentOwnerName = (application, name, userEmail) => {
      const loginParentIndex = application.parentCarers.findIndex(
        (p) => p.parentCarerEmail === userEmail
      )
      if (loginParentIndex != -1) {
        application.parentCarers[loginParentIndex].parentCarerFamilyName =
          name.lastName
        application.parentCarers[loginParentIndex].parentCarerGivenName =
          name.firstName
      } else {
        application.parentCarers[0].parentCarerFamilyName = name.lastName
        application.parentCarers[0].parentCarerGivenName = name.firstName
        application.parentCarers[0].parentCarerEmail = userEmail
        application.parentCarers[0].meta.isCompletingApplication = true
      }
    }

    // Resume OOA application
    if (state.isOoaApplication) {
      const ooaApplicationID = state.ooaApplicationID
      const ooaStudentApplications = state.ooaStudentApplications
      let { application } = ooaStudentApplications.find(
        ({ application }) => application.ooaApplicationID === ooaApplicationID
      )
      const enteredDate =
        application.intendedStartDate === CHOOSE_DATE
          ? application.enterStartDate
          : application.intendedStartDate
      // If the intended start date in the OoA EOI is less than today's date, set calendar year as current year
      const calendarYear =
        moment(enteredDate) < moment().startOf('day')
          ? moment().year()
          : moment(enteredDate, DATE_FORMAT).year()

      // If the intended start date in the OoA EOI is less than today's date, field should be left blank
      if (
        application.intendedStartDate !== CHOOSE_DATE &&
        moment(application.intendedStartDate) < moment().startOf('day')
      ) {
        application.intendedStartDate = null
      }
      if (
        application.intendedStartDate === CHOOSE_DATE &&
        moment(application.enterStartDate) < moment().startOf('day')
      ) {
        application.intendedStartDate = null
        application.enterStartDate = null
      }

      application.meta = {
        calendarYear: calendarYear
      }
      application.parentCarers.forEach((parentCarer) => {
        parentCarer.meta = {
          isCompletingApplication: parentCarer.isCompletingApplication
        }
      })
      let result = clone(mockApplication)
      result = _merge(result, application)
      parentCarerAltToParentCarer(result)
      setParentCarerResidentialAddress(result)
      commit('set', ['application', result])
      // save core application once OOA application is resumed
      // once the application is saved this application will be removed from the pending offers list
      dispatch('set', ['saveStatus', 'saving'])
      endPoint.createApplication(state.auth.idToken, result).then(() => {
        dispatch('set', ['saveStatus', 'saved'])
      })
    } else if (state.isY67TApplication) {
      const y67tApplicationID = state.y67tApplicationID
      let { application } = state.y67tApplications.find(
        ({ application }) => application.eoiID === y67tApplicationID
      )
      application.parentCarers.forEach((parentCarer) => {
        parentCarer.meta = {
          isCompletingApplication: parentCarer.isCompletingApplication
        }
      })
      application.intendedStartDate = null
      application.meta = {
        calendarYear: moment(application.dateReceived).year() + 1
      }
      application.catchmentLevel = 'secondary'
      let result = clone(mockApplication)
      result = _merge(result, application)
      parentCarerAltToParentCarer(result)
      setParentCarerResidentialAddress(result)
      if (!result.scholasticYear) {
        result.scholasticYear = '7'
      }
      commit('set', ['application', result])
      // save core application once Y67T application is resumed
      // once the application is saved this application will be removed from the pending offers list
      dispatch('set', ['saveStatus', 'saving'])
      endPoint.createApplication(state.auth.idToken, result).then(() => {
        dispatch('set', ['saveStatus', 'saved'])
      })
    } else {
      return endPoint.getFormData(state.auth.idToken).then((resp) => {
        if (
          resp.data.message === 'No pending applications found for this user'
        ) {
          dispatch('setError', [
            'No pending applications',
            ERROR_CODES.NO_PENDING
          ])
          throw {
            type: ERROR_CODES.NO_PENDING,
            message: 'No pending applications'
          }
        } else if (resp.data.body) {
          // Merge populated application values into empty template (so we have full
          // json application structure)
          let result = clone(mockApplication)
          state.sections.map((section) => {
            addBlankTemplateRecords(
              section.fields,
              result,
              resp.data.body.application
            )
          })

          // This ia to add the default contact details when the user doesn't edit the contact details and resume the application.
          // For details: https://jira.education.nsw.gov.au/browse/FUS-1042
          let resumeApplicaition = resp.data.body.application
          if (resumeApplicaition && resumeApplicaition.parentCarers) {
            const contactDetails = [
              {
                contactDetailRecordNo: null,
                contactType: '',
                contactValue: '',
                comments: ''
              }
            ]
            const parentCarers = resumeApplicaition.parentCarers.map(
              (parentCarer) => {
                if (!parentCarer.contactDetails) {
                  parentCarer = { ...parentCarer, contactDetails }
                }
                return parentCarer
              }
            )
            resumeApplicaition = { ...resumeApplicaition, parentCarers }
          }
          result = _merge(result, resumeApplicaition)

          parentCarerAltToParentCarer(result)
          if (state.ernEnrolmentOwnerName) {
            changeEnrolmentOwnerName(
              result,
              state.ernEnrolmentOwnerName,
              state.auth.userId
            )
          }
          setParentCarerResidentialAddress(result)
          commit('set', ['application', result])
          dispatch('setScholasticYear', state.application.scholasticYear)
        }
      })
    }
  },
  getOoaOffers({ state, commit }) {
    return endPoint
      .getOOAOffers(state.auth.idToken, state.school.schoolCode)
      .then((resp) => {
        const ooaApplications = resp.data.studentApplications
        commit('set', ['ooaStudentApplications', ooaApplications])
        return ooaApplications
      })
      .catch((err) => {
        if (err.message === 'Network Error' || err.response.status !== 400) {
          this.$router.history.push('/error?type=500')
        }
      })
  },
  getY67TOffers({ state, commit }) {
    return endPoint
      .getY67TOffers(state.auth.idToken, state.school.schoolCode)
      .then((resp) => {
        const y67tApplications = resp.data.studentApplications
        commit('set', ['y67tApplications', y67tApplications])
        return y67tApplications
      })
      .catch((err) => {
        if (err.message === 'Network Error' || err.response.status !== 400) {
          this.$router.history.push('/error?type=500')
        }
      })
  },

  initiateAuth({ commit }, { email, recaptchaVerifyToken }) {
    return endPoint
      .getAuthCognitoSession(email, recaptchaVerifyToken)
      .then((resp) => {
        // Save userID and cognitoSession to store
        commit('set', ['auth.userId', email])
        commit('set', ['auth.cognitoSession', resp.data.body.cognitoSession])
      })
  },

  getReferenceData({ commit }) {
    return endPoint
      .getReferenceData()
      .then((res) => commit('set', ['refData', normalise.refData(res.data)]))
  },

  getOESProperties({ commit, dispatch }) {
    return endPoint
      .getOESProperties()
      .then((res) => commit('set', ['oesProperties', res.data]))
      .catch((error) => {
        error = new Error('Network Error')
        dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.oesPropertiesJson
        })
        this.$router.history.push('/error')
      })
  },

  getTermDates({ commit }) {
    return endPoint
      .getTermDates()
      .then((res) => commit('set', ['termDates', res.data]))
  },

  getScholasticYears({ commit, state }) {
    return endPoint
      .getScholasticYears(state.application.schoolCode)
      .then((res) =>
        commit('set', ['scholasticYears', normalise.scholasticYears(res.data)])
      )
  },
  getScholasticYearsBySchoolCode({ commit }, schoolCode) {
    return endPoint
      .getScholasticYears(schoolCode)
      .then((res) =>
        commit('set', ['scholasticYears', normalise.scholasticYears(res.data)])
      )
  },
  getPilotSchools() {
    return endPoint.getPilotSchools()
  },

  getSchool({ dispatch }, schoolCode) {
    dispatch('set', ['localSchools', []]) //Clear local schools

    if (!schoolCode) {
      dispatch('setError', {
        type: ERROR_CODES.NO_SCHOOL_CODE,
        errorObject: new Error('schoolCode must be set')
      })

      return { isError: true, msg: 'schoolCode must be set' }
    }

    return endPoint
      .getPilotSchools()
      .then((res) => {
        const oesSchools = res.data

        if (!Array.isArray(oesSchools) || oesSchools.length === 0) return

        const school = oesSchools.find(
          (item) => String(item.schoolCode) === String(schoolCode)
        )

        if (!school) {
          dispatch('setError', {
            type: ERROR_CODES.NO_SCHOOL_CODE,
            errorObject: new Error(
              `Cannot find school with schoolCode (${schoolCode})`
            )
          })

          return {
            isError: true,
            msg: `Cannot find school with schoolCode (${schoolCode}`
          }
        }

        school.initialCatchmentLevel = school.catchmentLevel //We change catchmentLevel for central schools, so we need a way to track it was originally central

        dispatch('set', ['school', school])
        dispatch('set', ['oesSchools', oesSchools])

        sessionStorage.setItem('lastSchool', JSON.stringify(school))
        return school
      })
      .catch((error) => {
        error = new Error('Network Error')
        dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.oesPilotSchoolJson
        })
        this.$router.history.push('/error')
      })
  },

  cancelApplication({ state }) {
    return endPoint.cancelApplication(state.auth.idToken)
  },

  buildAlerts(store) {
    buildAlerts(store)
  },
  addAlert(store, data) {
    let { commit, state } = store
    addAlert(commit, state, data.section, data.field, store)
  },

  buildCounters(store) {
    buildCounters(store)
  },

  toggleSection({ commit, state }, section) {
    commit('set', [
      'application.meta.sectionExpandedId',
      section.id !== state.application.meta.sectionExpandedId
        ? section.id
        : null
    ])

    commit('set', ['auth.isUserActive', true])
    commit('set', ['auth.isUserActiveTime', new Date().getTime()])
  },

  createApplication({ dispatch, state }) {
    // Package data for sending
    let putData = clone(state.application)

    // Purge empty as ERM require
    const putDataPurge = purgeEmptyFromObject(putData)
    const draftApplicationId = createDraftApplicationId(state.auth.userId)
    putDataPurge.draftApplicationId = draftApplicationId

    dispatch('showSpinner')

    return endPoint
      .createApplication(state.auth.idToken, putDataPurge)
      .then(() => {
        // Clear counters on successful section save
        dispatch('set', ['counters', []])
        dispatch('hideSpinner')
      })
  },

  saveFullFormInOTP({ dispatch, state }) {
    if (state.showSpinner) return

    // Package data for sending
    let putData = clone(state.application)

    // Purge empty as ERM require
    const putDataPurge = purgeEmptyFromObject(putData)

    dispatch('showSpinner')

    return xhr
      .put(
        `${API_BASE}/applications/self`,
        {
          application: putDataPurge
        },
        {
          headers: {
            Pragma: 'no-cache',
            Authorization: `Bearer ${state.auth.idToken}`
          }
        }
      )
      .then(() => {
        // Clear counters on successful section save
        dispatch('set', ['counters', []])
        dispatch('hideSpinner')
      })
  },

  saveApplicationSection({ dispatch, state }, data) {
    const saveSectionId = data.section.id
    if (state.application) {
      if (!state.application.scholasticYear) {
        // if application.scholasticYear empty set value of currentApplication.scholasticYear into it.
        dispatch('setScholasticYear', state.currentApplication.scholasticYear)
      } else {
        // if application.scholasticYear not empty set currentApplication.scholasticYear with latest application.scholasticYear,
        // bcoz application.scholasticYear can be change by dropdown, so need to set latest value
        dispatch('setScholasticYear', state.application.scholasticYear)
      }
    }

    // TODO(PK): Don't rely on text strings
    if (state.saveStatus === 'Saving...') return

    // eslint-disable-next-line
    const processMerge = (field, oData, nData) => {
      // Special case: GROUPs
      if (field.type === FIELD_TYPE.GROUP) {
        field.fields().forEach((field) => processMerge(field, oData, nData))
        return
      }

      // Ignore heading field
      if (field.type !== FIELD_TYPE.HEADING) {
        _set(oData, field.apiKey, _get(nData, field.apiKey))
      }
    }

    // Package data for send
    let putData = clone(state.application)

    data.section.fields.map((field) => {
      processMerge(field, putData, state.application)
    })

    // Only override section if next section is specified
    putData.meta.sectionExpandedId = state.application.meta.sectionExpandedId

    if (data.type === 'next') {
      const SectionIndex = state.sections.findIndex(
        (item) => item.id === saveSectionId
      )
      putData.meta.sectionExpandedId = state.sections[SectionIndex + 1].id
    }

    // Purge empty fields (ERN requirement)
    const putDataPurge = purgeEmptyFromObject(putData)

    dispatch('set', ['saveStatus', 'saving'])

    // Endpoint refactor
    return endPoint
      .saveApplication(state.auth.idToken, putDataPurge)
      .then(() => {
        dispatch('set', ['saveStatus', 'saved'])
        // Navigate to next section
        if (data.type === 'next') {
          //clear the hidden fields
          let clearedApplication = clearHiddenFieldsFromSection(
            data.section.fields,
            state.application
          )
          dispatch('set', ['application', clearedApplication])

          dispatch('set', [
            'application.meta.sectionExpandedId',
            putData.meta.sectionExpandedId
          ])
        } else if (data.type === 'close') {
          window.close()
        }
      })
  },

  gotoApplicationSection({ dispatch, state }, data) {
    if (state.showSpinner) {
      return
    }

    // package data for send
    let putData = clone(state.application)

    // only override section if next section is specified
    if (data.type === 'next') {
      const SectionIndex = state.sections.findIndex(
        (item) => item.id === data.section.id
      )
      putData.meta.sectionExpandedId = state.sections[SectionIndex + 1].id
    }

    // purge empty as ERM require
    const putDataPurge = purgeEmptyFromObject(putData)

    dispatch('showSpinner')
    xhr
      .put(
        `${API_BASE}/applications/self`,
        {
          application: putDataPurge
        },
        {
          headers: {
            Pragma: 'no-cache',
            Authorization: `Bearer ${state.auth.idToken}`
          }
        }
      )
      .then(() => {
        dispatch('hideSpinner')

        // merge back into application once successful
        dispatch('set', ['application', putData])

        // navigate to next section
        if (data.type === 'next') {
          dispatch('set', [
            'application.meta.sectionExpandedId',
            putData.meta.sectionExpandedId
          ])
        } else if (data.type === 'close') {
          window.close()
        }
      })
      .catch(function (error) {
        xhrError(dispatch, error)
      })
  },

  pushNotification({ commit, state }, notification) {
    const id = uuidv4()
    const newNotification = { ...notification, id }
    const notifications = state.notificationQueue || []
    commit('set', ['notificationQueue', [...notifications, newNotification]])
    return id
  },

  popNotification({ commit, state }) {
    const notifications = [...state.notificationQueue] || []
    notifications.shift()
    commit('set', ['notificationQueue', notifications])
  },

  dismissNotification({ commit, state }, idOrIndex) {
    const notifications = [...state.notificationQueue] || []
    let index
    if (typeof idOrIndex === 'number') {
      //idOrIndex is ths index
      index = idOrIndex
    } else {
      //idOrIndex is ths id
      index = notifications.findIndex(
        (notification) => notification.id === idOrIndex
      )
    }
    if (index !== -1) {
      notifications.splice(index, 1)
      commit('set', ['notificationQueue', notifications])
    }
  },

  logout({ commit, state }) {
    // delete the tokens in session storage which have been saved by EHUB to retrieve application when landing on PI.
    // those tokens have been used to identify users are from ehub.
    sessionStorageUtils.removeItems(['idToken', 'comesFrom', 'refreshToken'])
    localStorageUtils.removeItems(['id_token', 'refresh_token'])

    commit('set', ['auth.idToken', null])
    commit('set', ['auth.refreshToken', null])
    commit('set', ['alerts', []])
    //save the school code and catchmentlevel to redirect users to school enrolment landing page
    commit('set', [
      'school',
      {
        ...state.school, // FUS-380 - save other school details for resuming after log out
        schoolCode: state.application
          ? state.application.schoolCode
          : state.school.schoolCode,
        catchmentLevel: state.application
          ? state.application.catchmentLevel
          : state.school.catchmentLevel,
        schoolName: state.application
          ? state.application.schoolName
          : state.school.schoolName
      }
    ])
  },

  setUploadingStatus({ commit, state }, uploadObject) {
    commit('set', [
      'uploadStatuses',
      { ...state.uploadStatuses, ...uploadObject }
    ])
  },

  getErnRecordBySRN({ state }, srn) {
    return endPoint.getErnRecordBySRN(state.auth.idToken, srn)
  },

  getPrefillDataBySRN({ state }, srn) {
    return endPoint.getPrefillDataBySRN(state.auth.idToken, srn)
  },

  getPrefillDataByNames({ state }, student) {
    return endPoint.getPrefillDataByNames(state.auth.idToken, student)
  },

  getPrefillDataForNewStudent({ state }) {
    return endPoint.getPrefillDataForNewStudent(state.auth.idToken)
  },

  refreshToken({ commit, state }) {
    return endPoint
      .getAuthRefreshToken(state.auth.userId, state.auth.refreshToken)
      .then((response) => {
        commit('set', ['auth.idToken', response.data.body.idToken])
        commit('set', ['auth.idTokenTime', new Date().getTime()])
        return response
      })
  },

  /**
   *  load school webcustomisation file by schoolCode and store the response
   */
  async getSchoolCustomisation({ commit }, schoolCode) {
    return await endPoint.getSchoolSettingsJson(schoolCode).then((data) => {
      commit('set', ['schoolCustomisation', { schoolCode, data }])
      return data
    })
  },

  /**
   * prepareSchoolOfferSREAndSEE by verifing with SRE/SEE partipation cretiria's
   *   1) as school enables with sre_see flag
   *   2) school webcustomisation data file loaded & has the data of schoolData object
   *   3) Is SRE or SEE options available or not
   *   4) Checking Parent selected scholasticYear is associated with Participation option
   *
   */
  prepareSchoolOfferSREAndSEE({ commit, state, dispatch }, param) {
    let validSREOptionsFilteredByScholasticYear = []
    let validSEEOptionsFilteredByScholasticYear = []
    let validAMAOptions = []
    let schoolData = state.schoolCustomisation
      ? state.schoolCustomisation.data
      : ''
    let isRemovedSelecedOption = false
    const scholasticYear = param.scholasticYear

    if (
      param.sre_see &&
      schoolData &&
      schoolData.local &&
      (schoolData.local.sre || schoolData.local.see)
    ) {
      if (schoolData.local.sre && schoolData.local.sre.length > 0) {
        validSREOptionsFilteredByScholasticYear = schoolData.local.sre.filter(
          (opt) => opt.scholasticYears.includes(scholasticYear)
        )
        // SRE options ordered by alphabetical order by title.
        if (
          validSREOptionsFilteredByScholasticYear &&
          validSREOptionsFilteredByScholasticYear.length > 0
        ) {
          validSREOptionsFilteredByScholasticYear.sort(function (a, b) {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
        }
      }
      if (schoolData.local.see && schoolData.local.see.length > 0) {
        validSEEOptionsFilteredByScholasticYear = schoolData.local.see.filter(
          (opt) => opt.scholasticYears.includes(scholasticYear)
        )
        // SRE options ordered by alphabetical order by title.
        if (
          validSEEOptionsFilteredByScholasticYear &&
          validSEEOptionsFilteredByScholasticYear.length > 0
        ) {
          validSEEOptionsFilteredByScholasticYear.sort(function (a, b) {
            if (a.providers[0] < b.providers[0]) {
              return -1
            }
            if (a.providers[0] > b.providers[0]) {
              return 1
            }
            return 0
          })
        }
      }

      validAMAOptions = schoolData.local.ama ? schoolData.local.ama : []
    }
    const isSchoolOfferSreSee =
      validSREOptionsFilteredByScholasticYear.length > 0 ||
      validSEEOptionsFilteredByScholasticYear.length > 0

    commit('set', ['validSREOptions', validSREOptionsFilteredByScholasticYear])
    commit('set', ['validSEEOptions', validSEEOptionsFilteredByScholasticYear])
    commit('set', [
      'validAMAOptions',
      isSchoolOfferSreSee ? validAMAOptions : []
    ])
    commit('set', ['isSchoolOfferSreSee', isSchoolOfferSreSee])

    dispatch('getSections')
    // For resuming application that the user has alreaday selected a SRE/SEE/AMA option
    if (state.application && state.application.selectedSreOrSeeOrAmaOption) {
      const selectedOption = state.application.selectedSreOrSeeOrAmaOption
        .trim()
        .split(':')
      const sreSeeAmaSelectionLabel = selectedOption[0].trim()
      const sreSeeAmaSelectionName =
        selectedOption[1] && selectedOption[1].trim().split('(')[0]
          ? selectedOption[1].trim().split('(')[0].trim()
          : null

      if (sreSeeAmaSelectionLabel === 'SRE') {
        const selectedSreOption = validSREOptionsFilteredByScholasticYear.find(
          (option) => option.name === sreSeeAmaSelectionName
        )
        isRemovedSelecedOption = selectedSreOption
          ? !selectedSreOption.scholasticYears.includes(scholasticYear)
          : true
        commit('set', ['isRemovedSelecedOption', isRemovedSelecedOption])
      }
      if (sreSeeAmaSelectionLabel === 'SEE') {
        const selectedSeeOption = validSEEOptionsFilteredByScholasticYear.find(
          (option) => option.name === sreSeeAmaSelectionName
        )
        isRemovedSelecedOption = selectedSeeOption
          ? !selectedSeeOption.scholasticYears.includes(scholasticYear)
          : true

        commit('set', ['isRemovedSelecedOption', isRemovedSelecedOption])
      }

      if (sreSeeAmaSelectionLabel === 'AMA') {
        isRemovedSelecedOption = !isSchoolOfferSreSee
        commit('set', ['isRemovedSelecedOption', isRemovedSelecedOption])
      }

      isRemovedSelecedOption &&
        commit('set', [
          'application.meta.visitedSections',
          state.application.meta.visitedSections.filter(
            (vs) => vs !== 'supplementaryDetails'
          )
        ])

      if (isRemovedSelecedOption || !isSchoolOfferSreSee) {
        commit('set', ['application.selectedSreOrSeeOrAmaOption', ''])
      }
    }
  },

  /**
   * This function helps to clear the Supplementary Details Data if incase it's exist
   *
   */
  async clearSupplementaryDetailsCacheData({ commit }) {
    // SET with empty and isSchoolOfferSreSee flag false
    commit('set', ['validSREOptions', []])
    commit('set', ['validSEEOptions', []])
    commit('set', ['validAMAOptions', []])
    commit('set', ['isSchoolOfferSreSee', false])
  }
}
