// See README.md for more info about field configuration

import { FIELD_TYPE, REFDATA_MAP } from '@/constants'

import { YES_NO } from '../droplistData/boolean'

import { LOTE } from '../droplistData/lote'

import _get from 'lodash/get'

import {
  getLabelName,
  getContactNumberFields,
  getParentContactText,
  getFirstName
} from '../helper'
import { dropDownSelect } from '../droplistData/helper'

export const createParent = () => {
  return {
    parentCarerRecordNo: null, //TODO: unused?
    isEnrolmentOwner: null, //This is used to indicate if the parent lives with the student
    parentCarerRelation: '',
    parentCarerTitle: '',
    parentCarerFamilyName: '',
    parentCarerGivenName: '',
    parentCarerGenderCode: '',
    parentCarerEmail: '',
    isParentCarerAboriOrTorres: '',
    parentCarerOccupationGroup: '',
    parentCarerOccupationName: '',
    parentCarerSchoolEducationCode: '',
    parentCarerEducationQualificationCode: '',
    contactDetails: [
      {
        contactDetailRecordNo: null,
        contactType: '',
        contactValue: '',
        comments: ''
      }
    ],
    meta: {
      isCompletingApplication: null
    },
    contactOrder: null, //TODO: unused?

    // Fields only for parents that live with student
    parentCarerCountryBirth: '',
    isParentCarerSpeakLangOtherEnglish: null,
    parentCarerMainLanguage: '',
    parentCarerSpeakLang: '',
    parentCarerRequireInterpreter: null,

    // Field only for parent that DO NOT live with student
    doesStudentLivesWithParentSometimes: null,
    doesParentCarerHaveCorrespondenceAddress: null,
    residentialAddress: {
      addressRecordNo: null,
      addressLine1: '',
      addressLine2: '',
      suburbName: '',
      suburbCode: '',
      stateCode: '',
      postCode: '',
      countryCode: ''
    },
    correspondenceAddress: {
      addressRecordNo: null,
      addressLine1: '',
      addressLine2: '',
      suburbName: '',
      suburbCode: '',
      stateCode: '',
      postCode: '',
      countryCode: ''
    }
  }
}

export const newContactDetails = {
  contactDetailRecordNo: null,
  contactType: '',
  contactValue: '',
  comments: ''
}

export default function getParent(key) {
  return {
    apiKey: key,
    type: FIELD_TYPE.COLLECTION,
    collapsible: true,
    showLine: false,
    hideAddButton: true,
    visible() {
      return true
    },
    heading(item) {
      const name =
        `${item.parentCarerGivenName} ${item.parentCarerFamilyName}`.trim()
      return `Parent/carer${name && `: ${name}`}`
    },
    headingAddFull: false,
    headingAddOutline: true,
    headingAdd: 'Add another Parent/Carer',
    newItem: createParent(),
    onItemRemoved(application, store) {
      store.dispatch('set', ['application.meta.toAddMoreParent', null])
    },
    fields(item, index, store) {
      var parentKey = `${this.apiKey}[${index}]`
      function livesWithStudent(application) {
        return application.parentCarers[index].isEnrolmentOwner
      }
      function doesNotliveWithStudent(application) {
        return application.parentCarers[index].isEnrolmentOwner === false
      }

      function isShsFirstParentCarer(application, index) {
        return index === 0 && !!application.shsOfferId
      }

      function prefillParent(application) {
        return !!application.parentCarers[index].parentId
      }

      function anyPrefilledParent(application) {
        return !!application.parentCarers.some((p) => p.parentId)
      }

      function parentNamesUpdatedWithErnRecord(application) {
        const parent = application.parentCarers[index]
        const ernEnrolmentOwnerName = store.state.ernEnrolmentOwnerName
        return (
          ernEnrolmentOwnerName &&
          ernEnrolmentOwnerName.firstName === parent.parentCarerGivenName &&
          ernEnrolmentOwnerName.lastName === parent.parentCarerFamilyName
        )
      }
      function hasErnEnrolmentOwnerNameSet() {
        const ernEnrolmentOwnerName = store.state.ernEnrolmentOwnerName
        return (
          ernEnrolmentOwnerName &&
          ernEnrolmentOwnerName.firstName &&
          ernEnrolmentOwnerName.lastName
        )
      }
      return [
        {
          apiKey: parentKey + '.parentCarerRelation',
          label: 'Relationship',
          required: true,
          description: 'e.g. mother, father',
          type: FIELD_TYPE.DROPDOWN,
          options() {
            return dropDownSelect(
              this.getReferenceDataSet(REFDATA_MAP.RELATIONSHIP)
            )
          }
        },
        {
          apiKey: parentKey + '.parentCarerTitle',
          label: 'Title',
          required: true,
          type: FIELD_TYPE.DROPDOWN,
          options() {
            return dropDownSelect(this.getReferenceDataSet(REFDATA_MAP.TITLE))
          }
        },
        {
          apiKey: parentKey + '.parentCarerGivenName',
          label: 'Given name',
          placeHolder: 'Enter given name',
          required: true,
          type: FIELD_TYPE.NAME,
          maxLength: 100,
          disabled(application) {
            return (
              prefillParent(application) ||
              parentNamesUpdatedWithErnRecord(application) ||
              isShsFirstParentCarer(application, index)
            )
          }
        },
        {
          apiKey: parentKey + '.parentCarerFamilyName',
          label: 'Family name',
          placeHolder: 'Enter family name',
          required: true,
          type: FIELD_TYPE.NAME,
          maxLength: 100,
          disabled(application) {
            return (
              prefillParent(application) ||
              parentNamesUpdatedWithErnRecord(application) ||
              isShsFirstParentCarer(application, index)
            )
          }
        },
        {
          apiKey: parentKey + '.parentCarerGenderCode',
          label: 'Gender',
          type: FIELD_TYPE.RADIO,
          options: [
            { text: 'Male', value: 'M' },
            { text: 'Female', value: 'F' }
          ]
        },
        {
          apiKey: parentKey + '.contactDetails',
          type: FIELD_TYPE.COLLECTION,
          heading(contactItem, contactItemIndex, application) {
            let contactText = getParentContactText(contactItem)
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              `${contactText ? '' : 'Enter '}`,
              name,
              `'s contact number ${
                contactItemIndex + (contactText ? `: ${contactText}` : '')
              }`,
              'parent/carer'
            )
          },
          required: true,
          headingAdd: 'Add another contact number',
          newItem: { ...newContactDetails },
          fields(contactItem, contactItemIndex, store) {
            return getContactNumberFields(
              this.apiKey,
              contactItem,
              contactItemIndex,
              store
            )
          }
        },
        {
          vHtmlLabel(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            const labelText = getLabelName(
              '',
              name,
              "'s Residential Address",
              'Parent/Carer'
            )

            return `<strong>${labelText}</strong>`
          },
          showLabel: true,
          type: FIELD_TYPE.HEADING,
          showInReview: true
        },
        {
          apiKey: parentKey + '.isEnrolmentOwner',
          label(application) {
            const pcName =
              _get(application, `${parentKey}.parentCarerGivenName`) ||
              'parent/carer'
            const studentName = getFirstName(application) || 'this student'
            return `Does ${pcName} live at ${studentName}'s residential address?`
          },
          onFieldSet(value, application, store) {
            if (value) {
              store.dispatch('set', [
                `application.parentCarers[${index}].residentialAddress`,
                application.residentialAddress
              ])
            } else {
              store.dispatch('set', [
                `application.parentCarers[${index}].residentialAddress`,
                {
                  addressRecordNo: null,
                  addressLine1: '',
                  addressLine2: '',
                  suburbName: '',
                  suburbCode: '',
                  stateCode: '',
                  postCode: '',
                  countryCode: ''
                }
              ])
            }
          },
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO,
          validation(value, application) {
            const numParentLivingWithStudent = application.parentCarers.reduce(
              (count, { isEnrolmentOwner }) =>
                isEnrolmentOwner ? ++count : count,
              0
            )
            return numParentLivingWithStudent === 0 && value === false
              ? "Please ensure at least one parent/carer lives at the student's residential address"
              : null
          }
        },
        {
          apiKey: parentKey + '.residentialAddress',
          type: FIELD_TYPE.ADDRESS,
          vHtmlLabel(application) {
            const doesLiveWithStudent = livesWithStudent(application)
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            const prefix = doesLiveWithStudent ? '' : 'Please provide '
            const text = getLabelName(
              prefix,
              name,
              "'s residential address",
              'parent/carer'
            )
            const labelClass = doesLiveWithStudent ? 'review-only-label' : ''
            return `<div class="${labelClass}">${text}</div>`
          },
          disabled(application) {
            return livesWithStudent(application)
          },
          visible(application) {
            return (
              doesNotliveWithStudent(application) ||
              livesWithStudent(application)
            )
          }
        },
        {
          apiKey: parentKey + '.doesParentCarerHaveCorrespondenceAddress',
          type: FIELD_TYPE.CHECKBOX,
          vHtmlLabel: 'Use a different <strong>postal address</strong>',
          trueValue: 'Yes',
          falseValue: 'No',
          visible(application) {
            return doesNotliveWithStudent(application)
          }
        },
        {
          apiKey: parentKey + '.correspondenceAddress',
          type: FIELD_TYPE.ADDRESS,
          withPoBox: true,
          // eslint-disable-next-line
          vHtmlLabel(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              '',
              name,
              "'s postal address<p class='labelDescription'>This is the address we will use to correspond with " +
                name +
                ' about the student',
              'parent/carer'
            )
          },
          // eslint-disable-next-line
          visible(application) {
            const hasCorrespondenceAddress = _get(
              application,
              `${parentKey}.doesParentCarerHaveCorrespondenceAddress`
            )
            return (
              hasCorrespondenceAddress === 'Yes' &&
              doesNotliveWithStudent(application)
            )
          }
        },
        {
          apiKey: parentKey + '.doesStudentLivesWithParentSometimes',
          label(application) {
            let addressObj =
              _get(application, `${parentKey}.residentialAddress`) || {}
            let address
            const name = getFirstName(application)
            if (
              addressObj.addressLine1 &&
              addressObj.suburbName &&
              addressObj.stateCode
            ) {
              address =
                addressObj.addressLine1 +
                ', ' +
                (addressObj.addressLine2
                  ? addressObj.addressLine2 + ', '
                  : '') +
                addressObj.suburbName +
                ', ' +
                addressObj.stateCode +
                ', ' +
                addressObj.postCode
            } else if (addressObj.addressLine1 && !addressObj.suburbName) {
              address =
                addressObj.addressLine1 +
                (addressObj.addressLine2 ? ', ' + addressObj.addressLine2 : '')
            } else {
              address = 'this address'
            }

            return (
              getLabelName('Does ', name) +
              ' live at ' +
              address +
              ' sometimes?'
            )
          },
          required(application) {
            return doesNotliveWithStudent(application)
          },
          visible(application) {
            return doesNotliveWithStudent(application)
          },
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          vHtmlLabel(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            const labelText = getLabelName(
              '',
              name,
              "'s Contact Email",
              'Parent/Carer'
            )
            return `<strong>${labelText}</strong>`
          },
          showLabel: true,
          type: FIELD_TYPE.HEADING,
          showInReview: true
        },
        {
          apiKey: parentKey + '.meta.isCompletingApplication',
          label(application) {
            const pcName =
              _get(application, `${parentKey}.parentCarerGivenName`) ||
              'parent/carer'
            const studentName = getFirstName(application) || 'this student'
            return `Is ${pcName} completing this application for ${studentName}?`
          },
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO,
          onFieldSet(value, application, store) {
            const numParentsCompletingApplication =
              application.parentCarers.reduce(
                (count, { meta }) =>
                  meta && meta.isCompletingApplication ? ++count : count,
                0
              )

            // If P/C is set as completing, but there are also others set as completing => set these others as not-completing and reset email.
            if (value && numParentsCompletingApplication > 1) {
              application.parentCarers.forEach((parentCarer, idx) => {
                if (
                  idx !== index &&
                  parentCarer.meta.isCompletingApplication === true
                ) {
                  store.dispatch('set', [
                    `application.parentCarers[${idx}].meta.isCompletingApplication`,
                    false
                  ])
                  store.dispatch('set', [
                    `application.parentCarers[${idx}].parentCarerEmail`,
                    ''
                  ])
                }
              })
            }

            //Set email if true
            if (value) {
              store.dispatch('set', [
                `application.parentCarers[${index}].parentCarerEmail`,
                store.state.auth.userId
              ])
            } else if (value === false) {
              store.dispatch('set', [
                `application.parentCarers[${index}].parentCarerEmail`,
                ''
              ])
            }
          },
          validation(value, application) {
            const numParentsCompletingApplication =
              application.parentCarers.reduce(
                (count, { meta }) =>
                  meta && meta.isCompletingApplication ? ++count : count,
                0
              )

            if (numParentsCompletingApplication === 0 && value === false) {
              return 'Please ensure at least one parent/carer is the one completing this application'
            }
            return null
          },
          disabled(application) {
            return (
              prefillParent(application) ||
              hasErnEnrolmentOwnerNameSet() ||
              anyPrefilledParent(application)
            )
          }
        },
        {
          apiKey: parentKey + '.parentCarerEmail',
          label: 'Contact email',
          placeHolder: 'Enter email address',
          required(application) {
            return livesWithStudent(application)
          },
          visible(application) {
            const { isCompletingApplication } =
              application.parentCarers[index].meta || {}
            return (
              isCompletingApplication === true ||
              isCompletingApplication === false
            )
          },
          disabled(application) {
            const { isCompletingApplication } =
              application.parentCarers[index].meta || {}
            return !!isCompletingApplication
          },
          type: FIELD_TYPE.EMAIL,
          maxLength: 132
        },
        {
          apiKey: parentKey + '.parentCarerCountryBirth',
          label: 'Country of birth',
          required(application) {
            return livesWithStudent(application)
          },
          visible(application) {
            return livesWithStudent(application)
          },
          placeHolder: 'Australia',
          type: FIELD_TYPE.DROPDOWN,
          options() {
            return dropDownSelect(
              this.getReferenceDataSet(REFDATA_MAP.COUNTRIES)
            )
          }
        },
        {
          apiKey: parentKey + '.isParentCarerAboriOrTorres',
          label(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              'Is ',
              name,
              ' Aboriginal and/or Torres Strait Islander?',
              'parent/carer'
            )
          },
          required: true,
          type: FIELD_TYPE.RADIO,
          options() {
            return this.getReferenceDataSet(REFDATA_MAP.ABORIGINALITY)
          }
        },
        {
          apiKey: parentKey + '.parentCarerOccupationGroup',
          label(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              'Select the group that best describes ',
              name,
              "'s occupation",
              'parent/carer'
            )
          },
          infoPanel: {
            title(application) {
              const name =
                _get(application, `${parentKey}.parentCarerGivenName`) ||
                `the parent`
              return `Can’t find ${name}’s occupation group above?`
            },
            icon: 'icon-question.svg',
            componentName: 'ModalOccupation',
            textBeforeButton:
              'You can find out more about these occupation groups',
            textAfterButton: `. This information assists the department better allocate school resources. It will not be shared with teaching staff.`
          },
          description:
            'This information assists the department better allocate school resources. It will not be shared with teaching staff.',
          required(application) {
            return livesWithStudent(application)
          },
          type: FIELD_TYPE.RADIO,
          options() {
            return this.getReferenceDataSet(REFDATA_MAP.OCCUPATION_GROUP)
          }
        },
        {
          apiKey: parentKey + '.parentCarerOccupationName',
          label(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              'What is ',
              name,
              "'s occupation?",
              'parent/carer'
            )
          },
          type: FIELD_TYPE.SAFE_TEXT,
          maxLength: 132
        },
        {
          apiKey: parentKey + '.parentCarerSchoolEducationCode',
          label(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              'What is the highest level of schooling ',
              name,
              ' completed?',
              'parent/carer'
            )
          },
          description: `This information assists the department better allocate school resources. It will not be shared with teaching staff. For persons who never attended school, please select 'Year 9 or equivalent or below'.`,
          required(application) {
            return livesWithStudent(application)
          },
          type: FIELD_TYPE.RADIO,
          options() {
            return this.getReferenceDataSet(REFDATA_MAP.PARENT_SCHOOL_EDUCATION)
          }
        },
        {
          apiKey: parentKey + '.parentCarerEducationQualificationCode',
          label(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              'What is the highest qualification ',
              name,
              ' completed?',
              'parent/carer'
            )
          },
          description:
            'This information assists the department better allocate school resources. It will not be shared with teaching staff.',
          required(application) {
            return livesWithStudent(application)
          },
          type: FIELD_TYPE.RADIO,
          options() {
            return this.getReferenceDataSet(
              REFDATA_MAP.PARENT_TERTIARY_EDUCATION
            )
          }
        },
        {
          apiKey: parentKey + '.isParentCarerSpeakLangOtherEnglish',
          label(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              'Does ',
              name,
              ' speak a language other than English at home?',
              'parent/carer'
            )
          },
          type: FIELD_TYPE.RADIO,
          options: LOTE,
          required(application) {
            return livesWithStudent(application)
          },
          visible(application) {
            return livesWithStudent(application)
          }
        },
        {
          apiKey: parentKey + '.parentCarerMainLanguage',
          label(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              'What language other than English does ',
              name,
              ' mainly speak at home?',
              'parent/carer'
            )
          },
          type: FIELD_TYPE.DROPDOWN,
          options() {
            return dropDownSelect(
              this.getReferenceDataSet(REFDATA_MAP.LANGUAGES).filter(
                (item) => item.value !== 'ENG'
              )
            )
          },
          visible(application) {
            return (
              application.parentCarers[index]
                .isParentCarerSpeakLangOtherEnglish &&
              livesWithStudent(application)
            )
          },
          required(application) {
            return livesWithStudent(application)
          }
        },
        {
          apiKey: parentKey + '.parentCarerSpeakLang',
          label(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              'What other language does ',
              name,
              ' speak at home?',
              'parent/carer'
            )
          },
          type: FIELD_TYPE.DROPDOWN,
          options() {
            return dropDownSelect(
              this.getReferenceDataSet(REFDATA_MAP.LANGUAGES)
            )
          },
          visible(application) {
            return (
              application.parentCarers[index]
                .isParentCarerSpeakLangOtherEnglish &&
              livesWithStudent(application)
            )
          }
        },
        {
          apiKey: parentKey + '.parentCarerRequireInterpreter',
          label(application) {
            const name = _get(application, `${parentKey}.parentCarerGivenName`)
            return getLabelName(
              'Will ',
              name,
              ' require an interpreter during school interviews?',
              'parent/carer'
            )
          },
          type: FIELD_TYPE.RADIO,
          options: YES_NO,
          visible(application) {
            return (
              application.parentCarers[index]
                .isParentCarerSpeakLangOtherEnglish &&
              livesWithStudent(application)
            )
          },
          required(application) {
            return livesWithStudent(application)
          }
        }
      ]
    }
  }
}
