<template>
  <div
    v-if="
      application &&
      isAllowedFieldType(field) &&
      isVisible({ field, application })
    "
    :class="alertClass"
  >
    <div v-if="getType(field, application) === fieldType.HEADING">
      <ApplicationFieldHeading :field="field" :section="section" />
    </div>

    <template v-else>
      <!--<span style="float: right">[{{ field.apiKey }}, {{ field.type }}, {{ getValue(field) }}]</span>-->
      <div
        v-if="getType(field, application) !== fieldType.DOCUMENTS"
        :class="[{ disabled: isDisabled(field, application) }, 'label']"
      >
        <span
          class="required-mark"
          aria-hidden="true"
          v-if="getRequired(field, application)"
          >*</span
        >
        <span v-if="field.label">{{ getLabel(field, application) }}</span>
        <span
          v-else-if="field.vHtmlLabel"
          v-html="getVHtmlLabel(field, application)"
        ></span>
      </div>

      <div
        v-if="
          getType(field, application) === fieldType.DOCUMENTS &&
          finalValue.length > 0
        "
      >
        <p
          class="document__value value"
          v-for="file in finalValue"
          :key="file.name"
        >
          <v-icon size="30" class="document__icon" color="primary"
            >mdi-file-document-outline</v-icon
          >
          {{ file.name }}
        </p>
      </div>

      <div v-else-if="getType(field, application) === fieldType.CUSTOM">
        <ApplicationFieldCustom :field="field" />
      </div>

      <div
        v-else-if="typeof finalValue === 'string'"
        :class="[{ disabled: isDisabled(field, application) }, 'value']"
      >
        <span>
          <font-awesome-icon
            class="icon"
            :icon="['fas', 'lock']"
            v-if="isDisabled(field, application)"
        /></span>
        {{ finalValue }}
      </div>
      <div
        v-else-if="
          finalValue !== null &&
          typeof finalValue === 'object' &&
          Object.keys(finalValue).length
        "
        class="value"
      >
        <ul>
          <li v-for="item in finalValue" :key="item.value">{{ item }}</li>
        </ul>
      </div>
      <div v-else class="value value-empty">(empty)</div>

      <!-- If field has an alert... -->
      <div v-if="alert && alert.type" class="alertRow">
        <font-awesome-icon icon="exclamation-circle" />
        <span class="alertMessage" v-html="alert.message" />
      </div>
    </template>
  </div>
</template>

<script>
import ApplicationFieldCustom from '@/components/application/ApplicationFieldCustom'
import ApplicationFieldHeading from '@/components/application/ApplicationFieldHeading'

import { FIELD_TYPE } from '@/constants'
import {
  monthValueToMonthName,
  getAddressStringFromLine1,
  getSchoolNameFromId
} from '@/applicationDefinition/helper'
import fieldsMixin from '@/components/mixins/fieldsMixin'

export default {
  name: 'ApplicationField',
  props: {
    section: {
      type: Object
    },
    alert: {
      type: [Object, Number]
    },
    field: {
      type: Object
    }
  },

  components: { ApplicationFieldCustom, ApplicationFieldHeading },
  data() {
    return {
      fieldType: FIELD_TYPE
    }
  },
  computed: {
    application() {
      return this.$store.state.application || {}
    },
    finalValue() {
      const displayValues = this.getDisplayValue(this.field) || []
      if (this.section && this.section.id === 'supplementaryDetails') {
        if (
          (displayValues && typeof displayValues === 'string') ||
          displayValues instanceof String
        ) {
          // To Display in review supplementaryDetails page with out GUID
          const valueArray = displayValues.trim().split('|')
          if (Array.isArray(valueArray) && valueArray.length > 1) {
            return valueArray[0].trim()
          }
          return displayValues
        }
      }
      return displayValues
    },
    alertClass() {
      let result = ''
      if (this.alert !== undefined) {
        result = ' hasAlert'
      }
      return 'ApplicationFieldReadOnly' + result
    }
  },
  mixins: [fieldsMixin],
  methods: {
    isAllowedFieldType(field) {
      if (
        (field.type === FIELD_TYPE.HEADING && !field.showInReview) ||
        field.type === FIELD_TYPE.LINKTO ||
        field.type === FIELD_TYPE.BUTTON ||
        field.type === FIELD_TYPE.NOTIFICATION
      ) {
        return false
      }
      return true
    },

    getType(field, application) {
      if (typeof field.type === 'function') {
        return field.type(application)
      } else if (field.type) {
        return field.type
      }
    },

    getDisplayValue(field) {
      // Returns the field value as it should be displayed to the user. This might
      // require a lookup or formatting according to the type of field.
      const val = this.getValue(field)

      if (val === '') return ''

      if (
        field.type === '' ||
        field.type === FIELD_TYPE.TEXTAREA ||
        field.type === FIELD_TYPE.POSTCODE ||
        field.type === FIELD_TYPE.PHONE ||
        field.type === FIELD_TYPE.EMAIL ||
        field.type === FIELD_TYPE.NAME
      )
        return val

      if (
        field.type === FIELD_TYPE.RADIO ||
        field.type === FIELD_TYPE.DROPDOWN
      ) {
        const opts = this.getOptions(field, this.application) || []
        const filteredOpts = opts.filter((el) => el.value == val)

        if (
          !filteredOpts ||
          filteredOpts.length < 1 ||
          filteredOpts.every((o) => o.disabled === true)
        )
          return ''

        return filteredOpts[0].text
      }

      if (field.type === FIELD_TYPE.CHECKBOX_GROUP) {
        const opts = this.getOptions(field, this.application) || []
        const filteredMappedOpts = opts
          .filter((el) => val.indexOf(el.value) > -1)
          .map((el) => el.text)

        if (!filteredMappedOpts || filteredMappedOpts.length < 1) return ''

        return filteredMappedOpts
      }

      if (val && field.type === FIELD_TYPE.DATE) {
        let date = val.split('-')
        let day = date[2] || ''
        let month = monthValueToMonthName[date[1]] || ''
        let year = date[0] || ''
        // format month selectors differently
        if (field.selectMonth) {
          return `${month} ${year}`.trim()
        }
        return `${day} ${month} ${year}`.trim()
      }

      if (field.type === FIELD_TYPE.ADDRESS) {
        return getAddressStringFromLine1(val, this.$store)
      }

      if (field.type === FIELD_TYPE.LOOKUP) {
        return getSchoolNameFromId(val)
      }

      return val
    }
  }
}
</script>

<style lang="scss" scoped>
.ApplicationFieldReadOnly {
  margin-top: $content-padding * 2.5;
  margin-bottom: $content-padding * 2.5;
  &.hasAlert {
    border-left: 0.5em solid $color-red;
    padding-left: 0.5rem;
  }
  .alertRow {
    display: flex;
    padding-top: 0.5rem;
    svg {
      height: 2em;
    }
  }
  .alertMessage {
    display: inline-block;
    padding-left: 0.25rem;
    font-size: $text-small;
    color: $color-red;
    ::v-deep .link {
      color: $color-red;
    }
  }
  .smallMessage {
    display: block;
    padding-top: 0.8rem;
    font-size: $text-small;
  }
  .label {
    font-size: 1.4rem;

    .required-mark {
      float: left;
    }
  }
  .value,
  ::v-deep .value {
    font-weight: $text-normal;
    font-size: 2rem;
  }
  .value-empty,
  ::v-deep .disabled:not(.label) {
    color: $color-grey-60;
  }

  ::v-deep .labelDescription {
    color: $color-grey-60;
    font-size: 1.2rem;
  }

  ::v-deep .ApplicationFieldHeading {
    padding: 0;
  }

  .document {
    &__value {
      display: flex;
      align-items: flex-start;
    }

    &__icon {
      margin-right: 1rem;
    }
  }
}
</style>
