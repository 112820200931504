import { sessionStorageUtils } from '@/utils/browserStorage'
import { EHUB, CORE } from '@/constants'

const STORAGE_KEYS = [
  'idToken',
  'refreshToken',
  'schoolData',
  'formData',
  'ernEnrolmentOwnerName',
  'isSCTApp'
]
const OOA_OFFER_STORAGE_KEYS = ['idToken', 'refreshToken', 'ooaOffer']
const EOI_OFFER_STORAGE_KEYS = [
  'idToken',
  'refreshToken',
  'eoiOffer',
  'pidLinkedChildren'
]
const SHS_OFFER_STORAGE_KEYS = [
  'idToken',
  'refreshToken',
  'shsOffer',
  'pidLinkedChildren'
]

const getNewApplicationData = () => {
  const items = sessionStorageUtils.getItems(STORAGE_KEYS)
  // sessionStorageUtils.removeItems(STORAGE_KEYS)
  return items
}

const getOoaOfferData = () => {
  const items = sessionStorageUtils.getItems(OOA_OFFER_STORAGE_KEYS)
  // sessionStorageUtils.removeItems(STORAGE_KEYS)
  return items
}

const getEoiOfferData = () => {
  const items = sessionStorageUtils.getItems(EOI_OFFER_STORAGE_KEYS)
  return items
}

const getShsOfferData = () => {
  const items = sessionStorageUtils.getItems(SHS_OFFER_STORAGE_KEYS)
  return items
}

const isFromEhub = () => {
  return EHUB === JSON.parse(window.sessionStorage.getItem('comesFrom'))
}

const isSCTApplication = () => {
  const isSCTApplication = sessionStorageUtils.getItem('isSCTApp')
  return isSCTApplication
}

const getGtmApplication = () => {
  return isFromEhub() ? EHUB : CORE
}

const getGaOoaOfferLabel = () => {
  let gaLabel = 'core'
  const ooaOffer = sessionStorageUtils.getItem('ooaOffer')
  if (ooaOffer && ooaOffer.applicationId) {
    gaLabel = 'ooaconverted'
  }
  return gaLabel
}

const getFormData = () => {
  return sessionStorageUtils.getItem('formData')
}

const getCognitoLogoutUrl = () => {
  const cognitoLogoutUrl = process.env.VUE_APP_COGNITO_LOGOUT_URI
  const clientId = process.env.VUE_APP_SNSW_AUTH_CLIENT_ID
  const cognitoCallBackUrl = `${window.location.protocol}//${window.location.host}/logout`
  return `${cognitoLogoutUrl}?client_id=${clientId}&logout_uri=${cognitoCallBackUrl}`
}

const getY67tEoiCalendarYear = () => {
  const nextYear = new Date().getFullYear() + 1
  return `${nextYear}`
}

// eslint-disable-next-line import/prefer-default-export
export default {
  getNewApplicationData,
  getOoaOfferData,
  getEoiOfferData,
  getShsOfferData,
  isFromEhub,
  isSCTApplication,
  getGtmApplication,
  getGaOoaOfferLabel,
  getFormData,
  getCognitoLogoutUrl,
  getY67tEoiCalendarYear
}
