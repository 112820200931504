<template>
  <component
    :is="field.collapsible && !isReviewing ? 'v-expansion-panel-header' : 'div'"
    v-if="heading"
    :class="cssClasses"
  >
    <h3>
      <span tabindex="-1" ref="headingSpan">{{ heading }}</span>
      <button
        v-if="field.newItem && collectionSize > 1 && !isReviewing"
        class="remove"
        type="button"
        :title="'Are you sure you want to remove ' + heading"
        @click.stop="$emit('remove')"
        :data-testid="field.apiKey + collectionItemIndex"
      >
        <v-icon v-if="field.collapsible" class="pr-4">mdi-close</v-icon>
        <template v-else>Remove</template>
      </button>
    </h3>
  </component>
</template>

<script>
import { VExpansionPanelHeader } from 'vuetify/lib'

export default {
  name: 'ApplicationFieldCollectionHeading',
  components: { VExpansionPanelHeader },
  props: {
    field: {
      type: Object,
      required: false
    },
    collectionItem: {
      type: Object,
      required: false
    },
    collectionItemIndex: {
      type: Number,
      required: false
    },
    application: {
      type: Object,
      required: false
    },
    collectionSize: {
      type: Number,
      required: false
    }
  },
  computed: {
    cssClasses() {
      var classes = ['ApplicationFieldCollectionHeading']

      if (this.isReviewing) return classes.join(' ')

      if (this.field.showLine) {
        classes.push('showLine')
      } else if (this.field.newItem) {
        classes.push('canAddRemove')
      }
      if (this.field.collapsible) {
        classes.push('collapsible')
      }
      return classes.join(' ')
    },
    heading() {
      if (this.field.heading) {
        return this.field.heading(
          this.collectionItem,
          this.collectionItemIndex + 1,
          this.application,
          this.field
        )
      }
      return `${this.field.apiKey}[${this.collectionItemIndex}]`
    },
    isReviewing() {
      try {
        return this.$store.state.application.meta.sectionExpandedId === 'review'
      } catch (e) {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationFieldCollectionHeading {
  padding: $content-padding;

  h3 {
    margin: 0 0 3.5rem;
    display: flex;
    border-bottom: solid 1px $color-grey-30;
    span {
      &:focus {
        outline: revert !important;
      }
      font-size: $text-h5;
      color: $color-primary;
      font-weight: $text-bold;
      flex: 1;
      margin: 0.25rem 0;
    }
    .remove {
      color: $color-primary;
      font-weight: normal;
      font-size: $text;
      font-weight: 300;
      padding: 0;
      border: none;
      background: transparent;
    }
  }
  &.canAddRemove h3 {
    padding: 0 0 0.15rem;
    min-height: 3.2rem;
  }
  &.showLine h3 {
    border-bottom: 2px solid;
    padding: 0 0 0.5rem;
  }
  &.collapsible {
    h3 {
      border-bottom: none;
      margin: 0;
    }
    .remove {
      @include button-reset;
    }
  }
}

#app.v-application
  .ApplicationFieldCollectionHeading.v-expansion-panel-header.v-expansion-panel-header--active {
  background-color: inherit;
}
</style>
