<template>
  <div class="LogoutlView">
    <ApplicationBanner :content="'<h1>You are now signed out</h1>'" />
    <b-container>
      <b-row>
        <div class="Content">
          <h2 class="Content__Title">
            Your online application has been saved, and you can resume your
            application at any time.
          </h2>

          <ContentBox icon-name="update" icon-size="50">
            <div slot="copyContent">
              <p>
                To resume your application later, just go to the school website
                you used to start this application, choose
                <strong>Apply for enrolment online</strong> then select the
                <strong>Resume application</strong> button.<span
                  v-if="!hideResumeFromEmailText"
                  data-testid="logout-resume-from-email"
                >
                  You can also follow the
                  <strong>Resume application link</strong> sent to your email
                  address.</span
                >
              </p>
            </div>
          </ContentBox>
          <ContentBox
            icon-name="mdi-alert-outline"
            icon-size="40"
            v-if="authBySNSW"
          >
            <div slot="copyContent">
              <p>
                <strong>NOTE:</strong> If you signed in with your Service NSW
                details, you will need to
                <strong
                  ><a id="snsw-my-service-link" :href="SNSWLink"
                    >visit MyService NSW</a
                  ></strong
                >
                now to log out.
              </p>
            </div>
          </ContentBox>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import ContentBox from '@/components/app/ContentBox'

export default {
  name: 'LogoutlView',
  components: {
    ApplicationBanner,
    ContentBox
  },
  props: {
    hideResumeFromEmailText: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    authBySNSW() {
      return this.$store.state.auth.authProvider === 'SNSW'
    },
    SNSWLink() {
      return process.env.VUE_APP_SNSW_ACCOUNT_URI
    }
  },
  mounted() {
    if (!this.$store.state.auth.authProvider) {
      this.$store.dispatch('set', ['error', new Error('You have logged out')])
      this.$router.history.push('/error')
    }
  },
  destroyed() {
    this.$store.commit('set', ['auth.authProvider', null])
  }
}
</script>

<style lang="scss" scoped>
.LogoutlView::v-deep {
  height: 100%;
  color: $color-primary;
  h1 {
    text-align: center;
  }

  .ApplicationBanner {
    margin-bottom: 10rem;
  }
  .container {
    max-width: 97rem;
  }
  .Content {
    margin: 2rem auto;
    padding: 2rem 2rem;
    &__Title {
      margin-bottom: 4rem;
      padding: 0 5.5rem;
    }
    .content-box {
      padding-bottom: 1rem !important;
    }
    p {
      font-size: 1.6rem;
    }
  }
  .actions {
    margin-top: 3rem;
  }
}

@include desktop {
  .LogoutlView::v-deep {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
      .bannerTitle {
        max-width: 80rem;
        margin: 0 auto;
      }
      .container {
        max-width: 97rem;
      }
    }
    .Content {
      max-width: 900px;
      margin: 0 auto;
    }
  }
}

@include tablet-wide {
  .LogoutlView {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
      ::v-deep .container {
        max-width: 720px;
      }
    }
    .error-row {
      max-width: 940px;
    }
  }
  .Content {
    max-width: 720px;
    span {
      display: block;
    }
  }
}

@include tablet {
  .LogoutlView {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
      ::v-deep .container {
        max-width: 540px;
      }
    }

    .Content {
      padding-top: 2rem;
      max-width: 540px;
      span {
        display: block;
      }
      & ::v-deep h1 {
        font-size: 3.2rem;
      }
    }
  }
}

@include mobile {
  .LogoutlView {
    .Content {
      padding: 2rem;
      max-width: 420px;
      .Content__Title {
        padding: 0 1rem;
      }
      span {
        display: block;
      }
      & ::v-deep h1 {
        font-size: 2.6rem;
      }
    }
  }
}
</style>
