// Called from the Store to validate the current application, i.e. to
// check for missing fields, invalid fields or fields that conflict
// with ERN data. These are all stored in "state.alerts".

import moment from 'moment'

import { FIELD_TYPE, ALERT_TYPE, ERROR_MESSAGES } from '@/constants'

import UTILS from '@/store/utils'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

let alerts = []

export function buildAlerts(store) {
  let { commit, state, getters } = store
  alerts = []

  state.sections.forEach((section) => {
    section.fields.forEach((field) => {
      validateField(
        state.application,
        section,
        field,
        getters.hasAttemptToSubmit,
        store
      )
    })
  })

  commit('set', ['alerts', alerts])
}

export function buildCounters(store) {
  let { commit, state } = store
  alerts = []

  state.sections.forEach((section) => {
    section.fields.forEach((field) => {
      validateField(state.application, section, field, true, store)
    })
  })

  commit('set', ['counters', alerts])
}

export function addAlert(commit, state, section, field, store) {
  alerts = state.alerts || []

  validateField(state.application, section, field, false, store)

  commit('set', ['alerts', alerts])
}

function getFieldValue(field, application) {
  // Gets api field value. If field type is a collection, return that
  // collection with any necessary filtering.
  let val

  // Process dynamic field type
  const type =
    typeof field.type === 'function' ? field.type(application) : field.type

  try {
    val = _get(application, field.apiKey)
  } catch (ex) {
    // eslint-disable-next-line
    console.warn(`API MISSING FIELD: ${field.apiKey}`)
  }

  if (val === undefined) {
    return type === FIELD_TYPE.COLLECTION ? [] : ''
  }

  if (type === FIELD_TYPE.COLLECTION && field.filter) {
    return val.filter((item) => field.filter(item))
  }

  return val
}

export function getRequiredMessage(field, application) {
  let required, message
  const val = getFieldValue(field, application)
  // Alert missing fields (empty mandatory fields)
  // Required fields cannot contain only whitespace (ERN imposed)
  if (
    val === null ||
    val === undefined ||
    String(val).trim() === '' ||
    (field.type === FIELD_TYPE.ADDRESS &&
      (_isEmpty(val) || (val && val.countryCode === '')))
  ) {
    if (typeof field.required === 'function') {
      required = field.required(application)
    } else {
      required = field.required
    }
    if (required) {
      message = typeof required === 'string' ? required : 'Field is required'
    }
    return message
  }
}

export function isValidPhoneNumber(val) {
  return /^[0-9 ()+-]+$/.test(val)
}

function validateField(application, section, field, hasAttemptToSubmit, store) {
  const isFieldVisible = field.visible ? field.visible(application) : true

  let isRemove = true

  if (isFieldVisible) {
    if (field.type === FIELD_TYPE.COLLECTION) {
      let records = getFieldValue(field, application)
      records.forEach((record, index) => {
        field.fields(record, index, store).forEach((recordField) => {
          validateField(
            application,
            section,
            recordField,
            hasAttemptToSubmit,
            store
          )
        })
      })
    } else if (field.type === FIELD_TYPE.GROUP) {
      field.fields().forEach((childField) => {
        validateField(
          application,
          section,
          childField,
          hasAttemptToSubmit,
          store
        )
      })
    } else if (field.type === FIELD_TYPE.PANELS) {
      field.panels.forEach((panel) => {
        panel.fields.forEach((field) => {
          validateField(application, section, field, hasAttemptToSubmit, store)
        })
      })
    } else if (field.type !== FIELD_TYPE.HEADING) {
      // PI only check required on submit
      if (hasAttemptToSubmit) {
        checkMissing()
      }

      checkInvalid()

      if (isRemove) {
        removeAlert(section, field)
      }
    }
  }

  function checkMissing() {
    let requiredMessage = getRequiredMessage(field, application)
    if (requiredMessage) {
      addAlert(ALERT_TYPE.MISSING, requiredMessage, section, field)
    }
  }

  function checkInvalid() {
    // process dynamic field type
    const type =
      typeof field.type === 'function' ? field.type(application) : field.type
    const val = getFieldValue(field, application)
    const maxCharacter = UTILS.getMaxCharacter(field, type)
    const regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

    let message

    if (val === '' || val === null) {
      return
    } else if (val && maxCharacter) {
      if (val.length > maxCharacter) {
        message =
          `Can only contain ${maxCharacter} characters (including spaces).` +
          ` Exceeded limit by ${val.length - maxCharacter} character(s).`
      }
    } else if (
      type === FIELD_TYPE.TEXTAREA &&
      !maxCharacter &&
      val.length > 2048
    ) {
      message =
        `Can only contain 2048 characters (including spaces).` +
        ` Exceeded limit by ${val.length - 2048} character(s).`
    } else if (type === FIELD_TYPE.ADDRESS) {
      // #DSE-1245: Future note, this 'skipping' feature should be refactored
      // into either a field option, handled by buildAlerts, handled by ADDRESS
      // type, or something...
      if (!val.skipValidation) {
        if (val.countryCode === 'AUS') {
          if (
            !val ||
            !val.suburbName ||
            !val.postCode ||
            !val.addressLine1 ||
            !val.stateCode
          ) {
            message =
              'Please provide the street address, suburb, state and postcode for this contact.'
          }
        } else if (
          val.countryCode !== 'AUS' &&
          val.countryCode !== '' &&
          val.countryCode !== undefined &&
          !val.foreignAddress1
        ) {
          message = 'Please start address from the first line.'
        }
      }
    } else if (type === FIELD_TYPE.LOOKUP) {
      if (field.isInvalid) {
        message =
          'BUILD_ALERT: We cannot verify this school. Please clear the field and search for the school again.'
      }
    } else if (
      type === FIELD_TYPE.POSTCODE &&
      !/^[0-9][0-9][0-9][0-9]+$/.test(val)
    ) {
      message = 'Invalid postcode'
    } else if (
      type === FIELD_TYPE.DATE &&
      (!moment(val).isValid() || !/^\d+-\d+-\d+$/.test(val))
    ) {
      // process dynamic field type
      const required =
        typeof field.required === 'function'
          ? field.required(application)
          : field.required

      // Supress non required fields that is -- or --01 as they are valid, also
      // new date values are null
      if (
        !(!required && field.selectMonth && val === '--01') &&
        !(!required && !field.selectMonth && val === '--') &&
        !(required && val === null)
      ) {
        message = 'Invalid date'
      }
    } else if (
      type === FIELD_TYPE.MONTH_YEAR &&
      !/^(0[1-9]|1[0-2])\/([0-9]{4})$/gm.test(val)
    ) {
      message = 'Must be a valid MM/YYYY date'
    } else if (type === FIELD_TYPE.EMAIL && !regEmail.test(val)) {
      message = 'Invalid email'
    } else if (type === FIELD_TYPE.NAME && !/^[a-zA-Z ()‘'-]+$/.test(val)) {
      message = ERROR_MESSAGES.NAME
    } else if (
      type === FIELD_TYPE.SAFE_TEXT &&
      !/^[a-zA-Z ()/'-]+$/.test(val)
    ) {
      message = ERROR_MESSAGES.SAFE_TEXT
    } else if (type === FIELD_TYPE.PHONE && !isValidPhoneNumber(val)) {
      message = ERROR_MESSAGES.PHONE_NUM
    }

    if (!message && field.validation) {
      message = field.validation(val, application, store) || message
    }

    if (message) {
      addAlert(ALERT_TYPE.INVALID, message, section, field)
    }
  }

  function addAlert(type, message, section, field) {
    let index = alerts.findIndex(
      (a) => a.section.id === section.id && a.field.apiKey === field.apiKey
    )

    isRemove = false

    if (index > -1) {
      alerts[index].type = type
      alerts[index].message = message
    } else {
      alerts.push({
        type,
        section,
        field,
        message
      })
    }
  }

  function removeAlert(section, field) {
    alerts = alerts.filter((a) => {
      if (a.section.id !== section.id || a.field.apiKey !== field.apiKey) {
        return true
      }
    })
  }
}
