<template>
  <div
    v-if="isVisible(field, application)"
    :id="field.elemId"
    :class="
      'ApplicationFieldCollection' +
      (getHeadingAdd(field, application) ? '' : ' no-footer')
    "
  >
    <h2 v-if="field.description">
      {{ getDescription(field, application) }}
      <span class="smallMessage" v-if="field.note">{{ field.note }}</span>
      <ul class="smallMessage" v-if="field.descriptionList">
        <li
          :key="item"
          v-for="item in field.descriptionList(field, application)"
          v-html="item"
        ></li>
      </ul>
    </h2>

    <button
      v-if="field.collapsible"
      class="collapse-all-button"
      :class="{ 'collapse-all-button--active': anyPanelsExpanded }"
      @click="anyPanelsExpanded ? collapseAll() : expandAll()"
    >
      {{ anyPanelsExpanded ? 'Collapse all' : 'Expand all' }}
      <v-icon>mdi-chevron-down</v-icon>
    </button>

    <component
      :is="field.collapsible ? 'v-expansion-panels' : 'div'"
      v-model="panels"
      v-bind="{ multiple: field.collapsible }"
    >
      <component
        :is="field.collapsible ? 'v-expansion-panel' : 'div'"
        class="collectionItemFields"
        v-for="(collectionItem, index) in collection"
        :key="field.apiKey + index"
      >
        <ApplicationFieldCollectionHeading
          :field="field"
          ref="collectionHeading"
          :application="application"
          :collection-item="collectionItem"
          :collection-item-index="index"
          :collection-size="collection.length"
          @remove="remove(collectionItem, index, application, field, section)"
        />
        <component
          :is="field.collapsible ? 'v-expansion-panel-content' : 'div'"
        >
          <div
            v-for="collectionItemField in field.fields(
              collectionItem,
              index,
              $store
            )"
            :key="collectionItemField.apiKey"
          >
            <ApplicationFieldCollection
              v-if="collectionItemField.type == 'COLLECTION'"
              :section="section"
              :field="collectionItemField"
            />
            <ApplicationFieldHeading
              v-else-if="
                collectionItemField.type == 'HEADING' &&
                isVisible(collectionItemField, application)
              "
              :field="collectionItemField"
              :section="section"
            />
            <ApplicationField
              v-else-if="
                isVisible(collectionItemField, application) && !isReviewing
              "
              :field="collectionItemField"
              :section="section"
            />
          </div>
        </component>
      </component>
    </component>
    <div
      v-if="(getAlert().type || field.newItem) && !isReviewing"
      :class="
        'collectionFooter' +
        this.alertContainerCssClasses +
        (field.headingAddFull ? ' full' : '')
      "
    >
      <!-- If collection has an Add button... -->
      <button
        v-if="field.newItem && !field.hideAddButton"
        class="add"
        :class="{
          bold: field.showLine,
          primary: !field.headingAddOutline,
          'btn-outline-primary': field.headingAddOutline
        }"
        type="button"
        @click="add"
      >
        + {{ getHeadingAdd(field, application) }}
      </button>
    </div>
  </div>
</template>

<script>
import {
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent
} from 'vuetify/lib'

import ApplicationField from './ApplicationField.vue'
import ApplicationFieldCollection from './ApplicationFieldCollection.vue'
import ApplicationFieldCollectionHeading from './ApplicationFieldCollectionHeading.vue'
import ApplicationFieldHeading from './ApplicationFieldHeading.vue'

import ApplicationMixin from '@/components/mixins/application'
import fieldsMixin from '@/components/mixins/fieldsMixin'

export default {
  name: 'ApplicationFieldCollection',
  components: {
    ApplicationField,
    ApplicationFieldCollection,
    ApplicationFieldCollectionHeading,
    ApplicationFieldHeading,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent
  },
  mixins: [ApplicationMixin, fieldsMixin],
  props: {
    field: {
      type: Object
    },
    section: {
      type: Object
    }
  },
  computed: {
    application() {
      return this.$store.state.application
    },
    isReviewing() {
      return (
        this.application && this.application.meta.sectionExpandedId === 'review'
      )
    },
    anyPanelsExpanded() {
      return this.field.collapsible && this.panels.length > 0
    },
    collection() {
      return this.getCollection(this.field.apiKey).filter(
        (collectionItem) => !this.isItemFilteredOut(collectionItem)
      )
    }
  },
  data() {
    return {
      panels: this.getCollection(this.field.apiKey).map((k, i) => i) //Set all open initially
    }
  },
  watch: {
    collection(newValue, oldValue) {
      if (newValue.length > oldValue.length) {
        //New item
        //If collapsible, added new item in expanded state
        if (this.field.collapsible) {
          this.panels.push(newValue.length - 1)
        }
        //Must wait for new item to be rendered first
        this.$nextTick(() => this.focusItem(newValue.length - 1))
      }
    }
  },
  methods: {
    isVisible(field, application) {
      if (!application) {
        return false
      }
      if (field.visible) {
        return field.visible(application)
      } else {
        return true
      }
    },
    expandAll() {
      this.panels = this.collection.map((k, i) => i)
    },
    collapseAll() {
      this.panels = []
    },
    getHeadingAdd(field, application) {
      if (!field.headingAdd) {
        return false
      } else if (typeof field.headingAdd === 'function') {
        return field.headingAdd(application, this.$store)
      } else if (field.headingAdd) {
        return field.headingAdd
      }
    },
    getDescriptionList(field, application) {
      if (typeof field.descriptionList === 'function') {
        return field.descriptionList(application)
      } else if (field.descriptionList) {
        return field.descriptionList
      }
    },
    isItemFilteredOut(collectionItem) {
      if (this.field.filter) {
        return (
          collectionItem[this.field.filter.apiKey] !== this.field.filter.value
        )
      }
    },
    isFullWidthRow(field) {
      return field.type == 'HEADING' || field.type == 'COLLECTION'
    },
    add() {
      return this.addNewCollectionItem(this.field)
    },
    focusItem(index) {
      const newHeading = this.$refs.collectionHeading[index]
      newHeading.$refs.headingSpan.focus()
    },
    remove(collectionItem, index, application, field, section) {
      var me = this

      const title = this.field.heading(
        collectionItem,
        index + 1,
        application,
        field
      )

      me.$store.dispatch('showMessageBox', {
        message: `Are you sure you want to remove ${title}?`,
        textConfirm: 'Remove',
        onConfirm() {
          let newCollection = me.collection.filter(
            (item, itemIndex) => itemIndex !== index
          )

          me.$store.dispatch('set', [
            'application.' + me.field.apiKey,
            newCollection
          ])
          //call item remove handler set on the field definition
          if (field.onItemRemoved) {
            field.onItemRemoved(application, me.$store)
          }
          //auto save the deletion
          me.$store
            .dispatch('saveApplicationSection', { section, type: '' })
            .catch(() => {
              this.$store.dispatch('set', ['saveStatus', null])
              this.$store.dispatch('set', [
                'error',
                new Error('Error when auto-saving: Could not reach server')
              ])

              this.$router.history.push('/error')
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationFieldCollection {
  h2 {
    padding: 0 $content-padding;
    margin: 0 0 3.5rem;
    font-weight: $text-medium;
  }
  &.no-footer {
    margin-bottom: 4rem;
  }
  .collectionItemFields {
    background: $color-grey-2;
    padding: $content-padding;
    margin: $content-padding;
    flex-shrink: 1;
    .collectionItemFields {
      padding: 0;
      margin: 0;
    }
    .collectionFooter {
      button.primary {
        padding: 0;
        color: $color-primary;
        background: transparent !important;
      }
    }
  }
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .columnLayout {
    display: inline-block;
    width: 33.33%; // Currently giving a three column layout - TODO: Make responsive
    & > * {
      width: 100%;
    }
  }
  .collectionFooter {
    .ApplicationFieldAlert {
      padding: 0;
      margin: 0.5rem 0;
    }
    margin: 0.5rem 0 4rem 0;
    &.full {
      padding: 0 1rem;
      .add {
        margin: 0;
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }
  .add {
    &.bold {
      font-weight: bold;
    }
    margin: 0 0 0 $content-padding;
    padding: 1rem 2rem;
    border: 0;
    min-width: 7rem;
    font-size: 1.6rem;
  }
  .smallMessage {
    display: block;
    padding-top: 0.8rem;
    font-size: $text-small;
    padding-top: 0.5rem;
  }
}

.collapse-all-button {
  @include button-reset;
  display: block;
  margin-left: auto;
  margin-right: 1rem;
  font-size: 1.6rem;
  border: none;

  &--active {
    .v-icon {
      transform: rotate(-180deg);
    }
  }
}
</style>
