<template>
  <loading-view v-if="loading" loading-text="processing, one moment..." />
</template>

<script>
import jwtDecode from 'jwt-decode'
import LoadingView from '@/components/app/FullScreenLoader'
import ehubUtil from '@/utils/ehub'
import endPoint from '@/store/endPoint'
import { API_ERROR_SUBTYPES, CATCHMENT_LEVELS } from '@/constants'
import { getEhubPrefillFlags } from '@/applicationDefinition/helper.js'

export default {
  name: 'EHubAcceptEoiOffer',
  components: { LoadingView },
  mounted() {
    this.acceptEoiOffer()
  },
  data() {
    return {
      loading: true,
      defaultIntendedStartDate: null
    }
  },
  computed: {
    termDates() {
      return this.$store.state?.termDates
    }
  },
  methods: {
    async getOfferedEoiApplication(idToken, schoolCode, eoiId) {
      let eoiApp = null
      const eoiOfferResponse = await endPoint.getY67TOffers(idToken, schoolCode)
      const studentApplications =
        eoiOfferResponse?.data?.studentApplications || []
      if (
        Array.isArray(studentApplications) &&
        studentApplications.length > 0
      ) {
        eoiApp = studentApplications.find((app) => {
          if (app && app.application) {
            const application = app.application
            if (application.eoiID && application.eoiID === eoiId) {
              return true
            }
          }
          return false
        })
      }
      return eoiApp
    },
    getDefaultIntendedStartDate() {
      this.$store
        .dispatch('getTermDates')
        .then(() => {
          if (this.termDates) {
            const calendarYear = ehubUtil.getY67tEoiCalendarYear()
            const termOne = `Term One (${calendarYear})`
            const startTerm = this.termDates.find(
              (term) => term.code === termOne && term.calendarLateInd === false
            )
            this.defaultIntendedStartDate = startTerm ? startTerm.value : null
          }
        })
        .catch((e) => {
          this.showError(e)
        })
    },
    showError(error) {
      this.$store.dispatch('set', ['error', error])
      this.$router.history.push('/error')
    },
    getCatchmentLevel(school) {
      const catchmentLevelSecondary = CATCHMENT_LEVELS[1].value
      let result = catchmentLevelSecondary
      if (school) {
        const schoolCatchmentLevel =
          school['catchment_level'] || school['catchmentLevel']
        if (schoolCatchmentLevel && schoolCatchmentLevel !== 'central') {
          result = schoolCatchmentLevel
        } else {
          result = catchmentLevelSecondary
        }
      }
      return result
    },
    async acceptEoiOffer() {
      const { idToken, refreshToken, eoiOffer, pidLinkedChildren } =
        ehubUtil.getEoiOfferData()
      if (!idToken || !refreshToken || !eoiOffer) {
        this.$router.history.push('/error')
      }
      this.$store.commit('set', ['auth.isUserActive', true])
      this.$store.commit('set', ['auth.isUserActiveTime', new Date().getTime()])

      const decodedToken = jwtDecode(idToken)
      const email = decodedToken['email']
      const parentId = decodedToken['custom:pid']
      this.setStore(idToken, refreshToken, email)

      const schoolCode = eoiOffer?.schoolCode || ''
      const eoiId = eoiOffer?.eoiID || ''
      if (!schoolCode || !eoiId) {
        this.$router.history.push('/error')
      }

      try {
        this.getDefaultIntendedStartDate()
        const eoiApplicationRecord = await this.getOfferedEoiApplication(
          idToken,
          schoolCode,
          eoiId
        )

        if (eoiApplicationRecord) {
          let ernData = null
          let isPidLinkedChildren = null

          const eoiApplication = eoiApplicationRecord?.application || null
          const studentSrn = eoiApplication?.student?.srn

          // get the linked student data from ERN by srn
          if (
            Array.isArray(pidLinkedChildren) &&
            pidLinkedChildren.length > 0
          ) {
            isPidLinkedChildren = pidLinkedChildren.find(
              (child) => child.srn && child.srn === studentSrn
            )
          }
          if (parentId && !!isPidLinkedChildren) {
            // only stamped parent will do the ERN data prefilling, because only stamped parent will have court order.
            const ernResponse = await endPoint.getErnRecordBySRN(
              idToken,
              studentSrn
            )
            if (ernResponse && ernResponse.data) {
              ernData = ernResponse.data?.body
            }
          }

          this.loading = false
          await this.setAppData(
            schoolCode,
            eoiApplication,
            ernData,
            parentId,
            email,
            isPidLinkedChildren
          )
          this.startApp()
        } else {
          this.$router.history.push('/error')
        }
      } catch (error) {
        this.$store.dispatch('setAPIError', {
          error,
          fallbackCode: API_ERROR_SUBTYPES.getApplication
        })
        this.$router.history.push('/error')
      }
    },
    setStore(idToken, refreshToken, email) {
      this.$store.commit('set', ['auth.idToken', idToken])
      this.$store.commit('set', ['auth.idTokenTime', new Date().getTime()])
      this.$store.commit('set', ['auth.refreshToken', refreshToken])
      this.$store.commit('set', ['auth.userId', email])
      this.$store.commit('set', ['resumeViaEmail', true])
    },
    buildParentCarer(eoiParent) {
      return {
        parentCarerEmail: '',
        contactDetails: [],
        parentCarerRelation: eoiParent?.parentCarerRelation,
        parentCarerTitle: eoiParent?.parentCarerTitle,
        parentCarerFamilyName: eoiParent?.parentCarerFamilyName,
        parentCarerGivenName: eoiParent?.parentCarerGivenName,
        meta: {
          isCompletingApplication: eoiParent?.isCompletingApplication
        },
        residentialAddress: null
      }
    },
    prefillParentCarersFromEoiApp(
      eoiParentCarers,
      email,
      parentId,
      isPidLinkedChildren
    ) {
      const prefilledParentCarers = []
      if (Array.isArray(eoiParentCarers) && eoiParentCarers.length > 0) {
        eoiParentCarers.forEach((eoiParent) => {
          if (eoiParent) {
            const parentCarer = this.buildParentCarer(eoiParent)
            parentCarer.parentCarerEmail = email
            if (parentId && isPidLinkedChildren) {
              parentCarer.parentId = parentId
            }
            if (
              Array.isArray(eoiParent.contactDetails) &&
              eoiParent.contactDetails.length > 0
            ) {
              parentCarer.contactDetails = eoiParent.contactDetails.filter(
                (c) => c.contactType !== 'EMAIL' // filter out the contact details
              )
            }
            prefilledParentCarers.push(parentCarer)
          }
        })
      }
      return prefilledParentCarers
    },
    setApplicationByErnData(application, ernData) {
      if (
        Array.isArray(application.parentCarers) &&
        application.parentCarers.length
      ) {
        const firstParent = application.parentCarers[0]
        const parentCarerFromErn = ernData.parentCarer
        if (firstParent && parentCarerFromErn) {
          firstParent.parentCarerCountryBirth = parentCarerFromErn.countryBirth
          firstParent.isParentCarerAboriOrTorres =
            parentCarerFromErn.isAboriOrTorres
          firstParent.parentCarerOccupationName =
            parentCarerFromErn.occupationName
          firstParent.parentCarerOccupationGroup =
            parentCarerFromErn.occupationGroup
          firstParent.parentCarerSchoolEducationCode =
            parentCarerFromErn.schoolEducationCode
          firstParent.parentCarerEducationQualificationCode =
            parentCarerFromErn.educationQualificationCode
          firstParent.isParentCarerSpeakLangOtherEnglish =
            parentCarerFromErn.speakLanguageOtherThanEnglish
          firstParent.parentCarerMainLanguage =
            parentCarerFromErn.mainLangOtherThanEnglish
          firstParent.parentCarerSpeakLang = parentCarerFromErn.otherLanguage
          firstParent.parentCarerGenderCode = parentCarerFromErn.gender
          firstParent.isEnrolmentOwner =
            parentCarerFromErn.isEnrolmentOwner &&
            parentCarerFromErn.liveOnResidentialAddress
        }
      }
      // prefill with ern student data
      const studentFromErn = ernData.student
      if (studentFromErn) {
        if (!application.student) {
          application.student = {}
        }
        application.student.countryBorn = studentFromErn.countryBorn
        application.student.isAboriTorStraitIslander =
          studentFromErn.isAboriTorStraitIslander
        application.student.homeLangOtherThanEnglish =
          studentFromErn.homeLangOtherThanEnglish
        application.student.mainLanguageOtherThanEnglish =
          studentFromErn.mainLangOtherThanEnglish
        application.student.otherLanguage = studentFromErn.otherLanguage
        application.dateArriveAustralia = studentFromErn.arrivedInAustrliaDate
        application.residencyStatus = studentFromErn.residencyStatus
        application.visaSubClass = studentFromErn.visaSubclassCode
      }
      return application
    },
    async setAppData(
      schoolCode,
      eoiApplication,
      ernData,
      parentId,
      email,
      isPidLinkedChildren
    ) {
      const school = await this.$store.dispatch('getSchool', String(schoolCode))
      const catchmentLevel = this.getCatchmentLevel(school)
      const student = eoiApplication?.student
      const studentSrn = eoiApplication?.student?.srn
      // for unstamped parents, isEhubLinkedStudent should be false, this will decide siblings/supplementaryDetails sections
      const isEhubLinkedStudent = !!(
        studentSrn &&
        studentSrn !== 'new' &&
        parentId
      )
      const calendarYear = ehubUtil.getY67tEoiCalendarYear()
      const intendedStartDate = this.defaultIntendedStartDate
      const prefilledParentCarers = this.prefillParentCarersFromEoiApp(
        eoiApplication.parentCarers,
        email,
        parentId,
        isPidLinkedChildren
      )

      let application = {
        catchmentLevel,
        intendedStartDate,
        meta: {
          calendarYear,
          isEhubLinkedStudent,
          viaEhub: true,
          parentId,
          studentSrn
        },
        residentialAddress: eoiApplication?.residentialAddress,
        scholasticYear: eoiApplication?.scholasticYear,
        schoolCode: school?.schoolCode,
        schoolName: school?.schoolName,
        student,
        isEhubLinkedStudent,
        isInArea: eoiApplication?.isInArea,
        viaEhub: true,
        eoiID: eoiApplication?.eoiID,
        prevAttendSchool: eoiApplication?.prevAttendSchool,
        prevSchoolId: eoiApplication?.prevSchoolId,
        prevSchoolName: eoiApplication?.prevSchoolName,
        prevSchoolType: eoiApplication?.prevSchoolType,
        dateReceived: eoiApplication?.dateReceived,
        parentCarers: prefilledParentCarers,
        hasAnySiblings: eoiApplication?.hasAnySiblings,
        siblings: eoiApplication?.hasAnySiblings
          ? eoiApplication?.siblings
          : null
      }
      application.meta.prefillFlags = getEhubPrefillFlags(application)
      // if the student is a linked student, populate extra fields with ERN data
      if (ernData) {
        application = this.setApplicationByErnData(application, ernData)
      }
      if (isEhubLinkedStudent) {
        //Google Analytics
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'Prefill Details',
          action: 'form start',
          label: 'student and parent',
          application: ehubUtil.getGtmApplication()
        })
      }
      this.$store.commit('set', ['isNewApplication', true])
      this.$store.commit('set', ['application', application])
      this.$store.commit('set', ['school', school])
      return application
    },
    startApp() {
      this.$store
        .dispatch('cancelApplication')
        .then(() => {
          this.$store
            .dispatch('createApplication')
            .then(() => {
              //Google Analytics
              this.$gtm.trackEvent({
                event: 'interaction',
                category: 'Form Attempt',
                action: 'start',
                label: 'eoiconverted',
                application: ehubUtil.getGtmApplication()
              })
              this.$router.history.push('/application')
            })
            .catch((error) => {
              this.$store.dispatch('hideSpinner')
              this.$store.dispatch('setAPIError', {
                error,
                fallbackCode: API_ERROR_SUBTYPES.getApplication
              })
              this.$router.history.push('/error')
            })
        })
        .catch((error) => {
          //handle API errors
          this.$store.dispatch('setAPIError', {
            error,
            fallbackCode: API_ERROR_SUBTYPES.cancelApplication
          })
          this.$router.history.push('/error')
        })
    }
  }
}
</script>
