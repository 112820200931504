import { FIELD_TYPE } from '@/constants'

const categories = [
  { name: 'studentIdentity', base: {} },
  {
    name: 'residentialAddress',
    base: {
      additionalInfo: {
        linkText: `Additional information`,
        componentName: 'ModalResidentialAddress'
      }
    }
  },
  { name: 'immunisation', base: {} },
  { name: 'travel', base: {} },
  { name: 'medical', base: {} },
  { name: 'disability', base: {} },
  { name: 'familyLaw', base: {} }
]

export function generateDocumentField(categoryName) {
  return {
    vHtmlLabel() {
      const config = this.getSupportDocCategoryConfig(categoryName)
      return config && config.label
    },
    vHtmlDescription() {
      const config = this.getSupportDocCategoryConfig(categoryName)
      return config && config.description
    },
    category: categoryName,
    apiKey: `supportingDocuments.${categoryName}.includeFiles`,
    fields: [
      {
        apiKey: `supportingDocuments.${categoryName}.files`,
        label: 'Upload File(s)',
        required(application) {
          return (
            application.supportingDocuments[categoryName].includeFiles &&
            'Please upload files under this category or uncheck the checkbox.'
          )
        },
        accept() {
          const config = this.getSupportDocCategoryConfig(categoryName)
          return config && config.supportedMIMETypes
        },
        category: categoryName,
        sizeLimitMB() {
          const config = this.getSupportDocCategoryConfig(categoryName)
          return config && config.sizeLimitMB
        },
        fileLimit() {
          const config = this.getSupportDocCategoryConfig(categoryName)
          return config && config.fileLimit
        },
        vHtmlDescription() {
          const config = this.getSupportDocCategoryConfig(categoryName)
          return (
            config &&
            config.helpText &&
            `<span class="grey--text text--lighten-2">${config.helpText}<span>`
          )
        },
        placeholder: 'Attach files',
        type: FIELD_TYPE.DOCUMENTS
      }
    ]
  }
}

export default [
  {
    label: 'Supporting documents',
    vHtmlDescription:
      '<p aria-label="description" role="heading" tabindex="0">Supplying documents here will help the school to progress your application and to prepare for the student’s needs. After completing the online application, you may also need to visit the school to provide further documentation to progress the application. The school will contact you to confirm which original documents must be sighted to complete this application process.</p>' +
      '<p>Learn more about how we <a href="https://education.nsw.gov.au/about-us/rights-and-accountability/privacy/privacy-information-and-forms" class="link-outline" target="_blank" rel="noopener">manage and protect your personal information</a>.</p>',
    type: FIELD_TYPE.HEADING
  },
  {
    type: FIELD_TYPE.PANELS,
    panels: categories.map((category) => ({
      ...category.base,
      ...generateDocumentField(category.name)
    }))
  }
]
