export const STATES = [
  { value: '', text: 'Select' },
  { value: 'ACT', text: 'Australian Capital Territory' },
  { value: 'NSW', text: 'New South Wales' },
  { value: 'NT', text: 'Northern Territory' },
  { value: 'QLD', text: 'Queensland' },
  { value: 'SA', text: 'South Australia' },
  { value: 'TAS', text: 'Tasmania' },
  { value: 'VIC', text: 'Victoria' },
  { value: 'WA', text: 'Western Australia' }
]
