class AuthPersist {
  constructor(appStateToPersist = () => ({}), storagePrefix = 'auth') {
    this.storagePrefix = storagePrefix
    this.appStateToPersist = appStateToPersist
  }

  _storageKey(key) {
    return this.storagePrefix ? `${this.storagePrefix}.${key}` : key
  }

  _persist(key, value) {
    sessionStorage.setItem(this._storageKey(key), JSON.stringify(value))
  }

  _get(key) {
    return JSON.parse(sessionStorage.getItem(this._storageKey(key)))
  }

  _remove(key) {
    return sessionStorage.removeItem(this._storageKey(key))
  }

  setAuthValues(secret, authProvider) {
    this._persist('secret', secret)
    this._persist('authProvider', authProvider)
    Object.entries(this.appStateToPersist()).map(([key, value]) =>
      this._persist(key, value)
    )
  }

  getAuthValues() {
    return Object.keys(this.appStateToPersist()).reduce(
      (authVals, key) => {
        return { ...authVals, [key]: this._get(key) }
      },
      {
        secret: this._get('secret'),
        code: this._get('code'),
        authProvider: this._get('authProvider')
      }
    )
  }

  removeAuthValues() {
    this._remove('code')
    this._remove('secret')
    this._remove('authProvider')
    Object.keys(this.appStateToPersist()).map((key) => this._remove(key))
  }
}

export default AuthPersist
