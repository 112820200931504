<template>
  <div class="actions">
    <b-button
      variant="primary"
      @click="startNewApplication"
      data-testid="start-new-application-button"
    >
      <font-awesome-icon :icon="['fas', 'plus-circle']" /> Start a new
      application
    </b-button>
    <span><strong> OR </strong></span>
    <b-button
      variant="outline-primary"
      href="https://my.education.nsw.gov.au/school-finder"
      data-testid="school-finder-button"
    >
      <font-awesome-icon :icon="['fas', 'map-marker-alt']" /> Go to School
      finder
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'RestartAppActions',
  props: {
    schoolCode: {
      type: String,
      required: true
    },
    catchmentLevel: {
      type: String,
      required: true
    }
  },
  methods: {
    startNewApplication() {
      this.$store.dispatch('resetError')
      this.$router.history.push({
        name: 'WelcomeView',
        query: {
          schoolCode: this.schoolCode,
          catchmentLevel: this.catchmentLevel
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.actions {
  margin-top: 3rem;
  span {
    font-size: 2rem;
    margin: 1rem;
  }
  button {
    margin: 1rem 0;
  }
}
</style>
