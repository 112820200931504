<template>
  <div class="SchoolReminderView">
    <a :href="school.website" target="_blank">
      <ApplicationBanner
        :logo="school.logo"
        :content="
          '<h1>' +
          school.schoolName +
          '</h1><h3>School Enrolment Application</h3>'
        "
        :show-logo="true"
      />
    </a>
    <b-container class="SchoolReminderView__content">
      <LocalSchoolReminderCard v-if="hasLocalSchools" />
      <OOAReminderCard />
    </b-container>
  </div>
</template>

<script>
import ApplicationBanner from '@/components/application/ApplicationBanner.vue'
import LocalSchoolReminderCard from '@/components/app/LocalSchoolReminderCard'
import OOAReminderCard from '@/components/app/OOAReminderCard'

export default {
  name: 'SchoolReminderView',
  components: {
    ApplicationBanner,
    LocalSchoolReminderCard,
    OOAReminderCard
  },
  computed: {
    school() {
      return this.$store.state.school
    },
    hasLocalSchools() {
      return this.$store.state.localSchools.length > 0
    }
  },
  mounted() {
    const address =
      this.$store.state.application &&
      this.$store.state.application.residentialAddress
    if (!this.school) {
      this.$store.dispatch('set', ['error', new Error(`No school`)])
      this.$router.history.push('/error')
    } else if (this.$store.state.inCatchment !== false || !address) {
      this.$router.history.push(
        `/?schoolCode=${this.school.schoolCode}&catchmentLevel=${this.school.catchmentLevel}`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.ApplicationBanner {
  margin-bottom: 120px;
}

.OOASchoolReminderCard {
  margin-top: 3.5rem;
}

::v-deep .SchoolInfoPanels,
::v-deep .SchoolInfoCard {
  margin-top: 3.5rem;
}

::v-deep .v-expansion-panel-content {
  font-weight: 500;
}

::v-deep .local-school__apply-button {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
}

@include desktop {
  .ApplicationBanner {
    height: 218px;
  }
}

@include tablet-wide {
  .ApplicationBanner {
    height: 218px;
  }
}

@include tablet {
  .ApplicationBanner {
    height: 122px;
    max-height: 122px;
  }
}
</style>
