<template>
  <section class="ApplicationReviewSection">
    <v-btn
      type="button"
      depressed
      class="primary lighten-4"
      @click.stop="editSection(section)"
    >
      <v-icon color="primary" class="pr-1">mdi-pencil-outline</v-icon>
      <span class="text--primary text-h5 font-weight-medium">Edit section</span>
    </v-btn>

    <div v-if="section.id === 'supplementaryDetails'">
      &nbsp;
      <p
        aria-label="description"
        role="heading"
        tabindex="0"
        style="color: #002040; font-weight: 800; font-size: 1.6rem"
      >
        Special Religious Education and Special Education in Ethics
        participation
      </p>
    </div>

    <div :id="section.id + '-fields'" class="fields">
      <ApplicationReviewRow
        v-for="field in section.fields"
        :key="field.apiKey"
        :section="section"
        :field="field"
        :alerts="alerts"
      />
    </div>
  </section>
</template>

<script>
import ApplicationReviewRow from '@/components/application/ApplicationReviewRow.vue'

export default {
  name: 'ApplicationReviewSection',
  components: {
    ApplicationReviewRow
  },
  props: {
    section: {
      type: Object
    },
    alerts: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    application() {
      return this.$store.state.application || {}
    },
    alertBySection() {
      return this.alerts.filter((item) => item.section.id === this.section.id)
    },
    sectionToFocus() {
      return this.$store.state.focusID === this.section.id
    }
  },
  methods: {
    editSection(section) {
      this.$store.dispatch('set', [
        'application.meta.sectionExpandedId',
        section.id
      ])

      this.$scrollTo('#app', 1)

      this.$store.commit('set', ['auth.isUserActive', true])
      this.$store.commit('set', ['auth.isUserActiveTime', new Date().getTime()])

      this.$gtm.trackView('SectionView', `/section/${section.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.ApplicationReviewSection {
  padding-top: $content-padding * 1.25;
  padding-bottom: $content-padding * 1.25;

  .sectionHeader {
    cursor: pointer;
    display: flex;
    align-items: center;

    > h3 {
      display: inline-block;
      margin: 0;
      font-weight: $text-normal;
      font-size: 2rem;
      vertical-align: middle;
      flex: 1 1 auto;

      .icon--square {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        border-radius: 3px;
        font-weight: 700;
        font-size: 2rem;
        text-align: center;
        line-height: 30px;
      }

      > button {
        @include button-reset;

        display: flex;
        align-items: center;
        .icon {
          margin-left: 1rem;
        }
        &.error {
          color: $color-red;
          background-color: transparent !important;
        }
      }
    }

    > button {
      margin: 0;
      height: 30px;
      border: none;
      font-weight: 300;
      font-size: 1.6rem;
      margin-left: auto;
      flex: 0 0 120px;
    }
  }
}
@include mobile {
  .ApplicationReviewSection {
    .sectionHeader {
      h3 {
        width: 170px;
        button {
          text-align: left;
        }
      }
    }
  }
}

@include iphone-5 {
  .ApplicationReviewSection {
    .sectionHeader {
      h3 {
        width: 123px;
        button {
          font-size: 15px;
        }
      }
      > button {
        font-size: 15px;
      }
    }
  }
}
</style>
