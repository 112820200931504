export default [
  {
    code: 'ACT',
    name: 'Australian Capital Territory'
  },
  {
    code: 'NSW',
    name: 'New South Wales'
  },
  {
    code: 'NT',
    name: 'Northern Territory'
  },
  {
    code: 'OTH',
    name: 'Other Territories'
  },
  {
    code: 'OVS',
    name: 'Overseas'
  },
  {
    code: 'QLD',
    name: 'Queensland'
  },
  {
    code: 'SA',
    name: 'South Australia'
  },
  {
    code: 'TAS',
    name: 'Tasmania'
  },
  {
    code: 'VIC',
    name: 'Victoria'
  },
  {
    code: 'WA',
    name: 'Western Australia'
  },
  {
    code: 'XXX',
    name: 'Unknown/Not Provided'
  },
  {
    code: 'YYY',
    name: 'Other/Not Applicable'
  },
  {
    code: 'ZZZ',
    name: 'Not Stated (intentionally)'
  }
]
