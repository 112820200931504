<template>
  <div class="Page">
    <b-container class="content">
      <b-row>
        <b-col>
          <p>
            <strong
              >As part of the NSW Department of Education's online enrolment
              process we need your consent to collect and store your personal
              information. Before consenting, please ensure you read the
              following:</strong
            >
          </p>
          <h2>
            <strong
              >Why do we collect information about your child for enrolment and
              how do we store it?</strong
            >
          </h2>
          <p>
            The personal information collected on this application is for
            purposes directly related to your child's education including
            processing this application.
          </p>
          <p>
            Any information provided to the NSW Department of Education (the
            Department) will be used, disclosed and stored consistent with the
            NSW privacy laws.
          </p>
          <p>
            Certain information is required by the Department to meet its duty
            of care and other legal obligations under public health, education
            and child protection legislation and for meeting data collection and
            reporting requirements under Commonwealth – State funding agreements
            which may involve evaluation and assessment of student outcomes.
          </p>
          <p>
            Information may be disclosed to NSW State and Commonwealth
            government agencies and other organisations for the above purposes
            and as authorised or required by law.
          </p>
          <p>
            Information will be stored on a secure electronic database. You may
            access or correct the information by contacting your child's school.
            If you have a concern or complaint about the information collected
            or how it has been used or disclosed you should contact the school.
          </p>
          <p>
            If you choose not to provide some requested information it may have
            a detrimental impact on your child's enrolment, resourcing of the
            school or meeting your child's educational needs.
          </p>
          <p>
            Further information about the collection of information while your
            child is enrolled at a NSW Government school, and how we protect
            your privacy is available on the Department's website or from your
            school.
          </p>
          <p>
            After submitting your application, if you need to access or correct
            any personal or health information submitted you should contact the
            relevant school that you have applied for enrolment at.
          </p>
          <p>
            Further details setting out how the Department will treat the
            personal information and health information submitted as part of
            school enrolment processes (including permitted use and disclosure)
            is contained in:
          </p>

          <ul>
            <li>
              <a
                :href="`${privacyBaseUrl}/collection-notice.pdf`"
                target="_blank"
                >Data Collection Notice</a
              >
            </li>
            <li>
              <a :href="`${privacyBaseUrl}/privacy_code.pdf`" target="_blank"
                >Privacy Code of Practice</a
              >
            </li>
            <li>
              <a
                :href="`${privacyBaseUrl}/privacy-management-plan-march-2014.pdf`"
                target="_blank"
                >Privacy Management Plan</a
              >
            </li>
          </ul>

          <h2>
            <strong
              >What are we doing with information you provide to us
              online?</strong
            >
          </h2>
          <p>
            We will be collecting and analysing online enrolment usage data to
            help make this a better product into the future, making it easier
            for parents to enrol their children in our schools.
          </p>
          <p>
            At the end of the application process you will be asked to declare
            the accuracy of the information and submit. On submitting your
            application you will receive an email containing a password
            protected copy of your application.
          </p>
          <p>
            {{ schoolName }} will also receive a copy of this email. When you
            contact the school, they will advise you about which documents you
            will need to bring with you to the school.
          </p>
        </b-col>
      </b-row>

      <!-- privacy policy acceptance -->
      <b-row>
        <b-col>
          <b-card class="card--grey">
            <h2>Please confirm you accept this Privacy Agreement</h2>
            <p>
              If you do not agree, you will not be able to proceed with the
              online enrolment process. Don’t worry, you can still use existing
              enrolment processes to apply. You can find out more on our
              <a
                href="https://education.nsw.gov.au/public-schools/going-to-a-public-school"
                target="_blank"
                class="link-outline"
                >going to a public school website</a
              >.
            </p>
            <AppCheckbox
              value="No"
              true-value="Yes"
              false-value="No"
              label="I agree to the Privacy Agreement"
              @change="setPrivacyAgreement"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col md="3">
          <b-button variant="primary" :disabled="!isAgreed" @click="goLogin"
            >Continue</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AppCheckbox from '@/components/app/AppCheckbox.vue'

export default {
  name: 'PrivacyAgreementPage',
  components: { AppCheckbox },
  data() {
    return {
      isAgreed: false,
      privacyBaseUrl:
        'https://education.nsw.gov.au/content/dam/main-education/about-us/rights-and-accountability/media/documents/privacy'
    }
  },
  computed: {
    schoolName() {
      return this.$store.state.school && this.$store.state.school.schoolName
    }
  },
  beforeCreate() {
    // Sanity check: Schoolname can not be empty
    if (
      !this.$store.state.application ||
      !this.$store.state.application.schoolName
    ) {
      this.$store.dispatch('set', [
        'error',
        new Error('application.schoolName must be set')
      ])
      this.$router.history.push('/error')
    }
  },
  methods: {
    goLogin() {
      const isOoaApplication = this.$store.state.isOoaApplication
      const isY67TApplication = this.$store.state.isY67TApplication
      if (isOoaApplication || isY67TApplication) {
        this.$router.history.push('/application')
      } else {
        this.$router.history.push('/login/new')
      }
    },
    setPrivacyAgreement(e) {
      this.isAgreed = e === 'Yes'
    }
  }
}
</script>

<style lang="scss" scoped>
.Page {
  padding: 60px 0 20px;
  //owl selector to set consistent spaces between all html elements no matter how deeply they are nested, thanks to Heydon Pickering https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/
  * + * {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
  .content.container {
    padding: 40px 15px 0;
  }

  .content {
    margin: 0 auto;
    padding: 30px;
    text-align: left;

    h2 {
      padding: 2rem 0;
    }

    li {
      font-size: 1.6rem;
    }

    a {
      font-weight: bold;
    }
  }

  .fade-enter-active {
    transition: all 0.8s ease-in;
  }

  .fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter {
    transform: translateY(-5px);
  }

  .fade-leave-to {
    transform: translateY(5px);
  }

  .card {
    border: 2px solid $color-primary;
    border-radius: $card-border-radius;
  }

  .card--grey {
    background: $color-grey-5;
    color: $color-primary;
    h2 {
      padding: 0;
    }
  }

  .card-body {
    padding: 2.3rem;
  }

  &::v-deep .AppCheckbox {
    font-weight: bold;
    input {
      left: 0;
    }
    span:before,
    span:after {
      left: 0;
    }
  }

  .btn {
    width: 100%;
    margin-left: 0;
    &:disabled {
      background: $color-grey-60;
      color: $color-white;
      border-color: $color-grey-60;
      cursor: not-allowed;
    }
  }

  .link-outline {
    &:focus {
      outline-style: auto;
    }
  }
}
</style>
