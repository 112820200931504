<!-- Content with an image on the left, heading and text on the right. -->
<template>
  <b-row
    class="d-flex align-start pa-6 content-box"
    :style="{ 'flex-wrap': $vuetify.breakpoint.xsOnly ? 'wrap' : 'nowrap' }"
    :class="{ 'justify-center': $vuetify.breakpoint.xsOnly }"
  >
    <slot name="img">
      <div :class="imageClass">
        <div
          v-if="imgFileName"
          class="content-img"
          :class="{ rightSpacing: $vuetify.breakpoint.smAndUp }"
        >
          <img
            v-if="imgFileName"
            :class="imageClass"
            :alt="imgAlt || title"
            :src="require(`@/assets/${imgFileName}`)"
          />
        </div>
        <v-avatar
          v-if="iconName"
          size="80"
          color="primary lighten-4"
          class="mr-6 mb-3"
        >
          <v-icon color="primary" :size="iconSize">
            {{ iconName }}
          </v-icon>
        </v-avatar>
      </div>
    </slot>

    <slot name="content">
      <div
        class="content-body"
        :class="{ 'text-center': $vuetify.breakpoint.xsOnly }"
      >
        <slot name="title">
          <h2 class="pb-2" v-if="title">{{ title }}</h2>
        </slot>

        <slot name="copyContent">
          <p>{{ content }}</p>
        </slot>
      </div>
    </slot>
  </b-row>
</template>

<script>
export default {
  name: 'ContentBox',
  props: {
    title: {
      type: String
    },
    content: {
      type: String
    },
    imgFileName: {
      type: String
    },
    imgAlt: {
      type: String
    },
    imageClass: {
      type: String
    },
    iconName: {
      type: String
    },
    iconSize: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  margin: 0px 13px;
  padding-bottom: 60px !important; //!important required to override !important on vuetify styles
}

.mobile .content-img {
  margin-bottom: 12px;
}
.content-img img {
  width: 70px;
  height: 70px;
}
.content-img img.largeImage {
  width: 88px;
  height: 88px;
}
.content-img.rightSpacing {
  margin-right: 40px;
}
.largeImage .content-img.rightSpacing {
  margin-right: 31px;
  margin-left: -9px;
}
.content-body {
  width: 100%;
}

@include desktop {
  .content-box {
    padding-right: 60px !important; //!important required to override !important on vuetify styles
  }
}
</style>
