<template>
  <div class="FieldCustom">
    <div v-html="field.html(application, this.$store)"></div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationFieldCustom',
  props: {
    field: {
      type: Object
    }
  },
  computed: {
    application() {
      return this.$store.state.application || {}
    }
  }
}
</script>

<style scoped lang="scss">
.FieldCustom {
}
</style>
