<!--
  Allow users to confirm the NSW public school catchment based on the auto complete address input by users
-->

<template>
  <b-form class="AppCatchmentFinder">
    <b-row>
      <b-col sm="12" lg="9" class="pb-3 pt-3">
        <b-row>
          <b-col sm="12" lg="6" class="pb-3 pt-3">
            <v-select
              class="AppCatchmentFinder__enrolmentYear"
              v-model="selectedYear"
              :items="enrolmentYears"
              :placeholder="selectedYear ? null : 'Please Select'"
              persistent-placeholder
              label="Enrolling for"
              @change="onDropdownChange"
              @keydown.native="ie11BackspaceFix"
              outlined
              hide-details
            />
          </b-col>
          <b-col
            v-if="schoolDataHasValidCatchmentLevel"
            cols="12"
            lg="6"
            class="pb-3 pt-3"
          >
            <v-select
              class="AppCatchmentFinder__scholasticYear"
              v-model="selectedScholasticYear"
              :items="scholasticYears"
              :placeholder="selectedScholasticYear ? null : 'Please Select'"
              persistent-placeholder
              label="Scholastic year"
              no-data-text="Select a calendar year first"
              @keydown.native="ie11BackspaceFix"
              outlined
              hide-details
            />
          </b-col>
          <b-col
            v-if="!schoolDataHasValidCatchmentLevel"
            sm="12"
            lg="6"
            class="pb-3 pt-3"
          >
            <v-select
              class="AppCatchmentFinder__catchmentLevel"
              v-model="selectedCatchment"
              persistent-placeholder
              :items="catchmentLevels"
              :placeholder="selectedCatchment ? null : 'Please Select'"
              label="School level"
              @change="onDropdownChange"
              @keydown.native="ie11BackspaceFix"
              outlined
              hide-details
            /> </b-col
        ></b-row>
      </b-col>
    </b-row>
    <b-row v-if="showScholasticYearForCentral">
      <b-col
        v-if="!schoolDataHasValidCatchmentLevel"
        cols="12"
        lg="9"
        class="mt-2 mb-2"
      >
        <div>
          <v-select
            class="AppCatchmentFinder__scholasticYear"
            v-model="selectedScholasticYear"
            :items="scholasticYears"
            :placeholder="selectedScholasticYear ? null : 'Please Select'"
            label="Scholastic year"
            no-data-text="Select a calendar year first"
            @keydown.native="ie11BackspaceFix"
            outlined
            hide-details
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="9" class="pb-3 pt-3">
        <b-input-group left="@">
          <label class="catchmentFinder__label" for="catchmentFinderTypeAhead">
            Student's residential address
          </label>
          <TypeAhead
            id="catchmentFinderTypeAhead"
            :value="inputAddress"
            placeholder="Enter student's address"
            uppercase="true"
            :options="optionsList"
            @input="onTypeAheadInput"
            @search="onTypeAheadSearch"
            @select="onTypeAheadSelect"
            @blur="onTypeAheadBlur"
            min-length="4"
          />
        </b-input-group>
        <div class="ValidationMessage" aria-live="polite">
          <transition name="slide-fade">
            <div v-if="isWithInArea" class="alert alert--success">
              <font-awesome-icon
                class="icon icon--success alert__icon"
                :icon="['fas', 'check-circle']"
              />
              <div class="alert__content">
                This student is within this school’s local area.
                <br />Press <strong>Apply to Enrol</strong> to continue.
              </div>
            </div>
            <div v-else-if="isOutOfArea" class="alert alert--warning">
              <font-awesome-icon
                class="icon icon--warning alert__icon"
                :icon="['fas', 'exclamation-circle']"
              />
              <div class="alert__content">
                {{ school.schoolName }} is not your local school. If you have
                chosen the wrong address, you can clear the field above and try
                again, or choose Proceed to see more enrolment options.
              </div>
            </div>
            <div v-else-if="isNoneFound" class="alert alert--danger">
              <font-awesome-icon
                class="icon icon--danger alert__icon"
                :icon="['fas', 'exclamation-circle']"
              />
              <div class="alert__content">
                We are unable to find your address. Don't worry, you can still
                use existing enrolment processes to apply. You can find out more
                on our
                <a
                  href="https://education.nsw.gov.au/public-schools/going-to-a-public-school"
                  target="_blank"
                  class="alert__link"
                  >going to a public school website</a
                >.
              </div>
            </div>
            <div v-else-if="isCatchmentValError" class="alert alert--danger">
              <font-awesome-icon
                class="icon icon--danger alert__icon"
                :icon="['fas', 'exclamation-circle']"
              />
              <div class="alert__content">
                Sorry, we unable to validate your address at the moment. Please
                try again later.
              </div>
            </div>
            <div v-else-if="isLookupNotResponding" class="alert alert--danger">
              <font-awesome-icon
                class="icon icon--danger alert__icon"
                :icon="['fas', 'exclamation-circle']"
              />
              <div class="alert__content">
                Sorry, we can’t retrieve your address at the moment. Please try
                again later.
              </div>
            </div>
          </transition>
        </div>
      </b-col>
      <b-col sm="12" lg="3" class="pb-3 pt-3">
        <b-button
          :disabled="isButtonDisabled"
          class="btn-search"
          type="button"
          variant="primary"
          @click="onClick"
        >
          <font-awesome-icon
            v-if="isConfirmingCatchment"
            class="icon spin"
            icon="circle-notch"
            spin
          />
          {{ buttonText }}</b-button
        >
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import TypeAhead from '@/components/app/TypeAhead.vue'
import {
  ADDRESS_STATE,
  ADDRESS_VALIDATION,
  CATCHMENT_LEVELS,
  SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL
} from '@/constants'
import endPoint from '@/store/endPoint'
import UTILS from '@/store/utils'

import { getAddressLine1 } from '@/applicationDefinition/helper'
import states from '@/applicationDefinition/enum/stateDetails'

import _uniqBy from 'lodash/uniqBy'
export default {
  name: 'AppCatchmentFinder',
  components: { TypeAhead },
  props: { school: Object, catchmentLevel: String, prefill: Object },
  data() {
    let preFillCatchmentLevel
    const catchmentLevelValues = CATCHMENT_LEVELS.map(
      (catchmentLevel) => catchmentLevel.value
    )
    const schoolDataHasValidCatchmentLevel = catchmentLevelValues.includes(
      this.school.initialCatchmentLevel
    )

    if (schoolDataHasValidCatchmentLevel) {
      //If the school's reference data has a valid catchment level to use, then use that
      preFillCatchmentLevel = this.school.catchmentLevel
    } else if (catchmentLevelValues.includes(this.catchmentLevel)) {
      //Else we need to select between the valid catchmentLevels, and we should pre-fill from the provided catchmentLevel (taken from URL) if it's valid
      preFillCatchmentLevel = this.catchmentLevel
    } else if (
      this.prefill &&
      catchmentLevelValues.includes(this.prefill.catchmentLevel)
    ) {
      // else prefill from prefill data provided (for central schools)
      preFillCatchmentLevel = this.prefill.catchmentLevel
    }

    // filter out infants school for the school level dropdown
    let catchmentLevelsWithoutInfantsSchool = CATCHMENT_LEVELS.filter(
      (cl) => cl.value !== 'infants'
    )

    let prefillYear, prefillAddress
    let preFillScholasticYear = null
    if (this.prefill) {
      const parsedYear = parseInt(this.prefill.year)
      prefillYear = isNaN(parsedYear) ? null : parsedYear
      prefillAddress = this.prefill.address
      preFillScholasticYear = this.prefill.scholasticYear
    }

    return {
      inputAddress: prefillAddress ? prefillAddress.address : '',
      inputValue: prefillAddress || '',
      optionsList: [],
      predictiveAddressList: [],
      addressState: ADDRESS_STATE.INITIAL,
      enrolmentYears: [],
      catchmentLevels: catchmentLevelsWithoutInfantsSchool,
      selectedYear: prefillYear,
      selectedCatchment: preFillCatchmentLevel,
      selectedScholasticYear: preFillScholasticYear,
      scholasticYears: [],
      schoolDataHasValidCatchmentLevel,
      selectedAddress: prefillAddress,
      queryYearLookupArray: [],
      isFutureCatchment: {
        primary: false,
        secondary: false,
        infants: false
      }
    }
  },
  computed: {
    isInitial() {
      return this.addressState === ADDRESS_STATE.INITIAL
    },
    isWithInArea() {
      return this.addressState === ADDRESS_STATE.WITHIN_AREA
    },
    isOutOfArea() {
      return this.addressState === ADDRESS_STATE.OUT_OF_AREA
    },
    isNoneFound() {
      return this.addressState === ADDRESS_STATE.NONE_FOUND
    },
    isInputtingAddress() {
      return this.addressState === ADDRESS_STATE.INPUTTING_ADDRESS
    },
    isConfirmingCatchment() {
      return this.addressState === ADDRESS_STATE.CONFIRMING_CATCHMENT
    },
    isCatchmentValError() {
      return this.addressState === ADDRESS_STATE.CATCHMENT_VAL_ERROR
    },
    isLookupNotResponding() {
      return this.addressState === ADDRESS_STATE.LOOKUP_NOT_RESPONDING
    },
    schoolFinderURLs() {
      const endPoints = this.$store.state.oesProperties.endpoints
      return endPoints && endPoints.schoolFinder && endPoints.schoolFinder.urls
    },
    oesSchools() {
      return this.$store.state.oesSchools
    },
    canVerifyAddress() {
      return this.selectedCatchment && this.selectedYear && this.selectedAddress
    },
    buttonText() {
      if (this.isConfirmingCatchment) {
        return 'Searching...'
      } else if (this.isWithInArea) {
        return 'Apply to Enrol'
      } else if (this.isOutOfArea) {
        return 'Proceed'
      } else {
        return 'Search'
      }
    },
    isButtonDisabled() {
      return (
        this.isInitial ||
        this.isNoneFound ||
        this.isConfirmingCatchment ||
        !this.selectedYear ||
        !this.selectedCatchment ||
        !this.selectedScholasticYear
      )
    },
    showScholasticYearForCentral() {
      return this.selectedYear != null && this.selectedCatchment != null
    }
  },
  watch: {
    selectedCatchment: {
      handler(newValue) {
        this.$store.dispatch('set', ['school.catchmentLevel', newValue])
      },
      immediate: true
    },
    schoolFinderURLs: {
      handler() {
        //If enrolmentYears aren't yet set and schoolfinderURLs are now set
        if (
          this.enrolmentYears.length === 0 &&
          this.schoolFinderURLs &&
          this.schoolFinderURLs.length > 0
        ) {
          this.getCatchmentAndYearData()
        }
      },
      immediate: true
    }
  },
  methods: {
    getQueryYear(selectedCatchment, selectedYear) {
      // Maps selectCatchment and selectedYear to the year we must send in the SQL request.
      return this.queryYearLookupArray.find(
        (obj) =>
          obj.catchmentLevel === selectedCatchment &&
          obj.calendarYear === selectedYear
      ).queryYear
    },

    getFallBackQueryYear(selectedCatchment, selectedYear) {
      // Maps selectCatchment and selectedYear to the year we must send in the SQL request.
      return this.queryYearLookupArray.find(
        (obj) =>
          obj.catchmentLevel === selectedCatchment &&
          obj.calendarYear === selectedYear
      ).fallbackQueryYear
    },
    generateQueryYearLookupArray(
      rows,
      uniqueCatchmentLevels,
      baseYear,
      nextYear
    ) {
      // There calendar_year variable we send to the catchment area SQL query will not always match up with the actual selected calendar year.
      // The actual sent value can vary based on the catchmentLevel selected and the results of the getCatchmentAndYearData request.
      // The year were send in the request (referred to as queryYear) will be based on the following logic:
      //                1. the actual calendarYear. Only if it has been specified as a value in the DB. OR
      //				2. 0. Only if 1. is not true and 0 is specified as a value in the DB. OR
      //				3. baseYear. Only if 1. and 2. are not true.
      // This function generates an array we can use to map the selectedYear and selectedCatchment to the year we send in the request; this mapping
      // is done in 'getQueryYear'
      let queryYearLookupArray = []
      uniqueCatchmentLevels.forEach((catchmentLevel) => {
        return [baseYear, nextYear].forEach((calendarYear) => {
          //Check if the actual year exists as a SQL year, if true, then use that
          const actualYearEntry = rows.find(
            ({ calendar_year, catchment_level }) =>
              calendar_year === calendarYear &&
              catchment_level === catchmentLevel
          )
          if (actualYearEntry) {
            queryYearLookupArray.push({
              catchmentLevel,
              calendarYear,
              queryYear: calendarYear
            })
            return
          }

          queryYearLookupArray.push({
            catchmentLevel,
            calendarYear,
            queryYear: 0,
            fallbackQueryYear: calendarYear - 1
          })
        })
      })
      this.queryYearLookupArray = queryYearLookupArray
    },
    setEnrolmentYears(year1, year2) {
      this.enrolmentYears = [
        {
          value: year1,
          text: `${year1} calendar year`
        },
        {
          value: year2,
          text: `${year2} calendar year`
        }
      ]
    },
    getCatchmentAndYearData() {
      endPoint
        .getCatchmentAndYearData(this.schoolFinderURLs, this.school.schoolCode)
        .then(({ data: { rows } }) => {
          if (rows.length === 1 && rows[0]['calendar_year'] === 0) {
            //handle 0 calendar year case
            let currentYear = new Date().getFullYear()
            this.enrolmentYears = [
              {
                value: currentYear + 1,
                text: `${currentYear + 1} calendar year`
              }
            ]

            this.queryYearLookupArray = [
              {
                catchmentLevel: rows[0]['catchment_level'],
                calendarYear: currentYear + 1,
                queryYear: 0,
                fallbackQueryYear: currentYear
              }
            ]
          } else {
            //Get all calendar years and catchment levels
            const calendarYears = rows.map((row) => row.calendar_year)
            const catchmentLevels = rows.map((row) => row.catchment_level)

            //Remove the duplicates and sort years
            const uniqueCalendarYears = Array.from(
              new Set(calendarYears)
            ).sort()
            const uniqueCatchmentLevels = Array.from(new Set(catchmentLevels))

            for (let i = 0; i < uniqueCatchmentLevels.length; i++) {
              const cl = uniqueCatchmentLevels[i]
              let isFuture = rows.filter(
                (row) => row.catchment_level === cl && row.calendar_year === 0
              )
              this.isFutureCatchment[cl] =
                isFuture && isFuture.length > 0 ? true : false
            }

            //Take the base year as the lowest non-zero year
            const baseYear =
              uniqueCalendarYears[0] === 0
                ? uniqueCalendarYears[1]
                : uniqueCalendarYears[0]
            const nextYear = baseYear + 1

            //Set the 2 years to appear in the dropdown
            this.setEnrolmentYears(baseYear, nextYear)

            this.generateQueryYearLookupArray(
              rows,
              uniqueCatchmentLevels,
              baseYear,
              nextYear
            )
          }
        })
    },
    //https://stackoverflow.com/questions/56193666/why-when-you-click-outside-the-v-select-the-v-model-resets-to-null-vuetify
    ie11BackspaceFix(e) {
      if (e.key === 'Backspace') {
        e.preventDefault()
      }
    },
    onDropdownChange() {
      this.selectedScholasticYear = null
      this.scholasticYears = this.getScholasticYearsDropdown()

      if (this.canVerifyAddress) {
        this.verifyAddress()
      }
    },
    onClick() {
      if (
        this.isInputtingAddress &&
        this.selectedYear &&
        this.selectedCatchment
      ) {
        this.onManualSearch()
      } else if (this.isOutOfArea) {
        this.toReminderView()
      } else if (this.isWithInArea) {
        this.nextStep()
      } else if (
        this.selectedAddress &&
        this.selectedYear &&
        this.selectedCatchment
      ) {
        this.verifyAddress()
      }
    },
    onTypeAheadInput(val) {
      // Reset the UI if there's less than 3 characters entered
      if (val.length < 4) {
        this.optionsList = []
        this.addressState = ADDRESS_STATE.INITIAL
      } else {
        this.addressState = ADDRESS_STATE.INPUTTING_ADDRESS
      }

      this.inputValue = val
    },
    onTypeAheadSearch(val) {
      if (val.length >= 3) {
        // Send typed address to predictive API
        this.addressState = ADDRESS_STATE.CONFIRMING_CATCHMENT
        endPoint
          .getPredictiveAddresses(val)
          .then(this.resolvePredictiveAddresses)
          .catch(() => {
            this.addressState = ADDRESS_STATE.LOOKUP_NOT_RESPONDING
            return
          })
      }
    },
    verifyAddress(retry = 0) {
      this.addressState = ADDRESS_STATE.CONFIRMING_CATCHMENT

      //If the endpoints have not yet been retrieved and put in the store, we will retry up to 10 times over 5 seconds before failing
      if (!this.schoolFinderURLs) {
        if (retry < 10) {
          setTimeout(() => this.verifyAddress(++retry), 500)
          return
        }
        this.$store.dispatch('set', [
          'error',
          new Error('Could not identify endpoints for schoolFinder')
        ])
        this.$router.history.push('/error')
        return
      }

      // Send selected address to full address API
      endPoint
        .getFullAddressInfo(this.selectedAddress)
        .then(this.resolveFullAddressInfo)
        .then((resp) => {
          const coordinateData = resp.data.data.geo.geometry.coordinates

          const queryYear = this.getQueryYear(
            this.selectedCatchment,
            this.selectedYear
          )
          // if the normal query year returns no data, then use the fallBackQueryYear
          const fallbackQueryYear = this.getFallBackQueryYear(
            this.selectedCatchment,
            this.selectedYear
          )

          // Send coordinates to catchment API, which returns list of schools
          // within catchment.
          return endPoint
            .getLocalSchools(
              this.schoolFinderURLs,
              coordinateData[0],
              coordinateData[1],
              this.selectedCatchment,
              queryYear
            )
            .then((response) => {
              if (response.data.rows.length > 0 || !fallbackQueryYear) {
                return response
              } else {
                //if the 0 query year didn't return any results, call the api again using the fallbackQueryYear
                return endPoint.getLocalSchools(
                  this.schoolFinderURLs,
                  coordinateData[0],
                  coordinateData[1],
                  this.selectedCatchment,
                  fallbackQueryYear
                )
              }
            })
        })
        .then(this.resolveLocalSchools)
        .catch(() => {
          this.addressState = ADDRESS_STATE.CATCHMENT_VAL_ERROR
          return
        })
    },
    onTypeAheadSelect(selectedIndex) {
      if (selectedIndex === null) {
        this.optionsList = []
        this.addressState = ADDRESS_STATE.NONE_FOUND
        return
      }
      //DSE-2469: TypeAhead 'watch()es' inputAddress to update the visible text. However, if the same address is selected
      // then watch() won't see a change and the text won't update => clear inputAddress and set on nextTick.
      const selectedOption = this.optionsList[selectedIndex]
      this.selectedAddress = this.predictiveAddressList[selectedIndex]
      this.inputAddress = ''
      this.$nextTick(() => {
        this.inputAddress = selectedOption
      })

      this.optionsList = []
      this.addressState = ADDRESS_STATE.ADDRESS_SELECTED
      if (this.canVerifyAddress) {
        this.verifyAddress()
      }
    },
    resolvePredictiveAddresses(resp) {
      this.addressState = ADDRESS_STATE.INPUTTING_ADDRESS
      const addresses = _uniqBy(resp.data, (i) => i.address)

      // Sometimes the API returns something other than an array
      if (!Array.isArray(addresses)) {
        return
      }

      this.predictiveAddressList = addresses
      this.optionsList = addresses.map((item) => item.address)
    },
    resolveFullAddressInfo(resp) {
      const addressData = resp.data.data.addressDetails
      const stateData = states.find(
        (item) => item.code === addressData.stateTerritory
      )

      // Default country and state codes to `XXX` (unknown)
      let countryCode = 'XXX'
      let stateCode = 'XXX'
      let validFlag = ADDRESS_VALIDATION.UNKNOWN

      if (stateData) {
        countryCode = 'AUS'
        stateCode = stateData.code
        validFlag = ADDRESS_VALIDATION.VALID
      }

      // Set the primitive address object
      const address = {
        addressLine1: getAddressLine1(addressData),
        suburbName: addressData.localityName,
        // #DSE-685: postcode deliberately lower-case due to APIs
        postCode: addressData.postcode,
        stateCode: stateCode,
        countryCode: countryCode,
        validationFlag: validFlag
      }

      this.$store.dispatch('setInitialVitalData', {
        address: address,
        school: this.school,
        calendarYear: this.selectedYear,
        selectedScholasticYear: this.selectedScholasticYear
      })

      // Make response available to any following Promises
      return resp
    },
    resolveLocalSchools(resp) {
      const { rows } = resp.data

      // Sometimes the API returns something other than an array. For some
      // reason this has been historically ignored. Bail early if we do have an
      // array, but it's empty.
      if (!Array.isArray(rows) || rows.length === 0) {
        this.addressState = ADDRESS_STATE.OUT_OF_AREA
        this.$store.dispatch('set', ['inCatchment', false])
        this.$store.dispatch('set', ['localSchools', []])
        return
      }

      let localSchools = rows.map((schoolRow) => {
        let catchmentLevel = null

        const oesSchool = this.oesSchools.find(
          (school) => school.schoolCode === schoolRow.school_code.toString()
        )

        if (oesSchool) {
          if (oesSchool.catchmentLevel === 'central') {
            catchmentLevel = this.$store.state.school.catchmentLevel //If central use original schools catchmentLevel
          } else {
            catchmentLevel = oesSchool.catchmentLevel
          }
        }

        const suburb = UTILS.titleCase(schoolRow.town_suburb).trim()
        return {
          schoolCode: schoolRow.school_code,
          schoolName: schoolRow.school_name,
          email: schoolRow.school_email,
          website: `${schoolRow.website}`,
          phone: `(02) ${schoolRow.phone}`,
          address: `${schoolRow.street}, ${suburb}, NSW ${schoolRow.postcode}`,
          long: schoolRow.longitude,
          lat: schoolRow.latitude,
          oes: !!oesSchool,
          ooa: !!(oesSchool && oesSchool.ooa),
          catchmentLevel,
          calendar_year: schoolRow.calendar_year
        }
      })

      localSchools = localSchools.filter((lschool) => {
        if (
          lschool.schoolCode == this.school.schoolCode &&
          lschool.calendar_year !== 0 &&
          this.selectedYear != new Date().getFullYear() &&
          this.isFutureCatchment[this.selectedCatchment]
        ) {
          return false
        }
        return true
      })

      localSchools = localSchools.sort((ls1, ls2) => {
        if (ls1.schoolCode === ls2.schoolCode) {
          return ls1.calendar_year > ls2.calendar_year ? 1 : -1
        }
        return ls1.schoolCode > ls2.schoolCode ? 1 : -1
      })

      localSchools = localSchools.filter(
        (v, i, a) => a.findIndex((t) => t.schoolCode === v.schoolCode) === i
      )

      this.$store.dispatch('set', ['localSchools', localSchools])

      const withinCatchment = !!localSchools.find(
        (school) => school.schoolCode.toString() === this.school.schoolCode
      )

      if (withinCatchment) {
        this.addressState = ADDRESS_STATE.WITHIN_AREA
        this.$store.dispatch('set', ['inCatchment', true])
      } else {
        this.addressState = ADDRESS_STATE.OUT_OF_AREA
        this.$store.dispatch('set', ['inCatchment', false])
      }
    },
    onTypeAheadBlur(val) {
      if (val !== this.inputValue && this.isInputtingAddress) {
        this.addressState = ADDRESS_STATE.INITIAL
      }

      if (this.isInitial || this.isInputtingAddress) {
        this.inputAddress = this.inputValue
      }
    },
    onManualSearch() {
      // The button this method is attached to is being removed in v1.3/4
      window.setTimeout(() => {
        this.optionsList = []
        this.addressState = ADDRESS_STATE.NONE_FOUND
      }, 500)
    },
    nextStep() {
      this.$store.dispatch('set', ['isNewApplication', true])
      this.$store.dispatch('setScholasticYear', this.selectedScholasticYear)
      this.$scrollTo('#app', 1)
      this.$router.history.push('/pilot')
    },
    toReminderView() {
      this.$scrollTo('#app', 1)
      this.$router.history.push('/schoolreminder')
    },
    async getScholasticYears() {
      await this.$store
        .dispatch('getScholasticYearsBySchoolCode', this.school.schoolCode)
        .catch(() => {
          this.$router.push({ name: 'error' })
        })
    },
    getScholasticYearsDropdown() {
      if (!this.$store.state.scholasticYears || !this.selectedCatchment) {
        return []
      }
      const scholasticYears =
        this.$store.state.scholasticYears[this.selectedYear]
      const sYearsForCatchment =
        SCHOLASTIC_YEARS_BY_CATCHMENT_LEVEL[
          this.selectedCatchment.toUpperCase()
        ] || []
      return scholasticYears
        ? scholasticYears.filter((year) =>
            sYearsForCatchment.includes(year.value)
          )
        : []
    }
  },
  created() {
    this.getScholasticYears()
      .then(() => {
        this.scholasticYears = this.getScholasticYearsDropdown()
      })
      .catch(() => {
        this.$store.dispatch('set', [
          'error',
          new Error('Could not load scholastic years')
        ])
        this.$router.history.push('/error')
      })
  },
  mounted() {
    if (this.prefill && this.canVerifyAddress) {
      // validate the prefilled data
      this.verifyAddress()
    }
  }
}
</script>

<style scoped lang="scss">
.input-group {
  & ::v-deep .AppTypeAhead {
    width: 100%;
  }

  & ::v-deep .AppTypeAhead_input {
    width: 100%;
    margin-right: 0;
    @include vuetify-input;
  }
}

.btn-search {
  width: 100%;
  margin: 0;
  height: 5.6rem;
  padding: 0.9rem 1rem;
  &:disabled {
    border-color: $color-text-light;
    background-color: $color-text-light;
    cursor: not-allowed;
  }
}

.ValidationMessage {
  display: inline-block;
  padding: 1rem 0 0;
  .alert {
    padding: 1.25rem 0;
    font-weight: 500;
    font-size: 1.6rem;
    background: none;
    border: none;
  }

  .alert--success {
    color: $color-green-3;
  }

  .alert--danger {
    color: $color-red;
  }

  .alert--warning {
    color: $color-orange-2;
  }

  .alert__icon {
    display: inline-block;
    font-size: 3rem;
    margin: 0 1rem 0 0;
    vertical-align: top;
  }

  .alert__content {
    display: inline-block;
    width: calc(100% - 4rem);
  }

  .alert__link {
    color: $color-red;
    text-decoration: underline;
    transition: $basic-transition;

    &:hover {
      outline: none;
    }

    &:focus {
      outline-style: auto;
    }
  }
}

.catchmentFinder__label {
  @include vuetify-label;
}

.icon {
  &.spin {
    margin-right: 1rem;
  }
}
</style>
