// See README.md for more info about field configuration

import { FIELD_TYPE } from '@/constants'

import { YES_NO } from './droplistData/boolean'

import { getFirstName, getLabelName } from './helper'

export default [
  {
    label: 'Additional information',
    description(application) {
      const name = getFirstName(application)
      return getLabelName(
        'This section will help the school support any additional needs ',
        name,
        ' may have.',
        'the student'
      )
    },
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'anyAdditionalInforPriorEnrol',
    label(application) {
      const name = getFirstName(application)
      return getLabelName(
        'Is there any additional information about ',
        name,
        ' that the school should know prior to enrolment?',
        'the student'
      )
    },
    required: true,
    description: 'Additional information may include:',
    descriptionList: [
      'Living apart from parental supervision',
      'Subject of a court order',
      'Subject of bullying by others',
      'Living in out of home care arranged by the state'
    ],
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'anyAdditionalInforDetails',
    label: 'Provide details',
    placeHolder: 'Enter details',
    required: true,
    visible(application) {
      return application.anyAdditionalInforPriorEnrol === true
    },
    type: FIELD_TYPE.TEXTAREA
  }
]
