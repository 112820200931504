import { ABORIGINALITY } from './aboriginality'
import { GENDERCODE } from './genderCode'
import { LANGUAGES } from './languages'
import { SCHOLASTIC_YEARS } from './scholasticYears'
import { COUNTRIES } from './countries'
import { PRESCHOOLHOURSTYPE } from './preschoolHoursType'
import { PRESCHOOLTYPE } from './preschoolType'
import { RELATIONSHIPS } from './relationships'
import { RESIDENCYSTATUS } from './residencyStatus'
import { OCCUPATION_GROUPS } from './occupationGroups'
import { SCHOOL_EDUCATION } from './schoolEducation'
import { TERTIARY_EDUCATION } from './tertiaryEducation'
import { CONTACT_TYPES } from './contactTypes'
import CONDITION_ALLERGIES from './medicalConditionAllergies'
import CONDITION from './medicalConditionName'
import { SUSPENSIONS } from './suspensions'
import { DISABILITY } from './disability'
import { LEARNING_ADJUSTMENT } from './learningAdjustment'
import { PREVSCHOOLTYPE } from './prevSchoolType'
import { VISA_CLASSES } from './visaClasses'
import { TITLE } from './titles'

export default {
  ABORIGINALITY,
  GENDERCODE,
  LANGUAGES,
  SCHOLASTIC_YEARS,
  COUNTRIES,
  PRESCHOOLHOURSTYPE,
  PRESCHOOLTYPE,
  PREVSCHOOLTYPE,
  RELATIONSHIPS,
  RESIDENCYSTATUS,
  OCCUPATION_GROUPS,
  SCHOOL_EDUCATION,
  TERTIARY_EDUCATION,
  CONTACT_TYPES,
  CONDITION_ALLERGIES,
  CONDITION,
  SUSPENSIONS,
  DISABILITY,
  LEARNING_ADJUSTMENT,
  VISA_CLASSES,
  TITLE
}
