<template>
  <div>
    <ApplicationReviewCollection
      v-if="field.type === fieldType.COLLECTION"
      :section="section"
      :field="field"
      :alerts="alerts"
    />
    <ApplicationFieldReadOnly
      v-else-if="
        field.type === fieldType.GROUP && isVisible({ field, application })
      "
      v-for="childField in field.fields()"
      :key="childField.apiKey"
      :field="childField"
      :section="section"
      :alert="alertByApiKey(childField)"
    />
    <ApplicationReviewPanels
      v-else-if="field.type === fieldType.PANELS"
      :section="section"
      :alerts="alerts"
      :field="field"
    />
    <ApplicationFieldReadOnly
      v-else
      :field="field"
      :section="section"
      :alert="alertByApiKey(field)"
    />
  </div>
</template>

<script>
import ApplicationFieldReadOnly from '@/components/application/ApplicationFieldReadOnly.vue'
import ApplicationReviewCollection from '@/components/application/ApplicationReviewCollection.vue'
import ApplicationReviewPanels from '@/components/application/ApplicationReviewPanels.vue'
import ApplicationMixin from '@/components/mixins/application'
import fieldsMixin from '@/components/mixins/fieldsMixin'
import { FIELD_TYPE } from '@/constants'

export default {
  name: 'ApplicationReviewRow',
  props: {
    section: {
      type: Object
    },
    alerts: {
      type: Array,
      default: () => []
    },
    field: {
      type: Object
    }
  },
  mixins: [ApplicationMixin, fieldsMixin],
  components: {
    ApplicationReviewPanels,
    ApplicationFieldReadOnly,
    ApplicationReviewCollection
  },
  data() {
    return {
      fieldType: FIELD_TYPE
    }
  },
  methods: {
    alertByApiKey(field) {
      return this.alerts.find((alert) => alert.field.apiKey === field.apiKey)
    }
  }
}
</script>
