<template>
  <v-expansion-panels
    v-if="isVisible({ field, application })"
    class="ApplicationFieldPanels"
    accordion
    multiple
    readonly
    :value="visiblePanels"
  >
    <v-expansion-panel v-for="(panel, i) in field.panels" :key="i">
      <v-expansion-panel-header class="panel-header" hide-actions disabled>
        <template>
          <v-checkbox :value="i" v-model="visiblePanels" hide-details>
            <template #label>
              <h1 class="panel-header__title">
                <template v-if="panel.label">{{
                  getLabel(panel, application)
                }}</template>
                <span
                  class="jt-vhtml-label"
                  v-else-if="panel.vHtmlLabel"
                  v-html="getVHtmlLabel(panel, application)"
                ></span>
              </h1>
              <h2 class="panel-header__subtitle">
                <template v-if="panel.description">{{
                  getDescription(panel, application)
                }}</template>
                <span
                  class="jt-vhtml-description"
                  @click.stop=""
                  v-else-if="panel.vHtmlDescription"
                  v-html="getVHtmlDescription(panel, application)"
                ></span>
              </h2>
            </template>
          </v-checkbox>
        </template>
        <v-btn
          v-if="panel.additionalInfo"
          :aria-label="panel.additionalInfo.linkText"
          @click="openAdditionalInfo(panel)"
          class="panel-header__info-icon"
          icon
        >
          <v-icon color="primary">mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ApplicationField
          v-for="panelField in panel.fields"
          :section="section"
          :key="panelField.apiKey"
          :field="panelField"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ApplicationField from '@/components/application/ApplicationField.vue'
import fieldsMixin from '@/components/mixins/fieldsMixin'

export default {
  name: 'ApplicationFieldPanels',
  components: {
    ApplicationField
  },
  mixins: [fieldsMixin],
  props: {
    section: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  computed: {
    application() {
      return this.$store.state.application
    },
    visiblePanels: {
      get: function () {
        return this.field.panels.reduce(
          (indexArray, panel, index) =>
            this.getValue(panel) ? [...indexArray, index] : indexArray,
          []
        )
      },
      set: function (newValue) {
        const oldValue = this.visiblePanels
        let changedIndices
        let changedValue
        if (newValue.length > oldValue.length) {
          //New panel checked
          changedValue = true
          changedIndices = newValue.filter((index) => !oldValue.includes(index))
        } else if (oldValue.length > newValue.length) {
          //Old panel unchecked
          changedValue = false
          changedIndices = oldValue.filter((index) => !newValue.includes(index))
        }
        //NOTE: this should only ever be an array of length 1, but just in case we will do it for each and only if it exists.
        changedIndices &&
          changedIndices.forEach((index) => {
            this.setValue(this.section, this.field.panels[index], changedValue)
          })
      }
    }
  },
  methods: {
    openAdditionalInfo(panel) {
      this.$store.dispatch('openModal', panel.additionalInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-header {
  opacity: 1;
  padding-top: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;

  &__title,
  &__subtitle {
    color: $color-primary;
  }

  &__title {
    font-size: 2.2rem;
    font-weight: bold;
    margin-right: 2rem;
  }

  &__subtitle {
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__info-icon {
    flex: 0 0 auto;
    border: none;
    align-self: flex-start;
    margin: 0;
  }

  ::v-deep .v-input__slot {
    display: flex;
    align-items: flex-start;

    //Checkbox unchecked icon
    .mdi-checkbox-blank-outline {
      color: rgba(0, 0, 0, 0.54) !important;
    }

    //Checkbox label
    .v-label {
      margin-left: 2.5rem;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.v-expansion-panel-header {
  &--active {
    background-color: inherit;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }

  .jt-vhtml-description ::v-deep .link-outline {
    &:focus {
      outline-style: auto;
    }
  }
}

.v-expansion-panel-content {
  margin-left: 5.8rem;
  margin-right: 2.5rem;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0 14px 16px;
}

::v-deep .v-expansion-panel-content__wrap {
  padding-right: 16px;
}
</style>
